<template>
  <div class="info-tag-group">
    <InfoTag
      v-for="(category, index) in selectedServiceCategories"
      :key="index"
      :title="category.title"
      :subtitle="''"
      :icon-src="category.icon"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import InfoTag from "./InfoTag.vue";
import { ServiceCategory } from "@/stores/supplier/types";
import { useCmsStore } from "@/stores/cms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import cmsService from "@/services/cms/cmsService";
import { Icon } from "@/stores/cms/types";

interface AugmentedCategory extends ServiceCategory {
  title: string;
  icon: string;
  description: string;
}

const props = defineProps({
  serviceCategories: {
    type: Array as PropType<ServiceCategory[]>,
    default: () => [],
    required: true,
  },
});

const cmsStore = useCmsStore();

const serviceCms = computed(
  () => cmsStore[ContentType.SERVICE_TYPES_CMS] || [],
);

const selectedServiceCategories = computed((): AugmentedCategory[] => {
  const supplierServiceCategories = props.serviceCategories || [];

  return supplierServiceCategories
    .filter(
      (category): category is ServiceCategory => typeof category !== "number",
    )
    .map((category) => {
      const serviceCmsCategory = getServiceCmsCategory(category.categoryLabel);

      return {
        ...category,
        title: serviceCmsCategory?.title || category.categoryLabel,
        icon: serviceCmsCategory?.icons
          ? resolveIconUrl(serviceCmsCategory?.icons, "/images/Add.svg")
          : "/images/Add.svg",
        description: serviceCmsCategory?.description || "",
      };
    });
});

const getServiceCmsCategory = (categoryLabel: string) => {
  return serviceCms.value
    .flatMap((type) => type.serviceCategories)
    .find((category) => category?.label === categoryLabel);
};

const resolveIconUrl = (icons: Icon[], defaultImage: string): string => {
  const icon = icons?.[0]?.icon;
  return icon ? cmsService.resolveImage(icon).url() : defaultImage;
};
</script>

<style lang="scss" scoped>
.info-tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
