<template>
  <div class="once-or-more-per-month">
    <v-field
      v-slot="{ componentField, errors }"
      name="frequency"
      :rules="string().required()"
      :validate-on-input="true"
    >
      <div
        v-if="errors.length && frequencyCms.error"
        class="once-or-more-per-month__frequency-error"
      >
        <input-error :text="frequencyCms.error" />
      </div>
      <div class="once-or-more-per-month__frequency-select-wrapper">
        <base-select-input
          v-bind="componentField"
          :options="monthlyFrequencies"
          :placeholder="frequencyCms.placeholder"
        />
      </div>
    </v-field>
    <v-field v-slot="{ componentField }" name="limitedPeriod">
      <div class="once-or-more-per-month__limited-period-checkbox-wrapper">
        <base-checkbox-input
          v-bind="componentField"
          :label="limitedPeriodCms.title"
        />
      </div>
    </v-field>
    <div
      v-if="formValues.limitedPeriod"
      class="once-or-more-per-month__limited-period-months"
    >
      <div>
        <div class="once-or-more-per-month__start-month-select-label">
          {{ startMonthsCms.title }}
        </div>
        <div class="once-or-more-per-month__start-month-select-wrapper">
          <v-field v-slot="{ componentField }" name="limitedPeriodStartMonth">
            <base-select-input
              v-bind="componentField"
              :options="startMonths"
              :placeholder="startMonthsCms.placeholder"
            />
          </v-field>
        </div>
      </div>
      <div>
        <div class="once-or-more-per-month__start-month-select-label">
          {{ endMonthsCms.title }}
        </div>
        <div class="once-or-more-per-month__start-month-select-wrapper">
          <v-field v-slot="{ componentField }" name="limitedPeriodEndMonth">
            <base-select-input
              v-bind="componentField"
              :options="augmentedEndMonths"
              :placeholder="endMonthsCms.placeholder"
            />
          </v-field>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseSelectInput from "@/components/Base/BaseSelectInput/BaseSelectInput.vue";
import { getCmsItem } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import { getAugmentedActivityFrequencies } from "@/utilities/activityUtilities";
import { computed } from "vue";
import { cloneDeep } from "lodash";
import { Field as VField } from "vee-validate";
import { string } from "yup";
import BaseCheckboxInput from "@/components/Base/BaseCheckboxInput/BaseCheckboxInput.vue";

const emit = defineEmits(["new-form-values"]);

const props = defineProps({
  formValues: {
    type: Object,
    required: true,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  serviceActivityCms: {
    type: Object,
    required: true,
  },
});

const formSectionCms = computed(() => {
  return getCmsItem(
    props.activityFormCms.formSections,
    "onceOrMoreFrequencyContent",
  );
});
const frequencyCms = computed(() => {
  return getInputCms("frequency");
});
const limitedPeriodCms = computed(() => {
  return getInputCms("limitedPeriodCheckbox");
});
const startMonthsCms = computed(() => {
  return getInputCms("limitedPeriodStartMonth");
});
const endMonthsCms = computed(() => {
  return getInputCms("limitedPeriodEndMonth");
});
const monthlyFrequencies = computed(() => {
  const frequencies = props.frequencyOptions.monthly;
  const frequenciesCms = props.serviceActivityCms.frequencies;
  return getAugmentedActivityFrequencies(frequencies, frequenciesCms);
});
const startMonths = computed(() => {
  return startMonthsCms.value.SelectOptions.map((selectOption) => {
    return {
      label: selectOption.title,
      value: selectOption.label,
    };
  });
});
const endMonths = computed(() => {
  return endMonthsCms.value.SelectOptions.map((selectOption) => {
    return {
      label: selectOption.title,
      value: selectOption.label,
    };
  });
});
const augmentedEndMonths = computed(() => {
  let selectedStartMonthIndex = startMonths.value.findIndex(
    (month) => month.value === props.formValues.limitedPeriodStartMonth,
  );
  if (selectedStartMonthIndex < 0) {
    selectedStartMonthIndex = 0;
  }
  return [
    ...endMonths.value.slice(selectedStartMonthIndex),
    ...endMonths.value.slice(0, selectedStartMonthIndex),
  ];
});

const getInputCms = (label) => {
  return getCmsItem(formSectionCms.value.inputs, label);
};
const initialize = () => {
  const frequencyExists = monthlyFrequencies.value.some(
    (frequency) => frequency.value === props.formValues.frequency,
  );
  if (!frequencyExists) {
    const formValuesCopy = cloneDeep(props.formValues);
    formValuesCopy.frequency = "";
    emit("new-form-values", formValuesCopy);
  }
};
initialize();
</script>

<style lang="scss" scoped>
.once-or-more-per-month {
  &__frequency-error {
    margin-bottom: 0.75rem;
  }

  &__frequency-select-wrapper {
    max-width: 292px;
  }

  &__limited-period-checkbox-wrapper {
    margin-top: 1rem;
    font-size: $font-size-sm;
  }

  &__limited-period-months {
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__start-month-select-label {
    margin-top: 1rem;
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__start-month-select-wrapper {
    margin-top: 0.5rem;
    max-width: 155px;
  }
}
</style>
