<template>
  <div class="base-radio">
    <label class="base-radio__label">
      <input
        class="base-radio__input"
        type="radio"
        :checked="isChecked"
        :value="value"
        @change="onChange"
      />
      <span class="base-radio__checkmark" :class="checkmarkClasses" />
      <slot>
        <span class="base-radio__label-text">{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: "",
    },
    modelValue: {
      type: [String, Number, Boolean],
      default: "",
    },
    value: {
      type: [String, Number, Boolean],
      default: undefined,
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    isChecked() {
      return this.modelValue === this.value;
    },
    checkmarkClasses() {
      return this.error ? [`base-radio__checkmark--error`] : [];
    },
  },
  methods: {
    onChange() {
      this.$emit("update:modelValue", this.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-radio {
  line-height: 1;

  &__label {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0;
    cursor: pointer;
    user-select: none;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 50%;
    border: 1px solid $color-black;

    &:after {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      content: "";
      background-color: $color-black;
      width: 1rem;
      height: 1rem;
      border-radius: 50%;
      display: none;
    }

    &--error {
      border-color: $color-error;
    }
  }

  &__label-text {
    margin-left: 0.5rem;
    font-weight: 400;
    font-size: $font-size-sm;
  }
}

input:checked ~ .base-radio__checkmark:after {
  display: block;
}
</style>
