<template>
  <b-modal
    v-model="showModal"
    centered
    hide-footer
    hide-header
    @change="onModalChange"
  >
    <div class="confirmation">
      <div class="confirmation__status-icon-container">
        <img
          :src="`/images/service-report/check_mark.svg`"
          class="confirmation__status-icon"
          alt="Status icon"
        />
      </div>
      <p class="confirmation__title">Servicerapporten er lagret</p>
      <div class="confirmation__button-section">
        <b-button
          id="ok-button"
          class="confirmation__ok-button"
          type="submit"
          variant="primary"
          size="lg"
          block
          @click="onModalChange(false)"
          >Ok
        </b-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
export default {
  name: "ServiceReportModal",

  props: {
    showModalProp: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["on-change"],

  data() {
    return {
      showModal: this.showModalProp,
    };
  },

  watch: {
    showModalProp(newValue) {
      this.showModal = newValue;
    },
  },

  methods: {
    onModalChange(value) {
      this.$emit("on-change", value);
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: block;
  padding: 5.5rem 2rem 2.5rem;
  position: relative;
  text-align: center;

  &__status-icon-container {
    position: absolute;
    top: -5.375rem;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    height: 8rem;
    background-color: white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__status-icon {
    width: 100%;
  }

  &__title {
    font-weight: 700;
    font-size: $font-size-xl;
    color: #000;
    margin-bottom: 2.5rem;
  }

  &__ok-button {
    font-size: $font-size-base;
    font-weight: 600;
    padding: 1.125rem 2.5rem;
    text-transform: uppercase;
    color: #fff;
    background-color: #611f69;
    border-color: #611f69;

    &:hover {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
    }
  }
}
</style>
