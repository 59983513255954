<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="noCloseOnBackground"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div class="confirmation">
      <div
        class="confirmation__status-icon-container"
        :class="iconContainerClasses"
      >
        <slot name="status-icon">
          <img
            :src="statusIcon"
            class="confirmation__status-icon"
            :class="statusIconClasses"
            alt="Status icon"
          />
        </slot>
      </div>
      <p class="confirmation__title">
        {{ title }}
      </p>
      <p class="confirmation__description">
        {{ description }}
      </p>
      <slot name="extraContent" />
      <slot name="errorMessage">
        <p v-if="error" class="confirmation__error">{{ error }}</p>
      </slot>
      <div class="confirmation__button-section">
        <confirmation-modal-button
          v-if="secondaryButtonText"
          :text="secondaryButtonText"
          :disabled="isSubmitting"
          :uppercase="true"
          type="tertiary"
          @click="handleSecondaryButtonClick"
        />
        <confirmation-modal-button
          :text="primaryButtonText"
          :full-width="!secondaryButtonText"
          :uppercase="true"
          type="primary"
          @click="handlePrimaryButtonClick"
        >
          <template v-if="isSubmitting" #icon>
            <b-spinner class="confirmation__button-spinner" />
          </template>
        </confirmation-modal-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import ConfirmationModalButton from "@/components/ConfirmationModal/ConfirmationModalButton";
export default {
  components: { ConfirmationModalButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    iconOnEdge: {
      type: Boolean,
      default: true,
    },
    noCloseOnBackground: {
      type: Boolean,
      default: false,
    },
    statusIcon: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    primaryButtonText: {
      type: String,
      required: true,
    },
    secondaryButtonText: {
      type: String,
      default: "",
    },
    isSubmitting: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "secondary-click", "primary-click"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    iconContainerClasses() {
      return this.iconOnEdge
        ? ["confirmation__status-icon-container--on-edge"]
        : [];
    },
    statusIconClasses() {
      return this.iconOnEdge ? ["confirmation__status-icon--on-edge"] : [];
    },
  },
  methods: {
    handleSecondaryButtonClick() {
      this.$emit("secondary-click");
    },
    handlePrimaryButtonClick() {
      this.$emit("primary-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: block;
  padding: 2.5rem 3.5rem;
  position: relative;
  text-align: center;
  font-family: "Montserrat", sans-serif;

  &__status-icon-container {
    display: flex;
    justify-content: center;
    align-items: center;

    &--on-edge {
      position: absolute;
      top: -4.5rem;
      left: 50%;
      background-color: $color-white;
      border-radius: 50%;
      transform: translateX(-50%);
      width: 8rem;
      height: 8rem;
    }
  }

  &__status-icon {
    &--on-edge {
      width: 100%;
    }
  }

  &__title {
    margin: 1rem 0 0 0;
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-lg;
    color: $color-black;
  }

  &__description {
    font-weight: $font-weight-regular;
    margin: 1rem 0 0 0;
    font-size: $font-size-lg;
    color: $color-black;
  }

  &__error {
    font-weight: $font-weight-bold;
    font-size: $font-size-base;
    color: $color-error;
    margin-top: 1rem;
  }

  &__button-section {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2.0625rem;
    font-size: $font-size-base;
  }

  &__button-spinner {
    width: 0.8125rem;
    height: 0.8125rem;
  }
}
</style>
