export const deleteServiceReportCms = {
  submissionErrorMessage: "Mislyktes i å slette tilbudet",
  ok: "Ok",
  delete: "Slett",
  cancel: "Avbryt",
  confirmationTitle: "Er du sikker på at du vil slette denne servicerapporten?",
  confirmationSubtitle: "Denne handlingen kan ikke angres",
  successTitle: "Servicerapporten er slettet",
  successSubtitle: "",
};
