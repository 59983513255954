import router from "@/router";
import supplierService from "@/services/supplierService";

export default {
  selectAboutUsSupplierType: async ({ commit }, selectedSupplierType) => {
    commit("saveSelectedAboutUsSupplierType", selectedSupplierType);
  },

  fetchSupplier: async ({ commit, rootState }) => {
    const userId = rootState.api.user.userId;
    return supplierService
      .getSupplier(userId)
      .then((response) => {
        const data = response.data;
        if (data) {
          const supplier = { ...data };
          commit("setSupplier", supplier);
        }
      })
      .catch(() => {
        router.push("/error").catch(() => {});
      });
  },
  fetchSupplierDetails: async ({ commit, state }, industry) => {
    const supplierId = state.supplier.id;
    const supplierTypeId = industry.id;

    if (!supplierId || !supplierTypeId) return;

    return supplierService
      .getSupplierDetails(supplierId, supplierTypeId)
      .then((response) => {
        const data = response.data;
        if (data) {
          const supplierDetails = { ...data };
          commit("setSupplierDetails", supplierDetails);
        } else {
          commit("setSupplierDetails", {
            supplierId,
            supplierTypeId,
          });
        }
      })
      .catch(() => {
        commit("setSupplierDetails", { supplierId, supplierTypeId });
      });
  },
  refreshSupplierDetails: ({ commit }, supplierDetails) => {
    commit("setSupplierDetails", supplierDetails);
  },
  fetchCategoriesBySupplierType: async ({ commit, state }, supplierTypeId) => {
    const supplierId = state.supplier.id;

    if (!supplierId || !supplierTypeId) return;

    return supplierService
      .fetchCategoriesBySupplierType(supplierId, supplierTypeId)
      .then((response) => {
        commit("setCategoriesBySupplierType", response.data);
      });
  },
  setCategoriesBySupplierType: ({ commit }, categories) => {
    commit("setCategoriesBySupplierType", categories);
  },
};
