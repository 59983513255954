<template>
  <div class="description-item">
    <div class="description-item__count">{{ count }}</div>
    <div class="description-item__content">
      <div class="description-item__content-title">{{ title }}</div>
      <div class="description-item__content-body">{{ description }}</div>
    </div>
    <div v-if="!readOnly" class="description-item__menu">
      <work-description-dropdown-menu
        @edit-item="onEditItem"
        @delete-item="onDeleteItem"
      />
    </div>
  </div>
</template>

<script>
import WorkDescriptionDropdownMenu from "@/components/SupplierOfferWorkDescription/WorkDescriptionDropdownMenu.vue";

export default {
  components: { WorkDescriptionDropdownMenu },
  props: {
    count: {
      type: Number,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["edit-item", "delete-item"],
  methods: {
    onEditItem() {
      this.$emit("edit-item");
    },
    onDeleteItem() {
      this.$emit("delete-item");
    },
  },
};
</script>

<style lang="scss" scoped>
.description-item {
  display: flex;
  align-items: flex-start;
  gap: 1rem;

  &__count {
    flex-shrink: 0;
    background: #fff;
    border: 1px solid $color-primary-pink-darker;
    border-radius: 4px;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__menu {
    flex-shrink: 0;
  }

  &__content {
    flex-grow: 1;
    background: #fff;
    border: 1px solid $color-primary-pink-darker;
    border-radius: 4px;
    padding: 0.75rem 1rem;
  }

  &__content-title {
    font-weight: 600;
    font-size: $font-size-sm;
    line-height: 1.2;
  }

  &__content-body {
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 1.2;
  }
}
</style>
