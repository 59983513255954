import { v1Config, v2Config } from "@/config/cmsConfig";
import imageUrlBuilder from "@sanity/image-url";
import cachingDecorator from "@/utilities/cachingDecorator";

import { createClient } from "@sanity/client";

const v1Client = createClient({ ...v1Config });
const v2Client = createClient({ ...v2Config });
const builder = imageUrlBuilder(v2Config);

const cachedExecuteQuery = cachingDecorator(executeQuery);

export default {
  getErrors() {
    const query = "*[_type == 'error']{sysLabel,title,description}";
    return cachedExecuteQuery(query, {});
  },
  getFAQ() {
    const query =
      "*[_type == 'Faq' && !(_id in path('drafts.**')) ]{question,answer}";
    return cachedExecuteQuery(query, {});
  },
  getPriceGroups() {
    const query =
      "*[_type == 'priceGroup' && __i18n_lang == $language && !(_id in path('drafts.**')) ]";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getPackages() {
    const query =
      "*[_type == 'package' && !(_id in path('drafts.**')) ]{name, sysLabel, \"fileURL\": file.asset->url}";
    return cachedExecuteQuery(query, {});
  },
  getServiceTypesContent() {
    const query =
      "*[_type == 'serviceType' && __i18n_lang == $language && !(_id in path('drafts.**')) ]  | order(order asc) {serviceCategories[]->{selectOptions[]->,...},propertyTypes[]->,segmentGroup[]{segments[]{inputs[]{SelectOptions[]->,...},...},...},form[]{segments[]{inputs[]{SelectOptions[]->,...},...},...},..., serviceSubcategories[]{selectOptions[]->,...,...,...}}";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getServiceActivities() {
    const query = `*[ _type == 'serviceActivity' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]{
			...,frequencies[]->,activityItems[]->,
			}`;
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getSustainabilityGoals() {
    const query =
      "*[ _type == 'sustainabilityGoals' && __i18n_lang == $language && !(_id in path('drafts.**')) ]";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getCertifications() {
    const query =
      "*[ _type == 'certifications' && __i18n_lang == $language && !(_id in path('drafts.**')) ]";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getEditSupplierOfferPage() {
    const query = `*[ _type == 'pages' && label == 'editSupplierOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]{
			...,
			forms[]{...,formSections[]{...,inputs[]{...,SelectOptions[]->}}},
			}`;
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getCreateOfferPage() {
    const query = `*[ _type == 'pages' && label == 'newOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`;
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getTermsAndConditionsCms() {
    const query = `*[ _type == 'pages' && label == 'termsAndConditionsPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`;
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  async getSendOfferModalPage() {
    const query = `*[ _type == 'pages' && label == 'sendOfferModalPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`;
    const params = { language: "no" };
    const pagesResponse = await cachedExecuteQuery(query, params, "v2");
    return pagesResponse?.length ? pagesResponse[0] : null;
  },
  getGiveOfferPage() {
    const query =
      "*[ _type == 'pages' && label == 'giveOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  getSupplierMyAgreementsPage() {
    const query =
      "*[ _type == 'pages' && label == 'supplierMyAgreementsPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]";
    const params = { language: "no" };
    return cachedExecuteQuery(query, params, "v2");
  },
  resolveImage(source) {
    return builder.image(source);
  },
};

function executeQuery(query, params, datasource = "v1") {
  const client = datasource === "v1" ? v1Client : v2Client;
  return client.fetch(query, params).catch((error) => console.error(error));
}
