<template>
  <div class="less-than-once-per-month">
    <v-field
      v-slot="{ componentField, errors }"
      name="frequency"
      :rules="string().required()"
      :validate-on-input="true"
    >
      <div
        v-if="errors.length && frequencyCms.error"
        class="less-than-once-per-month__frequency-error"
      >
        <input-error :text="frequencyCms.error" />
      </div>
      <div class="less-than-once-per-month__frequency-select-wrapper">
        <base-select-input
          v-bind="componentField"
          :options="augmentedFrequencies"
          :placeholder="frequencyCms.placeholder"
        />
      </div>
    </v-field>
  </div>
</template>

<script setup>
import BaseSelectInput from "@/components/Base/BaseSelectInput/BaseSelectInput.vue";
import { getCmsItem } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import { getAugmentedActivityFrequencies } from "@/utilities/activityUtilities";
import { Field as VField } from "vee-validate";
import { computed } from "vue";
import { string } from "yup";
import { cloneDeep } from "lodash";

const emit = defineEmits(["new-form-values"]);

const props = defineProps({
  formValues: {
    type: Object,
    required: true,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  serviceActivityCms: {
    type: Object,
    required: true,
  },
});

const formSectionCms = computed(() => {
  return getCmsItem(
    props.activityFormCms.formSections,
    "lessThanOnceFrequencyContent",
  );
});
const frequencyCms = computed(() => {
  return getCmsItem(formSectionCms.value.inputs, "frequency");
});
const augmentedFrequencies = computed(() => {
  const frequencies = props.frequencyOptions.yearly;
  const frequenciesCms = props.serviceActivityCms.frequencies;
  return getAugmentedActivityFrequencies(frequencies, frequenciesCms);
});
const initialize = () => {
  const frequencyExists = augmentedFrequencies.value.some(
    (frequency) => frequency.value === props.formValues.frequency,
  );
  if (!frequencyExists) {
    const formValuesCopy = cloneDeep(props.formValues);
    formValuesCopy.frequency = "";
    emit("new-form-values", formValuesCopy);
  }
};
initialize();
</script>

<style lang="scss" scoped>
.less-than-once-per-month {
  &__frequency-error {
    margin-bottom: 0.75rem;
  }

  &__frequency-select-wrapper {
    max-width: 292px;
  }
}
</style>
@/utilities/cmsUtilities@/utilities/activityUtilities
