<template>
  <teleport to="body">
    <div>
      <b-modal
        v-model="showModal"
        :teleport-disabled="true"
        :no-close-on-backdrop="true"
        body-class="create-price-request-modal__body p-0"
        centered
        hide-footer
        hide-header
        size="lg"
      >
        <div v-if="state.formCms" class="create-price-request-modal">
          <b-form
            class="create-price-request-modal__form"
            @submit.prevent="submitForm"
          >
            <div class="create-price-request-modal__header">
              <div class="create-price-request-modal__header-title">
                {{ state.formCms.modalTitle }}
              </div>
              <div class="create-price-request-modal__header-subtitle">
                {{ state.formCms.modalSubtitle }}
              </div>
            </div>

            <b-form-group
              :label="state.formCms.inputs?.selectServiceTypeInput?.title"
              label-for="service-type-select"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="serviceType"
                :rules="string().required()"
              >
                <b-form-select
                  id="service-type-select"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="serviceTypeSelectOptions"
                  :disabled="hasSingleService"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ state.formCms.inputs?.selectServiceTypeInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="state.formCms.inputs?.selectCategoryInput?.title"
              label-for="service-type-category-select"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="serviceTypeCategory"
                :rules="string().required()"
              >
                <b-form-select
                  id="service-type-category-select"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="serviceCategorySelectOptions"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ state.formCms.inputs?.selectCategoryInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="state.formCms.inputs?.selectResponsibleUserInput?.title"
              label-for="select-responsible-user"
              class="create-price-request-modal__group"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="selectResponsibleUser"
              >
                <b-form-select
                  id="select-responsible-user"
                  v-bind="componentField"
                  :style="selectStyle"
                  :options="responsibleUserSelectOptions"
                  class="create-price-request-modal__select"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ state.formCms.inputs?.selectResponsibleUserInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <b-form-group
              :label="state.formCms.inputs?.descriptionInput?.title"
              label-for="description-textarea"
              class="create-price-request-modal__group-textarea"
            >
              <v-field
                v-slot="{ componentField, errors }"
                name="description"
                :rules="string().required()"
              >
                <b-form-textarea
                  id="description-textarea"
                  v-bind="componentField"
                  :placeholder="
                    state.formCms.inputs?.descriptionInput?.placeholder
                  "
                  rows="4"
                  class="create-price-request-modal__textarea"
                />
                <b-form-invalid-feedback :state="!errors.length">
                  {{ state.formCms.inputs?.descriptionInput?.error }}
                </b-form-invalid-feedback>
              </v-field>
            </b-form-group>

            <div class="create-price-request-modal__attachments">
              <div class="create-price-request-modal__header-subtitle">
                {{ state.formCms.inputs?.attachmentsInput?.title }}
              </div>
              <div
                v-if="values.attachments.length"
                class="create-price-request-modal__attachments"
              >
                <div
                  v-for="attachment in values.attachments"
                  :key="attachment.id"
                  class="create-price-request-modal__attachment"
                >
                  <attachment-file
                    :attachment="attachment"
                    @remove="removeAttachment(attachment)"
                  />
                </div>
              </div>
              <file-input
                v-slot="{ openFileInput }"
                :valid-file-extensions="state.validFileExtensions"
                @selected-files="handleSelectedFiles"
              >
                <button
                  class="btn create-price-request-modal__upload-btn"
                  @click.prevent="openFileInput"
                >
                  <img
                    :src="`/images/upload.svg`"
                    alt="upload Icon"
                    class="btn__icon"
                  />
                  {{ state.formCms.buttons?.uploadButton }}
                </button>
              </file-input>
            </div>

            <!-- Form Submission Errors -->
            <b-alert
              :show="state.dismissCountDown"
              dismissible
              :variant="state.feedbackVariant"
              @dismissed="state.dismissCountDown = 0"
              @dismiss-count-down="countDownChanged"
              >{{ state.feedbackMessage }}
            </b-alert>

            <div class="create-price-request-modal__btn-actions">
              <b-button
                :disabled="state.isSubmitting"
                class="btn create-price-request-modal__cancel-btn"
                @click.prevent="handleCancel"
              >
                {{ state.formCms.buttons?.cancelButton }}
              </b-button>
              <b-button
                type="submit"
                :disabled="state.isSubmitting"
                class="btn create-price-request-modal__submit-btn"
              >
                <b-spinner
                  v-if="state.isSubmitting"
                  class="create-price-request-modal__spinner"
                />
                {{ state.formCms.buttons?.submitButton }}
              </b-button>
            </div>
          </b-form>
        </div>
      </b-modal>
    </div>
  </teleport>
</template>

<script setup>
import FileInput from "@/components/FileInput/FileInput.vue";
import AttachmentFile from "./AttachmentFile.vue";
import { uuidv4 } from "@/utilities/uuid";
import {
  convertFileToBase64,
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtilities";
import tenderService from "@/services/tenderService";
import dayjs from "dayjs";
import { computed, onMounted, reactive, watch } from "vue";
import { useStore } from "vuex";
import { Field as VField, useForm } from "vee-validate";
import { string } from "yup";
import { cloneDeep } from "lodash";
import cmsService from "@/services/cmsService";

const store = useStore();

const emit = defineEmits(["update:modelValue"]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  supplierServiceTypes: {
    type: Array,
    required: true,
  },
  supplierUsers: {
    type: [Array, null],
    default: null,
  },
  tender: {
    type: Object,
    required: true,
  },
  supplierId: {
    type: Number,
    required: true,
  },
});

const state = reactive({
  dismissSecs: 10,
  dismissCountDown: 0,
  showDismissibleAlert: false,
  isSubmitting: false,
  selectIcon: "src/images/drop-down.svg",
  maxFileSizeInMB: 20,
  validFileExtensions: [".pdf", ".jpg", ".jpeg", ".png"],
  submissionError: "Feil ved innlevering av tilbud",
  descriptionError: "Vennligst fyll ut beskrivelsen",
  serviceTypeError: "Velg tjenestetype",
  serviceTypeCategoryError: "Velg tjenestetypekategori",
  successMessage: "Prisforespørselen ble sendt inn",
  selectResponsibleUserError: "Velg brukeransvarlig",
  feedbackVariant: "",
  feedbackMessage: "",
  pageCms: {},
  formCms: {},
});

const { resetForm, handleSubmit, values, setValues } = useForm({
  initialValues: {
    serviceType: "",
    serviceTypeCategory: "",
    selectResponsibleUser: "",
    description: "",
    attachments: [],
  },
});

const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:modelValue", newVal);
  },
});
const serviceTypeSelectOptions = computed(() => {
  return [
    { value: "", text: "Velg tjeneste" },
    ...props.supplierServiceTypes.map((serviceType) => ({
      value: serviceType.label,
      text: serviceType.title,
    })),
  ];
});
const serviceCategorySelectOptions = computed(() => {
  const selectedServiceType = props.supplierServiceTypes.find(
    (serviceType) => serviceType.label === values.serviceType,
  );
  const serviceTypeCategories = selectedServiceType?.categories || [];
  return [
    { value: "", text: "Velg kategori" },
    ...serviceTypeCategories.map((category) => ({
      value: category.label,
      text: category.title,
    })),
  ];
});
const responsibleUserSelectOptions = computed(() => {
  const responsibleUsers = props.supplierUsers ? props.supplierUsers : [];
  return [
    { value: "", text: "Alle brukere ansvarlig" },
    ...responsibleUsers.map((user) => ({
      value: user.userId,
      text: `${user.displayName} - ${user.email}`,
    })),
  ];
});
const hasSingleService = computed(() => {
  return props.supplierServiceTypes.length === 1;
});
const selectStyle = computed(() => {
  return {
    background: "none",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "right center",
    backgroundPositionX: "revert-layer",
    backgroundImage: `url(${state.selectIcon})`,
  };
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      resetForm();
      if (hasSingleService.value) {
        setValues(
          {
            serviceType: props.supplierServiceTypes[0].label,
          },
          false,
        );
      }
    }
  },
);
watch(
  () => values.serviceType,
  () => {
    if (values.category) {
      setValues(
        {
          serviceTypeCategory: "",
        },
        false,
      );
    }
  },
);

const submitForm = handleSubmit(async () => {
  state.isSubmitting = true;
  const dto = await constructDto();
  try {
    const response = await tenderService.submitTender(dto);
    if ([200, 204].includes(response.status)) {
      await handleSuccessfulSubmission(response.data);
    }
  } catch (error) {
    handleError(error);
  } finally {
    state.isSubmitting = false;
  }
});
// submit dto
const constructDto = async () => {
  return {
    supplierIds: [props.supplierId],
    parentTenderId: props.tender.tenderId,
    integrationSource: "SupplierAdmin",
    integrationExternalId: uuidv4(),
    customerOrganizationDto: {
      orgNumber: props.tender.customerOrganization.orgNumber,
      name: props.tender.customerOrganization.name,
    },
    orderingUserEmail: store.state.api.user.email,
    propertySubmissions: await getPropertySubmissions(),
  };
};
const handleSuccessfulSubmission = async (tenderData) => {
  await setAgreementResponsible(tenderData);
  showAlert("success", state.successMessage);
  redirectAfterDelay(tenderData, 3000);
};
const handleError = (error) => {
  showAlert("danger", state.submissionError);
  console.error(error);
};
const setAgreementResponsible = async (tender) => {
  const selectedResponsibleUser = props.supplierUsers.find(
    (user) => user.userId === values.selectResponsibleUser,
  );
  if (!selectedResponsibleUser) {
    return;
  }
  try {
    await tenderService.setAgreementResponsible(
      tender.tenderId,
      tender.priceRequests?.[0].id,
      props.supplierId,
      {
        AgreementOwnerUserId: selectedResponsibleUser?.userId ?? null,
        SendNotification: true,
      },
    );
  } catch (error) {
    handleError(error);
  }
};
const redirectAfterDelay = (tender, delay) => {
  console.log("redirecting after delay", tender, delay);
  showModal.value = false;
  const baseUrl = window.location.origin;
  const createOfferPath = `/tender/${tender.tenderId}/offers`;
  const fullUrl = `${baseUrl}${createOfferPath}`;
  return (window.location.href = fullUrl);
};
const getPropertySubmissions = async () => {
  const { priceRequests } = props.tender;
  const { customerPropertyDto, jobType } = priceRequests?.[0] ?? {};

  const selectedServiceType = props.supplierServiceTypes.find(
    (type) => type.label === values.serviceType,
  );
  const selectedServiceTypeCategory = selectedServiceType.categories.find(
    (category) => category.label === values.serviceTypeCategory,
  );

  const supplierServiceTypeId = selectedServiceType?.id;
  const serviceCategoryId = selectedServiceTypeCategory?.id;
  const augmentedAttachments = await Promise.all(
    values.attachments.map((attachment) => {
      return new Promise((resolve, reject) => {
        convertFileToBase64(attachment.file)
          .then((result) =>
            resolve({
              fileName: attachment.file.name,
              fileContent: result,
            }),
          )
          .catch((error) => reject(error));
      });
    }),
  );
  const serviceTypeFormDtos = [
    {
      categoryId: serviceCategoryId,
      categoryLabel: values.serviceTypeCategory,
      categoryLabelText: null,
      jobType: jobType,
      propertyTypeId: customerPropertyDto?.propertyTypeId,
      propertyTypeLabel: customerPropertyDto?.propertyType?.sysLabel,
      propertyTypeLabelText: null,
      robot: false,
      supplierTypeId: supplierServiceTypeId,
      supplierTypeLabel: values.serviceType,
      supplierTypeLabelText: null,
      message: values.description,
      attachments: augmentedAttachments,
      wantedStartDate: getMinimumStartDate(),
    },
  ];
  const propertyDto = {
    propertyTypeId: customerPropertyDto?.propertyTypeId,
    addressDto: {
      address: customerPropertyDto?.address?.address,
      postalCode: customerPropertyDto?.address?.postalCode,
      county: customerPropertyDto?.address?.county,
      city: customerPropertyDto?.address?.city,
    },
    nickName: customerPropertyDto?.nickname,
    id: customerPropertyDto?.id,
  };

  return [
    {
      serviceTypeFormDtos,
      propertyDto,
    },
  ];
};
const getMinimumStartDate = () => {
  return dayjs().add(7, "day").toDate();
};
const countDownChanged = (dismissCountDown) => {
  state.dismissCountDown = dismissCountDown;
};
const showAlert = (variant, message) => {
  state.feedbackVariant = variant;
  state.feedbackMessage = message;
  state.dismissCountDown = state.dismissSecs;
};
const handleCancel = () => {
  showModal.value = false;
};
const handleSelectedFiles = (files) => {
  let validFiles = getFilesWithValidExtension(files, state.validFileExtensions);
  validFiles = getFilesWithValidSize(validFiles, state.maxFileSizeInMB);
  const newAttachments = validFiles.map((file) => {
    return {
      id: uuidv4(),
      file: file,
      fileName: file.name,
      attachmentUri: null,
    };
  });
  setValues(
    {
      attachments: [...newAttachments, ...values.attachments],
    },
    false,
  );
};
const removeAttachment = (attachment) => {
  const attachments = cloneDeep(values.attachments);
  for (let i = attachments.length - 1; i >= 0; i--) {
    if (attachments[i].id === attachment.id) {
      attachments.splice(i, 1);
    }
  }
  setValues(
    {
      attachments: attachments,
    },
    false,
  );
};

const fetchPageCMSData = async () => {
  try {
    const data = await cmsService.getSupplierMyAgreementsPage();
    return data[0];
  } catch (error) {
    handleError(error);
    return null;
  }
};

const loadPageCMS = async () => {
  state.pageCms = await fetchPageCMSData();
};

onMounted(async () => {
  await loadPageCMS();

  if (state.pageCms) {
    const { forms } = state.pageCms;
    const form = forms.find(
      (form) => form.label === "createPriceRequestModalForm",
    );

    if (form) {
      const { otherText, inputs, buttons } = form;

      const modalTitle = otherText.find(
        (text) => text.label === "modalTitle",
      )?.title;
      const modalSubtitle = otherText.find(
        (text) => text.label === "modalSubtitle",
      )?.title;

      const formInputs = inputs.reduce((acc, input) => {
        acc[input.label] = {
          title: input.title,
          error: input.error,
        };
        return acc;
      }, {});

      const formButtons = buttons.reduce((acc, button) => {
        acc[button.label] = button.title;
        return acc;
      }, {});

      state.formCms = {
        modalTitle,
        modalSubtitle,
        inputs: formInputs,
        buttons: formButtons,
      };
    }
  }
});
</script>

<style lang="scss" scoped>
$base-padding: 20px;
$base-border: 1px solid #000;
$base-font-color: #333;
$sub-font-color: #666;
$highlight-color: rgba(0, 0, 0, 0.05);
$active-color: rgba(0, 0, 0, 0.1);
$primary-color: #611f69;
$primary-color-darker: #5b0050;

.create-price-request-modal {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  padding: 40px 56px;
  gap: 16px;
  font-family: "Montserrat", serif;

  &__form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 24px;
  }

  &__header-title {
    color: #000;
    font-size: 24px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__header-subtitle {
    color: #000;
    font-size: 16px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin-bottom: 16px;
  }

  &__group {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 336px;
  }

  &__group label {
    font-size: 16px;
  }

  &__group-textarea {
    display: flex;
    flex-direction: column;
    gap: 8px;
    width: 100%;
    min-width: 400px;
  }

  &__group-textarea label {
    font-size: 16px;
  }

  &__attachment {
    display: flex;
    flex-direction: column;
  }

  &__upload-btn {
    display: flex;
    padding: 0 24px 0 16px;
    align-items: center;
    gap: 8px;
    border-radius: 8px;
    background: $color-primary-pink;
    color: #000;
    font-size: 14px;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    text-transform: none !important;

    &:hover {
      background: $color-primary-pink-darker;
      transform: scale(1.05);
      color: #000;
    }

    &:active {
      background: $color-primary-pink-darker;
      color: #000;
      transform: scale(1);
    }
  }

  &__attachments {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  &__attachment {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__btn-actions {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: flex-start;
    gap: 26px;
  }

  &__cancel-btn {
    background-color: transparent;
    color: $primary-color;
    border: 2px solid $primary-color !important;

    &:hover {
      background-color: transparent;
      color: $primary-color;
      border-color: $primary-color-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      background-color: transparent;
    }

    &:disabled {
      background-color: transparent;
      color: $primary-color;
    }
  }

  &__submit-btn {
    background-color: $primary-color;
    border: 2px solid $primary-color !important;
    color: white;

    &:focus {
      background-color: $primary-color-darker;
    }

    &:hover {
      background-color: $primary-color-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
      background-color: $primary-color-darker;
    }

    &:disabled {
      background-color: $primary-color-darker;
      color: white;
    }
  }

  &__spinner {
    font-size: 14px;
    width: 1rem;
    height: 1rem;
    margin-right: 4px;
  }
}

.btn {
  padding: 16px 40px;
  border: none;
  border-radius: 6px;
  font-weight: bold;
  cursor: pointer;
  font-size: 14px;
  line-height: normal;
  text-transform: uppercase;
  transition:
    background-color 0.3s,
    transform 0.2s;
}

.btn__icon {
  margin-right: 8px;
  vertical-align: middle;
}

:deep(.form-control) {
  border-radius: 4px;
  border: 1px solid #1d1d1d;
}

:deep(.modal-body) {
  display: flex;
  padding: 40px 56px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
}

:deep(.form-select) {
  display: flex;
  align-items: center;
  padding: 0 16px;
  border: 1px solid #1d1d1d !important;
  border-radius: 4px;
  height: 48px;
  box-shadow: none;

  &:focus {
    box-shadow: none;
  }
}

:deep(.modal-lg) {
  max-width: 580px !important;
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
}
</style>
