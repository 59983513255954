<template>
  <div class="attachment" @click="handleAttachmentClick">
    <img
      :src="`/images/document.svg`"
      alt="left icon"
      class="attachment__left-icon"
    />
    <div class="attachment__title">{{ fileName }}</div>
  </div>
</template>

<script>
export default {
  props: {
    fileName: {
      type: String,
      required: true,
    },
    attachmentUri: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["attachment-clicked"],
  methods: {
    handleAttachmentClick() {
      this.$emit("attachment-clicked");
      if (this.attachmentUri) {
        window.open(this.attachmentUri, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  border: 1px solid gray;
  display: flex;
  align-items: center;
  padding: 0.5rem;
  border-radius: 4px;
  cursor: pointer;

  &__left-icon {
    flex-shrink: 0;
    margin-right: 0.5rem;
  }

  &__title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &:hover {
    background: #f6f6f6;
  }
}
</style>
