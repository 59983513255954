// useSavingState.ts
import { ref } from "vue";

export function useSupplierProfileSavingState() {
  const isSavingMap = ref(new Map<string, boolean>());

  const isSaving = (label: string) => {
    return isSavingMap.value.get(label) || false;
  };

  const startSaving = (label: string) => {
    isSavingMap.value.set(label, true);
  };

  const finishSaving = (label: string) => {
    isSavingMap.value.set(label, false);
  };

  return {
    isSaving,
    startSaving,
    finishSaving,
  };
}
