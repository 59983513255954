<template>
  <div :key="componentKey" class="total-billable-price__description">
    <span class="total-billable-price__description-text">{{
      descriptionText
    }}</span>
    <span class="total-billable-price__description-price">{{
      formattedPrice
    }}</span>
  </div>
</template>

<script>
export default {
  name: "BillablePriceDetail",
  props: {
    componentKey: {
      type: Number,
      required: true,
      default: 0,
    },
    descriptionText: {
      type: String,
      required: true,
    },
    price: {
      type: [String, Number],
      required: true,
    },
    currency: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      internalPrice: this.price,
    };
  },
  computed: {
    formattedPrice() {
      return `${this.internalPrice} ${this.currency}`;
    },
  },
  watch: {
    price(newVal) {
      this.internalPrice = newVal;
    },
  },
};
</script>

<style scoped lang="scss">
$color-primary: #000000;
$background-color: $color-primary-pink-dark;
$border-color: $color-primary-pink-darker;

@mixin font-style($weight, $size, $line-height, $color) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  color: $color;
}

.total-billable-price {
  &__description {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 11px;

    &-text {
      @include font-style(400, 14px, 17px, $color-primary);
      width: 300px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-align: left;
    }

    &-price {
      @include font-style(600, 14px, 17px, $color-primary);
      text-align: right;
    }
  }
}
</style>
