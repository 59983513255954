<template>
  <div class="supplier-offers-wrapper">
    <b-spinner
      v-if="isLoading"
      class="supplier-offers-wrapper__loader"
      type="grow"
      small
    />
    <template v-else>
      <no-supplier-offers
        v-if="hasNoOffers"
        :tender="tender"
        :is-give-offer-button-visible="isGiveOfferButtonVisible"
        :no-supplier-offers-cms="noSupplierOffersCms"
      />
      <div v-else class="supplier-offers-wrapper__offer-lists">
        <template v-if="isTenderStateAccepted">
          <supplier-offer-list
            v-if="toggleState"
            :tender="tender"
            :supplier-offers="nonAcceptedOffers"
            :cms-props="cmsProps"
            :supplier-id="supplierId"
            :offer-state-has-changed="offerStateHasChanged"
            :frequency-options="frequencyOptions"
            @refresh-tender-offer-data="initialize"
            @reload-my-agreements="reloadMyAgreements"
          />
        </template>
        <template v-else>
          <supplier-offer-list
            :tender="tender"
            :supplier-offers="supplierOffers"
            :cms-props="cmsProps"
            :supplier-id="supplierId"
            :offer-state-has-changed="offerStateHasChanged"
            :frequency-options="frequencyOptions"
            @refresh-tender-offer-data="initialize"
            @reload-my-agreements="reloadMyAgreements"
          />
        </template>
      </div>
      <div
        v-if="isTenderStateAccepted && notAcceptedOffersCount > 0"
        class="supplier-offers-wrapper__toggle-text"
        @click="toggleText"
      >
        {{ toggleState ? hideText : showText }}
      </div>
    </template>
  </div>
</template>

<script>
import NoSupplierOffers from "@/components/SupplierOffersWrapper/SupplierOffers/NoSupplierOffers.vue";
import SupplierOfferList from "@/components/SupplierOffersWrapper/SupplierOffers/SupplierOfferList.vue";
import cmsService from "@/services/cmsService";
import tenderService from "@/services/tenderService";
import { RouteNames } from "@/router/routes";
import { TenderState, RequestState, OfferStatus } from "@/custom-types/types";

export default {
  name: "SupplierOffersWrapper",
  components: { NoSupplierOffers, SupplierOfferList },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
    showNewOfferButton: {
      type: Boolean,
      default: true,
    },
    isGiveOfferButtonVisible: {
      type: Boolean,
      default: true,
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
  },
  data() {
    return {
      isLoading: false,
      pageCms: {},
      supplierOffers: [],
      offerStateHasChanged: false,
      toggleState: false,
    };
  },
  computed: {
    isTenderStateAccepted() {
      return (
        this.tender.tenderState === TenderState.Accepted ||
        this.tender.tenderState === TenderState.Completed
      );
    },
    showText() {
      return `Se tidligere sendte tilbud (${this.notAcceptedOffersCount})`;
    },
    hideText() {
      return `Skjul tidligere sendte tilbud (${this.notAcceptedOffersCount})`;
    },
    cmsProps() {
      return {
        successOfferModalCms: this.successOfferModalCms,
        sendToCustomerOfferModalCms: this.sendToCustomerOfferModalCms,
        deleteDraftOfferModalCms: this.deleteDraftOfferModalCms,
        withdrawOfferModalCms: this.withdrawOfferModalCms,
        editSentOrWithdrawnOfferModalCms: this.editSentOrWithdrawnOfferModalCms,
      };
    },
    acceptedOffers() {
      return this.supplierOffers.filter(
        (offer) =>
          offer.offerState === OfferStatus.Accepted ||
          offer.offerState === OfferStatus.Completed,
      );
    },
    nonAcceptedOffers() {
      return this.supplierOffers.filter(
        (offer) =>
          offer.offerState !== OfferStatus.Accepted &&
          offer.offerState !== OfferStatus.Completed,
      );
    },
    notAcceptedOffersCount() {
      return this.nonAcceptedOffers.length;
    },
    isNotRejectedJob() {
      // Check if the request state is rejected
      const requestState =
        this.tender.priceRequests?.[0]?.supplierRequests?.[0]?.requestState;
      if (requestState === RequestState.Rejected) {
        return false;
      }

      const VALID_STATES = [
        TenderState.Offered,
        TenderState.Accepted,
        TenderState.PriceRequested,
        TenderState.Completed,
      ];

      // Check if the tender state is within the valid states
      if (VALID_STATES.includes(this.tender?.tenderState)) {
        return true;
      }

      // If none of the above conditions are met, return false
      return true;
    },
    hasNoOffers() {
      return this.supplierOffers && this.supplierOffers.length === 0;
    },
    tabMenusCms() {
      return this.findSectionByLabel("tabMenus");
    },
    newOfferButtonText() {
      return this.findButtonTitleByLabel(this.tabMenusCms, "newOfferButton");
    },
    noSupplierOffersCms() {
      return this.findSectionByLabel("noSupplierOffers");
    },
    successOfferModalCms() {
      return this.findSectionByLabel("kingKongModal");
    },
    deleteDraftOfferModalCms() {
      return this.findSectionByLabel("deleteDraftModal");
    },
    editSentOrWithdrawnOfferModalCms() {
      return this.findSectionByLabel("changeOfferModal");
    },
    withdrawOfferModalCms() {
      return this.findSectionByLabel("withdrawOfferModal");
    },
    sendToCustomerOfferModalCms() {
      return this.findSectionByLabel("sendOfferModal_WithExistingOffer");
    },
  },

  async mounted() {
    this.initialize();
  },

  methods: {
    toggleText() {
      this.toggleState = !this.toggleState;
    },

    async initialize(offerStateHasChanged) {
      this.setOfferSentFlag(offerStateHasChanged);
      try {
        this.startLoading();
        await this.loadSupplierOffers();
        await this.loadPageCMS();
      } catch (error) {
        this.handleError(error);
      } finally {
        this.stopLoading();
      }
    },

    startLoading() {
      this.isLoading = true;
    },

    stopLoading() {
      this.isLoading = false;
    },

    async loadSupplierOffers() {
      this.supplierOffers = await this.fetchSupplierOffers();
    },

    async fetchSupplierOffers() {
      try {
        const { data } = await tenderService.fetchSupplierOffers(
          this.tender.tenderId,
          this.supplierId,
        );
        return data;
      } catch (error) {
        this.handleError(error);
        return [];
      }
    },

    async loadPageCMS() {
      this.pageCms = await this.fetchPageCMSData();
    },

    async fetchPageCMSData() {
      try {
        const data = await cmsService.getGiveOfferPage();
        return data[0];
      } catch (error) {
        this.handleError(error);
        return null;
      }
    },

    setOfferSentFlag(flag) {
      this.offerStateHasChanged = flag;
    },

    findItemByLabel(label, array = []) {
      return array.find((item) => item.label === label) || {};
    },

    findSectionByLabel(label) {
      return this.findItemByLabel(label, this.pageCms?.pageSections);
    },

    findButtonTitleByLabel(section, label) {
      return this.findItemByLabel(label, section?.buttons)?.title || "";
    },

    handleNewOfferButtonClick() {
      this.$router.push({
        name: RouteNames.CREATE_OFFER,
        params: {
          tenderId: this.tender.tenderId,
        },
      });
    },

    handleError(error) {
      console.error("An error occurred:", error);
    },

    async reloadMyAgreements(newOffers) {
      if (!newOffers || newOffers.length === 0) {
        console.warn("No offers provided to reloadMyAgreements");
        return;
      }
      this.refreshPageUrl();
    },

    refreshPageUrl() {
      const currentUrl = window.location.href;
      window.location.replace(currentUrl);
    },
  },
};
</script>

<style lang="scss" scoped>
$color-primary: #611f69;
$color-primary-darker: darken($color-primary, 10%);
$white-color: #ffffff;
.supplier-offers-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  min-height: 25rem;
  width: 100%;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    margin: auto;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
  }

  &__btn {
    width: fit-content;
    margin-bottom: 30px;
    font-weight: bold;
    font-size: $font-size-base;
    text-transform: uppercase !important;
    border-width: 0.1rem;
    margin-right: auto;
    background-color: $color-primary;
    border-color: $color-primary;
    color: $white-color;

    &:hover,
    &:active,
    &:focus {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
      color: $white-color;

      &:focus {
        box-shadow: 0 2px 10px 0 rgba($color-primary, 0.25);
      }
    }
  }

  &__offer-lists {
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  &__toggle-text {
    font-size: $font-size-base;
    margin-right: auto;
    font-weight: bold;
    line-height: 20px;
    letter-spacing: 0em;
    text-align: left;
    color: #000000;
    cursor: pointer;
    text-decoration: underline;
    margin-top: 20px;
  }
}
</style>
@/router @/types/AppTypes
