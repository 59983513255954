<template>
  <div class="app-header">
    <div class="app-header__content">
      <div class="app-header__top-bar">
        <img :src="LogoIcon" alt="" />
        <div class="app-header__top-bar-right-content">
          <notifications-button
            v-if="supplier"
            :audience="Audience.SUPPLIER"
            :audience-id="supplier.id"
            @clicked="togglePopupComponent(PopupComponent.NOTIFICATIONS)"
          />
          <user-profile-button
            :user="user"
            :organization="supplierOrganization"
            @clicked="togglePopupComponent(PopupComponent.USER_PROFILE)"
          />
        </div>
      </div>
      <div class="app-header__bottom-bar">
        <menu-item
          v-for="(menuItem, index) in menuItems"
          :key="index"
          :label="menuItem.label"
          :to="menuItem.to"
          :active="menuItem.active"
        >
          <img :src="menuItem.icon" alt="" />
        </menu-item>
      </div>
    </div>
    <div
      v-show="state.popupComponent"
      ref="popupElement"
      class="app-header__popup"
    >
      <user-profile
        v-if="state.popupComponent === PopupComponent.USER_PROFILE"
        :user="user"
        :organization="supplierOrganization"
        @sign-out="signOut"
        @updated-user="updateUser"
      />
      <notifications
        v-if="supplier && state.popupComponent === PopupComponent.NOTIFICATIONS"
        :audience="Audience.SUPPLIER"
        :audience-id="supplier.id"
        :service-types-cms="serviceTypesCms"
        :tenders="allTenders"
        @notification-clicked="handleNotificationClick"
        @close="togglePopupComponent(null)"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import MenuItem from "@/ui/Header/HeaderMenuItem.vue";
import LogoIcon from "@/assets/img/logo.svg";
import MyAgreementsIcon from "@/assets/img/my-agreements.svg";
import MyRequestsIcon from "@/assets/img/my-requests.svg";
import SupplierProfileIcon from "@/assets/img/supplier-profile.svg";
import PriceRobotIcon from "@/assets/img/price-robot.svg";
import HelpIcon from "@/assets/img/help.svg";
import {
  computed,
  onBeforeUnmount,
  reactive,
  ref,
  watch,
  onMounted,
} from "vue";
import { RouteNames } from "@/router/types";
import { useRoute, useRouter } from "vue-router";
import UserProfile from "@/components/UserProfile/UserProfile.vue";
import UserProfileButton from "@/components/UserProfile/UserProfileButton.vue";
import vClickOutside from "v-click-outside";
import { useUserStore } from "@/stores/user";
import { useSupplierStore } from "@/stores/supplier";
import { Organization, Tender } from "@/stores/tender/types";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import { useCheckUserAccess } from "@/composables/useCheckUserAccess";
import {
  Audience,
  TenderState,
  TenderViewTabLabels,
  User,
} from "@/custom-types/GeneralTypes";
import {
  EventNotification,
  Notifications,
  NotificationsButton,
  NotificationType,
} from "@really/really-events-recorder-components";
import { useStore } from "vuex";

enum PopupComponent {
  USER_PROFILE = "USER_PROFILE",
  NOTIFICATIONS = "NOTIFICATIONS",
}

const { userIsReallyAdmin } = useCheckUserAccess();
const { signOut } = useMsalAuthentication();
const { getters: vuexGetters, state: vuexState } = useStore();
const userStore = useUserStore();
const supplierStore = useSupplierStore();
const route = useRoute();
const router = useRouter();
const { bind, unbind } = vClickOutside.directive;

const popupElement = ref<HTMLDivElement | null>(null);
const state = reactive({
  popupComponent: null as PopupComponent | null,
  clicksOutside: 0,
});

const serviceTypesCms = computed(() => vuexState.cms.serviceTypes);
const allTenders = computed(() => vuexGetters["offers/getAllTenders"]);
const user = computed(() => userStore?.user);
const supplier = computed(() => supplierStore?.supplier);
const supplierOrganization = computed(() => {
  if (!supplier.value) {
    return;
  }
  const organization: Organization = {
    name: supplier.value?.sysName,
    orgNumber: supplier.value.orgNumber,
    locked: false,
  };
  return organization;
});
const menuItems = computed(() => {
  const items = [
    {
      label: "Mine forespørsler",
      icon: MyRequestsIcon,
      to: { name: RouteNames.MY_REQUESTS },
    },
    {
      label: "Mine avtaler",
      icon: MyAgreementsIcon,
      to: { name: RouteNames.MY_AGREEMENTS },
    },
    {
      label: "Leverandørprofil",
      icon: SupplierProfileIcon,
      to: { name: RouteNames.SUPPLIER_PROFILE },
    },
    {
      label: "Prisrobot",
      icon: PriceRobotIcon,
      to: { name: RouteNames.PRICE_ROBOT },
    },
    {
      label: "Trenger du hjelp?",
      icon: HelpIcon,
      to: "https://www.really.no/no/kontakt-oss",
    },
  ];
  if (userStore.userAccount && userIsReallyAdmin(userStore.userAccount)) {
    items.splice(-1, 0, {
      label: "Mine registreringer",
      icon: PriceRobotIcon,
      to: { name: RouteNames.MY_REGISTRATIONS },
    });
  }
  return items.map((item) => {
    return {
      ...item,
      active: typeof item.to !== "string" && item.to.name === route.name,
    };
  });
});

watch(
  () => state.popupComponent,
  (newVal, oldVal) => {
    if (!popupElement.value) {
      return;
    }
    if (oldVal && newVal) {
      state.clicksOutside -= 1;
    }
    if (!oldVal && newVal) {
      bind(popupElement.value, {
        value: () => {
          state.clicksOutside += 1;
          if (state.clicksOutside) {
            state.popupComponent = null;
          }
        },
      });
    } else if (!newVal) {
      unbind(popupElement.value);
      state.clicksOutside = 0;
    }
  },
);
onBeforeUnmount(() => {
  if (popupElement.value) {
    unbind(popupElement.value);
  }
});
const togglePopupComponent = (popupComponent: PopupComponent | null) => {
  state.popupComponent =
    state.popupComponent === popupComponent ? null : popupComponent;
};
const updateUser = (updatedUser: User) => {
  userStore.updateUser(updatedUser);
};
const handleNotificationClick = (
  notification: EventNotification,
  tender: Tender | undefined,
  callbackFn: (navigationSuccessful: boolean) => void,
) => {
  if (!tender) {
    return;
  }
  callbackFn(true);
  const tenderPendingAcceptance =
    tender.tenderState === TenderState.PRICE_REQUESTED ||
    tender.tenderState === TenderState.INFORMATION_REQUESTED ||
    tender.tenderState === TenderState.OFFERED;

  const tenderViewRouteName = tenderPendingAcceptance
    ? RouteNames.MY_REQUESTS
    : RouteNames.MY_AGREEMENTS;
  const notificationType = notification.category;
  if (
    notificationType === NotificationType.MessageSentEvent ||
    notificationType === NotificationType.InformationRequested
  ) {
    return router.push({
      name: tenderViewRouteName,
      query: {
        priceRequestId: tender.priceRequests[0]?.id,
        tab: TenderViewTabLabels.messages,
      },
    });
  }
  return router.push({
    name: tenderViewRouteName,
    query: {
      priceRequestId: tender.priceRequests[0]?.id,
    },
  });
};
const initialize = () => {
  userStore.fetchLoggedInUser();
};
onMounted(() => {
  initialize();
});
</script>

<style lang="scss" scoped>
$header-z-index: 10000;

.app-header {
  height: calc(#{$headerTopBarHeight} + #{$headerBottomBarHeight});

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: calc(#{$headerTopBarHeight} + #{$headerBottomBarHeight});
    z-index: $header-z-index;
    background: #f7f0fa;
  }

  &__top-bar {
    height: $headerTopBarHeight;
    padding: 0 3rem;
    border-bottom: 1px solid #f1e6f5;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__top-bar-right-content {
    height: 100%;
    display: flex;
    align-items: center;
    gap: 1.5rem;
  }

  &__bottom-bar {
    height: $headerBottomBarHeight;
    padding: 0 3rem;
    border-bottom: 1px solid #f1e6f5;
    display: flex;
    align-items: center;
  }

  &__popup {
    visibility: hidden;
    z-index: calc(#{$header-z-index} + 1);
    background: red;
    position: fixed;
    top: $headerTopBarHeight;
    right: 0;
    width: 25rem;
    bottom: 0;

    > * {
      visibility: visible;
      background: white;
      box-shadow: 0 4px 15px 0 #00000026;
    }
  }
}
</style>
