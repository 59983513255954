<template>
  <div>
    <template v-if="serviceReports">
      <template v-if="serviceReports.length">
        <div
          v-for="serviceReport in serviceReports"
          :key="serviceReport.id"
          class="reports-list__item"
        >
          <div
            class="reports-list__item-header"
            :class="getItemHeaderClasses(serviceReport)"
            @click="toggleShowReportDetails(serviceReport)"
          >
            <div class="reports-list__item-title">
              <span>
                {{ serviceReportContent.serviceReportDetails.serviceDateText }}
                {{ formatServiceDate(serviceReport.serviceDate) }}
              </span>
              <span>
                {{
                  serviceReportContent.serviceReportDetails.nextServiceDateText
                }}
                {{ formatNextServiceDate(serviceReport.nextServiceDate) }}
              </span>
            </div>
            <div class="reports-list__item-actions">
              <button
                v-if="isServiceReportCollapsed(serviceReport)"
                class="reports-list__delete-button"
                @click.stop="handleDeleteButtonClick(serviceReport)"
              >
                <img :src="`/images/${deleteIconUrl}`" alt="cms img" />
              </button>
              <img
                :src="`/images/${getListItemCollapseIcon(serviceReport)}`"
                alt="Collapse icon"
              />
            </div>
          </div>
          <b-collapse :visible="isServiceReportCollapsed(serviceReport)">
            <service-report-details :service-report="serviceReport" />
          </b-collapse>
        </div>
      </template>
      <div v-else class="reports-list__no-items">
        {{ serviceReportContent.myServiceReport.emptyText }}
      </div>
    </template>
    <div v-else class="reports-list__loader">
      <b-spinner class="align-middle" type="grow" />
    </div>
    <delete-service-report-modal
      v-if="reportIdToDelete"
      v-model="showServiceReportDeleteModal"
      :service-report-id="reportIdToDelete"
      @deleted="onReportDeleted"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import ServiceReportDetails from "./ServiceReportDetails";
import DeleteServiceReportModal from "@/components/DeleteServiceReportModal/DeleteServiceReportModal";
import dayjs from "dayjs";

export default {
  components: { DeleteServiceReportModal, ServiceReportDetails },
  props: {
    tenderId: {
      type: Number,
      required: true,
    },
    customerOrgNumber: {
      type: String,
      required: true,
    },
    serviceOfferId: {
      type: Number,
      required: true,
    },
    serviceReports: {
      type: [null, Array],
      default: null,
    },
  },
  emits: ["delete-service-report"],

  data() {
    return {
      showServiceReportDetails: [],
      showServiceReportDeleteModal: false,
      reportIdToDelete: 0,
    };
  },
  computed: {
    ...mapState(["api"]),
    ...mapGetters("cms", {
      serviceReportContent: "getServiceReportContent",
    }),
    deleteIconUrl() {
      return this.$store.state.cms.serviceReportContent.formContent.attachments
        .icon;
    },
  },
  methods: {
    toggleShowReportDetails(serviceReport) {
      const matchingIndex = this.showServiceReportDetails.findIndex(
        (id) => id === serviceReport.id,
      );
      if (matchingIndex >= 0) {
        this.showServiceReportDetails.splice(matchingIndex, 1);
      } else {
        this.showServiceReportDetails.push(serviceReport.id);
      }
    },
    onReportDeleted() {
      this.$emit("delete-service-report", this.reportIdToDelete);
    },
    handleDeleteButtonClick(serviceReport) {
      this.reportIdToDelete = serviceReport.id;
      this.showServiceReportDeleteModal = true;
    },
    formatServiceDate(date) {
      return dayjs(date).format("YYYY-MM-DD");
    },
    formatNextServiceDate(date) {
      return dayjs(date).format("YYYY-MM");
    },
    isServiceReportCollapsed(serviceReport) {
      return this.showServiceReportDetails.includes(serviceReport.id);
    },
    getListItemCollapseIcon(tender) {
      return this.isServiceReportCollapsed(tender)
        ? this.$store.state.cms.profile.body.tenderUnfoldIcon
        : this.$store.state.cms.profile.body.tenderFoldIcon;
    },
    getItemHeaderClasses(serviceReport) {
      return this.isServiceReportCollapsed(serviceReport)
        ? ["reports-list__item-header--collapsed"]
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.reports-list {
  &__item-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    color: $color-primary-darker;
    border-bottom: 1px solid #aea993;
    cursor: pointer;

    &--collapsed {
      background: #fff;
    }
  }

  &__item-title {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-sm;
    display: flex;
    gap: 2rem;
  }

  &__item-actions {
    display: flex;
    align-items: center;
    gap: 2rem;
  }

  &__no-items {
    text-align: center;
    color: $color-primary-darker;
    font-weight: $font-weight-semi-bold;
    padding: 1.5rem;
  }

  &__delete-button {
    border: none;
    padding: 0;
    background: transparent;
  }

  &__loader {
    display: flex;
    justify-content: center;
    padding: 1.5rem;
    color: $color-primary;
  }
}

.service-report {
  &__actions {
    display: flex;
    align-items: center;
    justify-content: right;
  }

  &__delete-button {
    $buttonPadding: 0.5rem;
    border: none;
    background: transparent;
    margin-right: 1.5rem;
    margin-top: -$buttonPadding;
    margin-bottom: -$buttonPadding;
    padding: $buttonPadding;
  }
}

:deep(.row-class-1) {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-sm;
  font-weight: 500;
  color: $color-primary-darker;
  cursor: pointer;

  td {
    background-color: #faf9f3;
    border-bottom: 0.063rem solid #aea993 !important;
  }
}

:deep(.row-class-2) {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-sm;
  font-weight: 500;
  color: $color-primary-darker;
  cursor: pointer;

  td {
    background-color: #fff;
    border-bottom: 0.063rem solid #aea993 !important;
  }
}

:deep(.name-column-class) {
  width: 95%;
  background-color: #fff;
  border-bottom: 0.063rem solid #aea993;
  color: $color-primary-darker;
  font-weight: 600;
  font-size: $font-size-sm;
  padding-left: 1.5rem !important;
}

.no-service-reports {
  font-size: $font-size-sm;
  font-weight: 400;
  color: $color-primary-darker;
  margin-top: 0.5rem;
}
</style>
