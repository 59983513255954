<template>
  <div class="base-price-calculator">
    <div class="base-price-calculator__title">{{ title }}</div>
    <div v-if="description" class="base-price-calculator__description">
      {{ description }}
    </div>
    <div class="base-price-calculator__calculator-section">
      <slot />
    </div>
    <div class="billable-price">
      <div class="billable-price__title">
        {{ billablePriceTitle }}
      </div>
      <div class="billable-price__description">
        {{ billablePriceDescription }}
      </div>
      <div class="billable-price__amount">
        {{ billablePrice }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      default: "",
    },
    billablePriceTitle: {
      type: String,
      required: true,
    },
    billablePriceDescription: {
      type: String,
      required: true,
    },
    billablePrice: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.base-price-calculator {
  background: $color-primary-pink;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 1.5rem;

  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
  }

  &__description {
    margin-top: 0.25rem;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__calculator-section {
    margin-top: 1.5rem;
  }

  &__item-count-label {
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__item-count-input-wrapper {
    margin-top: 0.5rem;
  }
}

.billable-price {
  margin-top: 2rem;

  &__title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__description {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__amount {
    margin-top: 1.125rem;
    background: $color-primary-pink-dark;
    border-radius: 4px;
    text-align: center;
    padding: 0.75rem 0;
    font-weight: 600;
    font-size: $font-size-lg;
  }
}
</style>
