<template>
  <div class="base-input">
    <input
      v-model="inputValue"
      class="base-input__input"
      :class="inputClasses"
      :style="inputStyles"
      :placeholder="placeholder"
      :disabled="readOnly"
      @change="onChange"
      @blur="$emit('blur')"
      @focus="$emit('focus')"
    />
    <span
      v-if="rightText"
      ref="rightTextElement"
      class="base-input__right-text"
      >{{ rightText }}</span
    >
  </div>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [Number, String],
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    borderRadius: {
      type: String,
      default: "", //left-only
    },
    error: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["focus", "blur", "update:modelValue", "change"],
  data() {
    return {
      rightTextWidth: 0,
    };
  },
  computed: {
    inputClasses() {
      const baseClass = "base-input";
      const classes = [`${baseClass}--border-radius-${this.borderRadius}`];
      if (this.error) {
        classes.push(`${baseClass}--error`);
      }
      return classes;
    },
    inputStyles() {
      const styles = {};
      if (this.rightText) {
        styles.paddingRight = `${this.rightTextWidth / 16 + 1 + 0.5}rem`;
      }
      return styles;
    },
    inputValue: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  mounted() {
    this.$watch(
      "rightText",
      () => {
        setTimeout(() => {
          if (this.rightText && this.$refs.rightTextElement) {
            this.rightTextWidth =
              this.$refs.rightTextElement.getBoundingClientRect().width;
          }
        }, 0);
      },
      { immediate: true },
    );
  },
  methods: {
    onChange(event) {
      this.$emit("change", event.target.value);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-input {
  position: relative;

  &__input {
    width: 100%;
    border: 1px solid #1d1d1d;
    border-radius: 0.25rem;
    padding: 0 16px;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 1.2;
    height: 2.75rem;
  }

  &--error {
    border: 1px solid $color-error;
  }

  &--border-radius-left-only {
    border-radius: 4px 0 0 4px;
  }

  &__right-text {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: $font-size-sm;
  }
}
</style>
