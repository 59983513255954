export default {
  setSupplier: (state, payload) => {
    state.supplier = payload;
  },
  setSupplierDetails: (state, payload) => {
    state.supplierDetails = payload;
  },
  setCategoriesBySupplierType: (state, payload) => {
    state.categoriesBySupplierType = payload;
  },
  setSupplierDetailsError: (state, payload) => {
    state.supplierDetails.error = payload;
  },
  setFormDisabled: (state, payload) => {
    state.formDisabled = payload;
  },
  saveSelectedAboutUsSupplierType(state, selectedSupplierType) {
    state.selectedAboutUsSupplierType = selectedSupplierType;
  },
  saveSupplierSustainabilityGoals(state, payload) {
    state.supplierDetails.sustainabilityGoals = payload;
  },
  saveSupplierCertifications(state, payload) {
    state.supplierDetails.certifications = payload;
  },
};
