<template>
  <base-delete-modal
    v-model="showModal"
    :icon="icon"
    :title="deleteItemModalCms.title"
    :description="deleteItemModalCms.content[0]"
    :cancel-button-text="cancelButtonText"
    :delete-button-text="deleteButtonText"
    @delete-item="$emit('delete-activity-item', activity)"
  />
</template>

<script>
import BaseDeleteModal from "./BaseDeleteModal.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { BaseDeleteModal },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    deleteItemModalCms: {
      type: Object,
      required: true,
    },
    activity: {
      type: Object,
      required: true,
    },
  },
  emits: ["delete-activity-item", "update:modelValue"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    cancelButtonText() {
      return this.deleteItemModalCms.buttons.find(
        (text) => text.label === "cancelButton",
      ).title;
    },
    deleteButtonText() {
      return this.deleteItemModalCms.buttons.find(
        (text) => text.label === "deleteButton",
      ).title;
    },
    icon() {
      const icon = this.deleteItemModalCms.icons.find(
        (text) => text.label === "popupIcon",
      ).icon;
      return this.getIconUrl(icon).toString() || "";
    },
  },
  methods: {
    getIconUrl(icon) {
      return cmsService.resolveImage(icon);
    },
  },
};
</script>

<style lang="scss" scoped></style>
