<template>
  <div class="base-menu">
    <div
      v-for="item in items"
      :key="item.id"
      class="base-menu__menu-item"
      @click="handleMenuItemClick(item)"
    >
      {{ item.text }}
    </div>
  </div>
</template>

<script>
export default {
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  emits: ["item-clicked"],
  data() {
    return {};
  },
  methods: {
    handleMenuItemClick(item) {
      this.$emit("item-clicked", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.base-menu {
  background: #ffffff;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0.5rem 0;

  &__menu-item {
    padding: 0.75rem;
    font-weight: 600;
    font-size: $font-size-sm;
    line-height: 1.2;
    cursor: pointer;

    &:hover {
      background: #f1f1f1;
    }
  }
}
</style>
