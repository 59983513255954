import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum UserApiEndpoints {
  FETCH_SUPPLIER_USERS = "fetchSupplierUsers",
  GET_USER_BY_EMAIL = "getUserByEmail",
  GET_USER_INFO = "getUserInfo",
  EDIT_USER_INFO = "editUserInfo",
  CREATE_USER = "createUser",
}

export const UserApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/User",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(UserApiEndpoints.FETCH_SUPPLIER_USERS),
      method: "GET",
      url: "/users/supplier",
      description: "Fetch supplier users",
    },
    {
      key: Symbol.for(UserApiEndpoints.GET_USER_BY_EMAIL),
      method: "GET",
      url: "/Users/Email",
      description: "Get user by email",
    },
    {
      key: Symbol.for(UserApiEndpoints.GET_USER_INFO),
      method: "GET",
      url: "/Users",
      description: "Get user info",
    },
    {
      key: Symbol.for(UserApiEndpoints.EDIT_USER_INFO),
      method: "PUT",
      url: "/Edit",
      description: "Edit user info",
    },
    {
      key: Symbol.for(UserApiEndpoints.CREATE_USER),
      method: "POST",
      url: "/Create",
      description: "Create user",
    },
  ],
};
