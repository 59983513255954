<template>
  <base-supplier-offer-modal
    v-model="showModal"
    :icon="modalIcon"
    :title="successOfferModalCms.title"
    :description="successOfferModalCms.content"
    :align-items="'center'"
  >
    <!-- Buttons slot -->
    <template #buttons>
      <div class="modal__action-buttons">
        <primary-button @click="$emit('success-event', reloadMyAgreements)">
          {{ successButtonText }}
        </primary-button>
      </div>
    </template>
  </base-supplier-offer-modal>
</template>

<script>
import BaseSupplierOfferModal from "./BaseSupplierOfferModal.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { BaseSupplierOfferModal, PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    successOfferModalCms: {
      type: Object,
      required: true,
    },
    reloadMyAgreements: {
      type: Boolean,
      required: false,
    },
  },
  emits: ["success-event", "update:modelValue"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    successButtonText() {
      const button =
        this.findItemByLabel(this.successOfferModalCms.buttons, "itIsGood") ??
        {};
      return button.title || "";
    },
    modalIcon() {
      const iconItem =
        this.findItemByLabel(this.successOfferModalCms.icons, "documentIcon") ??
        {};
      const iconUrl = this.getIconUrl(iconItem.icon);
      return iconUrl.toString() || "";
    },
  },
  methods: {
    getIconUrl(icon) {
      if (!icon) return "";
      try {
        return cmsService.resolveImage(icon);
      } catch (error) {
        return "";
      }
    },
    findItemByLabel(array, label) {
      if (!array || !label) return null;
      return array.find((item) => item.label === label);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__action-buttons {
    margin-top: 66px;
    display: flex;
    justify-content: center;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}
</style>
