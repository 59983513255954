import { computed, Ref } from "vue";
import { useSupplierStore } from "@/stores/supplier";
//import { useSupplierSegmentsStore } from "@/stores/supplierSegments";
import { useUserStore } from "@/stores/user";
import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";

interface UseSupplierCmsData {
  isSupplierLoaded: Ref<boolean>;
  fetchSupplierAndCmsContent: () => Promise<void>;
}

const criticalContentKeys: ContentType[] = [
  ContentType.HEADER_CMS,
  ContentType.HEADER_MENU_CMS,
  ContentType.SERVICE_TYPES_CMS,
  ContentType.SUPPLIER_TASKS_PAGE_CMS,
];

const nonCriticalContentKeys: ContentType[] = [
  ContentType.SUPPLIER_PROFILE_PAGE_CMS,
  ContentType.SUPPLIER_CERTIFICATIONS_CMS,
  ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS,
  ContentType.SUPPLIER_PRICE_ROBOT_PAGE_CMS,
  ContentType.PRICE_GROUPS_CMS,
  ContentType.SUPPLIER_REGISTRATIONS_PAGE_CMS,
];

export function useSupplierCmsData(): UseSupplierCmsData {
  const supplierStore = useSupplierStore();
  const userStore = useUserStore();
  //const supplierSegmentsStore = useSupplierSegmentsStore();

  const { fetchAndSetCmsContent } = useCms();

  const storedUserId = computed(
    () => userStore.userAccount?.idTokenClaims?.sub ?? "",
  );

  const isSupplierLoaded = computed(() => !!supplierStore.supplier);

  const fetchSupplierAndCmsContent = async (): Promise<void> => {
    try {
      await fetchCmsContent(criticalContentKeys, "critical");
      await fetchCmsContent(nonCriticalContentKeys, "non-critical");
      await supplierStore.fetchSupplierByUserId(storedUserId.value);
      //await supplierSegmentsStore.fetchSupplierTypes();
    } catch (error) {
      console.error("Error fetching supplier and CMS content:", error);
    }
  };

  const fetchCmsContent = async (
    contentKeys: ContentType[],
    contentType: "critical" | "non-critical",
  ): Promise<void> => {
    try {
      await Promise.all(contentKeys.map((key) => fetchAndSetCmsContent(key)));
    } catch (error) {
      console.error(`Error fetching ${contentType} CMS content:`, error);
      // TODO: Handle content fetch error based on contentType
    }
  };

  return { isSupplierLoaded, fetchSupplierAndCmsContent };
}
