import cmsService from "@/services/cmsService";
import { v2Config } from "@/config/cmsConfig";

const getOtherText = (otherText, label) => {
  const text = otherText.find((record) => record.label === label);
  return text ? text.title : "";
};

const getIconUrl = (icons, label) => {
  const matchingIcon = icons?.find((icon) => icon.label === label);
  return matchingIcon ? cmsService.resolveImage(matchingIcon.icon).url() : "";
};

const getFileUrl = (files, label) => {
  const filedoc = files.find((filedoc) => filedoc.label === label);
  const ref = filedoc.document.asset._ref;
  const [, id, extension] = ref.split("-");
  return `https://cdn.sanity.io/files/${v2Config.projectId}/${v2Config.dataset}/${id}.${extension}`;
};

const getCmsItem = (items, label) => {
  return items?.find((record) => record.label === label);
};

const getCmsItemText = (items, label) => {
  const item = getCmsItem(items, label);
  return item ? item.title : "";
};

const getServiceTypeCms = (serviceTypesCms, serviceTypeLabel) => {
  return serviceTypesCms?.find(
    (serviceType) => serviceType.label === serviceTypeLabel,
  );
};
const getServiceCategoryCms = (serviceTypeCms, serviceCategoryLabel) => {
  return serviceTypeCms?.serviceCategories?.find(
    (serviceCategory) => serviceCategory.label === serviceCategoryLabel,
  );
};
const getServiceName = (serviceCategoryCms, serviceTypeCms) => {
  const serviceCategoryName = serviceCategoryCms?.title;
  const serviceTypeName = serviceTypeCms?.name;
  const title = serviceCategoryName ? serviceCategoryName : serviceTypeName;
  return title || "";
};

export {
  getOtherText,
  getIconUrl,
  getFileUrl,
  getCmsItem,
  getCmsItemText,
  getServiceTypeCms,
  getServiceCategoryCms,
  getServiceName,
};
