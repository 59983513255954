import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

const BASE_URI = import.meta.env.VITE_APP_BASE_URI;
const AXIOS = Axios.create({
  baseURL: `${BASE_URI}`,
  headers: {
    "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
  },
});

export default {
  fetchSupplierDetails(supplierId) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierDetailsApi}${supplierId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  fetchCategoriesBySupplierType(supplierId, supplierTypeId) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierTypesBySupplierApi}${supplierId}/supplierType/${supplierTypeId}/categories`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  async updateCategoriesBySupplierType(
    supplierId,
    supplierTypeId,
    supplierCategories,
  ) {
    const url = `${apiConfig.apiConfig.getSupplierTypesBySupplierApi}${supplierId}/supplierType/${supplierTypeId}/categories`;
    return AXIOS.put(url, supplierCategories);
  },
  async createNewSupplier(query) {
    return Axios.post(
      `${apiConfig.apiConfig.newSupplierApi}/${query.supplierTypeId}`,
      query,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  async getSupplier(userId) {
    const url = `/Supplier/user/${userId}`;
    return AXIOS.get(url).catch((reason) => console.error(reason));
  },
  async getSupplierDetails(supplierId, supplierTypeId) {
    const url = `/${supplierId}/supplierType/${supplierTypeId}`;
    return AXIOS.get(url);
  },
  async createSupplierDetails(supplierId, supplierTypeId, supplierDetails) {
    const url = `/${supplierId}/supplierType/${supplierTypeId}`;
    return AXIOS.put(url, supplierDetails);
  },
  async updateSupplierDetails(supplierId, supplierTypeId, supplierDetails) {
    const url = `/${supplierId}/supplierType/${supplierTypeId}/update`;
    return AXIOS.put(url, supplierDetails);
  },
  async getSupplierSustainabilityGoals(supplierId, supplierTypeId) {
    const url = `/${supplierId}/supplierType/${supplierTypeId}/sustainability`;
    return AXIOS.get(url);
  },
  async updateSupplierSustainabilityGoals(supplierId, supplierTypeId, goals) {
    const url = `/sustainability/supplier/${supplierId}/supplierType/${supplierTypeId}`;
    return AXIOS.put(url, goals);
  },
  async getSupplierCertifications(supplierId, supplierTypeId) {
    const url = `/${supplierId}/supplierType/${supplierTypeId}/certifications`;
    return AXIOS.get(url);
  },
  async updateSupplierCertifications(
    supplierId,
    supplierTypeId,
    certifications,
  ) {
    const url = `/certifications/supplier/${supplierId}/supplierType/${supplierTypeId}`;
    return AXIOS.put(url, certifications);
  },
};
