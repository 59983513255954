<template>
  <div class="checkbox">
    <label class="checkbox__label">
      <input
        class="checkbox__input"
        type="checkbox"
        :checked="modelValue"
        :value="modelValue"
        :unchecked-value="false"
        @change="onChange"
      />
      <span class="checkbox__checkmark" />
      <slot>
        <span class="checkbox__label-text">{{ label }}</span>
      </slot>
    </label>
  </div>
</template>

<script>
export default {
  model: {
    prop: "modelValue",
    event: "change",
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["change"],
  methods: {
    onChange() {
      this.$emit("change", !this.modelValue);
    },
  },
};
</script>

<style lang="scss" scoped>
.checkbox {
  line-height: 1;

  &__label {
    display: inline-flex;
    align-items: center;
    position: relative;
    margin: 0;
    cursor: pointer;
  }

  &__input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  &__checkmark {
    position: relative;
    height: 1.5rem;
    width: 1.5rem;
    border: 1px solid $color-black;
    border-radius: 4px;

    &:after {
      position: absolute;
      content: "";
      left: 50%;
      top: 50%;
      width: 6px;
      height: 12px;
      border: solid $color-black;
      border-width: 0 2px 2px 0;
      transform: translate(-50%, -50%) rotate(45deg);
      display: none;
    }
  }

  &__label-text {
    margin-left: 0.5rem;
    font-weight: 400;
  }
}

input:checked ~ .checkbox__checkmark:after {
  display: block;
}
</style>
