<template>
  <div class="conversation-list">
    <div
      v-for="conversation in formattedConversations"
      :key="conversation.conversationId"
      class="conversation"
      @click="onConversationClick(conversation.conversationId)"
    >
      <div class="conversation__header">
        <div class="conversation__recipient-name">
          {{ conversation.recipientName }}
        </div>
        <div class="conversation__header-right-content">
          <div class="conversation__latest-date">
            {{ conversation.latestDate }}
          </div>
          <img :src="`/images/arrow-right.svg`" alt="ArrowRight" />
        </div>
      </div>
      <div class="conversation__latest-message">
        {{ conversation.latestMessage }}
      </div>
      <div class="conversation__footer">
        <div class="conversation__message-type">
          {{ conversation.messageType }}
        </div>
        <img
          v-if="conversation.latestMessageAttachments.length"
          :src="`/images/attachment.svg`"
          alt="Attachment"
        />
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from "dayjs";
import { MessageType } from "@/custom-types/types";

export default {
  props: {
    recipientName: {
      type: String,
      required: true,
    },
    conversations: {
      type: Array,
      required: true,
    },
  },
  emits: ["conversation-clicked"],
  computed: {
    sortedConversations() {
      return this.conversations.slice().sort((conversationA, conversationB) => {
        const latestDateA = new Date(conversationA.latestMessage.createdOn);
        const latestDateB = new Date(conversationB.latestMessage.createdOn);
        return latestDateB.getTime() - latestDateA.getTime();
      });
    },
    formattedConversations() {
      return this.sortedConversations.map((conversation) => {
        const latestMessage = conversation.latestMessage;
        return {
          recipientName: this.recipientName,
          latestDate: dayjs(latestMessage.createdOn).format("DD.MM.YYYY"),
          latestMessage: latestMessage.messageBody,
          messageType: this.getTranslatedMessageType(latestMessage.messageType),
          latestMessageAttachments: latestMessage.attachments,
          conversationId: latestMessage.conversationId,
        };
      });
    },
  },
  methods: {
    onConversationClick(conversationId) {
      this.$emit("conversation-clicked", conversationId);
    },
    getTranslatedMessageType(messageType) {
      switch (messageType) {
        case MessageType.GeneralMessage:
          return "Generell";
        case MessageType.Complaint:
          return "Klage";
        case MessageType.Compliment:
          return "Skryt";
        default:
          return "Prisforespørsel";
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  padding: 1rem 2rem;
  background: white;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;

  &:not(:first-child) {
    margin-top: 0.25rem;
  }

  &:hover {
    background: #f6f6f6;
  }

  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__header-right-content {
    display: flex;
    align-items: center;
  }

  &__latest-date {
    font-weight: 500;
    font-size: $font-size-base;
  }

  &__recipient-name {
    font-weight: 700;
    font-size: $font-size-base;
  }

  &__latest-message {
    max-width: 20rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__footer {
    display: flex;
    align-items: center;
  }

  &__message-type {
    padding: 0.5rem 1rem;
    font-weight: 600;
    font-size: $font-size-sm;
  }
}
</style>
@/types/AppTypes
