<template>
  <div class="property-selection">
    <b-form-group id="input-group-property">
      <base-select-input v-model="property" :options="propertyTypes" />
    </b-form-group>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import BaseSelectInput from "@/components/Base/BaseSelectInput/BaseSelectInput.vue";

export default {
  name: "PropertyTypeSelector",
  components: { BaseSelectInput },
  emits: ["get-supplier-segments"],
  data() {
    return {
      property: null,
      propertyTypes: [],
    };
  },
  computed: {
    ...mapGetters("cms", {
      selectedSupplierType: "getSelectedSupplierType",
      serviceTypes: "getServiceTypes",
    }),
    ...mapState(["cms", "api"]),
  },
  watch: {
    "$store.state.api.propertyTypes": function () {
      this.loadPropertyTypes();
    },
    property: {
      handler(newVal) {
        if (newVal) {
          this.getSupplierSegments();
        }
      },
      immediate: true,
    },
  },
  async mounted() {
    await this.loadPropertyTypes();
    setTimeout(() => {
      this.selectFirstOption();
    }, 2000);
  },
  methods: {
    getSupplierSegments() {
      const property = this.$store.state.api.propertyTypes.find(
        (type) => type.id === this.property,
      );
      const getSupplierSegmentsParams = {
        supplierType: {
          label: this.selectedSupplierType.sysLabel,
          id: this.selectedSupplierType.id,
        },
        propertyType: { label: property.sysLabel, id: property.id },
      };
      this.$store.dispatch(
        "api/saveSupplierSegmentsParams",
        getSupplierSegmentsParams,
      );
      this.$emit("get-supplier-segments", getSupplierSegmentsParams);
    },
    async loadPropertyTypes() {
      if (this.$store.state.api.propertyTypes.length > 0) {
        this.propertyTypes = [
          {
            value: null,
            label: "Næringstype",
            disabled: true,
          },
          ...this.$store.state.api.propertyTypes.map((property) => ({
            value: property.id,
            label: this.getPropertyLabelText(property.sysLabel),
          })),
        ];
      }
    },
    getPropertyLabelText(sysLabel) {
      if (sysLabel === "unknown") return "";
      if (!this.serviceTypes) return sysLabel;
      const serviceType = this.serviceTypes.find(
        (serviceType) =>
          serviceType.label === this.selectedSupplierType.sysLabel,
      );
      const propertyType = serviceType?.propertyTypes.find(
        (serviceType) => serviceType.label === sysLabel,
      );
      return propertyType ? propertyType.title : sysLabel;
    },
    selectFirstOption() {
      if (this.propertyTypes.length > 1) {
        this.property = this.propertyTypes[1].value;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.property-selection {
  width: 22rem;
  margin-left: 2.7rem;
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-base;
}
</style>
