<template>
  <div class="info-tag-group">
    <InfoTag
      v-for="(item, index) in selectedCertifications"
      :key="index"
      :title="item?.title"
      :subtitle="item?.subtitle"
      :icon-src="certificationsIcon"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { useCmsStore } from "@/stores/cms";
import { ContentType } from "@/custom-types/CmsContentTypes";

const cmsStore = useCmsStore();

interface Goal {
  title: string;
  certificationLabel: string;
  subtitle: string;
  selected: boolean;
}

const props = defineProps({
  certifications: {
    type: Array as PropType<Goal[]>,
    default: () => [],
    required: true,
  },
  certificationsIcon: {
    type: String,
    default: "",
  },
});

const certificationsCMS = cmsStore[ContentType.SUPPLIER_CERTIFICATIONS_CMS];

const selectedCertifications = computed(() => {
  return props.certifications
    .filter((certification) => certification.selected)
    .map((certification) => ({
      ...certification,
      title:
        certificationsCMS?.find(
          (certificationCms) =>
            certificationCms.label === certification.certificationLabel,
        )?.title || certification.certificationLabel,
    }));
});
</script>

<style lang="scss" scoped>
.info-tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
