<template>
  <div class="price-robot-footer__row">
    <div class="price-robot-footer__bottom-arrow">
      <img :src="arrowDownIcon" class="price-robot-footer__icon" />
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { useStore } from "vuex";

const store = useStore();

const cms = computed(() => store.state.cms);
const arrowDownIcon = computed(
  () => `/images/${cms.value.myPrices.bottomArrowIcon}`,
);
</script>

<style lang="scss" scoped>
.price-robot-footer__row {
  display: flex;
}

.price-robot-footer__bottom-arrow {
  margin-left: 1.5rem;
  margin-bottom: -2.2em;
  z-index: 999;
}

.price-robot-footer__icon {
  margin-left: 1rem;
}
</style>
