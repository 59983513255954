import { Router } from "vue-router";
import { useUserValidation } from "@/composables/useUserValidation";
import { RouteNames } from "@/router/types";

export function registerValidateUserGuard(router: Router): void {
  router.beforeEach(async (to, from, next) => {
    const { validateUser } = useUserValidation();
    const isUserValid = await validateUser();

    if (isUserValid) {
      if (to.name === RouteNames.LOADING) {
        next({ name: RouteNames.SUPPLIER_ADMIN });
      } else {
        next();
      }
    } else {
      next({ name: RouteNames.LOADING });
    }
  });
}
