<template>
  <div class="tender-detail-tabs">
    <div class="tender-detail-tabs__container">
      <button
        v-for="tab in tabs"
        :key="tab.label"
        class="tender-detail-tabs__tab"
        :class="{
          'tender-detail-tabs__tab--active': activeTabLabel === tab.label,
        }"
        @click="selectTab(tab)"
      >
        {{ tab.title }}
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { TenderViewTabLabels } from "@/custom-types/GeneralTypes";

interface Tab {
  label: string;
  title: string;
}

interface Props {
  tabs: Tab[];
  activeTabLabel: string;
}

withDefaults(defineProps<Props>(), {
  activeTabLabel: TenderViewTabLabels.priceRequest,
});

const emit = defineEmits<{
  (e: "tab-change", tab: Tab): void;
}>();

const selectTab = (tab: Tab) => {
  emit("tab-change", tab);
};
</script>

<style lang="scss" scoped>
.tender-detail-tabs {
  width: 100%;

  &__container {
    display: flex;
    align-items: center;
    background: white;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 4px;
  }

  &__tab {
    flex: 1;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 20px;
    background: transparent;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    color: black;
    font-size: 12px;
    font-weight: 600;
    text-transform: uppercase;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 18rem;

    &--active {
      background-color: #441b49 !important;
      color: white;

      &:hover {
        background-color: #441b49 !important;
      }
    }

    &:hover {
      background-color: #f0f0f0;
    }
  }
}
</style>
