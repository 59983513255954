import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

export default {
  sendMessage(query) {
    return Axios.post(`${apiConfig.apiConfig.sendMessageApi}`, query, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    });
  },

  fetchSupplierConversations(supplierRequestId) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierConversationsApi}${supplierRequestId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  fetchConversationMessages(conversationId) {
    return Axios.get(
      `${apiConfig.apiConfig.getConversationMessagesApi}${conversationId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  fetchSupplierUnreadMessagesCount(query) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierUnreadMessagesCountApi}address/${query.addressId}/supplier/${query.supplierId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  fetchAllSupplierUnreadMessagesCount(supplierId) {
    return Axios.get(
      `${apiConfig.apiConfig.getAllSupplierUnreadMessagesCountApi}Supplier/${supplierId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  markMessagesRead(messageIds) {
    return Axios.patch(
      `${apiConfig.apiConfig.markMessagesReadApi}`,
      { messageIds },
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  fetchPriceRequestAttachment(attachmentId, tenderId, supplierRequestId) {
    return Axios.get(
      `${apiConfig.apiConfig.getFileAttachmentApi}${attachmentId}/tender/${tenderId}/request/${supplierRequestId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
        responseType: "blob",
      },
    );
  },
};
