<template>
  <div class="user-profile">
    <user-info
      :user="user"
      :organization="organization"
      @updated-user="updateUser"
    />
    <organization-info :organization="organization" />
    <button class="user-profile__logout-button" @click="signOut">
      <img alt="Logout icon" :src="LogoutIcon" />
      <span>Logg ut</span>
    </button>
  </div>
</template>

<script lang="ts" setup>
import UserInfo from "@/components/UserProfile/UserInfo.vue";
import { User } from "@/custom-types/GeneralTypes";
import OrganizationInfo from "@/components/UserProfile/OrganizationInfo.vue";
import { Organization } from "@/stores/tender/types";
import LogoutIcon from "@/assets/img/logout.svg";

const emit = defineEmits(["sign-out", "updated-user"]);
withDefaults(
  defineProps<{
    user?: User | null;
    organization?: Organization | null;
  }>(),
  {
    user: undefined,
    organization: undefined,
  },
);

const signOut = async () => {
  emit("sign-out");
};
const updateUser = (updatedUser: User) => {
  emit("updated-user", updatedUser);
};
</script>

<style lang="scss" scoped>
.user-profile {
  padding: 1.25rem;

  &__logout-button {
    margin-top: 1.5625rem;
    display: flex;
    align-items: center;
    gap: 0.75rem;
    width: 100%;
    border: none;
    border-top: 1px solid #e6e6e6;
    border-bottom: 1px solid #e6e6e6;
    background-color: transparent;
    padding: 0.5rem 0.75rem;
    text-align: left;

    &:hover {
      background-color: #f1f1f1;
    }
  }
}
</style>
