<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    class="base-btn"
    @click="handleButtonClick"
  >
    <span
      v-if="showIcon || $slots.icon"
      class="base-btn__icon-container"
      data-testid="button-icon"
    >
      <slot name="icon">
        <img
          v-if="showIcon"
          :src="icon"
          class="base-btn__icon"
          alt="Button icon"
        />
      </slot>
    </span>
    <span v-if="showText" :class="textClasses" data-testid="button-text">
      {{ text }}
    </span>
  </button>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      default: "primary", // "primary" | "tertiary"
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    uppercase: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
  },

  emits: ["click"],

  computed: {
    showIcon() {
      return this.icon;
    },
    showText() {
      return this.text;
    },
    buttonClasses() {
      const baseClass = "base-btn";
      const classes = [`${baseClass}--${this.type}`];
      if (this.disabled) classes.push(`${baseClass}--disabled`);
      if (this.fullWidth) classes.push(`${baseClass}--full-width`);
      return classes;
    },
    textClasses() {
      return this.uppercase ? ["base-btn__text--uppercase"] : [];
    },
  },
  methods: {
    handleButtonClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-btn {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-sm;
  background: none;
  border: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 4px;
  padding: 0.75rem 1.875rem;

  &__icon-container {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    width: 100%;
  }

  &:active:enabled {
    box-shadow: none;
  }

  &:disabled {
    cursor: no-drop;
  }

  &--primary {
    background-color: $color-primary;
    color: white;

    &:active:enabled {
      background-color: $color-black;
    }

    &:hover:enabled {
      box-shadow: 0 2px 10px 0 rgba($color-black, 0.25);
    }

    &:hover:enabled,
    &:focus:enabled {
      background-color: $color-primary-dark;
    }

    &:disabled {
      background-color: #727272;
    }
  }

  &--tertiary {
    border: 1px solid $color-black;
    color: $color-black;

    &:disabled {
      border: 1px solid #727272;
      opacity: 0.3;
    }
  }

  &__text--uppercase {
    text-transform: uppercase;
  }

  &--full-width {
    width: 100%;
  }
}
</style>
