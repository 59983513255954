import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum CompanyRegistryApiEndpoints {
  FIND_COMPANY = "findCompany",
}

export const CompanyRegistryApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/CompanyRegistry",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(CompanyRegistryApiEndpoints.FIND_COMPANY),
      method: "GET",
      url: "/FindCompany",
      description: "Find company",
    },
  ],
};
