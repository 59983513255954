import {
  ActivityFrequency,
  ServiceActivityCmsLabelType,
} from "@/custom-types/types";
import { getCmsItem } from "@/utilities/cmsUtilities";

const resolveFrequency = (serviceActivityCms, frequencyGroup, frequency) => {
  if (!frequencyGroup) {
    return "";
  }
  if (frequencyGroup === ActivityFrequency.Fixed) {
    return serviceActivityCms.title;
  }
  const frequencyCms = serviceActivityCms?.frequencies?.find(
    (cms) => cms.label === frequency,
  );
  return frequencyCms ? frequencyCms.title : frequency;
};

const serviceActivityCms = (serviceActivitiesCms, frequencyGroup) => {
  const cmsLabelMapping = {
    [ActivityFrequency.Monthly]: ServiceActivityCmsLabelType.Monthly,
    [ActivityFrequency.Yearly]: ServiceActivityCmsLabelType.Yearly,
    default: ServiceActivityCmsLabelType.Fixed,
  };

  const cmsLabel = cmsLabelMapping[frequencyGroup] || cmsLabelMapping.default;

  return getCmsItem(serviceActivitiesCms, cmsLabel);
};

const getAugmentedActivityFrequencies = (frequencies, frequenciesCms) => {
  const augmentedFrequencies = [];
  frequenciesCms.forEach((frequencyCms) => {
    const frequency = frequencies.find(
      (frequency) => frequency.label === frequencyCms.label,
    );
    if (frequency) {
      augmentedFrequencies.push({
        label: frequencyCms.title,
        value: frequency.label,
      });
    }
  });
  frequencies.forEach((frequency) => {
    const frequencyCms = frequenciesCms.find(
      (frequencyCms) => frequencyCms.label === frequency.label,
    );
    if (!frequencyCms) {
      augmentedFrequencies.push({
        label: frequency.label,
        value: frequency.label,
      });
    }
  });
  return augmentedFrequencies;
};

export {
  resolveFrequency,
  serviceActivityCms,
  getAugmentedActivityFrequencies,
};
