<template>
  <span class="message-renderer">
    <slot>{{ message }}</slot>
  </span>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.message-renderer {
  white-space: pre-wrap;
}
</style>
