<template>
  <div class="organization">
    <img
      class="organization__icon"
      alt="Organization icon"
      :src="OrganizationCircleIcon"
    />
    <div class="organization__details">
      <div class="organization__name">{{ organizationName }}</div>
      <div class="organization__number">Org. nr. {{ orgNumber }}</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import OrganizationCircleIcon from "@/assets/img/organization-circle.svg";
import { Organization } from "@/stores/tender/types";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    organization?: Organization | null;
  }>(),
  {
    organization: undefined,
  },
);

const organizationName = computed(() => props.organization?.name || "");
const orgNumber = computed(() => props.organization?.orgNumber || "");
</script>

<style lang="scss" scoped>
.organization {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  margin-top: 1rem;

  &__details {
    margin-left: 0.75rem;
  }

  &__name {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
  }

  &__number {
    margin-top: 0.125rem;
    font-weight: 400;
    font-size: 0.75rem;
  }
}
</style>
