const RouteNames = {
  SUPPLIER_ADMIN: "supplierAdmin",
  MY_REQUESTS: "myRequests",
  MY_AGREEMENTS: "myAgreements",
  PRICE_ROBOT: "priceRobot",
  ABOUT_US: "aboutUs",
  OTHER_SERVICES: "otherServices",
  LOCATIONS: "locations",
  MY_REGISTRATIONS: "myRegistrations",
  ERROR: "error",
  PDF: "pdf",
  FILE_ATTACHMENT: "fileAttachment",
  ORDER_CONFIRMATION: "orderConfirmation",
  CREATE_OFFER: "createOffer",
  EDIT_OFFER: "editOffer",
  TENDER_OFFER: "tenderOffer",
  FETCHING_OFFER_LOADER: "fetchingOfferLoader",
};

export { RouteNames };
