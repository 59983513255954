import {
  SupplierApi,
  SupplierApiEndpoints,
} from "@/config/api/supplier/supplierApi";
import { executeApiRequest } from "@/services/api/apiService";
import {
  Supplier,
  ServiceCategory,
  SustainabilityGoal,
  Certification,
  UpdateSupplierProfileDto,
  SupplierAddress,
} from "@/stores/supplier/types";

const getEndpointFactory = (endpointKey: symbol) => ({
  endpointKey,
  config: SupplierApi,
});

const getSupplierByUserId = async (userId: string) => {
  return executeApiRequest<Supplier | void>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_USER_ID),
    ),
    {
      pathParams: [userId],
    },
  );
};

const fetchCategoriesBySupplierType = async (params: {
  supplierId: number;
  supplierTypeId: number;
}) => {
  return executeApiRequest<ServiceCategory[]>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.FETCH_CATEGORIES_BY_SUPPLIER_TYPE),
    ),
    {
      pathParams: [
        params.supplierId,
        "supplierType",
        params.supplierTypeId,
        "categories",
      ],
    },
  );
};

const getSupplierSustainabilityGoals = async (params: {
  supplierId: number;
}) => {
  return executeApiRequest<SustainabilityGoal[]>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SUSTAINABILITY_GOALS),
    ),
    {
      pathParams: [params.supplierId, "sustainability"],
    },
  );
};

const getSupplierCertifications = async (params: { supplierId: number }) => {
  return executeApiRequest<Certification[]>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_CERTIFICATIONS),
    ),
    {
      pathParams: [params.supplierId, "certifications"],
    },
  );
};

const getSupplierServiceCategories = async (params: { supplierId: number }) => {
  return executeApiRequest<ServiceCategory[]>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_SERVICE_CATEGORIES),
    ),
    {
      pathParams: [params.supplierId, "categories"],
    },
  );
};

const getSupplierAddresses = async (params: { supplierId: number }) => {
  return executeApiRequest<SupplierAddress[]>(
    getEndpointFactory(Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_ADDRESSES)),
    {
      pathParams: [params.supplierId, "Addresses"],
    },
  );
};

const updateSupplierProfile = async (params: {
  supplierId: number;
  data: UpdateSupplierProfileDto;
}) => {
  return executeApiRequest<Supplier>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.UPDATE_SUPPLIER_PROFILE),
    ),
    {
      data: params.data,
      pathParams: [params.supplierId],
    },
  );
};

const createSupplier = async (data: UpdateSupplierProfileDto) => {
  return executeApiRequest<Supplier>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_PROFILE),
    ),
    {
      data: data,
    },
  );
};

const createSupplierUser = async (params: {
  supplierId: number;
  userId: string;
}) => {
  return executeApiRequest<string>(
    getEndpointFactory(Symbol.for(SupplierApiEndpoints.CREATE_SUPPLIER_USER)),
    {
      pathParams: [params.supplierId, "User", params.userId],
    },
  );
};

const getSupplierByOrgNumber = async (orgNumber: string) => {
  return executeApiRequest<Supplier>(
    getEndpointFactory(
      Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_BY_ORG_NUMBER),
    ),
    {
      pathParams: [orgNumber],
    },
  );
};

const editSupplierLogo = async (params: {
  supplierId: number;
  logo: string;
}) => {
  return executeApiRequest<void>(
    getEndpointFactory(Symbol.for(SupplierApiEndpoints.EDIT_SUPPLIER_LOGO)),
    {
      data: { logo: params.logo },
      pathParams: [params.supplierId, "logo"],
    },
  );
};

const getSupplierLogo = async (supplierId: number) => {
  return executeApiRequest<string>(
    getEndpointFactory(Symbol.for(SupplierApiEndpoints.GET_SUPPLIER_LOGO)),
    {
      pathParams: [supplierId, "logo"],
    },
  );
};

type UseSupplierApi = () => {
  getSupplierByUserId: typeof getSupplierByUserId;
  fetchCategoriesBySupplierType: typeof fetchCategoriesBySupplierType;
  getSupplierSustainabilityGoals: typeof getSupplierSustainabilityGoals;
  getSupplierCertifications: typeof getSupplierCertifications;
  getSupplierServiceCategories: typeof getSupplierServiceCategories;
  getSupplierAddresses: typeof getSupplierAddresses;
  updateSupplierProfile: typeof updateSupplierProfile;
  createSupplierUser: typeof createSupplierUser;
  createSupplier: typeof createSupplier;
  getSupplierByOrgNumber: typeof getSupplierByOrgNumber;
  editSupplierLogo: typeof editSupplierLogo;
  getSupplierLogo: typeof getSupplierLogo;
};

export const useSupplierApi: UseSupplierApi = () => {
  return {
    getSupplierByUserId,
    fetchCategoriesBySupplierType,
    getSupplierSustainabilityGoals,
    getSupplierCertifications,
    getSupplierServiceCategories,
    getSupplierAddresses,
    updateSupplierProfile,
    createSupplierUser,
    createSupplier,
    getSupplierByOrgNumber,
    editSupplierLogo,
    getSupplierLogo,
  };
};
