<template>
  <div class="save-supplier-segments-prices">
    <price-robot-header class="mt-4" :header-content-prop="headerContent" />
    <b-alert
      v-model="state.showDismissibleAlert"
      :variant="state.alertVariant"
      class="save-supplier-segments-prices__b-alert"
      dismissible
      @dismissed="handleAlertDismissed"
    >
      {{ state.alertText }}
    </b-alert>
    <save-button
      :is-loading="state.isLoading"
      :disabled="state.disableButton"
      :button-text="state.buttonText"
      :note-text="state.noteText"
      :component-type="state.componentType"
      @save-segment-prices="handleSaveSegmentPrices"
    />
  </div>
</template>

<script setup>
import { computed, reactive, watch } from "vue";
import { useStore } from "vuex";
import PriceRobotHeader from "@/components/PriceRobot/PriceRobotHeader.vue";
import SaveButton from "@/components/SaveButton/SaveButton.vue";
import myPricesService from "@/services/myPricesService";

const store = useStore();

// Reactive state
const state = reactive({
  isLoading: false,
  disableButton: false,
  componentType: "service-type-segments-prices",
  buttonText: "Lagre priser",
  noteText: "Endrede priser er aktive med en gang du trykker på “Lagre priser.",
  successAlertText: "Prisene ble oppdatert",
  errorAlertText: "Noe gikk galt. Prøv igjen.",
  generatedPriceId: 990000,
  alertVariant: "",
  alertText: "",
  showDismissibleAlert: false,
});

// Computed properties
const cms = computed(() => store.state.cms);
const headerContent = computed(
  () => cms.value.myPrices.savePrices.headerContent,
);

const savePriceReady = computed(() => store.state.api.savePriceReady);
const supplierTypePricingPackages = computed(
  () => store.state.api.supplierTypePricingPackages,
);

// Watch savePriceReady
watch(savePriceReady, (newVal) => {
  state.disableButton = !newVal;
});

// Methods
const handleSaveSegmentPrices = async () => {
  state.isLoading = true;
  state.disableButton = true;

  if (!isSavePricesReady()) {
    state.isLoading = false;
    state.disableButton = true;
    showUiFeedback("danger", state.errorAlertText);
    return;
  }

  try {
    const resolvedPrices = resolvePricesData();

    const response = await myPricesService.savePrices(resolvedPrices);

    if (response.status === 200) {
      showUiFeedback("success", state.successAlertText);
      store.dispatch("api/removeDeletedPriceForPosting", []);
      store.dispatch("api/removeEditedPriceForPosting", []);
      reloadPrices();
    } else {
      showUiFeedback("danger", state.errorAlertText);
    }
  } catch (error) {
    showUiFeedback("danger", state.errorAlertText);
  } finally {
    state.isLoading = false;
    state.disableButton = false;
    setTimeout(() => (state.showDismissibleAlert = false), 7000);
  }
};

const isSavePricesReady = () => {
  return (
    savePriceReady.value === true &&
    supplierTypePricingPackages.value.length > 0
  );
};

const showUiFeedback = (variant, text) => {
  state.alertVariant = variant;
  state.alertText = text;
  state.showDismissibleAlert = true;
};

const handleAlertDismissed = () => {
  state.showDismissibleAlert = false;
  state.disableButton = false;
};

const formatPriceObject = (price) => ({
  ...price,
  priceId: price.priceId > state.generatedPriceId ? 0 : price.priceId,
  kilometerPrice: price.kilometerPrice === "" ? 0 : price.kilometerPrice,
  numberOfTrips: price.numberOfTrips === "" ? 0 : price.numberOfTrips,
  price: price.price === "" ? 0 : price.price,
});

const resolvePricesData = () => {
  const formatPrices = (prices) => prices.map(formatPriceObject);
  return [
    ...formatPrices(store.state.api.deletedPrices),
    ...formatPrices(store.state.api.editedPrices),
  ];
};

const reloadPrices = () => {
  setTimeout(() => store.dispatch("api/reloadSupplierPrices", true), 2500);
};
</script>

<style lang="scss" scoped>
$font-size-large: 1.25rem;

.save-supplier-segments-prices {
  font-size: $font-size-large;
  color: $color-primary-darker;
  background-color: $color-primary-pink;
  padding-bottom: 4rem;
  padding-top: 2rem;

  &__b-alert {
    width: max-content;
    margin-top: 4rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-size: $font-size-sm;
    font-weight: 700;
  }
}
</style>
