import repository from "@/store/repository";
import cmsService from "@/services/cmsService";

const state = {
  header: {},
  gettingStarted: {},
  home: {},
  suppliersSection: {},
  footer: {},

  services: [],
  priceGroups: [],
  selectedService: {},

  howToOrder: {},
  aboutUs: {},
  suppliers: {},
  registration: {},
  tenderConfirmed: {},
  tenderAccepted: {},

  viewOffer: {},

  myAgreements: {},

  editUser: {},

  myPrices: {},
  otherServicesContent: {},
  supplierAdminMenuOptions: {},
  selectedMenuOption: {},

  supplierMetadata: {},

  supplierAddress: {},

  agreementsContent: {},

  supplierSustainability: {
    headerLogo: "",
    headerTitle: "",
    headerSubtitle: "",
    sustainabilityGoals: [],
    footerButtons: [],
  },

  supplierCertification: {
    title: "",
    descriptions: [],
    content: [],
    buttons: [],
  },

  supplierTypes: {},
  selectedSupplierType: {},

  segmentsReservationsContent: {},

  windowConstraint: {},

  supplierIndustryTypes: [],
  supplierRegistrationContent: {},

  errorContent: {},
  faqContent: {},

  serviceReportContent: {},

  locationsContent: {},
  serviceTypes: [],
};

const getters = {
  getCompanyInfoHeaderContent: (state) =>
    state.supplierMetadata.companyInfo.headerContent,
  getSegmentsReservationsContent: (state) => state.segmentsReservationsContent,
  getIndustrySectionHeaderContent: (state) =>
    state.supplierMetadata.supplierType.headerContent,
  getIndustrySectionFormContent: (state) =>
    state.supplierMetadata.supplierType.formContent,
  getUpdateMetadataHeaderContent: (state) =>
    state.supplierMetadata.updateMetadata.headerContent,
  getUpdateMetadataButtonContent: (state) =>
    state.supplierMetadata.updateMetadata.buttonContent,
  getIndustryServices: (state) => state.supplierIndustryTypes,
  getSustainabilityContent: (state) => state.supplierSustainability,
  getCertificationContent: (state) => state.supplierCertification,
  getSelectedSupplierType: (state) => state.selectedSupplierType,
  getSupplierAddressContent: (state) => state.supplierAddress,
  getSupplierRegistrationContent: (state) => state.supplierRegistrationContent,
  getServiceReportContent: (state) => state.serviceReportContent,
  getServiceTypes: (state) => state.serviceTypes,
};

const actions = {
  initializeStore({ commit }) {
    commit("reloadSelectedService");
  },
  loadServiceReportContent({ commit }) {
    const cmsResponse = {
      title: "Last opp dokumentasjon",
      description:
        "Her kan du laste opp siste servicerapporter, restanselister eller annen dokumentasjon på utført arbeid. Denne dokumentasjonen vil kunden få enkel tilgang til på sin side.",
      buttonText: "Legg  til ny dokumentasjon",
      modalContent: {
        title: "Servicerapporten er lagret",
        icon: "service-report/check_mark.svg",
        buttonText: "OK",
      },
      serviceReportDetails: {
        serviceDateText: "Dato utført:",
        nextServiceDateText: "Måned for neste service:",
        commentText: "Kommentar:",
        filesText: "Filer:",
        read: {
          text: "Les",
          icon: "service-report/read.svg",
        },
        download: {
          text: "Last ned",
          icon: "service-report/download.svg",
        },
      },
      myServiceReport: {
        title: "Mine servicerapporter",
        subtitle: "(Disse er også synlig for kunden)",
        emptyText: "Ingen servicerapporter er registrert enda",
      },
      formContent: {
        serviceDate: {
          name: "ServiceDate",
          label: "Velg dato for uført service",
          placeholder: "Ingen dato valgt",
          error: "*Fyll ut dato",
        },
        nextServiceDate: {
          name: "NextServiceDate",
          label: "Velg måned for neste service",
          placeholder: "Ingen dato valgt",
          error: "*Fyll ut dato",
        },
        attachments: {
          name: "Attachments",
          label: "Last opp filer",
          placeholder: "",
          span: "(Godtatte filtyper: .pdf, .jpg, .jpeg, .png)",
          buttonText: "Velg fil!",
          error: "*Vennligst last opp filer",
          extra: "Valgt fil:",
          icon: "service-report/delete.svg",
        },
        comment: {
          name: "Comment",
          label: "Valgfri kommentar:",
          placeholder: "Skriv inn kommentar...",
          error: "*Fyll ut kommentar",
        },
        calendar: {
          name: "Calendar",
          icon: "service-report/calendar.svg",
        },
        cancelButtonText: "Avbryt",
        submitButtonText: "Lagre",
        errorText: "Kunne ikke utføre forespørselen. Prøv igjen senere.",
      },
    };
    commit("saveServiceReportContent", cmsResponse);
  },
  loadHeaderContent({ commit }) {
    const cmsResponse = {
      logo: "header/logo.svg",
      logIn: "Logg inn",
      logOut: "Logg ut",
      profile: {
        text: "Min Side",
        icon: "header/profile.svg",
      },
      links: [
        {
          id: 1,
          name: "howToOrder",
          text: "Slik bestiller du inn priser",
          link: "/how-to-order",
        },
        {
          id: 2,
          name: "forSuppliers",
          text: "For leverandører",
          link: "/suppliers",
        },
      ],
      registration: {
        text: "Registrer deg",
        content: {
          icon: "header/register.svg",
          title: "Registrer deg",
          description:
            "For å registrere deg trenger vi å vite navn, e-post og org nr til bedriften eller organisasjonen du representerer. Så må du lage et passord.",
          placeholder: {
            name: "Navn",
            email: "E-post adresse",
            orgNumber: "Org. nr. (9 siffer)",
            password: "Velg passord",
            confirmPassword: "Velg passord",
          },
          footer: {
            password: "(min. 8 bokstaver. Et tegn og et tall)",
            confirmPassword: "Sånn for å være helt sikker",
          },
          gdpr: {
            title:
              "Vi lover deg å stelle pent med opplysningene dine,  og ikke bruke dem til kjipe formål",
            text: "Når du registrerer deg, oppretter vi en brukerprofil på deg. Vi gir definitift ikke dine persondata videre til bruk for utenforstående, for det hadde ikke vi likt heller. Du kan lese mer om hvordan vi jobber med personvern her.",
            confirm: "Ja, dette høres greit ut",
          },
          registerButton: "Registrer deg!",
        },
      },
    };
    commit("saveHeaderContent", cmsResponse);
  },
  loadSuppliersSectionContent({ commit }) {
    const cmsResponse = {
      icon: "suppliers-section/supplier.svg",
      title: "For leverandører",
      text: "Vi er på jakt etter gode samarbeidspartnere. Legg igjen informasjon om deg, så tar vi kontakt.",
      buttonText: "Jeg er interessert!",
      link: "/suppliers",
    };
    commit("saveSuppliersSectionContent", cmsResponse);
  },
  loadFooterContent({ commit }) {
    const cmsResponse = {
      logo: "footer/logo.svg",
      contactUs: {
        text: "Kontakt oss",
        icon: "footer/contact_us.svg",
      },
      reallyOrganization: {
        orgName: "Really! AS",
        address: "Edvard Griegs Vei 3b",
        cityAddress: "5059 Bergen",
        orgNumber: "925401919",
        city: "Bergen",
        country: "Norway",
      },
      navigationLinks: [
        {
          id: 1,
          name: "howToOrder",
          text: "Slik bestiller du",
          route: "/how-to-order",
        },
        {
          id: 2,
          name: "register",
          text: "Registrer deg",
          route: "/register",
        },
        {
          id: 3,
          name: "forSuppliers",
          text: "For leverandører",
          route: "/suppliers",
        },
        {
          id: 4,
          name: "aboutUs",
          text: "Om oss",
          route: "/about-us",
        },
      ],
      serviceLinks: [
        {
          id: 1,
          name: "cleaning",
          text: "Renholdstjenester",
          route: "/service/1",
        },
        {
          id: 2,
          name: "maintenance",
          text: "Byggdrift",
          route: "/service/2",
        },
        {
          id: 3,
          name: "electric",
          text: "Elektro",
          route: "/service/3",
        },
        {
          id: 4,
          name: "ventilation",
          text: "Ventilasjon",
          route: "/service/4",
        },
      ],
      otherLinks: [
        {
          id: 1,
          name: "profile",
          text: "Min Side",
          route: "/profile",
        },
      ],
    };
    commit("saveFooterContent", cmsResponse);
  },
  loadSupplierAdminMenuContent({ commit }) {
    const cmsResponse = [
      {
        id: 1,
        name: "Oppgaver",
        route: "/mine-avtaler",
        activeIcon: "supplier-admin/mine_avtaler_active.svg",
        inactiveIcon: "supplier-admin/mine_avtaler_inactive.svg",
      },
      {
        id: 2,
        name: "Prisrobot",
        route: "/prisrobot",
        activeIcon: "supplier-admin/mine_priser_active.svg",
        inactiveIcon: "supplier-admin/mine_priser_inactive.svg",
      },
      {
        id: 3,
        name: "Profil",
        route: "/om-oss",
        activeIcon: "supplier-admin/om_oss_active.svg",
        inactiveIcon: "supplier-admin/om_oss_inactive.svg",
      },
      {
        id: 4,
        name: "Mine tjenester",
        route: "/mine-tjenester",
        activeIcon: "supplier-admin/mine_tjenester_active.svg",
        inactiveIcon: "supplier-admin/mine_tjenester_inactive.svg",
      },
      {
        id: 5,
        name: "Kontorer",
        route: "/lokasjoner",
        activeIcon: "supplier-admin/lokasjoner_active.svg",
        inactiveIcon: "supplier-admin/lokasjoner_inactive.svg",
      },
      {
        id: 6,
        name: "Mine registreringer",
        route: "/mine-registreringer",
        activeIcon: "supplier-admin/mine_registreringer_active.svg",
        inactiveIcon: "supplier-admin/mine_registreringer_inactive.svg",
      },
    ];
    commit("saveSupplierAdminMenuContent", cmsResponse);
  },
  loadSupplierRegistrationContent({ commit }) {
    const cmsResponse = {
      titleText: "Registrer leverandør",
      placeholder: {
        supplierName: "Leverandørnavn",
        fullName: "Fullt navn",
        phoneNumber: "Telefonnummer",
        orgNumber: "Organisasjonsnummer",
        password: "Velg passord",
        email: "E-post adresse",
      },
      button: {
        buttonText: "Registrer deg!",
      },
      icons: {
        hidden: "eye-slash-fill.svg",
        shown: "eye-fill.svg",
      },
      responseText: {
        successText: "Sende vellykket",
        errorText: "Feil beklager",
      },
    };
    commit("saveSupplierRegistrationContent", cmsResponse);
  },
  loadSupplierMetadataContent({ commit }) {
    const cmsResponse = {
      bottomArrowIcon: "my-prices/bottom_arrow.svg",
      industry: {
        crookedArrowIcon: "/crooked_line.svg",
        headerContent: {
          title: "Velg bransje:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "about-us",
          instructionText:
            "Her velger du hvilke tjenester du kan tilby. For hver enkelt tjeneste du tilbyd må informasjon om bedriften settes opp.",
        },
      },
      companyInfo: {
        headerContent: {
          title: "Skriv inn informasjon om bedriften din",
          subTitle:
            "Dette er en tekst om selskapet du representerer. Informasjon vil postensielle kunder se på blant annet tilbud og ikke minst på signerte avtaler. Ikke glem å fylle ut sertifiseringer, godkjenninger og bærekraftsmål, vi ser at dette er viktige grunner til å velge akkurat deg som leverandør-",
        },
      },
      supplierType: {
        headerContent: {
          title: "AB Solutions sitt verdiforslag",
          subTitle:
            "Her kan du skrive verdiforslaget til. Dette er en tekst om selskapet du representerer. Det beste er at Stein skriver denne promoen men i mangel på noe bedre knaster jeg ned noe selv. Helt alene. Du er blitt stor nå Morten. Dette kan du klare. Altså en tekst om selskapet. Ikke glem å fylle ut alle feltene",
        },
        formContent: [
          {
            label: "Selskapsnavn",
            placeholder: "Selskapsnavn",
          },
          {
            label: "Organisasjon",
            placeholder: "Organisasjon",
          },
          {
            label: "Kontaktperson",
            placeholder: "Kontaktperson",
          },
          {
            label: "E-post",
            placeholder: "E-post",
          },
          {
            label: "Startbank",
            placeholder: "Startbank",
          },
          {
            label: "Bransje",
            placeholder: "Velg bransje",
            append: "supplier-admin-metadata/arrow_down.svg",
          },
          {
            label: "Ansatte",
            placeholder: "0",
            append: "Pers.",
          },
          {
            label: "Omsetning",
            placeholder: "0",
            append: "NOK",
          },
          {
            label: "Valgt fil",
            placeholder: "AB_Solutions_RGB.png",
          },
          {
            label: "Beskrivelse av bedriften",
            placeholder:
              "Skriv eller kopier inn din beskrivelse. \n" +
              "Fritekst. Max 100 ord. (autostop på 100)",
          },
          {
            label: "Rabatt filter",
          },
          {
            label: "Camfil",
            placeholder: "0",
          },
          {
            label: "Interfil",
            placeholder: "0",
          },
          {
            label: "Swegon",
            placeholder: "0",
          },
          {
            label: "Flexit",
            placeholder: "0",
          },
          {
            label: "E-post",
            placeholder: "e-post",
          },
        ],
      },

      updateMetadata: {
        headerContent: {
          title: "Last opp logo",
          subTitle:
            "Her kan du laste opp logoen til selskapet. (Gyldige filformat: JPG, PNG, GIF, SVG)",
        },
        buttonContent: [
          {
            text: "Velg fil!",
            label: "(Bla igjennom)",
          },
          {
            text: "Forhåndsvis verdiforslag",
            label: "Forhåndsvis verdiforslag",
          },
          {
            text: "Lagre",
            label: "Lagre",
          },
        ],
        formContent: [
          {
            label: "Valgt fil",
            placeholder: "AB_Solutions_RGB.png",
          },
          {
            label: "Beskrivelse av bedriften",
            placeholder:
              "Skriv eller kopier inn din beskrivelse. \n" +
              "Fritekst. Max 100 ord. (autostop på 100)",
          },
        ],
      },
    };
    commit("saveSupplierMetadataContent", cmsResponse);
  },
  loadSupplierAddressContent({ commit }) {
    const cmsResponse = {
      titleText: "kontorer/avdelinger:",
      office: {
        subtitleText: "Kontor/avdeling",
        inputLabelText: "Kontor/avdeling:",
        inputPlaceHolderText: "Kontor/avdeling",
      },
      street: {
        subtitleText: "Gateadresse",
        inputLabelText: "Gateadresse:",
        inputPlaceHolderText: "Gateadresse:",
      },
      zip: {
        subtitleText: "Postnr./Sted",
        inputLabelText: "Postnr:",
        inputPlaceHolderText: "Postnummer:",
        cityPlaceholder: "Sted",
      },
      priceAdjust: {
        subtitleText: "Prisjustering",
        inputLabelText: "Prisjustering:",
        inputPlaceHolderText: "Prisjustering:",
      },
      handling: {
        subtitleText: "Handling",
        saveIcon: "utility/save.svg",
        editIcon: "utility/edit.svg",
        deleteIcon: "utility/delete.svg",
        saveIconDisabled: "utility/save_disabled.svg",
        editIconDisabled: "utility/edit_disabled.svg",
        deleteIconDisabled: "utility/delete_disabled.svg",
      },
      buttons: {
        addAddress: "Legg til kontor/avdeling ",
      },
    };
    commit("saveSupplierAddressContent", cmsResponse);
  },
  loadSupplierSustainabilityContent({ commit }) {
    const cmsResponse = {
      headerLogo: "supplier-admin-metadata/sustainability.svg",
      headerTitle: "sitt fokus på FNs Bærekraftmål",
      headerSubtitle:
        "Her velger du de 3 bærekraftmålene din bedrift fokuserer på eller en bedre tekstlig intro:",
      footerButtons: [
        {
          id: 1,
          icon: "supplier-admin-metadata/save.svg",
          text: "Lagre",
        },
        {
          id: 2,
          icon: "supplier-admin-metadata/update.svg",
          text: "Rediger",
        },
      ],
    };
    commit("saveSupplierSustainabilityContent", cmsResponse);
  },
  async loadSupplierCertificationContent({ commit }) {
    const content = await cmsService.getCertifications();
    const cmsResponse = {
      title: "Sertifiseringer og godkjenninger",
      descriptions: [
        "Her markerer du firmaets sertifiseringer. Kunder vil se dine sertifiseringer ved bestilling av tjeneste.",
        "PS: Du er selv ansvarlig for å kunne bevise at du har sertifiseringen du viser frem.",
      ],
      buttons: [
        {
          label: "updateButton",
          icon: "supplier-admin-metadata/save.svg",
          text: "Lagre",
        },
      ],
      content,
    };
    commit("saveSupplierCertification", cmsResponse);
  },
  loadMyPricesSupplierTypeContent({ commit }) {
    const cmsResponse = [
      {
        id: 1,
        icon: "my-prices/renhold.svg",
      },
      {
        id: 2,
        icon: "my-prices/byggdrift.svg",
      },
      {
        id: 3,
        icon: "my-prices/elektro.svg",
      },
      {
        id: 4,
        icon: "my-prices/ventilasjon.svg",
      },
      {
        id: 7,
        icon: "my-prices/renovasjon.svg",
      },
      {
        id: 8,
        icon: "my-prices/sprinkler.svg",
      },
      {
        id: 9,
        icon: "my-prices/heis.svg",
      },
      {
        id: 10,
        icon: "my-prices/andre_tjenester.svg",
      },
    ];
    commit("saveMyPricesServicesContent", cmsResponse);
  },
  loadSegmentsReservationsContent({ commit }) {
    let cmsResponse = {};
    cmsResponse = {
      segmentAreaLabel: "Reservasjoner:",
      segmentAreaContent: [
        {
          title: "reservationHigher",
          label: "Maks beløp pr oppdrag",
          placeholder: "0,-",
          unit: "NOK",
        },
        {
          title: "reservationLower",
          label: "Min. beløp pr oppdrag",
          placeholder: "0,-",
          unit: "NOK",
        },
        {
          title: "reservationKilometers",
          label: "Maks avstand i km",
          placeholder: "0 km",
          unit: "Km",
        },
      ],
    };
    commit("saveSegmentsReservationsContent", cmsResponse);
  },
  async loadServicesContent({ commit }) {
    const cmsResponse = [
      {
        id: 1,
        name: "cleaning",
        activeIcon: "getting_started/cleaning_active.svg",
        inactiveIcon: "getting_started/cleaning_inactive.svg",
        supplierIcon: "my-prices/renhold.svg",
        icon: "my-prices/renhold.svg",
        banner: {
          icon: "services/cleaning.svg",
          title: "Renhold",
          subTitle:
            "Godt utført renhold bidrar til lavere sykefravær, bedre trivsel og forlenger levetiden til lokaler, møbler og inventar.",
        },
        descriptions: [
          {
            id: 1,
            text: "Basert på erfaring har vi satt sammen to renholdspakker; enkelt renhold og standard renhold.",
          },
        ],
        types: [
          {
            id: 1,
            title: "Enkel renhold",
            icon: "services/cleaning_icon_one.svg",
            subTitle: [
              {
                id: 1,
                text: "Her får du dekket basis renholdsbehov; slik vi vet er tilstrekkelig i mange sammenhenger.",
              },
            ],
          },
          {
            id: 2,
            title: "Standard renhold",
            icon: "services/cleaning_icon_two.svg",
            subTitle: [
              {
                id: 1,
                text: "Vårt mest populære valg. Basert på tilbakemelding fra kunder og leverandører har vi satt sammen en pakke som gir deg alt du trenger - også det du hadde glemt at du trengte.",
              },
            ],
          },
        ],
        actionButtonText: "Hva er inkludert?",
        note: "*Frekvens på renholdet velger du selv uansett pakke. Enkelt og greit.",
        convince: {
          title: "Ikke overbevist?",
          subTitle: "Les mer",
          link: "",
        },
        visualImg: "services/cleaning_visual.png",
        form: {
          property: {
            title:
              "Det neste vi trenger å vite er litt om eiendommen du vil ha utført arbeid på:",
            address: "Skriv inn adressen",
            zip: {
              placeholder: "Postnr.",
              label: "[Poststed auto]",
            },
            name: "Boligselskap",
          },
          content: {
            title:
              "Så trenger vi  å vite litt om selve arbeidet som skal utføres.",
          },
          bottomIcon: "getting_started/bottom_arrow.svg",
          installationButton: {
            text: "Legg til installasjon",
            addIcon: "services/add_installation.svg",
            removeIcon: "services/remove_installation.svg",
          },
          addFormButtonText: "Legg til installasjon",
          contact: {
            title: "Ok!",
            subTitle:
              "Vi trenger også en kontaktperson med e-postadresse, slik at vi kan sende tilbudene.",
            email: "E-post adresse",
            orgNumber: "Organisasjonsnummer eller selskapsnavn",
            buttonText: "Se tilbud",
          },
          register: {
            title:
              "Hvis du har tid kan du enkelt lage en bruker når du først er igang. Det gjør det prosessen enklere. Men det er ikke påkrevd for å få tilbud.",
            password: "Velg passord med min. 8 bokstaver. Et tegn og et tall",
            confirmPassword: "Bekreft passord sånn for å være helt sikker",
            buttonText: "Registrer deg!",
          },
        },
      },
      {
        id: 2,
        name: "maintenance",
        icon: "my-prices/byggdrift.svg",
        activeIcon: "getting_started/construction_active.svg",
        inactiveIcon: "getting_started/construction_inactive.svg",
        banner: {
          icon: "services/construction.svg",
          title: "Byggdrift",
          subTitle:
            "Byggdrift også kalt “Vaktmester” er for mange selve tannhjulet innen eiendomsforvaltning. Det omhandler  tekniske installasjoner som heiser, brannvarsling, låssystemer, varmeanlegg og andre tekniske anlegg i bygget. Sommer som vinter.",
        },
        descriptions: [
          {
            id: 1,
            text: "Sammen med bransjen har vi skreddersydd pakkeløsninger som vil imøtekomme ulike behov. Du velger selv hvilke tjenester du ønsker.",
          },
          {
            id: 2,
            text: "Vi har kategorisert byggdrift  i fire alternativer.",
          },
        ],
        types: [
          {
            id: 1,
            title: "Enkel byggdrift:",
            icon: "services/maintenance_icon_one.svg",
            subTitle: [
              {
                id: 1,
                text: "Her får dere dekket grunnleggende behov.  Passer bra for sameier, boligselskap eller dersom du er leietaker.",
              },
            ],
          },
          {
            id: 2,
            title: "Standard byggdrift",
            icon: "services/maintenance_icon_two.svg",
            subTitle: [
              {
                id: 1,
                text: "I tillegg til de grunnleggende behovene tar byggdrifter seg av energioptimalisering og tilsyn / kontroll av tekniske anlegg.",
              },
            ],
          },
          {
            id: 3,
            title: "Premium byggdrift:",
            icon: "services/maintenance_icon_three.svg",
            subTitle: [
              {
                id: 1,
                text: "Vår mest populære pakke. Basert på tilbakemelding fra kunder og leverandører har vi satt sammen en fleksibel pakke som gir deg alt du  trenger - også det du hadde glemt at du trengte. Som for eksempel brannvern.",
              },
            ],
          },
          {
            id: 4,
            title: "Vaktmester på timebasert avtale.",
            icon: "services/maintenance_icon_four.svg",
            subTitle: [
              {
                id: 1,
                text: "Du kan også leie timebaserte vaktmester-tjenester.  Ofte nyttig for å sikre god eiendomsforvaltning der det er vanskelig å planlegge kapasitets-behovet.",
              },
            ],
            disabled: true,
          },
        ],
        actionButtonText: "Hva er inkludert?",
        convince: {
          title: "Ikke overbevist?",
          subTitle: "Les mer",
          link: "",
        },
        form: {
          property: {
            title:
              "Det neste vi trenger å vite er litt om eiendommen du vil ha utført arbeid på:",
            address: "Skriv inn adressen",
            zip: {
              placeholder: "Postnr.",
              label: "[Poststed auto]",
            },
            name: "Boligselskap",
          },
          content: {
            title:
              "Så trenger vi  å vite litt om selve arbeidet som skal utføres.",
          },
          bottomIcon: "getting_started/bottom_arrow.svg",
          installationButton: {
            text: "Legg til installasjon",
            addIcon: "services/add_installation.svg",
            removeIcon: "services/remove_installation.svg",
          },
          contact: {
            title: "Ok!",
            subTitle:
              "Vi trenger også en kontaktperson med e-postadresse, slik at vi kan sende tilbudene.",
            email: "E-post adresse",
            orgNumber: "Organisasjonsnummer eller selskapsnavn",
            buttonText: "Se tilbud",
          },
          register: {
            title:
              "Hvis du har tid kan du enkelt lage en bruker når du først er igang. Det gjør det prosessen enklere. Men det er ikke påkrevd for å få tilbud.",
            password: "Velg passord med min. 8 bokstaver. Et tegn og et tall",
            confirmPassword: "Bekreft passord sånn for å være helt sikker",
            buttonText: "Registrer deg!",
          },
        },
      },
      {
        id: 3,
        name: "electric",
        icon: "my-prices/elektro.svg",
        activeIcon: "getting_started/electrical_active.svg",
        inactiveIcon: "getting_started/electrical_inactive.svg",
        banner: {
          icon: "services/electrical.svg",
          title: "Elektro",
          subTitle:
            "Her bestiller du service som oppfyller lovpålagte og andre anbefalinger i internforskriften.",
        },
        descriptions: [
          {
            id: 1,
            text: "Sammen med bransjen har vi skreddersydd pakkeløsninger som vil imøtekomme ulike behov. Du velger selv hvilke tjenester du ønsker.",
          },
          {
            id: 2,
            text: "Noe elektroservice er lovåplagt frekvens, andre kan du velge selv.",
          },
        ],
        types: [
          {
            id: 1,
            title: "Lovpålagt årlig service",
            icon: "services/electric_icon_one.svg",
            subTitle: [
              {
                id: 1,
                text: "Brannvarslingsanlegg, tavleutstyr og nød & ledelys må kontrolleres hvert år. Enkelt og greit.",
              },
            ],
          },
          {
            id: 2,
            title: "Anbefalt service",
            icon: "services/electric_icon_two.svg",
            subTitle: [
              {
                id: 1,
                text: "Internkontroll Elektro og termografering er ikke lovpålagt å gjennomføre hvert eneste år. Men det kan være en fordel. Her velger du selv frekvens",
              },
            ],
          },
        ],
        actionButtonText: "Hva er inkludert?",
        convince: {
          title: "Ikke overbevist?",
          subTitle: "Les mer",
          link: "",
        },
        form: {
          property: {
            title:
              "Det neste vi trenger å vite er litt om eiendommen du vil ha utført arbeid på:",
            address: "Skriv inn adressen",
            zip: {
              placeholder: "Postnr.",
              label: "[Poststed auto]",
            },
            name: "Boligselskap",
          },
          content: {
            title:
              "Så trenger vi  å vite litt om selve arbeidet som skal utføres.",
          },
          bottomIcon: "getting_started/bottom_arrow.svg",
          installationButton: {
            text: "Legg til installasjon",
            addIcon: "services/add_installation.svg",
            removeIcon: "services/remove_installation.svg",
          },
          contact: {
            title: "Ok!",
            subTitle:
              "Vi trenger også en kontaktperson med e-postadresse, slik at vi kan sende tilbudene.",
            email: "E-post adresse",
            orgNumber: "Organisasjonsnummer eller selskapsnavn",
            buttonText: "Se tilbud",
          },
          register: {
            title:
              "Hvis du har tid kan du enkelt lage en bruker når du først er igang. Det gjør det prosessen enklere. Men det er ikke påkrevd for å få tilbud.",
            password: "Velg passord med min. 8 bokstaver. Et tegn og et tall",
            confirmPassword: "Bekreft passord sånn for å være helt sikker",
            buttonText: "Registrer deg!",
          },
        },
      },
      {
        id: 4,
        name: "ventilation",
        icon: "my-prices/ventilasjon.svg",
        activeIcon: "getting_started/ventilation_active.svg",
        inactiveIcon: "getting_started/ventilation_inactive.svg",
        banner: {
          icon: "services/ventilation.svg",
          title: "Ventilasjon",
          subTitle:
            "Ventilasjonssystemet er en av de mest energikrevende installasjonene i bygg. Regelmessig service er nødvendig for å sikre godt inneklima uten at det sløses med energi.",
        },
        descriptions: [
          {
            id: 1,
            text: "Sammen med bransjen har vi skreddersydd pakkeløsninger som vil imøtekomme ulike behov. Du velger selv hvilke tjenester du ønsker.",
          },
          {
            id: 2,
            text: "Vi har kategorisert ventilasjon  i fire alternativer.",
          },
        ],
        types: [
          {
            id: 1,
            title: "Enkel ventilasjon",
            icon: "services/ventilation_icon_one.svg",
            subTitle: [
              {
                id: 1,
                text: "Her får du dekket basis ventilasjon installeringer; slik vi vet er tilstrekkelig i mange sammenhenger.",
              },
            ],
          },
          {
            id: 2,
            title: "Standard ventilasjon",
            icon: "services/ventilation_icon_two.svg",
            subTitle: [
              {
                id: 1,
                text: "Vårt mest populære valg. Basert på tilbakemelding fra kunder og leverandører har vi satt sammen en pakke som gir deg alt du trenger - også det du hadde glemt at du trengte.",
              },
            ],
          },
          {
            id: 3,
            title: "Digital ventilasjon",
            icon: "services/ventilation_icon_three.svg",
            subTitle: [
              {
                id: 1,
                text: "Her får du dekket basis ventilasjon installeringer; slik vi vet er tilstrekkelig i mange sammenhenger.",
              },
            ],
          },
          {
            id: 4,
            title: "Premium ventilasjon",
            icon: "services/ventilation_icon_four.svg",
            subTitle: [
              {
                id: 1,
                text: "Vårt mest populære valg. Basert på tilbakemelding fra kunder og leverandører har vi satt sammen en pakke som gir deg alt du trenger - også det du hadde glemt at du trengte.",
              },
            ],
          },
        ],
        actionButtonText: "Hva er inkludert?",
        convince: {
          title: "Ikke overbevist?",
          subTitle: "Les mer",
          link: "",
        },
        form: {
          property: {
            title:
              "Det neste vi trenger å vite er litt om eiendommen du vil ha utført arbeid på:",
            address: "Skriv inn adressen",
            zip: {
              placeholder: "Postnr.",
              label: "[Poststed auto]",
            },
            name: "Boligselskap",
          },
          content: {
            title:
              "Så trenger vi  å vite litt om selve arbeidet som skal utføres.",
          },
          bottomIcon: "getting_started/bottom_arrow.svg",
          installationButton: {
            text: "Legg til installasjon",
            addIcon: "services/add_installation.svg",
            removeIcon: "services/remove_installation.svg",
          },
          contact: {
            title: "Ok!",
            subTitle:
              "Vi trenger også en kontaktperson med e-postadresse, slik at vi kan sende tilbudene.",
            email: "E-post adresse",
            orgNumber: "Organisasjonsnummer eller selskapsnavn",
            buttonText: "Se tilbud",
          },
          register: {
            title:
              "Hvis du har tid kan du enkelt lage en bruker når du først er igang. Det gjør det prosessen enklere. Men det er ikke påkrevd for å få tilbud.",
            password: "Velg passord med min. 8 bokstaver. Et tegn og et tall",
            confirmPassword: "Bekreft passord sånn for å være helt sikker",
            buttonText: "Registrer deg!",
          },
        },
      },
    ];
    commit("saveServicesContent", cmsResponse);
  },
  loadHowToOrderContent({ commit }) {
    const cmsResponse = {
      banner: {
        icon: null,
        title: "Slik bestiller du.",
        subTitle: "Fire enkle trinn; så er du der",
      },
      steps: [
        {
          id: 1,
          title: "Velg tjeneste",
          text: "Bare trykk i vei for å teste hvordan det fungerer. Renhold, Byggdrift, Elektro eller ventilasjon. Du binder ikke til noen ting.",
          icon: "how-to-order/step_1.svg",
        },
        {
          id: 2,
          title: "Her trenger vi informasjon om hvem dere er",
          text: "Hvordan type bygning(er) er det snakk om og hvem skal vi forholde oss til.",
          icon: "how-to-order/step_2.svg",
        },
        {
          id: 3,
          title: "Hva skal utføres og i hvilket omfang?",
          text: "Vi trenger ikke mye informasjon, vi trenger bare litt. Fyll inn og gjør noen enkle valg.",
          icon: "how-to-order/step_3.svg",
        },
        {
          id: 4,
          title: "Da er du egentlig klar til å velge leverandør",
          text: 'Vi samarbeider kun med seriøse og ordentlige leverandører; de fleste av dem du allerede kjenner til eller bruker + kanskje noen fler. Trykk på den grønne "Se tilbud"-knappen, så får du opp pris og pakker på alle som ønsker å ha deg som kunde. Det blir nesten ikke enklere.',
          icon: "how-to-order/step_4.svg",
        },
      ],
    };
    commit("saveHowToOrder", cmsResponse);
  },
  loadAboutUsContent({ commit }) {
    const cmsResponse = {
      banner: {
        icon: null,
        title: "Team Really!",
        subTitle:
          "Really! er en digital plattform for eiendomstjenester som gjennom å forenkle, effektivisere og berike prosessen med anskaffelse, effekturering og forvaltning av eiendomstjenester bidra til reduserte kostnader, bedre kontroll og mer bærekraftige løsninger for både kunde og leverandør.",
      },
      description: {
        title: "Really! vil:",
        sections: [
          {
            id: 1,
            text: "Gjøre det enklere for innkjøpere å manøvrere i jungelen av og velge leverandører bl.a. prekvfalifisere og luke vekk useriøse aktører gjennom digitale oppslag, brukertifredshet og anbefalingsteknologi.",
          },
          {
            id: 2,
            text: "Gjøre det enklere å velge tjeneste gjennom standardiserte pakker og oversiktlige valgmuligheter innenfor hvert tjenesteområde.",
          },
          {
            id: 3,
            text: "Eliminere tidsbruk på tilbud og fysisk befaring. En digital prisrobot kan innkjøper hente priser fra ulike leverandører i realtid.",
          },
          {
            id: 4,
            text: "Digitalisere oppfølgning av inngått avtale ved å legge til rette for moderne teknologi, som for eksempel sensor og moderne kommunikasjonsverktøy.",
          },
          {
            id: 5,
            text: "Dette vil gi innkjøper bedre kontroll over faktisk leveranse, og eksempelvis bli varslet raskere hvis service uteblir.",
          },
          {
            id: 6,
            text: "Benchmarke leveranser løpende - på pris, kvalitet/brukertilfreshet og etterhvert bærekraft basert på data og algoritmer.",
          },
          {
            id: 7,
            text: "Team Really! har lang erfaring innen ledelse av eiendomstjenester og har lang erfaring med innovasjon innenfor eiendomsteknologi. De kjenner markedet svært godt.",
          },
        ],
        team: [
          {
            id: 1,
            avatar: "about-us/team_1.svg",
            name: "Dagfinn Edvardsen",
            description:
              "Dagfinn har over 16 års erfaring fra eiendomsbransjen. Den siste tiden har han ledet avdelingen for eiendomstjenester til BOB, Norges tredje største boligbyggelag. Han har vært tømrer, driftssjef, teknisk forvalter og leder av diverse håndverksbedrifter.",
          },
          {
            id: 2,
            avatar: "about-us/team_2.svg",
            name: "Anders Daniel Brekke",
            description:
              "Ander har jobbet lenge med teknologi i eiendomsbransjen. Han er tidligere kjøpmann og har jobbet med teknologi innen boligutvikling og eiendomstjenester.",
          },
        ],
      },
    };
    commit("saveAboutUs", cmsResponse);
  },
  loadSuppliersContent({ commit }) {
    const cmsResponse = {
      banner: {
        icon: null,
        title: "For leverandører",
        subTitle:
          "Vi er alltid på jakt etter kvalitetsleverandører som ser vekstmuligheten som tilbys gjennom Really!-plattformen",
      },
      gettingStarted: {
        title: "Helt uforpliktende",
        start: {
          title: "Bli med!",
          subTitle:
            "Vi ser hele tiden etter leverandører som leverer kvalitet. Registrer bedriften din. \n" +
            "Hvilken tjeneste leverer du?",
        },
        sideIcon: "getting_started/side_arrow.svg",
        bottomIcon: "getting_started/bottom_arrow_suppliers.svg",
      },
      description: {
        title: "Fordeler for leverandør",
        text: "Vår teknologi skal hjelpe deg med å bli mer effektiv og øke oppdrags-mengden. Administrasjonstiden skal reduseres, spesielt når det gjelder tilbud, befaring og fakturering gjennom digital prisrobot og oppfølging digitalt.",
        sections: [
          {
            id: 1,
            icon: "suppliers/customers.svg",
            text: "Vi skaffer deg kunder",
          },
          {
            id: 2,
            icon: "suppliers/offers.svg",
            text: "Vi gjør anbud/tilbud til en enkel, sømløs og trygg prosess.",
          },
          {
            id: 3,
            icon: "suppliers/efficient.svg",
            text: "Effektiv drift betyr reduserte kostnader og økt bunnlinje.",
          },
        ],
      },
      form: {
        title: "Det neste vi trenger å vite er litt om bedriften din:",
        company: "Selskapsnavn",
        contactPerson: "Kontaktperson",
        email: "E-post adresse",
        phone: "Telefonnummer",
        location: {
          placeholder: "By (geografisk område)",
          text: "Du kan velge flere områder i menyen.",
        },
      },
      buttonText: "Registrer deg!",
    };
    commit("saveSuppliersContent", cmsResponse);
  },
  loadTenderConfirmedContent({ commit }) {
    const cmsResponse = {
      icon: "Tender/ok.svg",
      title: "Helt King kong!",
      description: [
        {
          id: 1,
          text: "Da kan du bare legge deg nedpå igjen. Roboten vår jobber så det knaker i tannhjul og blinker i dioder",
        },
        {
          id: 2,
          text: "Eller, du kan trykke på Ok! og se tilbudene med engang",
        },
      ],
      reference: "Referanse: #R123456",
      buttonText: "OK",
    };
    commit("saveTenderConfirmedContent", cmsResponse);
  },
  loadProfileContent({ commit }) {
    const cmsResponse = {
      banner: {
        icon: null,
        title: "AB Solutions",
        subTitle: "",
      },
      header: {
        name: "Navn",
        phone: "Telefon",
        email: "E:post",
        company: "Firma",
        address: "Fakturaadresse",
        postNr: "Postnr./Sted",
        orgNumber: "Organisasjonsnummer",
        editButtonText: "Endre brukerinformasjon",
      },
      body: {
        buildingIcon: "profile/building.svg",
        foldIcon: "profile/fold.svg",
        unFoldIcon: "profile/unFold.svg",
        nestedFoldIcon: "profile/nestedFold.svg",
        nestedUnfoldIcon: "profile/nestedUnfold.svg",
        buildingText: "Mine bygg",
        offerOverview: "Tilbudsoversikt",
        communicationWithCustomer: "Kommunikasjon med kunde",
        onGoingAgreements: "Løpende avtaler",
        tenderFoldIcon: "profile/tenderFold.svg",
        tenderUnfoldIcon: "profile/tenderUnfold.svg",
        offerFoldIcon: "profile/offerFold.svg",
        offerUnfoldIcon: "profile/offerUnfold.svg",
      },
    };
    commit("saveProfileContent", cmsResponse);
  },
  loadViewOfferContent({ commit }) {
    const cmsResponse = {
      banner: {
        icon: null,
        title: "Hei, her er tilbudene vi har samlet for deg",
        subTitle:
          "Nå er det opp til deg å velge det dere opplever som best. Du trenger ikke å svare de leverandørene dere ikke velger. Det ordner vi.",
      },
      description: {
        title: "Tilbudsoversikt",
        text: "For å bestille tjenester dere man være registrert. Kanskje like greit å gjøre det med en gang. Så er dere klare når behovet oppstår.",
      },
      toolTip: "Hoy! Trykk på pilen for å lese innholdet i tilbudene.",
      register: {
        title: "Nå er det er lurt å registrere seg!",
        subTitle:
          "Vi har sendt dette tilbudet på e-post til deg. Om du registrerer deg her,  finner du også alle tilbudene dine samlet i en oversikt på Min side.",
        buttonText: "Registrer deg!",
      },
      viewDetailsIcon: {
        folded: "offers/view_button_folded.svg",
        unFolded: "offers/view_button_unfolded.svg",
      },
      toolTipIcon: "offers/green_tool_tip.svg",
      offerDetails: {
        summary: {
          title: "Oppsumering tilbud",
          small: "Enkel ",
          medium: "Standard ",
          vat: ",- eks. mva.",
          filterDiscount: "Rabatt filter",
          description:
            "(Du velger hvilken pakke du vil ha nederst i dette tilbudet)",
          validThrough: "Gyldig til:",
          contactPerson: "Kontaktperson",
          icons: {
            folded: "offers/collapse_folded.svg",
            unFolded: "offers/collapse_unfolded.svg",
          },
          bookingNumber: "Referanse",
          packageContents: "Pakkens innhold",
        },
        supplierProfile: {
          title: "Leverandørprofil",
          employees: "Ansatte",
          revenue: "Omsetning",
          startBank: "Startbank",
          sustainability: {
            icon: "profile/sustainability_icon.svg",
            title: "FNs BÆREKRAFTMÅL",
            description:
              "Bygging og drift av bygg utgjør 40% av norske klimautslipp. Derfor er det relevant for vår bransje bidra til at FNs bærekraftmål nås. Alle 17 målene henger sammen, og vi bidrar ved å fokusere på:",
          },
        },
        invoiceRecipient: {
          title: "Velg fakturamottaker",
          header:
            "Fakturaen vil bli sendt til <Firmanavn>  Att: <Navn Navnesen> Org. nr.: <000 000 000> Om fakturaen skal sendes annet firma og kontaktperson kan gjøre det her.",
          confirm: "Ja, faktura skal sendes annen mottaker",
          placeholder: {
            orgNumber: "Organisasjonsnummer til fakturamottaker",
            email: "E-post til fakturamottaker",
          },
        },
        action: {
          title: "Velg pakke og oppstartsdato",
          packageDetails: {
            first: "Se hva",
            second: "inkluderer",
            icon: "offers/info.svg",
            activeIcon: "offers/active_icon.svg",
          },
          price: "Pris",
          startDate: "Ønsket oppstartsdato: ",
          dateDescription: "(Ikke lengre enn 2 mnd fram i tid)",
        },
        reservation: {
          title: "Forbehold",
          reservations: [
            {
              id: 1,
              text: "Riktig input ved bestilling.",
            },
            {
              id: 2,
              text: " Arbeid innenfor normal arbeidstid, kl. 07.00 - 15.00.",
            },
            {
              id: 3,
              text: "Tilstrekkelig tilkomst.",
            },
          ],
        },
        termsOfUse: {
          title: "Brukervilkår",
          description:
            "Ved å bestille tjenester gjennom Really! godtar du som bruker og kunde følgende vilkår:",
          icon: "offers/tc_file.svg",
          read: "Les eller last ned:",
          file: "Bruksvilkar_Kundeavtale.pdf",
        },
        confirmAndAccept: {
          acceptTermsOfUse: "Jeg har lest og godkjenner brukervilkårene",
          canSign:
            "Jeg bekrefter at jeg har rett til å signere på vegne av selskapet",
          sms: {
            button: "send",
            placeholder: "Ditt mobilnummer",
            footer: "Bekreftelseskode blir sendt på sms.",
          },
          buttonText: "Godta tilbud!",
          registerWarning:
            "You are not registered, please register to proceeed.",
        },
      },
      offerAgreement: {
        supplier: {
          contactPerson: "Kontaktperson:",
          price: "Faktureres til Really: 43.000",
          reference: "Referanse:",
          package: "Pakke: ",
        },
        title: "Kundeavtale for kjøp av servicetjenester",
        client: {
          name: "Kundenavn:",
          orgNumber: "Organisasjonsnummer:",
        },
        subTitle: "Leverandørprofil",
        agreement: {
          icon: "profile/agreement_download.svg",
          text: "Last ned avtalen som PDF",
        },
        orderConfirmation: {
          icon: "profile/agreement_download.svg",
          text: "Ordrebekretelse",
        },
        packageDescription: {
          icon: "profile/package_download.svg",
          text: "Last ned pakkebeskrivelse",
        },
      },
    };
    commit("saveViewOfferContent", cmsResponse);
  },
  loadMyAgreementsContent({ commit }) {
    const cmsResponse = {
      tabs: [
        {
          title: "Alle jobber",
          label: "all_jobs",
        },
        {
          title: "Gi pris på forespørsel",
          label: "accepted_offers",
        },
        {
          title: "Sendt tilbud",
          label: "pending_offers",
        },
        {
          title: "Aktive Oppdrag",
          label: "active_jobs",
        },
        {
          title: "Avsluttet/Arkivert",
          label: "terminated_or_archived_jobs",
        },
      ],
    };
    commit("saveMyAgreementsContent", cmsResponse);
  },
  loadEditUserContent({ commit }) {
    const cmsResponse = {
      title: "Endre brukerinformasjon",
      name: "Navn",
      phone: "Telefon",
      email: "E-post",
      orgNumber: "Firma/Organisasjon",
      address: "Fakturaadresse",
      postNr: "Postnummer",
      buttonText: "Bekreft endringer",
      cancel: "Avbryt",
    };
    commit("saveUserEditContent", cmsResponse);
  },
  loadWindowConstraintsContent({ commit }) {
    const cmsResponse = {
      title: "Nå blir det veldig trangt her!",
      sections: [
        {
          id: 1,
          text: "Størrelsen på vinduet til nettleseren din blir så smalt at roboten vår rett og slett ikke klarer å tenke. For å unngå at det går i ball for han trenger han litt plass.",
        },
        {
          id: 2,
          text: "(Minimum 768pxl bredde)",
        },
        {
          id: 3,
          text: "Vi jobber med en mobilversjon som fungerer på mindre skjermstørrelser. Men inntil det må du være litt grei med Robotolf og gjøre vinduet litt større igjen.",
        },
      ],
      buttonText: "Mottatt!",
      icon: "layout/robot.svg",
    };
    commit("saveWindowConstraintContent", cmsResponse);
  },
  async loadMyPricesContent({ commit }) {
    const cmsResponse = {
      bottomArrowIcon: "my-prices/bottom_arrow.svg",
      industry: {
        crookedArrowIcon: "crooked_line.svg",
        headerContent: {
          title: "Bransje:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "industry",
          instructionText:
            "Her velger du hvilken bransje og virksomhet du vil legge inn pris segmenter til.",
        },
      },
      segments: {
        headerContent: {
          title: "Segmenter:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "segments",
          instructionText:
            "Hvert segment kan deles inn i forskjellige områder for å kategorisere prisene ytterligere. Bruk skjemaet under til å administrere segmentområdene for ",
        },
        saveButtonContent: {
          buttonText: "Lagre Segmentområder",
          note: "Når du har lagt inn alle segmentene for overflater og frekvens bekrefter du med å trykke på knappen her.",
        },
      },
      yourPrices: {
        headerContent: {
          title: "Dine priser:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "prices",
          instructionText:
            "«Vennligst fyll ut prisene under for de ulike segmentene for Renhold og Bolig. Alle priser oppgis eks. mva.",
        },
        generalPriceContent: {
          title: "Reservasjoner",
          content: [
            {
              text: "Reservasjonsbeløp maks. størrelse pr. oppdrag:",
              price: "500.000,-",
            },
            {
              text: "Reservasjonsbeløp minimum størrelse pr. oppdrag:",
              price: "3000,-",
            },
            {
              text: "Reservasjon maks. avstand oppgitt i kilometer:",
              price: "40 km",
            },
          ],
          instructionImage: "my-prices/generelle_priser_robot.svg",
          headerContent: {
            title: "",
            instructionImage: "my-prices/robot_head.svg",
            imgClass: "general-robot-head-img",
            textClass: "general-prices",
            instructionText:
              "De generelle prisene kan du forandre i skjemaet over. (Under Segmenter)",
          },
        },
      },
      savePrices: {
        headerContent: {
          title: "Jeg er helt ferdig!",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "save-prices",
          instructionText:
            "NB! Om du ikke er helt ferdig kan du lagre og fortsette senere når du har tid. Om du er ferdig er det godt jobbet! Nå har du fortjent en god kaffe! MEN... IKKE GLEM Å TRYKKE PÅ LAGRE!",
        },
        saveButtonContent: {
          buttonText: "Lagre priser",
          noteText:
            "Endrede priser er aktive med en gang du trykker på “Lagre priser.",
        },
      },
    };
    commit("saveMyPricesContent", cmsResponse);
  },
  async loadOtherServicesContent({ commit }) {
    const cmsResponse = {
      bottomArrowIcon: "my-prices/bottom_arrow.svg",
      industry: {
        crookedArrowIcon: "my-prices/crooked_arrow.svg",
        headerContent: {
          title: "Bransje:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "industry",
          instructionText:
            "Her velger du hvilke andre tjenester du kan tilby. Når du har valgt en tjeneste vil du kunne motta forespørsler fra kunder på denne jobben.",
        },
      },
      segments: {
        headerContent: {
          title: "Velg hvilke oppdrag du kan ta",
        },
        saveButtonContent: {
          buttonText: "Lagre engangsoppdrag",
          note: "Når du har lagt inn alle segmentene for overflater og frekvens bekrefter du med å trykke på knappen her.",
        },
      },
    };
    commit("saveOtherServicesContent", cmsResponse);
  },
  async loadLocationsContent({ commit }) {
    const cmsResponse = {
      bottomArrowIcon: "my-prices/bottom_arrow.svg",
      industry: {
        crookedArrowIcon: "my-prices/crooked_arrow.svg",
        headerContent: {
          title: "Dine lokasjoner:",
          instructionImage: "my-prices/robot_head.svg",
          imgClass: "robot-head-img",
          textClass: "offices",
          instructionText:
            "Her legger du til dine lokasjoner. Vi bruker dette til å finne ut om du kan levere tjenester til kunden og kalkulere kilometer du må kjøre for hvert oppdrag.",
        },
      },
    };
    commit("saveLocationsContent", cmsResponse);
  },
  async selectSelectedService({ commit }, selectedService) {
    commit("saveSelectedService", selectedService);
  },
  clearSelectedService({ commit }) {
    commit("clearSelectedService");
  },
  async selectMenuOption({ commit }, selectedMenuOption) {
    commit("saveSelectedMenuOption", selectedMenuOption);
  },
  clearSelectedMenuOption({ commit }) {
    commit("clearSelectedMenuOption");
  },
  async selectSupplierType({ commit }, selectedSupplierType) {
    commit("saveSelectedSupplierType", selectedSupplierType);
  },
  clearSelectedSupplierType({ commit }) {
    commit("clearSelectedSupplierType");
  },
  saveServiceTypes({ commit }, serviceTypes) {
    commit("saveServiceTypes", serviceTypes);
  },
  loadIndustryServices({ commit }) {
    const cmsResponse = [
      {
        id: 1,
        industryType: "Renhold",
        industryTypeTranslation: "cleaning",
      },
      {
        id: 2,
        industryType: "Byggdrift",
        industryTypeTranslation: "maintenance",
      },
      {
        id: 3,
        industryType: "Elektro",
        industryTypeTranslation: "electric",
      },
      {
        id: 4,
        industryType: "Ventilasjon",
        industryTypeTranslation: "ventilation",
      },
    ];

    commit("saveSupplierIndustryTypes", cmsResponse);
  },

  loadFaqContent({ commit }) {
    cmsService
      .getFAQ()
      .then((faqContent) => commit("saveFaqContent", faqContent));
  },
  loadPriceGroups({ commit }) {
    cmsService
      .getPriceGroups()
      .then((priceGroups) => commit("savePriceGroups", priceGroups));
  },

  loadErrorContent({ commit }) {
    const cmsResponse = {
      banner: {
        footerIcon: "error-page/robot_error_icon.svg",
        title: "Hmmm... dette var litt merkelig?",
        subTitle:
          "Vi finner ingen leverandører knyttet til ditt brukernavn, er du helt sikker på at du brukte riktig innlogging når du prøvde nå? Hvis du tror dette er feil, send brukernavnet ditt til ",
        subTitleEmail: "support@really-services.no",
      },
      faq: {
        title: "FAQ",
        subTitle:
          "Under finner du de vanligst spørsmålene vi får om løsningen vår. Om du kjenner deg igjen i spørsmålet,  trykker du bare på det så får du opp et svar.",
        subHeader: "Spørsmål: ",
        replyIcon: "Faq/robot_reply.svg",
      },
    };
    commit("saveErrorContent", cmsResponse);
  },
};

const mutations = {
  saveServiceReportContent(state, serviceReportContent) {
    state.serviceReportContent = serviceReportContent;
  },
  reloadSelectedService(state) {
    if (repository.exists("selectedService"))
      state.selectedService = repository.get("selectedService");
  },
  saveMyPricesContent(state, myPricesContent) {
    state.myPrices = myPricesContent;
  },
  saveOtherServicesContent(state, otherServicesContent) {
    state.otherServicesContent = otherServicesContent;
  },
  saveLocationsContent(state, locationsContent) {
    state.locationsContent = locationsContent;
  },
  saveHeaderContent(state, headerContent) {
    state.header = headerContent;
  },
  saveHomeContent(state, homeContent) {
    state.home = homeContent;
  },
  saveSuppliersSectionContent(state, suppliersContent) {
    state.suppliersSection = suppliersContent;
  },
  saveSupplierAdminMenuContent(state, supplierMenuContent) {
    state.supplierAdminMenuOptions = supplierMenuContent;
  },
  saveSupplierRegistrationContent(state, payload) {
    state.supplierRegistrationContent = payload;
  },
  saveSupplierMetadataContent(state, payload) {
    state.supplierMetadata = payload;
  },
  saveFooterContent(state, footerContent) {
    state.footer = footerContent;
  },
  saveMyPricesServicesContent(state, supplierTypesContent) {
    state.supplierTypes = supplierTypesContent;
  },
  saveSegmentsReservationsContent(state, segmentsReservationsContent) {
    state.segmentsReservationsContent = segmentsReservationsContent;
  },
  saveSelectedSupplierType(state, selectedSupplierType) {
    state.selectedSupplierType = selectedSupplierType;
    repository.save("selectedSupplierType", selectedSupplierType);
  },
  clearSelectedSupplierType(state) {
    state.selectedSupplierType = {};
    repository.delete("selectedSupplierType");
  },
  saveServiceTypes(state, serviceTypes) {
    state.serviceTypes = serviceTypes;
  },
  saveServicesContent(state, servicesContent) {
    state.services = servicesContent;
  },
  saveSelectedService(state, selectedService) {
    state.selectedService = selectedService;
    repository.save("selectedService", selectedService);
  },
  clearSelectedService(state) {
    state.selectedService = {};
    repository.delete("selectedService");
  },
  saveSelectedMenuOption(state, selectedMenuOption) {
    state.selectedMenuOption = selectedMenuOption;
    repository.save("selectedMenuOption", selectedMenuOption);
  },
  clearSelectedMenuOption(state) {
    state.selectedMenuOption = {};
    repository.delete("selectedMenuOption");
  },

  saveHowToOrder(state, howToOrderContent) {
    state.howToOrder = howToOrderContent;
  },
  saveAboutUs(state, aboutUsContent) {
    state.aboutUs = aboutUsContent;
  },
  saveSuppliersContent(state, suppliersContent) {
    state.suppliers = suppliersContent;
  },
  saveTenderConfirmedContent(state, tenderConfirmedContent) {
    state.tenderConfirmed = tenderConfirmedContent;
  },
  saveViewOfferContent(state, viewOfferDetails) {
    state.viewOffer = viewOfferDetails;
  },
  saveProfileContent(state, profileDetails) {
    state.profile = profileDetails;
  },
  saveUserEditContent(state, editUserDetails) {
    state.editUser = editUserDetails;
  },
  saveWindowConstraintContent(state, windowConstraintDetails) {
    state.windowConstraint = windowConstraintDetails;
  },
  saveSupplierIndustryTypes(state, industryTypes) {
    state.supplierIndustryTypes = industryTypes;
  },
  saveSupplierSustainabilityContent(state, sustainability) {
    state.supplierSustainability = sustainability;
  },
  saveSupplierCertification(state, certification) {
    state.supplierCertification = certification;
  },
  saveSupplierAddressContent(state, addressContent) {
    state.supplierAddress = addressContent;
  },
  saveMyAgreementsContent(state, agreementsContent) {
    state.agreementsContent = agreementsContent;
  },
  saveFaqContent(state, faqContent) {
    state.faqContent = faqContent;
  },
  savePriceGroups(state, priceGroups) {
    state.priceGroups = priceGroups;
  },
  saveErrorContent(state, errorContent) {
    state.errorContent = errorContent;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
