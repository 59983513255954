<template>
  <textarea
    v-bind="$attrs"
    ref="textArea"
    @input="$emit('update:modelValue', $event.target.value)"
  />
</template>

<script>
import autosize from "autosize/dist/autosize";

export default {
  inheritAttrs: false,
  props: {
    autoResize: {
      type: Boolean,
      default: false,
    },
    modelValue: {
      type: String,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  mounted() {
    this.$watch(
      "autoResize",
      () => {
        if (this.autoResize) {
          autosize(this.$refs.textArea);
        } else {
          autosize.destroy(this.$refs.textArea);
        }
      },
      { immediate: true },
    );
  },
};
</script>
