<template>
  <div ref="menuContainer" class="supplier-offer-menu">
    <button class="supplier-offer-menu__btn" @click="toggleShowMenu">
      <img :src="`/images/24/alternatives.svg`" alt="Menu icon" />
    </button>
    <div v-if="showMenu" class="supplier-offer-menu__dropdown">
      <base-menu :items="getMenuOptions" @item-clicked="onMenuItemClick" />
    </div>
  </div>
</template>

<script>
import BaseMenu from "@/components/Base/BaseMenu/BaseMenu.vue";
import { uuidv4 } from "@/utilities/uuid";
import { OfferStatus, TenderState } from "@/custom-types/types";
import vClickOutside from "v-click-outside";

export default {
  components: { BaseMenu },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  props: {
    offer: {
      type: Object,
      required: true,
    },
    tender: {
      type: Object,
      required: true,
    },
  },
  emits: ["item-action"],
  data() {
    return {
      showMenu: false,
      menuItems: this.getInitialMenuOptions(),
    };
  },
  computed: {
    getMenuOptions() {
      const menuOptions = this.getInitialMenuOptions();

      const stateHandlers = {
        [OfferStatus.Draft]: this.handleDraftState,
        [OfferStatus.Offered]: this.handleOfferedState,
        [OfferStatus.Withdrawn]: this.handleWithdrawnState,
        [OfferStatus.Accepted]: this.handleAcceptedState,
        [OfferStatus.Completed]: this.handleAcceptedState,
        [OfferStatus.Rejected]: this.handleWithdrawnState,
      };

      stateHandlers[this.offer.offerState]?.(menuOptions);

      return menuOptions;
    },
  },
  watch: {
    showMenu(newVal) {
      if (!this.$refs.menuContainer) {
        return;
      }
      if (newVal) {
        vClickOutside.directive.bind(this.$refs.menuContainer, {
          value: this.onOutsideClick,
        });
      } else {
        vClickOutside.directive.unbind(this.$refs.menuContainer);
      }
    },
  },
  methods: {
    getInitialMenuOptions() {
      const menuItems = [
        { text: "Se tilbud", eventName: "view-offer" },
        { text: "Send til kunde", eventName: "send-to-customer" },
        { text: "Endre", eventName: "edit" },
        { text: "Lag kopi", eventName: "copy" },
        { text: "Slett", eventName: "delete-draft" },
        { text: "Trekk tilbake", eventName: "revoke-offer" },
        { text: "Se avtale", eventName: "view-offer" },
        { text: "Last ned avtale", eventName: "see-agreement" },
        { text: "Last ned vilkår", eventName: "download-terms" },
      ];

      return menuItems.map(this.createMenuItem).filter((menu) => menu !== null);
    },

    createMenuItem({ text, eventName }) {
      if (this.shouldMenuItemBeShown(text, eventName)) {
        return {
          id: uuidv4(),
          text,
          eventName,
        };
      }
      return null;
    },

    shouldMenuItemBeShown(text) {
      return !(
        (this.tender.tenderState === TenderState.Accepted ||
          this.tender.tenderState === TenderState.Completed) &&
        text !== "Se tilbud" &&
        text !== "Endre"
      );
    },

    removeMenuItemsByCondition(menuOptions, condition) {
      return menuOptions.filter((option) => condition(option));
    },

    handleStateChanges(menuOptions, modifications) {
      modifications.forEach(({ action, text }) => {
        const index = menuOptions.findIndex((item) => item.text === text);
        if (index !== -1) {
          if (action === "remove") {
            menuOptions.splice(index, 1);
          } else if (action === "replace") {
            menuOptions[index] = this.createMenuItem({
              text: action.replaceWith,
              eventName: action.eventName,
            });
          }
        }
      });
    },

    handleDraftState(menuOptions) {
      if (
        this.tender.tenderState === TenderState.Accepted ||
        this.tender.tenderState === TenderState.Completed
      ) {
        this.handleStateChanges(
          menuOptions,
          menuOptions.reduce((actions, item) => {
            if (item.text !== "Se tilbud") {
              actions.push({
                action: "remove",
                text: item.text,
              });
            }
            return actions;
          }, []),
        );
      } else {
        this.handleStateChanges(menuOptions, [
          { action: "remove", text: "Se tilbud" },
          { action: "remove", text: "Trekk tilbake" },
          { action: "remove", text: "Se avtale" },
          { action: "remove", text: "Last ned avtale" },
          { action: "remove", text: "Last ned vilkår" },
        ]);
      }
    },

    handleOfferedState(menuOptions) {
      if (
        this.tender.tenderState === TenderState.Accepted ||
        this.tender.tenderState === TenderState.Completed
      ) {
        this.handleStateChanges(
          menuOptions,
          menuOptions.reduce((actions, item) => {
            if (item.text !== "Se tilbud") {
              actions.push({
                action: "remove",
                text: item.text,
              });
            }
            return actions;
          }, []),
        );
      } else {
        this.handleStateChanges(menuOptions, [
          { action: "remove", text: "Send til kunde" },
          { action: "remove", text: "Lag kopi" },
          { action: "remove", text: "Slett" },
          { action: "remove", text: "Se avtale" },
          { action: "remove", text: "Last ned avtale" },
          { action: "remove", text: "Last ned vilkår" },
        ]);
      }
    },

    handleWithdrawnState(menuOptions) {
      if (
        this.tender.tenderState === TenderState.Accepted ||
        this.tender.tenderState === TenderState.Completed
      ) {
        this.handleStateChanges(
          menuOptions,
          menuOptions.reduce((actions, item) => {
            if (item.text !== "Se tilbud") {
              actions.push({
                action: "remove",
                text: item.text,
              });
            }
            return actions;
          }, []),
        );
      } else {
        this.handleStateChanges(menuOptions, [
          {
            action: {
              replaceWith: "Se tilbud",
              eventName: "view-offer",
            },
            text: "Send til kunde",
          },
          { action: "remove", text: "Lag kopi" },
          { action: "remove", text: "Slett" },
          { action: "remove", text: "Send til kunde" },
          { action: "remove", text: "Se avtale" },
          { action: "remove", text: "Trekk tilbake" },
          { action: "remove", text: "Last ned avtale" },
          { action: "remove", text: "Last ned vilkår" },
        ]);
      }
    },

    handleAcceptedState(menuOptions) {
      this.handleStateChanges(
        menuOptions,
        menuOptions.reduce((actions, item) => {
          if (item.text !== "Se avtale") {
            actions.push({
              action: "remove",
              text: item.text,
            });
          }
          return actions;
        }, []),
      );
    },

    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },

    onOutsideClick() {
      this.showMenu = false;
    },

    onMenuItemClick(item) {
      this.$emit("item-action", item.eventName);
    },
  },
};
</script>

<style lang="scss" scoped>
.supplier-offer-menu {
  display: inline-block;
  position: relative;

  &__btn {
    background: #fff;
    border: 1px solid $color-primary-pink-darker;
    border-radius: 4px;
    width: 4rem;
    height: 4rem;
    display: flex;
    justify-content: center;
    align-items: center;
    transition:
      box-shadow 0.3s ease,
      transform 0.3s ease;

    &:hover {
      box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
      transform: translateY(-2px);
      z-index: 0;
    }
  }

  &__dropdown {
    position: absolute;
    right: 0;
    width: 140px;
    top: 100%;
    z-index: 999;
    text-align: left;
  }
}
</style>
@/utilities/uuid @/types/AppTypes
