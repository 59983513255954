import actions from "@/store/modules/supplier/actions";
import getters from "@/store/modules/supplier/getters";
import mutations from "@/store/modules/supplier/mutations";
import state from "@/store/modules/supplier/state";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
