export const isOverlap = (
  rangeA: { rangeFrom: number; rangeTo: number },
  rangeB: { rangeFrom: number; rangeTo: number },
) => {
  return (
    (rangeA.rangeFrom <= rangeB.rangeTo &&
      rangeA.rangeFrom >= rangeB.rangeFrom) ||
    (rangeA.rangeTo <= rangeB.rangeTo && rangeA.rangeTo >= rangeB.rangeFrom) ||
    (rangeB.rangeFrom <= rangeA.rangeTo &&
      rangeB.rangeFrom >= rangeA.rangeFrom) ||
    (rangeB.rangeTo <= rangeA.rangeTo && rangeB.rangeTo >= rangeA.rangeFrom)
  );
};
