<template>
  <div class="terms-and-conditions">
    <div class="terms-and-conditions__title">{{ title }}</div>
    <div class="terms-and-conditions__description">
      {{ descriptionContent }}
    </div>
    <table class="terms-and-conditions__terms">
      <tbody>
        <tr
          v-for="(term, index) in terms"
          :key="index"
          class="terms-and-conditions__term"
        >
          <td class="terms-and-conditions__term-title">
            {{ term.title }}
          </td>
          <td class="terms-and-conditions__term-description">
            <custom-block-content v-if="term.content" :blocks="term.content" />
          </td>
        </tr>
      </tbody>
    </table>
    <a
      :href="seeAllTermsFileURL"
      target="_blank"
      class="terms-and-conditions__see-all-offers-button"
    >
      <img :src="seeAllTermsButtonIcon" alt="New window" />
      <span>{{ seeAllTermsButtonText }}</span>
    </a>
  </div>
</template>

<script>
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import {
  getCmsItemText,
  getFileUrl,
  getIconUrl,
} from "@/utilities/cmsUtilities";

export default {
  components: { CustomBlockContent },
  props: {
    termsAndConditionsCms: {
      type: Object,
      required: true,
    },
  },
  computed: {
    title() {
      return this.termsAndConditionsCms.title;
    },
    descriptionContent() {
      return getCmsItemText(
        this.termsAndConditionsCms.otherText,
        "description",
      );
    },
    terms() {
      return this.termsAndConditionsCms.otherText.filter((otherText) =>
        otherText.label.includes("term"),
      );
    },
    seeAllTermsButton() {
      return this.termsAndConditionsCms.buttons.find(
        (button) => button.label === "seeAllTermsButton",
      );
    },
    seeAllTermsButtonText() {
      return this.seeAllTermsButton.title;
    },
    seeAllTermsButtonIcon() {
      return getIconUrl(this.seeAllTermsButton.icons, "newWindowIcon");
    },
    seeAllTermsFileURL() {
      return getFileUrl(this.termsAndConditionsCms.filedoc, "seeAllTerms");
    },
  },
};
</script>

<style lang="scss" scoped>
.terms-and-conditions {
  border: 1px solid $color-primary-pink-darker;
  padding: 1.5rem;
  border-radius: 8px;
  color: $color-black;
  background: $color-primary-pink-dark;

  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
    line-height: 1.2;
  }

  &__description {
    font-weight: 400;
    font-size: $font-size-sm;
    margin-top: 0.5rem;
  }

  &__terms {
    margin-top: 0.25rem;
  }

  &__term-title,
  &__term-description {
    padding-top: 0.75rem;
    vertical-align: top;
    line-height: 1.2;
    font-size: $font-size-sm;
  }

  &__term-title {
    padding-right: 1rem;
    font-weight: 600;
  }

  &__term-description {
    font-weight: 400;
  }

  &__see-all-offers-button {
    margin-top: 1.5rem;
    border: 1px solid #1d1d1d;
    border-radius: 8px;
    padding: 0.625rem 1rem;
    background-color: transparent;
    font-weight: 400;
    font-size: $font-size-sm;
    display: inline-flex;
    align-items: center;
    gap: 0.5rem;
    color: $color-black;
    text-decoration: none;

    &:hover {
      cursor: pointer;
    }
  }
}
</style>
@/utilities/cmsUtilities
