<template>
  <div class="button-with-note d-flex ps-3">
    <div class="button-with-note__save-button ms-4">
      <button
        class="button-with-note__btn"
        :disabled="disabled"
        @click="handleClick"
      >
        {{ buttonText }}
        <b-spinner
          v-if="isLoading"
          type="grow"
          class="button-with-note__spinner"
        />
      </button>
    </div>

    <div class="button-with-note__note ms-3 text-left">
      {{ noteText }}
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits } from "vue";

const EMIT_MESSAGES = {
  "service-type-segments": "save-segment-ranges",
  "service-type-segments-prices": "save-segment-prices",
  "supplier-profile": "update-supplier-profile",
  "supplier-services": "update-supplier-services",
};

const props = defineProps({
  isLoading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  buttonText: {
    type: String,
    required: true,
  },
  noteText: {
    type: String,
    required: true,
  },
  componentType: {
    type: String,
    required: true,
  },
});

const emit = defineEmits([
  "save-segment-ranges",
  "save-segment-prices",
  "update-supplier-profile",
  "update-supplier-services",
]);

const handleClick = () => {
  const message = EMIT_MESSAGES[props.componentType];
  if (message) {
    emit(message, true);
  }
};
</script>

<style lang="scss" scoped>
$green-color: #5d9179;
$green-color-darker: #3d5f4b;
$green-color-lighter: #a3c1a0;
$white-color: #fff;
$padding-standard: 1rem;
$font-size-standard: 1rem;
$font-size-small: 0.75rem;

.button-with-note {
  margin-top: 3rem;
  margin-bottom: 3rem;

  &__btn {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-base;
    font-weight: 700;
    width: auto;
    padding: 1rem 2rem;
    border-radius: 0.375rem;
    color: $white-color;
    background-color: $green-color;
    border: 0.1rem solid $green-color;
    align-items: center;
    text-align: center;
    letter-spacing: 0.04rem;
    line-height: normal;
    transition:
      background-color 0.3s,
      transform 0.2s;
    cursor: pointer;

    &:hover {
      background-color: $green-color-darker;
      border: 0.1rem solid $green-color-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1.05);
    }

    &:disabled {
      background-color: $green-color-lighter;
      border: 0.1rem solid $green-color-lighter;
      cursor: not-allowed;
    }
  }

  &__note {
    width: 20%;
    font-size: $font-size-small;
    color: $color-primary;
    font-weight: 700;
  }

  &__spinner {
    width: 1.2rem;
    height: 1.2rem;
  }
}
</style>
