<template>
  <div v-if="!isTenderLoading && selectedTender" class="tender-offer">
    <router-view :tender="selectedTender" :supplier-info="supplier" />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { RouteNames } from "@/router/routes";
import { useTenderApi } from "@/services/api/useTenderApi";

const { getTender } = useTenderApi();

export default {
  data() {
    return {
      selectedTender: null,
      isTenderLoading: true,
    };
  },

  computed: {
    ...mapGetters("offers", {
      allTenders: "getAllTenders",
    }),
    ...mapGetters("supplier", {
      supplier: "getSupplier",
    }),
  },

  watch: {
    "$route.params.tenderId": {
      immediate: true,
      handler() {
        //this.fetchTenderDetails();
      },
    },
  },

  async created() {
    await this.initialize();
  },

  methods: {
    async initialize() {
      try {
        await this.fetchSupplierIfNeeded();
        await this.fetchAllTendersIfNeeded();
        await this.fetchTenderDetails();
      } catch (error) {
        console.error("Error initializing:", error);
      }
    },

    async fetchSupplierIfNeeded() {
      if (!this.supplier?.id) {
        await this.$store.dispatch("supplier/fetchSupplier");
      }
    },

    async fetchAllTendersIfNeeded() {
      if (!this.allTenders) {
        await this.$store.dispatch("offers/fetchOffers", this.supplier.id);
      }
    },

    async fetchTenderDetails() {
      try {
        const supplierId = this.supplier?.id;
        const tenderId = parseInt(this.$route.params.tenderId, 10);

        if (!supplierId || !tenderId) {
          this.redirectToMyRequests();
          return;
        }

        this.isTenderLoading = true;
        const response = await getTender({ supplierId, tenderId });
        this.selectedTender = response.data;
      } catch (error) {
        console.error("Error fetching tender details:", error);
        this.redirectToMyRequests();
      } finally {
        this.isTenderLoading = false;
      }
    },

    redirectToMyRequests() {
      this.$router.push({ name: RouteNames.MY_REQUESTS });
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-offer {
  &__loader {
    display: flex;
    justify-content: center;
    padding: 4rem 0;
  }
}
</style>
