<template>
  <div :class="['industry-section', 'text-left', sectionClass]">
    <div class="industry-section__arrow--crooked">
      <img :src="state.crookedArrowIcon" alt="Crooked Arrow" />
    </div>
    <div class="industry-section__arrow--bottom">
      <img :src="state.bottomArrowIcon" alt="Bottom Arrow" />
    </div>

    <div class="calculator-settings">
      <v-form
        ref="form"
        :validation-schema="validationSchema"
        @submit="onSubmit"
      >
        <div class="settings-title">Kalkulatorinnstillinger:</div>
        <div class="settings-row">
          <div class="settings-field">
            <label for="inputMaksBelopPerOppdrag" class="settings-label"
              >Maks beløp per oppdrag</label
            >
            <div class="input-group">
              <v-field
                id="inputMaksBelopPerOppdrag"
                v-model="formData.maksBelopPerOppdrag"
                type="number"
                name="maksBelopPerOppdrag"
                class="form-control"
                placeholder="0"
              />
              <span class="input-group-text">NOK</span>
            </div>
            <error-message name="maksBelopPerOppdrag" class="form-error-text" />
          </div>

          <div class="settings-field">
            <label for="inputMinBelopPerOppdrag" class="settings-label"
              >Min. beløp per oppdrag</label
            >
            <div class="input-group">
              <v-field
                id="inputMinBelopPerOppdrag"
                v-model="formData.minBelopPerOppdrag"
                type="number"
                name="minBelopPerOppdrag"
                class="form-control"
                placeholder="0"
              />
              <span class="input-group-text">NOK</span>
            </div>
            <error-message name="minBelopPerOppdrag" class="form-error-text" />
          </div>
        </div>

        <div class="settings-actions">
          <button
            type="submit"
            class="settings-actions__btn btn-primary"
            :disabled="isSubmitting || !meta.valid"
          >
            Lagre
            <b-spinner
              v-if="isSubmitting"
              type="grow"
              class="settings-actions__spinner"
            />
          </button>
        </div>
      </v-form>
    </div>

    <price-robot-footer />
  </div>
</template>

<script setup>
import { defineProps, computed, onMounted, reactive, ref, watch } from "vue";
import { useStore } from "vuex";
import { useUserStore } from "@/stores/user";
import { useSupplierStore } from "@/stores/supplier";
import {
  useForm,
  Form as VForm,
  Field as VField,
  ErrorMessage,
} from "vee-validate";
import * as yup from "yup";
import PriceRobotFooter from "@/components/PriceRobot/PriceRobotFooter.vue";
import { useSupplierProfileActions } from "@/composables/useSupplierProfileActions";
import { useSupplierProfileData } from "@/composables/useSupplierProfileData";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import { useSystemApi } from "@/services/api/useSystemApi";

// Define props
const props = defineProps({
  cmsContent: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

// Use Vuex store
const store = useStore();
const userStore = useUserStore();
const supplierStore = useSupplierStore();
console.log("store", store);

const { getServiceCategories } = useSystemApi();
const {
  getSupplierCertifications,
  getSupplierSustainabilityGoals,
  getSupplierAddresses,
  getSupplierServiceCategories,
} = useSupplierApi();
const { supplierProfileData, updateSupplierProfileData } =
  useSupplierProfileData();
const { saveSupplierProfile } = useSupplierProfileActions();

// State Reactive
const state = reactive({
  crookedArrowIcon: "",
  bottomArrowIcon: "",
});

const formData = reactive({
  maksBelopPerOppdrag: 0,
  minBelopPerOppdrag: 0,
});

const isSubmitting = ref(false);

const validationSchema = yup.object().shape({
  maksBelopPerOppdrag: yup
    .number()
    .typeError("Maks beløp må være et gyldig tall")
    .required("Maks beløp er påkrevd"),
  minBelopPerOppdrag: yup
    .number()
    .typeError("Min beløp må være et gyldig tall")
    .required("Min beløp er påkrevd")
    .max(
      yup.ref("maksBelopPerOppdrag"),
      "Min beløp kan ikke være større enn maks beløp",
    ),
});

// Initialize the form
const { handleSubmit, meta, resetForm } = useForm({
  validationSchema,
  initialValues: formData,
});

// Computed properties
const sectionClass = computed(() => (props.showServiceTypes ? "" : "offices"));

// Methods
const safeImage = (imagePath) => {
  try {
    return `/images/${imagePath}`;
  } catch (error) {
    console.error(`Failed to load image at path: ${imagePath}`, error);
    return "";
  }
};

const onSubmit = handleSubmit(async () => {
  isSubmitting.value = true;

  try {
    await updateSupplierProfileData({
      reservationHigher: formData.maksBelopPerOppdrag,
      reservationLower: formData.minBelopPerOppdrag,
    });
    await saveSupplierProfile(supplierProfileData.value);
    resetForm();
  } catch (error) {
    console.error("Failed to update reservationHigher and Lower", error);
  } finally {
    isSubmitting.value = false;
  }
});

const initializeSupplierProfileData = async () => {
  try {
    const supplier = await supplierStore.supplier;
    const supplierId = supplier?.id ?? 0;
    const certificationResponse = await getSupplierCertifications({
      supplierId: supplierId,
    });
    const sustainabilityGoalsResponse = await getSupplierSustainabilityGoals({
      supplierId: supplierId,
    });
    const supplierAddressesResponse = await getSupplierAddresses({
      supplierId: supplierId,
    });
    const supplierServiceCategoriesResponse =
      await getSupplierServiceCategories({
        supplierId: supplierId,
      });

    const serviceCategoriesResponse = await getServiceCategories();

    state.serviceCategories = serviceCategoriesResponse?.data || [];

    const certifications = certificationResponse?.data || [];
    const sustainabilityGoals = sustainabilityGoalsResponse?.data || [];
    const supplierServiceCategories =
      supplierServiceCategoriesResponse?.data || [];
    const supplierAddresses = supplierAddressesResponse?.data || [];

    const storedUserId = userStore.userAccount?.idTokenClaims?.sub ?? "";

    if (supplier) {
      const {
        id,
        contactPerson,
        description,
        email,
        logo,
        numEmployees,
        revenue,
        orgNumber,
        sysName,
        reservationHigher,
        reservationLower,
      } = supplier;

      updateSupplierProfileData({
        id: id ?? null,
        contactPerson: contactPerson ?? "",
        description: description ?? "",
        email: email ?? "",
        logo: logo ?? null,
        numEmployees: numEmployees ?? 0,
        revenue: revenue ?? null,
        reservationHigher: reservationHigher ?? 0,
        reservationLower: reservationLower ?? 0,
        userId: storedUserId,
        orgNumber: orgNumber ?? "",
        sysName: sysName ?? "",
        serviceCategories: supplierServiceCategories ?? [],
        offices:
          supplierAddresses?.map((supplierAddress) => ({
            address: {
              id: supplierAddress.address?.id ?? null,
              address: supplierAddress.address?.address ?? "",
              city: supplierAddress.address?.city ?? "",
              postalCode: supplierAddress.address?.postalCode ?? "",
              county: supplierAddress.address?.county ?? "",
            },
            reservationKilometers: supplierAddress?.reservationKilometers ?? 0,
          })) ?? [],
        certifications:
          certifications?.map((certification) => ({
            id: certification.id ?? 0,
            certificationLabel: certification.certificationLabel ?? "",
            selected: certification.selected ?? false,
          })) ?? [],
        sustainabilityGoals:
          sustainabilityGoals?.map((goal) => ({
            id: goal.id ?? 0,
            goalLabel: goal.goalLabel ?? "",
            selected: goal.selected ?? false,
          })) ?? [],
      });

      // Set formData values after initialization
      formData.maksBelopPerOppdrag = reservationHigher ?? 0;
      formData.minBelopPerOppdrag = reservationLower ?? 0;
    }
  } catch (error) {
    console.error("Failed to fetch supplier profile data:", error);
    // Handle the error, show an error message, or redirect to an error page
  }
};

// Watch for changes in supplierProfileData and update formData
watch(
  () => supplierProfileData.value,
  (newValue) => {
    if (newValue) {
      formData.maksBelopPerOppdrag = newValue.reservationHigher ?? 0;
      formData.minBelopPerOppdrag = newValue.reservationLower ?? 0;
    }
  },
  { deep: true },
);

onMounted(async () => {
  state.crookedArrowIcon = safeImage(
    props.cmsContent.industry.crookedArrowIcon,
  );
  state.bottomArrowIcon = safeImage(props.cmsContent.bottomArrowIcon);
  await initializeSupplierProfileData();
});
</script>

<style lang="scss" scoped>
$border-color: #fff;
$green-color: #5d9179;
$green-color-darker: #3d5f4b;
$green-color-lighter: #a3c1a0;
$white-color: #fff;
$padding-standard: 1rem;
$font-size-standard: 1rem;
$font-size-small: 0.75rem;

.industry-section {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-base;
  color: $color-primary-darker;
  background-color: $color-primary-pink;
  border-bottom: 0.375rem solid $border-color;

  &__arrow {
    &--crooked {
      margin-left: 4rem;

      img {
        width: 55%;
      }
    }

    &--bottom {
      margin-left: 2.2rem;
      margin-bottom: 2rem;
    }
  }
}

.calculator-settings {
  padding-left: 2.6rem;

  .settings-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #611f69;
  }

  .form-control {
    border: 0.1rem solid $color-primary-darker;
    border-radius: 0;
    font-size: $font-size-sm;
  }

  .settings-row {
    display: flex;
    gap: 1rem;
    margin-bottom: 1rem;
    width: 45rem;
  }

  .settings-field {
    flex: 1;
    display: flex;
    flex-direction: column;
  }

  .settings-label {
    font-weight: 600;
    font-size: $font-size-base;
    margin-bottom: 0.5rem;
  }

  .input-group {
    width: 100%;
  }

  .input-group-text {
    background-color: $color-primary-pink-darker;
    border: 0.1rem solid $color-primary-darker;
    border-left: 0.1rem solid $color-primary-light;
    font-size: $font-size-sm;
    font-weight: 600;
    border-radius: 0;
    color: $color-primary-darker;
  }

  .form-error-text {
    color: #dc3545;
    font-size: 12px;
    margin-top: 0.5rem;
    text-align: left;
  }

  .settings-actions {
    display: flex;
    justify-content: flex-start;
    margin-top: 2rem;
    margin-bottom: 2rem;

    &__btn {
      font-weight: $font-weight-semi-bold;
      font-size: $font-size-base;
      font-weight: 700;
      width: auto;
      padding: 1rem 2rem;
      border-radius: 0.375rem;
      color: $white-color;
      background-color: $green-color;
      border: 0.1rem solid $green-color;
      align-items: center;
      text-align: center;
      letter-spacing: 0.04rem;
      line-height: normal;
      transition:
        background-color 0.3s,
        transform 0.2s;
      cursor: pointer;

      &:hover {
        background-color: $green-color-darker;
        border: 0.1rem solid $green-color-darker;
        transform: scale(1.05);
      }

      &:active {
        transform: scale(1.05);
      }

      &:disabled {
        background-color: $green-color-lighter;
        border: 0.1rem solid $green-color-lighter;
        cursor: not-allowed;
      }
    }

    &__spinner {
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
</style>
