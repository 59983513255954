<template>
  <div class="attachments-input">
    <input
      ref="fileInput"
      type="file"
      :accept="validFileExtensions.join(',')"
      class="attachments-input__file-input"
      multiple
      @change="handleSelectedFiles"
    />
    <slot :open-file-input="openFileInput" />
  </div>
</template>

<script>
import { makeFilesCopy } from "@/utilities/fileUtilities";

export default {
  props: {
    validFileExtensions: {
      type: Array,
      default: () => [],
    },
  },
  emits: ["selected-files"],
  methods: {
    openFileInput() {
      const fileInput = this.$refs.fileInput;
      if (fileInput) {
        fileInput.click();
      }
    },
    handleSelectedFiles() {
      const filesData = this.$refs.fileInput?.files;
      const files = filesData?.length ? Array.from(filesData) : [];
      this.$emit("selected-files", makeFilesCopy(files));
    },
  },
};
</script>

<style lang="scss" scoped>
.attachments-input {
  display: inline-block;

  &__file-input {
    display: none;
  }
}
</style>
@/utilities/fileUtilities
