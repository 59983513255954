<template>
  <div class="price-package-inputs">
    <v-form :validation-schema="validationSchema">
      <div class="price-package-inputs__package-inputs row d-flex g-3">
        <div class="col-auto">
          <v-field
            id="price"
            v-model="price"
            type="number"
            name="price"
            placeholder="0,- pr. "
            :validate-on-blur="true"
            :validate-on-input="true"
            :validate-on-change="true"
            class="form-control"
          />
          <error-message name="price" class="form-error-text" />
        </div>
        <div v-if="!isFixedBillingCycle" class="col-auto">
          <v-field
            id="kilometer-price"
            v-model="kilometerPrice"
            type="number"
            name="kilometerPrice"
            placeholder="0,- pr. km"
            :validate-on-blur="true"
            :validate-on-input="true"
            :validate-on-change="true"
            class="form-control"
          />
          <error-message name="kilometerPrice" class="form-error-text" />
        </div>
        <div v-if="!isFixedBillingCycle" class="col-auto">
          <v-field
            id="number-of-trips"
            v-model="numberOfTrips"
            type="number"
            name="numberOfTrips"
            placeholder="Ant. turer pr. frekvens"
            :validate-on-blur="true"
            :validate-on-input="true"
            :validate-on-change="true"
            class="form-control"
          />
          <error-message name="numberOfTrips" class="form-error-text" />
        </div>
      </div>
    </v-form>
  </div>
</template>

<script setup>
import { ref, computed, watch } from "vue";
import {
  useForm,
  Form as VForm,
  Field as VField,
  ErrorMessage,
  defineRule,
} from "vee-validate";
import { useStore } from "vuex";
import * as yup from "yup";

const props = defineProps({
  pricingPackageInputs: {
    type: Object,
    required: true,
  },
});

// const emit = defineEmits(["price-update"]);

const store = useStore();

const isFixedBillingCycle = computed(
  () => props.pricingPackageInputs.billingCycle === "Fixed",
);

const price = ref(props.pricingPackageInputs.price);
const kilometerPrice = ref(props.pricingPackageInputs.kilometerPrice);
const numberOfTrips = ref(props.pricingPackageInputs.numberOfTrips);
const generatedPriceId = ref(990000);

const supplierId = computed(() => store.state.supplier.supplier.id);
const propertyType = computed(
  () => store.state.api.supplierSegmentsParams.propertyType.id,
);
const supplierType = computed(
  () => store.state.api.supplierSegmentsParams.supplierType.id,
);

const pricePackageInput = (value, [context]) => {
  if (
    context.parent.price === "" &&
    context.parent.kilometerPrice === "" &&
    context.parent.numberOfTrips === ""
  ) {
    return true;
  }

  return value !== "" || "Dette feltet er påkrevd";
};

// Custom validation rule
defineRule("pricePackageInput", pricePackageInput);

// Validation schema
const validationSchema = yup.object({
  price: yup
    .number()
    .typeError("Dette feltet er påkrevd")
    .required()
    .typeError("Dette feltet er påkrevd")
    .test({
      name: "price",
      message: "Dette feltet er påkrevd",
      test: (value, ctx) => {
        if (value === undefined || value === null || value === "") {
          return true;
        }
        if (pricePackageInput(value, [ctx]) === true) {
          return true;
        } else {
          return ctx.createError({
            message: "Dette feltet er påkrevd",
          });
        }
      },
    }),
  kilometerPrice: yup
    .number()
    .typeError("Dette feltet er påkrevd")
    .required()
    .typeError("Dette feltet er påkrevd")
    .test({
      name: "kilometerPrice",
      message: "Dette feltet er påkrevd",
      test: (value, ctx) => {
        if (value === undefined || value === null || value === "") {
          return true;
        }
        if (pricePackageInput(value, [ctx]) === true) {
          return true;
        } else {
          return ctx.createError({
            message: "Dette feltet er påkrevd",
          });
        }
      },
    }),
  numberOfTrips: yup
    .number()
    .typeError("Dette feltet er påkrevd")
    .required()
    .typeError("Dette feltet er påkrevd")
    .test({
      name: "numberOfTrips",
      message: "Dette feltet er påkrevd",
      test: (value, ctx) => {
        if (value === undefined || value === null || value === "") {
          return true;
        }
        if (pricePackageInput(value, [ctx]) === true) {
          return true;
        } else {
          return ctx.createError({
            message: "Dette feltet er påkrevd",
          });
        }
      },
    }),
});

// useForm hook
useForm({ validationSchema });

// Watch for changes in price, kilometerPrice and numberOfTrips
watch(
  [price, kilometerPrice, numberOfTrips],
  ([newPrice, newKilometerPrice, newNumberOfTrips]) => {
    if (
      newPrice !== "" &&
      newKilometerPrice !== "" &&
      newNumberOfTrips !== ""
    ) {
      onPriceUpdate({
        priceId: props.pricingPackageInputs.priceId,
        price: newPrice,
        kilometerPrice: newKilometerPrice,
        numberOfTrips: newNumberOfTrips,
        rangeIds: props.pricingPackageInputs.rangeId,
        segmentId: props.pricingPackageInputs.segmentId,
        package: props.pricingPackageInputs.package,
        packageId: props.pricingPackageInputs.packageId,
        groupId: props.pricingPackageInputs.groupId,
      });
      store.dispatch("api/savePriceReady", true);
    } else {
      store.dispatch("api/savePriceReady", false);
    }
  },
);

// Methods
const parsePriceValue = (value) =>
  parseFloat(value.toString().replace(/,/g, ".")) || 0;

const onPriceUpdate = (pricePackageInput) => {
  const priceObj = {
    ...pricePackageInput,
    price: parsePriceValue(pricePackageInput.price),
    kilometerPrice: parsePriceValue(pricePackageInput.kilometerPrice),
    numberOfTrips: parsePriceValue(pricePackageInput.numberOfTrips),
  };

  updateStorePrices(
    priceObj,
    generatedPriceId.value,
    isFixedBillingCycle.value,
  );
};

const isPriceValid = (priceObj) => {
  return (
    priceObj.price >= 0 &&
    priceObj.kilometerPrice >= 0 &&
    priceObj.numberOfTrips >= 0
  );
};

const isPriceEmpty = (priceObj, generatedPriceId) => {
  return (
    priceObj.priceId < generatedPriceId &&
    priceObj.price.length === 0 &&
    priceObj.kilometerPrice.length === 0 &&
    priceObj.numberOfTrips.length === 0
  );
};

const shouldAddDeletedPrice = (
  priceObj,
  generatedPriceId,
  isFixedBillingCycle,
) => {
  return (
    isPriceEmpty(priceObj, generatedPriceId) &&
    (!isFixedBillingCycle || priceObj.price.length === 0)
  );
};

const shouldAddEditedPrice = (priceObj, isFixedBillingCycle) => {
  return isPriceValid(priceObj) && (!isFixedBillingCycle || priceObj.price > 0);
};

const updateStorePrices = (priceObj, generatedPriceId, isFixedBillingCycle) => {
  if (!priceObj || priceObj.priceId === 0) return;

  if (shouldAddDeletedPrice(priceObj, generatedPriceId, isFixedBillingCycle)) {
    addDeletedPrice(priceObj);
    removeEditedPrice(priceObj);
  }

  if (shouldAddEditedPrice(priceObj, isFixedBillingCycle)) {
    removeDeletedPrice(priceObj);
    addEditedPrice(priceObj);
  }
};

const createPriceObject = (price, additionalProps) => {
  return {
    ...price,
    ...additionalProps,
  };
};

const findPriceIndex = (prices, priceId) =>
  prices.findIndex((x) => x.priceId === priceId);

// Store Action dispatchers
const savePrices = (prices, actionName) => {
  store.dispatch(actionName, prices);
};

const addEditedPrice = (price) => {
  const editedPrices = [...store.state.api.editedPrices];
  const foundIndex = findPriceIndex(editedPrices, price.priceId);

  const additionalProps = {
    supplierId: supplierId.value,
    propertyType: propertyType.value,
    supplierType: supplierType.value,
    rangeId: price.rangeIds,
    packageId: price.packageId,
    groupId: price.groupId,
  };
  const priceObj = createPriceObject(price, additionalProps);

  if (foundIndex !== -1) {
    editedPrices.splice(foundIndex, 1, priceObj);
  } else {
    editedPrices.push(priceObj);
  }

  savePrices(editedPrices, "api/saveEditedPriceForPosting");
};

const removeEditedPrice = (priceId) => {
  const editedPrices = [...store.state.api.editedPrices];
  const foundIndex = findPriceIndex(editedPrices, priceId);

  if (foundIndex !== -1) {
    editedPrices.splice(foundIndex, 1);
    savePrices(editedPrices, "api/removeEditedPriceForPosting");
  }
};

const addDeletedPrice = (price) => {
  const deletedPrices = [...store.state.api.deletedPrices];
  const foundIndex = findPriceIndex(deletedPrices, price.priceId);

  if (foundIndex === -1) {
    const additionalProps = {
      supplierId: supplierId.value,
      propertyType: propertyType.value,
      supplierType: supplierType.value,
      rangeId: price.rangeIds,
      packageId: price.packageId,
      groupId: price.groupId,
    };
    const priceObj = createPriceObject(price, additionalProps);
    savePrices(priceObj, "api/saveDeletedPriceForPosting");
  }
};

const removeDeletedPrice = (priceId) => {
  const deletedPrices = [...store.state.api.deletedPrices];
  const newDeletedPrices = deletedPrices.filter(
    (price) => price.priceId !== priceId,
  );

  if (newDeletedPrices.length !== deletedPrices.length) {
    savePrices(newDeletedPrices, "api/removeDeletedPriceForPosting");
  }
};
</script>

<style lang="scss" scoped>
.price-package-inputs {
  display: flex;
  flex-wrap: wrap;
  width: max-content;

  &__package-inputs {
    margin-bottom: 0.5rem;
  }

  .form-control {
    border: 0.1rem solid $color-primary-dark;
    border-radius: 0;
    font-size: $font-size-sm;
    width: 10rem;
  }

  .col-auto {
    padding-right: 0;
  }

  .col-form-label {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  .input-group-text {
    background-color: $color-primary-light;
    border: 0.1rem solid $color-primary-dark;
    border-left: 0.1rem solid $color-primary-light;
    font-size: $font-size-sm;
    font-weight: 700;
    border-radius: 0;
    color: white;
  }

  .form-error-text {
    color: #dc3545;
    font-size: 10px;
    max-width: 10rem;
    display: flex;
    margin-top: 0.5rem;
    text-align: center;
  }
}
</style>
