import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum AttachmentApiEndpoints {
  GET_FILE_ATTACHMENT = "getFileAttachment",
  GET_ATTACHMENT = "getAttachment",
}

export const AttachmentApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/attachment",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(AttachmentApiEndpoints.GET_FILE_ATTACHMENT),
      method: "GET",
      url: "",
      description: "Get file attachment",
    },
    {
      key: Symbol.for(AttachmentApiEndpoints.GET_ATTACHMENT),
      method: "GET",
      url: "",
      description: "Get attachment",
    },
  ],
};
