import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum SystemApiEndpoints {
  CREATE_SUPPLIER_TENDER_OFFER = "createSupplierTenderOffer",
  UPDATE_SUPPLIER_TENDER_OFFER = "updateSupplierTenderOffer",
  GET_ACTIVITY_FORM_FREQUENCY_OPTIONS = "getActivityFormFrequencyOptions",
  GET_SERVICE_CATEGORIES = "getServiceCategories",
  GET_SERVICE_TYPES = "getServiceTypes",
  GET_SERVICE_TYPES_CATEGORIES = "getServiceTypesCategories",
}

export const SystemApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/system",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
      method: "POST",
      url: "/offer",
      description: "Create supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
      method: "PUT",
      url: "/offer",
      description: "Update supplier tender offer",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS),
      method: "GET",
      url: "/segmentOptions",
      description: "Get activity form frequency options",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_SERVICE_CATEGORIES),
      method: "GET",
      url: "serviceCategories/false",
      description: "Get service categories",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES),
      method: "GET",
      url: "/serviceTypes/{lang=no}",
      description: "Get service types",
    },
    {
      key: Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES_CATEGORIES),
      method: "GET",
      url: "/serviceType",
      description: "Get service types categories",
    },
  ],
};
