<template>
  <base-supplier-offer-modal
    v-model="showModal"
    :title="editSentOrWithdrawnOfferModalCms.title"
    :description="descriptionText || ''"
    :align-items="'left'"
  >
    <!-- Buttons slot -->
    <template #buttons>
      <div class="modal__action-buttons">
        <tertiary-button @click="showModal = false">{{
          cancelButtonText
        }}</tertiary-button>
        <primary-button @click="createCopy">{{
          createCopyButtonText
        }}</primary-button>
      </div>
    </template>
  </base-supplier-offer-modal>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseSupplierOfferModal from "./BaseSupplierOfferModal.vue";
import { OfferStatus } from "@/custom-types/types";

export default {
  components: { BaseSupplierOfferModal, TertiaryButton, PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    editSentOrWithdrawnOfferModalCms: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: false,
      default: null,
    },
  },
  emits: ["update:modelValue", "edit"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    cancelButtonText() {
      return this.editSentOrWithdrawnOfferModalCms.buttons.find(
        (text) => text.label === "cancelButton",
      ).title;
    },
    createCopyButtonText() {
      return this.editSentOrWithdrawnOfferModalCms.buttons.find(
        (text) => text.label === "createCopyButton",
      ).title;
    },
    descriptionText() {
      const { content } = this.editSentOrWithdrawnOfferModalCms;
      return this.offer?.offerState === OfferStatus.Withdrawn
        ? this.replaceSentWithWithdrawn(content)
        : content;
    },
  },
  methods: {
    createCopy() {
      this.$emit("edit", this.offer);
      this.showModal = false;
    },
    replaceSentWithWithdrawn(content) {
      if (!Array.isArray(content)) {
        // Handle the case where content is not an array.
        return content;
      }
      return content.map((item) => {
        if (item.children) {
          item.children = item.children.map((child) => {
            if (child.text) {
              child.text = child.text.replace(
                "sendt til kunde",
                "trukket tilbake",
              );
            }
            return child; // Always return the child from inner map.
          });
        }
        return item; // Always return the item from outer map.
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__action-buttons {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}
</style>
@/types/AppTypes
