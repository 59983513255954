<template>
  <div class="segment">
    <div class="segment__title">{{ segmentLabelText }}</div>
    <segment-range-inputs
      :segment-area-ranges-prop="segmentAreaRanges"
      @validation-error="handleValidationInputError"
      @open-delete-modal="openDeleteModal"
    />
    <div class="segment__add-area">
      <button class="segment__add-area-button" @click="addNewRange">
        Legg til segmentområde
        <img
          :src="`/images/my-prices/add.svg`"
          class="segment__add-area-icon"
        />
      </button>
    </div>
    <delete-segment-range-modal
      v-model="showDeleteModalState"
      :selected-range="selectedRange"
      @confirm-delete="handleConfirmDelete"
      @close-modal="closeDeleteModal"
    />
  </div>
</template>

<script setup>
import { computed, onMounted, toRefs, ref, watch } from "vue";
import { useStore } from "vuex";
import SegmentRangeInputs from "./SegmentRangeInputs.vue";
import DeleteSegmentRangeModal from "./DeleteSegmentRangeModal.vue";
import myPricesService from "@/services/myPricesService";

const RANGE_ID_THRESHOLD = 99900;

const store = useStore();

const props = defineProps({
  segmentContent: {
    type: Object,
    required: true,
  },
});

const emit = defineEmits(["validation-error"]);

// Use toRefs to maintain reactivity when destructuring props
const { segmentContent } = toRefs(props);

// Define reactive state
const segmentAreaRanges = ref([]);
const supplierSegmentRanges = ref([]);
const showDeleteModalState = ref(false);
const selectedRange = ref({});

// Watch for changes to the segment content
watch(segmentContent, (newVal, oldVal) => {
  if (newVal !== oldVal) {
    segmentAreaRanges.value = [];
    fetchSegmentAreasFromApi();
  }
});

// Computed properties
const selectedSupplierType = computed(
  () => store.getters["cms/getSelectedSupplierType"],
);
const serviceTypes = computed(() => store.getters["cms/getServiceTypes"]);
const supplier = computed(() => store.getters["supplier/getSupplier"]);

// Computed properties for segment labels
const segmentResidentialLabel = computed(() => {
  if (!segmentContent.value) return "";
  return segmentContent.value.segmentName.includes("Residential")
    ? `${segmentContent.value.segmentLabel} (kun bolig)`
    : segmentContent.value.segmentLabel;
});

const segmentResidentialName = computed(() => {
  if (!segmentContent.value) return "";
  return segmentContent.value.segmentName.includes("Residential")
    ? `${segmentContent.value.segmentName} (kun bolig)`
    : segmentContent.value.segmentName;
});

const segmentLabelText = computed(() =>
  getSegmentLabelText(
    segmentResidentialLabel.value,
    segmentResidentialName.value,
  ),
);

// Methods
const getSegmentLabelText = (segmentLabel, segmentName) => {
  const isNullLabel =
    segmentLabel?.toString().toLowerCase() === "null" ||
    segmentLabel?.toString().toLowerCase().startsWith("null");

  if (!segmentName && !segmentLabel) {
    return "Segmentet";
  }

  if (!segmentLabel) {
    return segmentName;
  }

  if (!serviceTypes.value) {
    return segmentLabel;
  }

  const serviceType = serviceTypes?.value.find(
    (type) => type.label === selectedSupplierType?.value.sysLabel,
  );

  if (!serviceType) {
    return segmentLabel;
  }

  const segments = getSegmentsFromServiceType(serviceType, segmentLabel);

  const foundSegment = segments.find((segment) => segment !== undefined);

  return foundSegment
    ? foundSegment.name
    : isNullLabel
      ? segmentName
      : segmentLabel;
};

const getSegmentsFromServiceType = (serviceType, segmentLabel) => {
  const findSegment = (segment) => segment.label === segmentLabel;

  if (serviceType.renderType === "segmentGroup") {
    return (
      serviceType.segmentGroup?.flatMap((group) =>
        group.segments.filter(findSegment),
      ) || []
    );
  } else {
    return (
      serviceType.form?.flatMap((form) =>
        form.segments.filter(findSegment),
      )[0] || []
    );
  }
};

const processRangesData = (rangesData) => {
  // Process the range data, change -1 to infinity
  return rangesData.map((range) => ({
    ...range,
    rangeTo: range.rangeTo === -1 ? Infinity : range.rangeTo,
  }));
};

const addRangeToState = (range) => {
  const index = segmentAreaRanges.value.findIndex((r) => r.id === range.id);
  if (index === -1) {
    segmentAreaRanges.value.push(range);
  } else {
    segmentAreaRanges.value.splice(index, 1, range);
  }
};

const fetchSegmentAreasFromApi = async () => {
  if (!supplier.value.id || !props.segmentContent.id) return;
  try {
    const rangeParams = {
      segmentId: props.segmentContent.id,
      supplierId: supplier.value.id,
    };
    const response =
      await myPricesService.fetchSupplierSegmentRanges(rangeParams);
    if (response.data.length > 0) {
      supplierSegmentRanges.value = processRangesData(response.data);
      supplierSegmentRanges.value.forEach((range) =>
        addRangeToState(editedRangeFromApi(range)),
      );
    } else {
      fetchSegmentAreaRangesFromStore();
    }
  } catch (error) {
    console.error("Error fetching segment areas:", error);
  }
};

const fetchSegmentAreaRangesFromStore = () => {
  const ranges = store.state.api.ranges;
  const filteredArr = ranges?.filter(
    (range) => range.priceSegment === props.segmentContent.id,
  );
  filteredArr.forEach((range) => addRangeToState(range));
};

// const saveRangeToStore = (range) => {
// 	const rangeObject = { ...range };
// 	rangeObject.rangeFrom = parseFloat(
// 		String(range.rangeFrom).replace(/,/g, ".")
// 	);
// 	rangeObject.rangeTo = parseFloat(
// 		String(range.rangeTo).replace(/,/g, ".")
// 	);
// 	store.dispatch("api/saveRange", rangeObject);
// };

const addNewRange = () => {
  const newRange = {
    id: Math.floor(
      Math.random() * (100000 - RANGE_ID_THRESHOLD) + RANGE_ID_THRESHOLD,
    ),
    segmentLabel: segmentContent.value.segmentLabel,
    segmentRangeId: segmentContent.value.id,
    priceSegment: segmentContent.value.id,
    supplier: supplier.value.id,
    rangeFrom: 0,
    rangeTo: 0,
    unit: segmentContent.value.unit,
  };
  addRangeToState(newRange);
};

const editedRangeFromApi = (range) => {
  return {
    id: range.id,
    segmentLabel: segmentContent.value.segmentLabel,
    segmentRangeId: segmentContent.value.id,
    priceSegment: range.priceSegment,
    supplier: range.supplier,
    rangeFrom: range.rangeFrom,
    rangeTo: range.rangeTo,
    unit: segmentContent.value.unit,
  };
};

const openDeleteModal = (range) => {
  selectedRange.value = range;
  showDeleteModalState.value = true;
};

const closeDeleteModal = () => {
  showDeleteModalState.value = false;
};

const updateDeletedRanges = (range) => {
  if (range.id > RANGE_ID_THRESHOLD && range.id < 100000) return;
  const rangeArr = [...store.state.api.deletedRanges];
  const newRangeObj = { ...range, rangeFrom: 0, rangeTo: 0 };
  rangeArr.push(newRangeObj);
  store.dispatch("api/saveDeletedRangesForPosting", rangeArr);
};

const handleConfirmDelete = (confirm) => {
  if (confirm) {
    const foundIndex = segmentAreaRanges.value.findIndex(
      (x) => x.id === selectedRange.value.id,
    );
    if (foundIndex !== -1) {
      segmentAreaRanges.value.splice(foundIndex, 1);
      updateDeletedRanges(selectedRange.value);
    }
  }
  return closeDeleteModal();
};

const handleValidationInputError = (hasOverlap) => {
  emit("validation-error", hasOverlap);
};

onMounted(() => {
  fetchSegmentAreasFromApi();
});
</script>

<style lang="scss" scoped>
$white-color: #fff;
$font-size-standard: 1rem;
$font-size-small: 0.875rem;

.segment {
  width: 100%;

  &__title {
    font-size: $font-size-base;
    font-weight: 700;
    color: $color-primary-dark;
  }

  &__add-area {
    margin-top: 1rem;
    text-align: right;
    margin-right: 2rem;
  }

  &__add-area-button {
    font-size: $font-size-small;
    font-weight: 700;
    padding: 0.6rem;
    border-radius: 0.375rem;
    color: $white-color;
    background-color: $color-primary-dark;
    border: 0.1rem solid $color-primary-dark;

    &:hover {
      background-color: $color-primary-darker;
      border-color: $color-primary-darker;
      transform: scale(1.05);
    }

    &:active {
      transform: scale(1);
    }
  }
}
</style>
