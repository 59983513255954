<template>
  <div class="base-text-and-select-input">
    <div
      class="base-text-and-select-input__text-input-wrapper"
      :style="textInputWrapperStyles"
    >
      <v-field
        v-slot="{ componentField }"
        :name="textInputFieldName"
        :rules="number().required().moreThan(0)"
        :validate-on-input="true"
      >
        <base-input
          v-bind="componentField"
          :right-text="textInputRightText"
          border-radius="left-only"
        />
      </v-field>
    </div>
    <v-field
      v-slot="{ componentField }"
      name="itemsUnit"
      :rules="string().required()"
      :validate-on-input="true"
    >
      <div
        class="base-text-and-select-input__select-input-wrapper"
        :style="selectInputWrapperStyles"
      >
        <base-select-input
          v-bind="componentField"
          :options="selectOptions"
          border-radius="right-only"
        />
      </div>
    </v-field>
  </div>
</template>

<script setup>
import BaseSelectInput from "@/components/Base/BaseSelectInput/BaseSelectInput.vue";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import { computed } from "vue";
import { Field as VField } from "vee-validate";
import { number, string } from "yup";

const props = defineProps({
  textInputFieldName: {
    type: String,
    required: true,
  },
  textInputRightText: {
    type: String,
    default: "",
  },
  textInputWidth: {
    type: String,
    default: "3.75rem",
  },
  selectOptions: {
    type: Array,
    required: true,
  },
  selectInputWidth: {
    type: String,
    default: "8.875rem",
  },
});

const textInputWrapperStyles = computed(() => {
  return {
    width: props.textInputWidth,
  };
});
const selectInputWrapperStyles = computed(() => {
  return {
    width: props.selectInputWidth,
  };
});
</script>

<style lang="scss" scoped>
.base-text-and-select-input {
  display: flex;
  align-items: center;

  &__text-input-wrapper {
    position: relative;
    margin-right: -1px;
    z-index: 1;
  }
}
</style>
