<template>
  <ul class="suggestions-list">
    <li
      v-for="suggestion in finalizedSuggestions"
      :key="suggestion.id"
      class="suggestions-list__suggestion"
      :class="getPropertyClasses(suggestion)"
      @mousedown="handleSuggestionClick(suggestion)"
    >
      <div class="suggestions-list__suggestion-icon-container">
        <img
          :src="suggestion.icon"
          class="suggestions-list__suggestion-icon"
          alt="Suggestion icon"
        />
      </div>
      <div class="suggestions-list__suggestion-text">
        {{ suggestion.suggestionText }}
      </div>
    </li>
  </ul>
</template>

<script setup lang="ts">
import { computed, PropType } from "vue";
import { SearchSuggestion } from "@/custom-types/GeneralTypes";

const props = defineProps({
  suggestions: {
    type: Array as PropType<SearchSuggestion[]>,
    required: true,
  },
  sortList: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits<{
  (e: "selected-suggestion", suggestion: SearchSuggestion): void;
}>();

const finalizedSuggestions = computed(() => {
  if (!props.sortList) {
    return props.suggestions;
  }
  return [...props.suggestions].sort((a, b) => {
    const aNormalized = a.suggestionText.trim().toLowerCase();
    const bNormalized = b.suggestionText.trim().toLowerCase();
    return aNormalized.localeCompare(bNormalized);
  });
});

const handleSuggestionClick = (suggestion: SearchSuggestion) => {
  if (!suggestion.disabled) {
    emit("selected-suggestion", suggestion);
  }
};

const getPropertyClasses = (suggestion: SearchSuggestion) => {
  return suggestion.disabled ? ["suggestions-list__suggestion--disabled"] : [];
};
</script>

<style lang="scss" scoped>
.suggestions-list {
  padding: 0;
  margin: 0;
  background: #fff;
  z-index: 1000;
  position: absolute;
  width: 100%;
  box-shadow: 1px 3px 0 0 rgba(0, 0, 0, 0.1);
  padding: 8px 16px 8px 16px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;

  &__suggestion {
    list-style: none;
    padding: 0.5rem 1rem;
    cursor: pointer;
    display: flex;
    align-items: center;

    &:hover {
      background-color: #f1f1f1;
    }

    &--disabled {
      cursor: default;
      opacity: 0.6;

      &:hover {
        background-color: transparent;
      }
    }

    &:first-child {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-top: 2px solid #f5f5f5;
    }

    &:last-child {
      border-bottom-left-radius: 20px;
      border-bottom-right-radius: 20px;
    }
  }

  &__suggestion-icon-container {
    margin-right: 1rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__suggestion-icon {
    max-width: 100%;
    max-height: 100%;
  }

  &__suggestion-text {
    font-weight: 400;
    font-size: 1rem;
  }
}
</style>
