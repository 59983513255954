import { executeApiRequest } from "@/services/api/apiService";
import {
  CompanyRegistryApi,
  CompanyRegistryApiEndpoints,
} from "@/config/api/companyRegistry/companyRegistryApi";

export type Company = {
  companyName: string;
  orgNumber: string;
};

const findCompany = async (orgNumber: string) => {
  return executeApiRequest<Company[]>(
    {
      config: CompanyRegistryApi,
      endpointKey: Symbol.for(CompanyRegistryApiEndpoints.FIND_COMPANY),
    },
    {
      pathParams: [orgNumber],
    },
  );
};

type UseCompanyRegistryApi = () => {
  findCompany: typeof findCompany;
};

export const useCompanyRegistryApi: UseCompanyRegistryApi = () => {
  return {
    findCompany,
  };
};
