<template>
  <div class="frequency-options">
    <div class="frequency-options__title">
      {{ activityFrequencyCms.title }}
    </div>
    <div v-if="formErrors.frequencyGroup" class="frequency-options__error">
      <input-error :text="activityFrequencyCms.error" />
    </div>
    <div
      v-for="option in options"
      :key="option.value"
      class="frequency-options__option"
    >
      <v-field
        v-slot="{ componentField }"
        name="frequencyGroup"
        type="radio"
        :value="option.value"
        :rules="string().required()"
      >
        <base-radio-input
          v-bind="componentField"
          :label="option.label"
          :value="option.value"
        />
      </v-field>
      <div
        v-if="
          option.contentComponent && formValues.frequencyGroup === option.value
        "
        class="frequency-options__option-selected-content"
      >
        <component
          :is="option.contentComponent"
          :form-values="formValues"
          :activity-form-cms="activityFormCms"
          :frequency-options="frequencyOptions"
          :service-activity-cms="option.serviceActivityCms"
          @new-form-values="updateFormValues"
        />
      </div>
    </div>
  </div>
</template>

<script setup>
import BaseRadioInput from "@/components/Base/BaseRadioInput/BaseRadioInput.vue";
import {
  ActivityFrequency,
  ServiceActivityCmsLabelType,
} from "@/custom-types/types";
import OnceOrMorePerMonthFrequencyContent from "@/components/ActivityModal/OnceOrMorePerMonthFrequencyContent.vue";
import LessThanOncePerMonthFrequencyContent from "@/components/ActivityModal/LessThanOncePerMonthFrequencyContent.vue";
import { getCmsItem, getCmsItemText } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import { computed } from "vue";
import { Field as VField } from "vee-validate";
import { string } from "yup";

const emit = defineEmits(["new-form-values"]);

const props = defineProps({
  formErrors: {
    type: Object,
    required: true,
  },
  formValues: {
    type: Object,
    required: true,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
});

const activityFrequencyCms = computed(() => {
  return props.activityFormCms.inputs.find(
    (input) => input.label === "activityFrequency",
  );
});
const options = computed(() => {
  const radioOptions = activityFrequencyCms.value.RadioOptions;
  return [
    {
      label: getCmsItemText(radioOptions, "singleCost"),
      value: ActivityFrequency.Fixed,
      contentComponent: null,
      serviceActivityCms: getCmsItem(
        props.serviceActivitiesCms,
        ServiceActivityCmsLabelType.Fixed,
      ),
    },
    {
      label: getCmsItemText(radioOptions, "onceOrMorePerMonth"),
      value: ActivityFrequency.Monthly,
      contentComponent: OnceOrMorePerMonthFrequencyContent,
      serviceActivityCms: getCmsItem(
        props.serviceActivitiesCms,
        ServiceActivityCmsLabelType.Monthly,
      ),
    },
    {
      label: getCmsItemText(radioOptions, "lessOftenThanOncePerMonth"),
      value: ActivityFrequency.Yearly,
      contentComponent: LessThanOncePerMonthFrequencyContent,
      serviceActivityCms: getCmsItem(
        props.serviceActivitiesCms,
        ServiceActivityCmsLabelType.Yearly,
      ),
    },
  ];
});

const updateFormValues = (formValues) => {
  emit("new-form-values", formValues);
};
</script>

<style lang="scss" scoped>
.frequency-options {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__error,
  &__option {
    margin-top: 0.5rem;
  }

  &__option-selected-content {
    padding-top: 0.75rem;
    padding-bottom: 0.5rem;
    margin-left: 2rem;
  }
}
</style>
@/utilities/cmsUtilities @/types/AppTypes
