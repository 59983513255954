<template>
  <div v-if="errorPageCmsContent" class="error-page">
    <div class="error-page__message">
      <h1>{{ errorPageCmsContent.errorMessage }}</h1>
      <button class="error-page__button" @click="handleRetry">
        {{ errorPageCmsContent.retryButton }}
      </button>
    </div>
    <img
      :src="errorPageCmsContent.iconUrl"
      alt="Error Icon"
      class="error-page__icon"
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { Button, ExtraText, Icon } from "@/stores/cms/types";
import cmsService from "@/services/cms/cmsService";

interface SupplierErrorPageCms {
  buttons: Button[];
  icons: Icon[];
  otherText: ExtraText[];
}

const router = useRouter();
const { fetchCmsContent } = useCms();

const cms = ref<SupplierErrorPageCms | null>(null);

const errorPageCmsContent = computed(() => {
  if (!cms.value) return null;

  const { buttons, icons, otherText } = cms.value;

  return {
    retryButton: getButtonTitle(buttons, "retryButton", "Prøv Igjen"),
    iconUrl: findIconResolveIconUrl(
      icons,
      "errorIcon",
      "/images/error-page/robot_error_icon.svg",
    ),
    errorMessage: getOtherTextTitle(otherText, "errorMessage", ""),
  };
});

const findIconResolveIconUrl = (
  icons: Icon[],
  label: string,
  defaultImage: string,
) => {
  const icon = icons.find((icon) => icon.label === label)?.icon;
  return icon ? cmsService.resolveImage(icon).url() : defaultImage;
};

const getButtonTitle = (
  buttons: Button[],
  label: string,
  defaultTitle: string,
) => {
  const button = buttons.find((btn: Button) => btn.label === label);
  return button?.title || defaultTitle;
};

const getOtherTextTitle = (
  otherText: ExtraText[],
  label: string,
  defaultTitle: string,
) => {
  const text = otherText.find((txt: ExtraText) => txt.label === label);
  return text?.title || defaultTitle;
};

const transformResponseToSupplierErrorPageCms = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
): SupplierErrorPageCms => {
  return {
    buttons: response.buttons,
    icons: response.icons,
    otherText: response.otherText,
  };
};

onMounted(async () => {
  const response = await fetchCmsContent(ContentType.SUPPLIER_ERROR_PAGE_CMS);
  if (response) {
    const transformedResponse =
      transformResponseToSupplierErrorPageCms(response);
    cms.value = transformedResponse;
  } else {
    cms.value = null;
  }
});

const handleRetry = () => {
  router.push("/").catch((err) => {
    console.error(err);
  });
};
</script>

<style lang="scss" scoped>
.error-page {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(340deg, #7c3373 1.27%, #44003c 93.38%);
  color: white;
  text-align: center;
  position: relative;

  &__message {
    z-index: 2;
  }

  &__button {
    margin-top: 10rem;
    padding: 2.6rem 4rem;
    background: white;
    color: #44003c;
    font-size: 2.4rem;
    font-weight: bold;
    border: none;
    border-radius: 0.25rem;
    cursor: pointer;

    &:hover {
      background: #f2f2f2;
    }
  }

  &__icon {
    position: absolute;
    right: 3.6rem;
    bottom: 0;
    width: 30rem;
    height: 30rem;
  }
}
</style>
