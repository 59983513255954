<template>
  <div class="industry-service-types">
    <div class="industry-service-types__wrapper">
      <div class="industry-service-types__container">
        <div
          v-for="serviceType in validServiceTypes"
          :key="serviceType.id"
          :class="[
            'industry-service-types__rectangle',
            {
              'industry-service-types__rectangle--selected':
                isSelected(serviceType),
              'industry-service-types__rectangle--unselected':
                !isSelected(serviceType),
            },
          ]"
          @click="onServiceTypeSelected(serviceType)"
        >
          <button
            class="industry-service-types__button"
            :aria-label="
              getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
            "
            @click="onServiceTypeSelected(serviceType)"
          >
            <img
              :id="serviceType.id"
              :src="getServiceTypeIcon(serviceType.sysLabel)"
              class="industry-service-types__icon"
              :alt="
                getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
              "
            />
            <div class="industry-service-types__text">
              {{
                getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
              }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<!-- <template>
  <div class="industry-service-types">
    <div class="row ms-4 my-4">
      <div class="col-6 d-flex gap-3">
        <div
          v-for="serviceType in validServiceTypes"
          :key="serviceType.id"
          :class="[
            'industry-service-types__rectangle',
            {
              'industry-service-types__rectangle--selected':
                isSelected(serviceType),
              'industry-service-types__rectangle--unselected':
                !isSelected(serviceType),
            },
          ]"
          @click="onServiceTypeSelected(serviceType)"
        >
          <button
            class="industry-service-types__button"
            :aria-label="
              getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
            "
            @click="onServiceTypeSelected(serviceType)"
          >
            <img
              :id="serviceType.id"
              :src="getServiceTypeIcon(serviceType.sysLabel)"
              class="industry-service-types__icon"
              :alt="
                getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
              "
            />
            <div class="industry-service-types__text">
              {{
                getSysLabelText(serviceType.sysLabel, serviceType.sysLabelText)
              }}
            </div>
          </button>
        </div>
      </div>
    </div>
  </div>
</template> -->

<script setup>
import { computed, defineEmits, defineProps } from "vue";
import { useStore } from "vuex";
import { RouteNames } from "@/router/routes";
import { useRoute } from "vue-router";
import cmsService from "@/services/cmsService";

defineProps({
  validServiceTypes: {
    type: Array,
    default: () => [],
  },
});

const emit = defineEmits(["service-type-selected"]);

const store = useStore();
const route = useRoute();

// Computed properties
const serviceTypes = computed(() => store.getters["cms/getServiceTypes"]);
const selectedServiceType = computed(
  () => store.getters["cms/getSelectedSupplierType"],
);
const selectedAboutUsServiceType = computed(
  () => store.getters["supplier/getSelectedAboutUsSupplierType"],
);

// Methods
const getServiceTypeIcon = (sysLabel) => {
  if (!sysLabel) return genericIcon();
  // get icon from CMS serviceTypes if it exists
  if (serviceTypes.value) {
    const serviceType = serviceTypes.value.find(
      (type) => type.label === sysLabel,
    );
    if (serviceType && serviceType.icons) {
      return getIconUrls(serviceType.icons, serviceType.icons[0].label);
    }
  }
  return genericIcon();
};

const getIconUrls = (icons, label) => {
  const matchingIcon = icons.find((icon) => icon.label === label);
  return matchingIcon ? cmsService.resolveImage(matchingIcon.icon) : "";
};

const genericIcon = () => `/images/my-prices/andre_tjenester.svg`;

const getSysLabelText = (sysLabel, sysLabelText) => {
  if (!sysLabel || !serviceTypes.value) return "";
  if (sysLabelText === null) return sysLabel;
  const serviceType = serviceTypes.value.find(
    (type) => type.label === sysLabel,
  );
  return serviceType ? serviceType.name : "";
};

const onServiceTypeSelected = async (serviceType) => {
  try {
    if (route.name === RouteNames.ABOUT_US) {
      await store.dispatch("supplier/selectAboutUsSupplierType", serviceType);
    } else {
      emit("service-type-selected", serviceType);
    }
  } catch (error) {
    console.error("Failed to select supplier type:", error);
  }
};

const isSelected = (supplierType) => {
  const selected =
    route.name === RouteNames.ABOUT_US
      ? selectedAboutUsServiceType.value
      : selectedServiceType.value;
  return selected && supplierType.id === selected.id;
};
</script>

<style lang="scss" scoped>
$border-width: 0.2rem;
$border-radius: 0.25rem;
$icon-size: 4rem;

.industry-service-types {
  &__wrapper {
    overflow-x: auto;
    padding: 2rem 2.6rem;
  }

  &__container {
    max-width: 100vw;
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 1rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__rectangle {
    background: $color-primary-pink;
    border: $border-width solid #fff;
    box-sizing: border-box;
    border-radius: $border-radius;
    cursor: pointer;

    &:hover {
      border-color: $color-primary;
    }

    &--selected {
      border-color: $color-primary;
    }

    &--unselected {
      border-color: #fff;
    }
  }

  &__button {
    background: $color-primary-pink;
    border: none;
    padding: 1rem;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    width: 14rem;
    height: 100%;
    gap: 1rem;

    &:focus {
      outline: 2px solid $color-primary;
    }

    &:hover .industry-service-types__text {
      color: $color-primary-dark;
    }
  }

  &__icon {
    width: $icon-size;
    height: $icon-size;
    object-fit: contain;
  }

  &__text {
    margin-top: auto;
    font-weight: bold;
    text-align: center;
    color: $color-black;
    word-break: break-word;
    hyphens: auto;

    &:hover {
      color: $color-primary-dark;
    }
  }
}
</style>
