import { AccountInfo } from "@azure/msal-browser";

const getUserIdFromMsalAccount = (account: AccountInfo) => {
  return account.idTokenClaims?.sub ?? "";
};

const getUserOrgNumbersFromMsalAccount = (account: AccountInfo) => {
  return (account.idTokenClaims?.extension_OrgNumber ?? "") as string;
};

type UseMsalAccount = () => {
  getUserIdFromMsalAccount: typeof getUserIdFromMsalAccount;
  getUserOrgNumbersFromMsalAccount: typeof getUserOrgNumbersFromMsalAccount;
};

const useMsalAccount: UseMsalAccount = () => {
  return {
    getUserIdFromMsalAccount,
    getUserOrgNumbersFromMsalAccount,
  };
};

export default useMsalAccount;
