<template>
  <button class="profile-button" @click="handleButtonClick">
    <span class="profile-button__avatar">
      {{ userName[0] }}
    </span>
    <span class="profile-button__user-info">
      <span class="profile-button__user-organization">
        {{ organizationName }}
      </span>
      <span class="profile-button__user-name">
        {{ `ved ${userName}` }}
      </span>
    </span>
  </button>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { User } from "@/custom-types/GeneralTypes";
import { Organization } from "@/stores/tender/types";

const emit = defineEmits(["clicked"]);
const props = withDefaults(
  defineProps<{
    user?: User | null;
    organization?: Organization | null;
  }>(),
  {
    user: undefined,
    organization: undefined,
  },
);

const userName = computed(() => {
  return props.user?.name || "";
});
const organizationName = computed(() => props.organization?.name || "");

const handleButtonClick = () => {
  emit("clicked");
};
</script>

<style lang="scss" scoped>
.profile-button {
  height: 100%;
  padding: 0 1rem;
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  gap: 0.75rem;
  transition: all 250ms ease;

  &:hover {
    background: rgba(#1d1d1d, 0.03);
  }

  &__avatar {
    width: 1.5rem;
    height: 1.5rem;
    background-color: #1d1d1d;
    border-radius: 50%;
    display: flex;
    font-weight: 700;
    color: white;
    font-size: 0.75rem;
    align-items: center;
    justify-content: center;
  }

  &__user-info {
    color: #1d1d1d;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    @media only screen and (max-width: 75em) {
      display: none;
    }
  }

  &__user-organization {
    font-weight: 700;
    font-size: 0.875rem;
  }

  &__user-name {
    font-weight: 400;
    font-size: 0.75rem;
  }
}
</style>
