<template>
  <div v-if="isSupplierLoaded" class="supplier-admin">
    <app-header />
    <router-view />
  </div>
  <loading-view v-else />
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useSupplierCmsData } from "@/composables/useSupplierCmsData";
import LoadingView from "@/views/LoadingView/LoadingView.vue";
import { useStore } from "vuex";
import { useCmsStore } from "@/stores/cms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import AppHeader from "@/ui/Header/AppHeader.vue";

const store = useStore();
const cmsStore = useCmsStore();

const { isSupplierLoaded, fetchSupplierAndCmsContent } = useSupplierCmsData();
const isLoading = ref(true);

const loadCmsContent = async () => {
  const contentTypes = [
    "cms/loadMyAgreementsContent",
    "cms/loadPriceGroups",
    "cms/loadProfileContent",
    "cms/loadServicesContent",
    "cms/loadMyPricesContent",
    "cms/loadOtherServicesContent",
  ];
  await Promise.all(contentTypes.map((type) => store.dispatch(type)));
};

const setServiceTypes = async () => {
  try {
    await store.dispatch(
      "cms/saveServiceTypes",
      cmsStore[ContentType.SERVICE_TYPES_CMS],
    );
  } catch (e) {
    console.error(e);
  }
};

const initialize = async () => {
  try {
    await Promise.all([loadCmsContent(), fetchSupplierAndCmsContent()]);
    await setServiceTypes();
    isLoading.value = false;
  } catch (error) {
    console.error("Error initializing component:", error);
  }
};
initialize();
</script>

<style lang="scss" scoped>
.supplier-admin {
  display: block;
  min-height: 100vh;
}
</style>
