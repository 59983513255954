import dayjs from "dayjs";
import "dayjs/locale/nb";

type DateParam = Date | string | undefined | null;

const WEEKEND_DAYS = [0, 6];
const SEPARATOR = ".";
const YEAR_SLICE_LENGTH = -2;

export const formatToDateTimeString = (date: DateParam): string => {
  return `${formatToDateString(date)} ${formatDateInNorwegian(date, "HH:mm")}`;
};

export const formatToLongDateString = (date: DateParam): string => {
  return formatToDateString(date, "long");
};

export const formatToDateString = (
  date: DateParam,
  monthStyle: "number" | "short" | "long" = "number",
): string => {
  if (monthStyle === "number") {
    return formatDateInNorwegian(date, "DD.MM.YYYY");
  }
  if (monthStyle === "short") {
    return formatDateInNorwegian(date, "DD. MMM YYYY");
  }
  return formatDateInNorwegian(date, "DD. MMMM YYYY");
};

export const formatDateInNorwegian = (
  date: DateParam,
  format: string,
): string => {
  return date ? dayjs(date).locale("nb").format(format) : "";
};

export const extractDateString = (dateTimeStr: string) =>
  dateTimeStr.split("T")[0];

export const formatDateBasedOnTimeLapsed = (date: DateParam) => {
  if (!date) {
    return "";
  }
  const diffInDays = dayjs()
    .startOf("day")
    .diff(dayjs(date).startOf("day"), "day");
  const timeFormat = "kl. HH:mm";
  if (diffInDays === 0) {
    return `i dag ${formatDateInNorwegian(date, timeFormat)}`;
  }
  if (diffInDays === 1) {
    return `i går ${formatDateInNorwegian(date, timeFormat)}`;
  }
  if (diffInDays >= 2 && diffInDays <= 6) {
    return formatDateInNorwegian(date, `dddd ${timeFormat}`);
  }
  return formatDateInNorwegian(date, "DD.MMM YYYY");
};

export const addBusinessDays = (date: DateParam, daysToAdd: number): Date => {
  const startDate = dayjs(date);

  if (!startDate.isValid() || typeof daysToAdd !== "number") {
    throw new Error(
      "Invalid input: date must be valid, and daysToAdd must be a number",
    );
  }

  let currentDate = startDate;
  let remainingDays = daysToAdd;

  while (remainingDays > 0) {
    currentDate = currentDate.add(1, "day");
    if (!WEEKEND_DAYS.includes(currentDate.day())) {
      remainingDays--;
    }
  }

  return currentDate.toDate();
};

export const convertDateToLocaleTimeStringDotSeparated = (
  date: DateParam,
): string => (date ? formatDateInNorwegian(date, "DD.MM.YYYY") : "");

export const formatDateToDotSeparated = (dateString: string): string => {
  if (!dateString?.includes("T")) return "";

  const [datePart] = dateString.split("T");
  const dateParts = datePart?.split("-");

  if (dateParts?.length !== 3) return "";

  const [year, month, day] = dateParts;
  return [day, month, year?.slice(YEAR_SLICE_LENGTH)].join(SEPARATOR);
};
