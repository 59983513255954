<template>
  <div class="economy-tab">
    <div v-if="isLoading" class="economy-tab__loader">
      <b-spinner type="grow" size="lg" />
    </div>

    <div v-else-if="error" class="economy-tab__error">
      <p>{{ error }}</p>
      <base-button variant="primary" @click="fetchInvoices"
        >Prøv igjen</base-button
      >
    </div>

    <div v-else-if="!invoices.length" class="economy-tab__empty">
      <p>Ingen fakturaer funnet for denne avtalen</p>
    </div>

    <base-list v-else>
      <supplier-invoice-list-item
        v-for="invoice in sortedInvoices"
        :key="invoice.invoiceNumber"
        :invoice="mapToInvoiceDTO(invoice)"
        :list-item-id="'invoice-' + invoice.invoiceNumber"
      />
    </base-list>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import type { InvoiceDTO } from "@/custom-types/GeneralTypes";
import { useFinanceApi } from "@/services/api/useFinanceApi";
import {
  BaseList,
  SupplierInvoiceListItem,
} from "@really/really-tender-components";
import BaseButton from "@/components/Base/BaseButton/BaseButton.vue";
import dayjs from "dayjs";

const props = defineProps<{
  tender: {
    tenderId: string;
    customerOrganization: {
      orgNumber: string;
    };
  };
  bookingNumber: string;
}>();

const invoices = ref<InvoiceDTO[]>([]);
const isLoading = ref(true);
const error = ref<string | null>(null);
const { getTenderInvoices } = useFinanceApi();

const mapToInvoiceDTO = (invoice: InvoiceDTO): InvoiceDTO => ({
  id: invoice.id,
  invoiceNumber: invoice.invoiceNumber,
  customerName: invoice.customerName,
  invoiceDate: invoice.invoiceDate,
  paymentDueDate: invoice.paymentDueDate,
  taxInclusiveAmount: invoice.taxInclusiveAmount,
  statusCode: invoice.statusCode,
  restAmount: invoice.restAmount,
  vatTotalsAmount: invoice.vatTotalsAmount,
  taxExclusiveAmount: invoice.taxExclusiveAmount,
  emailAddress: invoice.emailAddress,
  comment: invoice.comment,
  credited: invoice.credited,
  lastPaymentDate: invoice.lastPaymentDate,
  deliveryDate: invoice.deliveryDate,
  ourReference: invoice.ourReference,
  yourReference: invoice.yourReference,
});

const sortedInvoices = computed(() => {
  return [...invoices.value].sort(
    (a, b) => dayjs(b.invoiceDate).valueOf() - dayjs(a.invoiceDate).valueOf(),
  );
});

const fetchInvoices = async () => {
  try {
    isLoading.value = true;
    error.value = null;

    console.log(props.tender.customerOrganization.orgNumber);
    console.log(props.bookingNumber);

    const response = await getTenderInvoices({
      orgNumber: props.tender.customerOrganization.orgNumber,
      bookingNumber: props.bookingNumber,
    });

    invoices.value = response.data;
  } catch (err) {
    error.value =
      "Det oppstod en feil ved henting av fakturaer. Vennligst prøv igjen.";
    console.error("Failed to fetch invoices:", err);
  } finally {
    isLoading.value = false;
  }
};

onMounted(fetchInvoices);
</script>

<style lang="scss" scoped>
.economy-tab {
  padding: 1.5rem;

  &__loader,
  &__error,
  &__empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 200px;
    gap: 1rem;
    color: #1d1d1d;
    font-weight: 400;
  }
}
</style>
