import { createApiClient } from "./apiClient";
import { ApiResponse, EndpointFactory } from "@/config/api/types";
import { ResponseType } from "axios";
import { useUserValidation } from "@/composables/useUserValidation";

const { validateUser } = useUserValidation();

const resolvePathParams = (pathParams?: Array<string | number>) => {
  return pathParams
    ? pathParams.map((param) => param.toString()).join("/")
    : "";
};

export const executeApiRequest = async <RESPONSE>(
  endpointFactory: EndpointFactory,
  requestPayload?: {
    data?: unknown;
    pathParams?: Array<string | number>;
    queryParams?: Record<string, unknown>;
    responseType?: ResponseType;
    signal?: AbortSignal;
  },
): Promise<ApiResponse<RESPONSE>> => {
  const { config, endpointKey } = endpointFactory;
  const endpoint = config.endpoints.find(
    (endpoint) => endpoint.key === endpointKey,
  );
  if (!endpoint) {
    throw new Error(`Endpoint with key ${String(endpointKey)} not found`);
  }
  await validateUser();

  const apiClient = createApiClient(config, requestPayload?.responseType);
  const url = `${endpoint.url}/${resolvePathParams(requestPayload?.pathParams)}`;
  const response = await apiClient.request<RESPONSE>({
    method: endpoint.method,
    url: url,
    data: requestPayload?.data,
    params: requestPayload?.queryParams,
    signal: requestPayload?.signal,
  });
  return {
    data: response.data,
    status: response.status,
  };
};
