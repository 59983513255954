export const defaultUser = {
  isAuthenticated: false,
  accessToken: {},
  userAccount: {},
  name: "",
  email: "",
  phoneNumber: "",
  orgNumber: "",
  orgName: "",
  userId: "",
};
