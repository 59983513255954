import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

const BASE_URI = import.meta.env.VITE_APP_ADDRESS_BASE_URI;
const AXIOS = Axios.create({
  baseURL: `${BASE_URI}`,
  headers: {
    "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
  },
});

export default {
  async createSupplierAddress(supplierAddress) {
    const url = `${apiConfig.apiConfig.createSupplierAddressApi}`;
    return AXIOS.post(url, supplierAddress);
  },
  async updateSupplierAddress(supplierId, addressId, supplierAddress) {
    const url = `${apiConfig.apiConfig.updateSupplierAddressApi}${supplierId}/Address/${addressId}`;
    return AXIOS.put(url, supplierAddress);
  },
  async deleteSupplierAddress(supplierId, addressId) {
    const url = `${apiConfig.apiConfig.deleteSupplierAddressApi}${supplierId}/Address/${addressId}`;
    return AXIOS.delete(url);
  },
  async getSupplierAddresses(supplierId) {
    const url = `${apiConfig.apiConfig.getSupplierAddressesApi}${supplierId}/Addresses`;
    return AXIOS.get(url);
  },
};
