import {
  ActivityFrequency,
  BillingType,
  Months,
  RequestType,
} from "@/custom-types/types";
import { groupBy } from "lodash";
import dayjs from "dayjs";

const getSupplierCommission = (
  segmentGroupPrice,
  configuredSupplierCommission,
) => {
  if (segmentGroupPrice.supplierCommission === null) {
    return configuredSupplierCommission;
  }

  return segmentGroupPrice.supplierCommission;
};

const extractFixedActivitiesFromRobotServiceOffer = (
  serviceOffer,
  tender,
  serviceTypesCms,
  configuredSupplierCommission = null,
) => {
  const { priceRequests } = tender;
  const { segmentValues } = priceRequests?.[0] ?? {};

  const hasUngroupedSegmentValues = segmentValues?.some(
    (segmentValue) =>
      !segmentValue.frequencyValue &&
      !segmentValue.serviceOfferId &&
      (!segmentValue.externalGrouping ||
        segmentValue.externalGrouping === "UnGrouped"),
  );

  const groupedSegmentValues = groupBy(segmentValues, (segmentValue) =>
    hasUngroupedSegmentValues
      ? segmentValue.priceSegmentGroupId
      : segmentValue.externalGrouping,
  );

  return Object.entries(groupedSegmentValues)
    .map(([uniqueGroupId, segmentValues]) => {
      const groupId = hasUngroupedSegmentValues
        ? parseInt(uniqueGroupId, 10)
        : uniqueGroupId;

      const serviceOfferPrice = findServiceOfferPriceByGrouping(
        serviceOffer,
        groupId,
      );
      if (!serviceOfferPrice) return null;

      const segmentGroupPrice = findSegmentGroupPriceByGrouping(
        serviceOfferPrice,
        groupId,
      );
      if (!segmentGroupPrice) return null;

      const serviceTypeCategoryCms = getServiceTypeCategory(
        priceRequests[0],
        serviceTypesCms,
      );
      if (!serviceTypeCategoryCms) return null;

      const segmentGroupCmsTitle = findSegmentGroupCmsTitle(
        segmentGroupPrice.segmentGroupName,
        serviceTypeCategoryCms,
      );

      const itemsSegment = segmentValues.find((sv) => !sv.frequencyValue);
      const frequencySegment = segmentValues.find((sv) => sv.frequencyValue);

      const supplierCommission = getSupplierCommission(
        segmentGroupPrice,
        configuredSupplierCommission,
      );

      return {
        id: segmentGroupPrice.segmentGroupId,
        description: segmentGroupCmsTitle || "",
        frequencyGroup: serviceOfferPrice.billingCycle,
        frequency: frequencySegment?.inputLabel.split("-").pop().trim() || "",
        limitedPeriod: false,
        limitedPeriodStartMonth: "",
        limitedPeriodEndMonth: "",
        itemsCount: itemsSegment?.value || 0,
        itemsUnit: itemsSegment?.unit || "",
        pricePerItem: Math.round(segmentGroupPrice?.unitCost) || 0,
        baseAmount: segmentGroupPrice?.baseAmount || 0,
        requestType: priceRequests[0].requestType,
        jobType: priceRequests[0].jobType,
        supplierCommission,
      };
    })
    .filter((activity) => activity !== null);
};

const extractFixedActivitiesFromManualServiceOffer = (
  serviceOffer,
  tender,
  configuredSupplierCommission = null,
) => {
  const { priceRequests } = tender;
  const { segmentValues } = priceRequests?.[0] ?? {};

  const isFixedBillingType = (segmentValue) =>
    segmentValue.serviceOfferId === serviceOffer?.id &&
    segmentValue.billingType === BillingType.Fixed;

  const groupedSegmentValues = groupBy(
    segmentValues.filter(isFixedBillingType),
    "externalGrouping",
  );

  return Object.entries(groupedSegmentValues)
    .map(([externalGrouping, segmentValues]) => {
      const serviceOfferPrice = findServiceOfferPriceByGrouping(
        serviceOffer,
        externalGrouping,
      );
      if (!serviceOfferPrice) return null;

      const segmentGroupPrice = findSegmentGroupPriceByGrouping(
        serviceOfferPrice,
        externalGrouping,
      );
      if (!segmentGroupPrice) return null;

      const itemsSegment = segmentValues.find((sv) => !sv.frequencyValue);
      const frequencySegment = segmentValues.find((sv) => sv.frequencyValue);

      const limitedPeriodStartMonth =
        serviceOfferPrice.activePeriodStart?.toLowerCase() || "";
      const limitedPeriodEndMonth =
        serviceOfferPrice.activePeriodEnd?.toLowerCase() || "";

      const supplierCommission = getSupplierCommission(
        segmentGroupPrice,
        configuredSupplierCommission,
      );

      return {
        id: segmentGroupPrice.segmentGroupId,
        description: itemsSegment?.inputLabel || "",
        frequencyGroup: serviceOfferPrice.billingCycle,
        frequency: frequencySegment?.inputLabel.split("-").pop().trim() || "",
        limitedPeriod: !!(limitedPeriodStartMonth || limitedPeriodEndMonth),
        limitedPeriodStartMonth,
        limitedPeriodEndMonth,
        itemsCount: itemsSegment?.value || 0,
        itemsUnit: itemsSegment?.unit || "",
        pricePerItem: Math.round(segmentGroupPrice?.unitCost) || 0,
        baseAmount: segmentGroupPrice?.baseAmount || 0,
        requestType: priceRequests[0].requestType,
        jobType: priceRequests[0].jobType,
        supplierCommission,
      };
    })
    .filter((activity) => activity !== null);
};

const findServiceOfferPriceByGrouping = (serviceOffer, groupingCriteria) => {
  return (
    serviceOffer?.serviceOfferPrices.find((serviceOfferPrice) => {
      if (typeof groupingCriteria === "number") {
        return serviceOfferPrice.segmentGroupPrices?.some(
          (segmentGroupPrice) =>
            segmentGroupPrice.segmentGroupId === groupingCriteria,
        );
      } else {
        return serviceOfferPrice.segmentGroupPrices?.some(
          (segmentGroupPrice) =>
            segmentGroupPrice.externalGrouping === groupingCriteria,
        );
      }
    }) ?? null
  );
};

const findSegmentGroupPriceByGrouping = (
  serviceOfferPrice,
  groupingCriteria,
) => {
  return (
    serviceOfferPrice.segmentGroupPrices.find((segmentGroupPrice) => {
      if (typeof groupingCriteria === "number") {
        return segmentGroupPrice.segmentGroupId === groupingCriteria;
      } else {
        return segmentGroupPrice.externalGrouping === groupingCriteria;
      }
    }) ?? null
  );
};

const getServiceTypeCategory = (priceRequest, serviceTypesCms) => {
  if (!priceRequest || typeof priceRequest.serviceTypeLabel !== "string") {
    return null;
  }
  const serviceType = serviceTypesCms.find(
    (type) => type.label === priceRequest.serviceTypeLabel,
  );
  return serviceType || null;
};

const findSegmentGroupCmsTitle = (segmentGroupName, serviceTypeCategoryCms) => {
  return serviceTypeCategoryCms?.segmentGroup?.find(
    (segmentGroup) => segmentGroup.label === segmentGroupName,
  )?.title;
};

const extractVariableActivitiesFromServiceOffer = (
  serviceOffer,
  tender,
  configuredSupplierCommission = null,
) => {
  const variableSegmentValues = tender.priceRequests[0].segmentValues.filter(
    (segmentValue) =>
      segmentValue.serviceOfferId === serviceOffer?.id &&
      segmentValue.billingType === BillingType.Variable,
  );
  return variableSegmentValues.map((segmentValue) => {
    const serviceOfferPrice = serviceOffer?.serviceOfferPrices.find(
      (serviceOfferPrice) => {
        return serviceOfferPrice.segmentGroupPrices?.find(
          (segmentGroupPrice) =>
            segmentGroupPrice.externalGrouping ===
            segmentValue.externalGrouping,
        );
      },
    );
    const segmentGroupPrice = serviceOfferPrice?.segmentGroupPrices.find(
      (segmentGroupPrice) =>
        segmentGroupPrice.externalGrouping === segmentValue.externalGrouping,
    );
    const supplierCommission = getSupplierCommission(
      segmentGroupPrice,
      configuredSupplierCommission,
    );
    return {
      id: segmentGroupPrice.segmentGroupId,
      description: segmentValue ? segmentValue.inputLabel : "",
      frequencyGroup: "",
      frequency: "",
      limitedPeriod: false,
      limitedPeriodStartMonth: "",
      limitedPeriodEndMonth: "",
      itemsCount: 0,
      itemsUnit: segmentValue ? segmentValue.unit : "",
      pricePerItem: segmentGroupPrice
        ? Math.round(segmentGroupPrice?.unitCost)
        : 0,
      requestType: tender.priceRequests[0].requestType,
      jobType: tender.priceRequests[0].jobType,
      supplierCommission: supplierCommission,
    };
  });
};

const extractLocalOfferFromServiceOffer = (
  serviceOffer,
  tender,
  serviceTypeCms,
  configuredSupplierCommission,
) => {
  const priceRequest = getPriceRequest(tender);

  if (!priceRequest) {
    console.warn("Price request not found in tender:", tender);
    return null;
  }

  const serviceOfferExtra = serviceOffer?.serviceOfferExtra;

  const wantedStartDate = serviceOfferExtra
    ? getValidWantedStartDate(serviceOfferExtra?.wantedStartDate)
    : null;
  const descriptions = serviceOfferExtra
    ? getDescriptionsFromServiceOffer(serviceOfferExtra?.descriptions)
    : null;
  const attachments = serviceOfferExtra
    ? getAttachmentsFromServiceOffer(serviceOfferExtra?.attachments)
    : null;

  return {
    nickName: serviceOfferExtra ? serviceOfferExtra?.nickname || "" : "",
    wantedStartDate: wantedStartDate,
    descriptions: descriptions,
    fixedCostActivities: getFixedActivities(
      serviceOffer,
      tender,
      priceRequest.requestType,
      serviceTypeCms,
      configuredSupplierCommission,
    ),
    variableCostActivities: extractVariableActivitiesFromServiceOffer(
      serviceOffer,
      tender,
      configuredSupplierCommission,
    ),
    attachments: attachments,
  };
};

const getPriceRequest = (tender) => {
  const { priceRequests } = tender;
  return priceRequests && priceRequests.length > 0 ? priceRequests[0] : null;
};

const getValidWantedStartDate = (wantedStartDate) => {
  if (wantedStartDate && dayjs(wantedStartDate).isValid()) {
    return dayjs(wantedStartDate).toDate();
  }
  return null;
};

const getDescriptionsFromServiceOffer = (descriptions) => {
  return (
    descriptions?.map((description, index) => ({
      id: index,
      title: description.title,
      description: description.body,
    })) ?? []
  );
};

const getAttachmentsFromServiceOffer = (attachments) => {
  return (
    attachments?.map((attachment) => ({
      id: attachment.attachmentId,
      file: null,
      fileName: attachment.fileName,
      attachmentUri: attachment.attachmentUri,
    })) ?? []
  );
};

const getFixedActivities = (
  serviceOffer,
  tender,
  requestType,
  serviceTypeCms,
  supplierCommission,
) => {
  if (!serviceOffer) return [];

  return requestType === RequestType.Robot
    ? extractFixedActivitiesFromRobotServiceOffer(
        serviceOffer,
        tender,
        serviceTypeCms,
        supplierCommission,
      )
    : extractFixedActivitiesFromManualServiceOffer(
        serviceOffer,
        tender,
        supplierCommission,
      );
};

const getServiceOfferFromTender = (serviceOfferId, tender) => {
  if (!tender?.supplierPortfolios) {
    return null;
  }

  const { supplierPortfolios } = tender;

  if (!Array.isArray(supplierPortfolios)) {
    return null;
  }

  return supplierPortfolios
    .flatMap(
      ({ serviceTypePackagePortfolio }) => serviceTypePackagePortfolio || [],
    )
    .flatMap(({ serviceOffers }) => serviceOffers || [])
    .find(({ id }) => id === serviceOfferId);
};

const roundOff = (value) => {
  return Math.round(value);
};

const calculateTotalYearlyCostForFixedCostActivities = (
  activities,
  frequencyOptions,
) => {
  if (!Array.isArray(activities)) {
    console.error("Invalid activities array");
    return null;
  }

  const monthlyActivities = activities.filter((activity) =>
    [ActivityFrequency.Monthly, ActivityFrequency.Yearly].includes(
      activity.frequencyGroup,
    ),
  );

  const fixedActivities = activities.filter(
    (activity) => activity.frequencyGroup === ActivityFrequency.Fixed,
  );

  try {
    const calculateTotalCostForActivities = (activityGroup) => {
      return activityGroup.reduce((totalCost, activity) => {
        const activityCost = getYearlyCostForFixedCostActivity(
          activity,
          frequencyOptions,
        );
        if (typeof activityCost !== "number" || isNaN(activityCost)) {
          throw new Error("Invalid activity cost");
        }
        return totalCost + activityCost;
      }, 0);
    };

    if (
      (monthlyActivities.length > 0 && fixedActivities.length > 0) ||
      fixedActivities.length === 0
    ) {
      return calculateTotalCostForActivities(monthlyActivities);
    } else if (fixedActivities.length > 0 && monthlyActivities.length === 0) {
      return calculateTotalCostForActivities(fixedActivities);
    } else {
      return null;
    }
  } catch (error) {
    console.error("Error calculating activity cost:", error);
    return null;
  }
};

const calculateOnceOrMorePerMonthTotal = (fixedCostActivities) => {
  return fixedCostActivities
    .filter(
      (activity) =>
        activity.frequencyGroup === ActivityFrequency.Monthly &&
        Number.isFinite(getFixedActivitySupplierCost(activity)) &&
        !activity.limitedPeriod, // Exclude activities with limitedPeriod set to true
    )
    .reduce(
      (total, activity) => total + getFixedActivitySupplierCost(activity),
      0,
    );
};

const getFixedActivitySupplierCost = (fixedActivity) => {
  if (
    !fixedActivity ||
    !fixedActivity.pricePerItem ||
    !fixedActivity.itemsCount
  ) {
    throw new Error("Invalid fixed activity details");
  }

  let totalPrice = 0;
  if (fixedActivity.requestType === RequestType.Robot) {
    totalPrice = fixedActivity.baseAmount;
  } else {
    totalPrice =
      Math.round(fixedActivity.pricePerItem) * fixedActivity.itemsCount;
  }

  return roundOff(
    ((100 - fixedActivity.supplierCommission) / 100) * totalPrice,
  );
};

const parseYearsFromFrequencyOptions = (frequency, frequencyOptions) => {
  if (!frequencyOptions || !frequencyOptions.yearly) {
    return 1;
  }
  const frequencyItem = frequencyOptions.yearly.find(
    (item) => item.label === frequency,
  );
  if (!frequencyItem) {
    return 1;
  }
  return frequencyItem.value || 1;
};

const calculateMonths = (startMonth, endMonth) => {
  const start = Months[startMonth.toLowerCase()];
  const end = Months[endMonth.toLowerCase()];

  return end >= start ? end - start + 1 : 12 - start + end + 1;
};

const getYearlyCostForFixedCostActivity = (fixedActivity, frequencyOptions) => {
  if (!fixedActivity || !fixedActivity.frequencyGroup) {
    throw new Error("Invalid fixed activity details");
  }

  const activityCost = getFixedActivitySupplierCost(fixedActivity);

  switch (fixedActivity.frequencyGroup) {
    case ActivityFrequency.Fixed:
      return activityCost;

    case ActivityFrequency.Yearly: {
      const years = parseYearsFromFrequencyOptions(
        fixedActivity.frequency,
        frequencyOptions,
      );
      return activityCost * years;
    }
    case ActivityFrequency.Monthly: {
      const {
        limitedPeriodStartMonth: startMonth,
        limitedPeriodEndMonth: endMonth,
      } = fixedActivity;
      const months =
        startMonth && endMonth ? calculateMonths(startMonth, endMonth) : 12;
      return roundOff(activityCost * months);
    }
    default:
      return 0;
  }
};

const getServiceOfferTender = (tenders, serviceOfferId) =>
  tenders.find((tender) => getServiceOfferFromTender(serviceOfferId, tender));

const getServiceCategory = (tender, serviceTypesCms) => {
  const priceRequest = tender?.priceRequests?.[0];
  const serviceTypeLabel = priceRequest?.serviceTypeLabel;
  const serviceCategoryLabel = priceRequest?.serviceCategoryLabel;

  const matchingServiceTypeCms = serviceTypesCms.find(
    (cms) => cms.label === serviceTypeLabel,
  );

  if (!matchingServiceTypeCms) {
    return "";
  }

  const serviceCategoryCms =
    serviceTypeLabel === "otherServices"
      ? matchingServiceTypeCms?.serviceSubcategories?.find(
          (cms) => cms.label === serviceCategoryLabel,
        )
      : matchingServiceTypeCms?.serviceCategories?.find(
          (cms) => cms.label === serviceCategoryLabel,
        );

  return serviceCategoryCms?.title || serviceCategoryLabel || "";
};

const getPriceRequestForGivenServiceOffer = (serviceOffer, tender) => {
  if (!tender || !tender.priceRequests || !serviceOffer) {
    return null;
  }

  return (
    tender.priceRequests.find(
      (priceRequest) =>
        priceRequest.customerPropertyId === serviceOffer?.customerPropertyId,
    ) || null
  );
};

export {
  getServiceOfferTender,
  getServiceOfferFromTender,
  getFixedActivitySupplierCost,
  extractLocalOfferFromServiceOffer,
  extractFixedActivitiesFromManualServiceOffer,
  extractFixedActivitiesFromRobotServiceOffer,
  extractVariableActivitiesFromServiceOffer,
  getYearlyCostForFixedCostActivity,
  calculateOnceOrMorePerMonthTotal,
  calculateTotalYearlyCostForFixedCostActivities,
  getServiceCategory,
  getPriceRequestForGivenServiceOffer,
};
