<template>
  <div class="conversation">
    <button
      v-if="showBackButton"
      class="conversation__back-button"
      @click="onBackButtonClick"
    >
      <img :src="`/images/arrow-left.svg`" alt="ArrowLeft" />
      <span>Tilbake</span>
    </button>
    <template v-if="!isLoadingMessages">
      <div class="conversation__messages">
        <thread-messages :messages="conversationMessages" />
      </div>
      <div v-if="isNotRejectedJob" class="conversation__new-message">
        <new-message :conversation="conversation" @send-message="sendMessage" />
      </div>
      <div v-if="$slots.error" class="conversation__error">
        <slot name="error" />
      </div>
    </template>
    <div v-else class="conversation__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script>
import ThreadMessages from "@/components/Conversation/ThreadMessages.vue";
import NewMessage from "@/components/Conversation/NewMessage.vue";
import { TenderState, RequestState } from "@/custom-types/types";

export default {
  components: { NewMessage, ThreadMessages },
  props: {
    conversation: {
      type: [Object, null],
      default: null,
    },
    tender: {
      type: [Object, null],
      required: true,
    },
    showBackButton: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["back-button-clicked", "send-message"],
  computed: {
    conversationMessages() {
      return this.conversation ? this.conversation.thread : [];
    },
    isLoadingMessages() {
      return this.conversation && !this.conversation.thread;
    },
    isNotRejectedJob() {
      // Check if the request state is rejected
      const requestState =
        this.tender.priceRequests?.[0]?.supplierRequests?.[0]?.requestState;
      if (requestState === RequestState.Rejected) {
        return false;
      }

      const VALID_STATES = [
        TenderState.Offered,
        TenderState.Accepted,
        TenderState.PriceRequested,
        TenderState.Completed,
      ];

      // Check if the tender state is within the valid states
      if (VALID_STATES.includes(this.tender?.tenderState)) {
        return true;
      }

      // If none of the above conditions are met, return false
      return true;
    },
  },
  methods: {
    onBackButtonClick() {
      this.$emit("back-button-clicked");
    },
    sendMessage(data) {
      console.log("data", data);
      this.$emit("send-message", data);
    },
  },
};
</script>

<style lang="scss" scoped>
.conversation {
  background: #ffffff;
  border: 1px solid #e4e4e4;
  border-radius: 8px;
  height: 440px;
  display: flex;
  flex-direction: column;
  position: relative;

  &__messages {
    flex-grow: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column-reverse;
  }

  &__loader {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__back-button {
    position: absolute;
    top: 0.625rem;
    left: 1rem;
    background: $color-primary-pink-dark;
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 0.75rem 1rem;
    display: flex;
    gap: 0.625rem;

    &:hover {
      background: $color-primary-pink-darker;
    }
  }

  &__error {
    color: red;
    padding: 1rem;
    text-align: center;
  }
}
</style>
@/types/AppTypes
