<template>
  <base-supplier-offer-modal
    v-model="showModal"
    :icon="icon"
    :title="withdrawOfferModalCms.title"
    :description="withdrawOfferModalCms.content"
    :align-items="'center'"
  >
    <!-- Buttons slot -->
    <template #buttons>
      <div class="modal__action-buttons">
        <tertiary-button :disabled="isSubmitting" @click="showModal = false">{{
          cancelButtonText
        }}</tertiary-button>
        <primary-button :is-submitting="isSubmitting" @click="withdrawOffer">{{
          withdrawOfferButtonText
        }}</primary-button>
      </div>
    </template>
  </base-supplier-offer-modal>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseSupplierOfferModal from "./BaseSupplierOfferModal.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { BaseSupplierOfferModal, TertiaryButton, PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    withdrawOfferModalCms: {
      type: Object,
      required: true,
    },
    messageWithdrawnText: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue", "withdraw-offer"],
  data() {
    return {
      isSubmitting: false,
      showMessageWithdrawnText: false,
    };
  },

  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    cancelButtonText() {
      return this.withdrawOfferModalCms.buttons.find(
        (text) => text.label === "cancelButton",
      ).title;
    },
    withdrawOfferButtonText() {
      return this.withdrawOfferModalCms.buttons.find(
        (text) => text.label === "withdrawOfferButton",
      ).title;
    },
    icon() {
      const icon = this.withdrawOfferModalCms.icons.find(
        (text) => text.label === "withdrawIcon",
      ).icon;
      return this.getIconUrl(icon).toString() || "";
    },
  },

  watch: {
    messageWithdrawnText(val) {
      if (val) {
        this.showMessageWithdrawnText = true;
        this.closeModalWithDelay();
      }
    },
  },

  methods: {
    getIconUrl(icon) {
      return cmsService.resolveImage(icon);
    },
    withdrawOffer() {
      this.isSubmitting = true;
      this.$emit("withdraw-offer");
    },
    closeModalWithDelay() {
      setTimeout(() => {
        this.isSubmitting = false;
        this.showModal = false;
      }, 2000);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__action-response-text {
    margin-top: 40px;
    font-weight: 600;
    font-size: $font-size-base;
    line-height: 20px;
    text-align: center;
  }
  &__action-buttons {
    margin-top: 56px;
    display: flex;
    justify-content: flex-end;
    gap: 24px;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-sm;
      line-height: 20px;
    }
  }
}
</style>
