<template>
  <div class="thread-messages">
    <div
      v-for="(message, index) in messages"
      :key="index"
      class="thread-messages__message"
      :class="getThreadMessageClasses(message)"
    >
      <thread-message :message="message" />
    </div>
  </div>
</template>

<script>
import ThreadMessage from "@/components/Conversation/ThreadMessage.vue";
import { MessageDomain } from "@/custom-types/types";

export default {
  components: { ThreadMessage },
  props: {
    messages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    getThreadMessageClasses(message) {
      return message.messageDomain === MessageDomain.SupplierCustomer
        ? ["thread-messages__message--supplier-to-customer"]
        : [];
    },
  },
};
</script>

<style lang="scss" scoped>
.thread-messages {
  padding: 1rem;

  &__message {
    margin-top: 1.5rem;
    width: 80%;

    &--supplier-to-customer {
      margin-left: auto;
    }
  }
}
</style>
@/types/AppTypes
