<template>
  <div v-if="visible" class="window-constraint">
    <img
      :src="windowConstraintPageCmsContent?.iconUrl"
      alt="Icon"
      class="window-constraint__img"
    />
    <div class="window-constraint__title">
      {{ windowConstraintPageCmsContent?.title }}
    </div>
    <custom-block-content :blocks="windowConstraintPageCmsContent?.content" />
    <button class="window-constraint__btn" @click="handleClick">
      {{ windowConstraintPageCmsContent?.okButton }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref } from "vue";
import { useRouter } from "vue-router";
import { useCms } from "@/composables/useCms";
import { ContentType } from "@/custom-types/CmsContentTypes";
import { Button, ExtraText, Icon } from "@/stores/cms/types";
import cmsService from "@/services/cms/cmsService";
import { useMediaQuery } from "@vueuse/core";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";

interface SupplierWindowPageCms {
  buttons: Button[];
  icons: Icon[];
  otherText: ExtraText[];
}

const router = useRouter();
const { fetchCmsContent } = useCms();

const isScreenSmall = useMediaQuery("(max-width: 1023px)");
const visible = ref(isScreenSmall.value);

const cms = ref<SupplierWindowPageCms | null>(null);

const windowConstraintPageCmsContent = computed(() => {
  if (!cms.value) return null;

  const { buttons, icons, otherText } = cms.value;

  return {
    okButton: getButtonTitle(buttons, "okButton", "Ok"),
    iconUrl: findIconResolveIconUrl(
      icons,
      "instructionIcon",
      "/images/error-page/robot_error_icon.svg",
    ),
    title: getOtherTextTitle(otherText, "instructionTitle", ""),
    content: getOtherTextContent(otherText, "instructionTitle", ""),
  };
});

const findIconResolveIconUrl = (
  icons: Icon[],
  label: string,
  defaultImage: string,
) => {
  const icon = icons.find((icon) => icon.label === label)?.icon;
  return icon ? cmsService.resolveImage(icon).url() : defaultImage;
};

const getButtonTitle = (
  buttons: Button[],
  label: string,
  defaultTitle: string,
) => {
  const button = buttons.find((btn: Button) => btn.label === label);
  return button?.title || defaultTitle;
};

const getOtherTextTitle = (
  otherText: ExtraText[],
  label: string,
  defaultTitle: string,
) => {
  const text = otherText.find((txt: ExtraText) => txt.label === label);
  return text?.title || defaultTitle;
};

const getOtherTextContent = (
  otherText: ExtraText[],
  label: string,
  defaultContent: string,
) => {
  const text = otherText.find((txt: ExtraText) => txt.label === label);
  return text?.content || defaultContent;
};

const transformResponseToSupplierWindowPageCms = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  response: any,
): SupplierWindowPageCms => {
  return {
    buttons: response.buttons,
    icons: response.icons,
    otherText: response.otherText,
  };
};

onMounted(async () => {
  const response = await fetchCmsContent(
    ContentType.SUPPLIER_WINDOW_CONSTRAINT_PAGE_CMS,
  );
  if (response) {
    const transformedResponse =
      transformResponseToSupplierWindowPageCms(response);
    cms.value = transformedResponse;
  } else {
    cms.value = null;
  }
});

const handleClick = () => {
  router.push("/").catch((err) => {
    console.error(err);
  });
};
</script>

<style lang="scss" scoped>
.window-constraint {
  display: flex;
  flex-direction: column;
  gap: 4rem;
  background-color: $color-secondary;
  border: 0.5rem solid $constraint-color;
  height: auto;
  min-height: 100vh;
  text-align: center;
  padding: 1.6rem 2.4rem;

  &__img {
    width: 12.5rem;
    height: 12.5rem;
    margin-top: 10rem;
    margin: auto;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    color: $constraint-color;
    font-size: $font-size-4xl;
  }

  &__content {
    font-weight: $font-weight-regular;
    color: $constraint-color;
    font-size: $font-size-lg;
    margin-top: 7rem;
  }

  &__btn {
    font-weight: $font-weight-bold;
    font-size: $font-size-lg;
    color: $color-secondary-darker;
    background-color: $constraint-color;
    border: 0.1rem solid $constraint-color;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
}
</style>
