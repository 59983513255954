<template>
  <div class="section-info">
    <img
      class="section-info__close-icon"
      :src="closeIconSrc"
      @click="emitClose"
    />
    <p class="section-info__text">
      {{ infoText }}
    </p>
  </div>
</template>

<script setup lang="ts">
import { defineEmits } from "vue";

defineProps({
  infoText: {
    type: String,
    required: true,
  },
  closeIconSrc: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["close"]);

const emitClose = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
.section-info {
  display: flex;
  padding: 1.2rem 1.6rem 1.2rem 1.2rem;
  align-items: flex-start;
  gap: 0.5rem;
  align-self: stretch;
  border-radius: 0.5rem;
  border: 1px solid var(--Sinbad, #9ecbd3);
  background: var(--Light-Turquoise, #eefdff);

  &__close-icon {
    background: none;
    border: none;
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
  }

  &__text {
    margin: 0;
    font-size: $font-size-sm;
    font-weight: 400;
  }
}
</style>
