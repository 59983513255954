<template>
  <div class="feedback-overlay">
    <div class="feedback-overlay__left-bar" :class="leftBarClasses" />
    <div class="feedback-overlay__content">
      <div class="feedback-overlay__header">
        <img
          v-if="feedbackType === FeedbackType.Error"
          :src="`/images/alert.svg`"
          alt="Alert"
        />
        <img v-else :src="`/images/24/check.svg`" alt="Check" />
        <div class="feedback-overlay__message-title">
          {{ messageTitle }}
        </div>
        <button
          v-if="messageInfo"
          class="feedback-overlay__show-info-button"
          @click="toggleMessageInfoVisibility"
        >
          {{ showMessageInfoButtonText }}
        </button>
        <button class="feedback-overlay__close-button" @click="close">
          <img :src="`/images/24/close.svg`" alt="Close" />
        </button>
      </div>
      <div
        v-if="messageInfo && showMessageInfo"
        class="feedback-overlay__message-info"
      >
        {{ messageInfo }}
      </div>
    </div>
  </div>
</template>

<script>
import { FeedbackType } from "@/custom-types/types";

export default {
  props: {
    feedbackType: {
      type: String, //error, success,
      default: FeedbackType.Error,
    },
    messageTitle: {
      type: String,
      default: "Default feedback title",
    },
    messageInfo: {
      type: String,
      default: "",
    },
  },
  emits: ["close"],
  data() {
    return {
      FeedbackType: FeedbackType,
      showMessageInfo: false,
    };
  },
  computed: {
    leftBarClasses() {
      const status =
        this.feedbackType === FeedbackType.Error ? "error" : "success";
      return [`feedback-overlay__left-bar--${status}`];
    },
    showMessageInfoButtonText() {
      return this.showMessageInfo ? "Skjul info" : "Vis info";
    },
  },
  created() {
    if (this.feedbackType === FeedbackType.Success) {
      setTimeout(() => {
        this.close();
      }, 6000);
    }
  },
  methods: {
    close() {
      this.$emit("close");
    },
    toggleMessageInfoVisibility() {
      this.showMessageInfo = !this.showMessageInfo;
    },
  },
};
</script>

<style lang="scss" scoped>
.feedback-overlay {
  display: flex;
  width: 100%;
  background-color: $color-white;
  border-radius: 4px;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.1);

  &__left-bar {
    flex-shrink: 0;
    width: 4px;
    border-radius: 4px 0 0 4px;

    &--error {
      background-color: #de0000;
    }

    &--success {
      background-color: #50a477;
    }
  }

  &__content {
    flex-grow: 1;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 0.75rem;
    gap: 0.75rem;
    justify-content: space-between;
  }

  &__message-title {
    flex-grow: 1;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  &__message-info {
    font-size: $font-size-sm;
    font-weight: 400;
    padding: 0 3rem 0.75rem;
  }

  &__show-info-button {
    border: none;
    text-decoration: underline;
    font-size: $font-size-sm;
    font-weight: 400;
    background-color: transparent;
  }

  &__close-button {
    border: none;
    background-color: transparent;
    padding: 0.25rem;
    margin: -0.25rem;
    border-radius: 4px;

    &:hover {
      background-color: $color-accent-dark;
    }
  }
}
</style>
@/types/AppTypes
