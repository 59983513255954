<template>
  <base-supplier-offer-modal
    v-model="showModal"
    :icon="iconUrl"
    :title="getTitle(goBackFromOfferModalCms)"
    :description="getDescription(goBackFromOfferModalCms)"
    :align-items="'center'"
  >
    <template #buttons>
      <button class="cancel-button" @click.prevent="cancelChanges">
        {{ cancelButtonText }}
      </button>
      <div class="modal__action-buttons">
        <tertiary-button @click="discardChanges">
          {{ discardChangesButtonText }}
        </tertiary-button>
        <primary-button @click="saveChanges">
          {{ saveChangesButtonText }}
        </primary-button>
      </div>
    </template>
  </base-supplier-offer-modal>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseSupplierOfferModal from "./BaseSupplierOfferModal.vue";
import { getCmsItemText, getIconUrl } from "@/utilities/cmsUtilities";

export default {
  components: { BaseSupplierOfferModal, TertiaryButton, PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    goBackFromOfferModalCms: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "save-changes", "discard-changes"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    buttons() {
      return this.goBackFromOfferModalCms?.buttons || [];
    },
    cancelButtonText() {
      return getCmsItemText(this.buttons, "cancelButton");
    },
    discardChangesButtonText() {
      return getCmsItemText(this.buttons, "discardChangesButton");
    },
    saveChangesButtonText() {
      return getCmsItemText(this.buttons, "saveButton");
    },
    iconUrl() {
      return getIconUrl(this.goBackFromOfferModalCms?.icons, "saveIcon");
    },
  },
  methods: {
    getTitle(goBackFromOfferModalCms) {
      return goBackFromOfferModalCms && goBackFromOfferModalCms.title
        ? goBackFromOfferModalCms.title
        : "Default Title";
    },
    getDescription(goBackFromOfferModalCms) {
      return goBackFromOfferModalCms && goBackFromOfferModalCms.content
        ? goBackFromOfferModalCms.content
        : [];
    },
    closeModal() {
      this.showModal = false;
    },
    saveChanges() {
      this.$emit("save-changes");
      this.closeModal();
    },
    discardChanges() {
      this.$emit("discard-changes");
    },
    cancelChanges() {
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__action-buttons {
    margin-top: 32px;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}

.cancel-button {
  background: transparent;
  position: absolute;
  top: 50px;
  right: 55px;
  font-weight: 500;
  font-size: $font-size-lg;
  line-height: 22px;
  color: #1d1d1d;
  border: 0 solid #000000;
  border-bottom-width: 2px;
  text-decoration: none;
}
</style>
@/utilities/cmsUtilities
