export default {
  selectedAboutUsSupplierType: {},
  formDisabled: false,
  supplier: {
    id: null,
    supplierId: null,
    supplierName: "",
  },
  supplierDetails: {
    id: null,
    supplierTypeId: null,
    organizationNumber: "",
    filterDiscount: 0.0,
    employees: 0,
    revenue: 0,
    logo: "",
    description: "",
    error: "",
  },
  categoriesBySupplierType: [],
};
