<template>
  <div class="delete-segment-range-modal">
    <b-modal
      v-model="showModal"
      content-class="delete-segment-range-modal__content"
      header-class="delete-segment-range-modal__header"
      hide-footer
      hide-header
    >
      <div class="delete-segment-range-modal__body">
        <div class="delete-segment-range-modal__title">
          <p>Slett segmentområdet</p>
        </div>

        <div class="delete-segment-range-modal__description">
          <p>
            {{
              `Er du sikker på at du vil slette segmentområdet fra ${selectedRange.rangeFrom} til ${selectedRange.rangeTo} ?`
            }}
          </p>
        </div>

        <div class="row d-flex pt-2">
          <div class="col-6">
            <button
              class="delete-segment-range-modal__cancel-btn"
              @click.prevent="closeModal"
            >
              Avbryt
            </button>
          </div>

          <div class="col-6">
            <button
              class="delete-segment-range-modal__confirm-btn"
              @click.prevent="handleConfirmDelete"
            >
              Bekreft Sletting
            </button>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";

const props = defineProps({
  modelValue: {
    type: Boolean,
    default: false,
    required: true,
  },
  selectedRange: {
    type: Object,
    default: () => {},
    required: true,
  },
});

const emit = defineEmits(["update:modelValue", "confirm-delete"]);

const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(show) {
    emit("update:modelValue", show);
  },
});

const handleConfirmDelete = () => {
  emit("confirm-delete", true);
};

const closeModal = () => {
  showModal.value = false;
};
</script>

<style lang="scss">
.delete-segment-range-modal {
  font-family: Montserrat, sans-serif;

  &__header {
    padding: 0.5rem;
    border-bottom: none;

    button {
      font-size: $font-size-xs;
      margin-right: 0.5rem !important;
      margin-top: 0.5rem !important;
      padding: 0;
    }
  }

  &__content {
    padding: 1rem;
    background-color: $color-primary-lighter !important;
    border: 3px solid $color-primary-light !important;
  }

  &__title,
  &__description,
  &__button,
  &__cancel {
    font-family: Montserrat, sans-serif;
    padding: 0.5rem;
  }

  &__title {
    font-weight: bold;
    color: $color-primary-darker;
    font-size: $font-size-xs;
  }

  &__description {
    font-weight: 500;
    color: $color-primary-darker;
    font-size: $font-size-base;
  }

  &__cancel-btn {
    font-family: Montserrat, sans-serif;
    float: left;
    text-align: left;
    text-decoration: underline;
    font-size: $font-size-base;
    font-weight: bold;
    color: $color-primary-darker;
    border-radius: 4px;
    padding: 1rem 1rem;
    background: none;
    border: none;
  }

  &__confirm-btn {
    font-family: Montserrat, sans-serif;
    float: right;
    text-align: right;
    font-size: $font-size-base;
    font-weight: bold;
    color: $color-primary-darker;
    border-radius: 4px;
    padding: 1rem 1.2rem;
    border: 3px solid $color-primary-darker;
    background: $color-primary-lighter;
  }
}
</style>
