import { ref } from "vue";
import { UpdateSupplierProfileDto } from "@/stores/supplier/types";

export const useSupplierProfileData = () => {
  const supplierProfileData = ref<UpdateSupplierProfileDto>({
    id: null,
    contactPerson: "",
    description: "",
    email: "",
    logo: "",
    numEmployees: 0,
    revenue: null,
    reservationHigher: 0,
    reservationLower: 0,
    userId: "",
    orgNumber: "",
    serviceCategories: [],
    certifications: [],
    offices: [],
    sustainabilityGoals: [],
  });

  const updateSupplierProfileData = (
    updatedData: Partial<UpdateSupplierProfileDto>,
  ) => {
    supplierProfileData.value = {
      ...supplierProfileData.value,
      ...updatedData,
    };
  };

  const getSupplierProfileData = <T extends keyof UpdateSupplierProfileDto>(
    key: T,
  ): UpdateSupplierProfileDto[T] | undefined => {
    return supplierProfileData.value[key];
  };

  return {
    supplierProfileData,
    updateSupplierProfileData,
    getSupplierProfileData,
  };
};
