import { useAuthenticationCookie } from "@/composables/useAuthenticationCookie";
import { useUserStore } from "@/stores/user";
import { computed } from "vue";
import { useRouter } from "vue-router";

interface UseUserValidationReturn {
  validateUser: () => Promise<boolean>;
}

export function useUserValidation(): UseUserValidationReturn {
  const { getAuthenticationCookieData } = useAuthenticationCookie();
  const router = useRouter();

  const cookieUserId = computed(() => {
    const cookieData = getAuthenticationCookieData();
    return cookieData?.idTokenClaims?.sub ?? "";
  });

  const validateUser = async (): Promise<boolean> => {
    const userStore = useUserStore();
    const storedUserId = computed(
      () => userStore.userAccount?.idTokenClaims?.sub ?? "",
    );

    if (storedUserId.value && storedUserId.value !== cookieUserId.value) {
      try {
        await router.push({ path: import.meta.env.VITE_APP_REDIRECT_URI });
        return false;
      } catch (error) {
        console.error("Navigation error:", error);
        // TODO: Fallback navigation or user notification can be handled here.
        // await router.push({ name: "error" });
        return false;
      }
    }
    return true;
  };

  return { validateUser };
}
