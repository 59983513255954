export const v1Config = {
  projectId: "utjbsohh",
  dataset: "really-cms-test",
  token: "",
  useCdn: false,
  version: "2021-08-26",
};

export const v2Config = {
  projectId: "0ls0csm4",
  dataset: "production",
  token:
    "skF3I3GTefhnWc6ta4QSMIwXiCUlQ1DHc2UuHfSoL22XgPVAnI6SX0e3Sxh5lLw7EnaIM4RHkd4w0ihgdtI7LFv7bNVF9sYS2PYeH87oQZitkj1M26Yl5mBHNV3YSYrTKxki5Ni2ABxP8mI3QSWc9u1tUCY4o43vbvZ5U4q2T8Qje5BXKeV3",
  useCdn: false,
  ignoreBrowserTokenWarning: true,
  version: "2021-09-03",
};
