<template>
  <form v-show="show" class="form" @submit.prevent="handleOnSubmit" @click.stop>
    <div class="form__email">
      <div class="form__label">Epost</div>
      <div class="form__value">{{ userEmail }}</div>
    </div>
    <div class="form__phone-number">
      <div class="form__label">Telefonnummer</div>
      <v-field
        v-slot="{ field }"
        name="phoneNumber"
        :rules="fieldRules"
        :validate-on-input="true"
      >
        <input v-bind="field" class="form__phone-number-input" />
      </v-field>
    </div>
    <div class="form__buttons">
      <base-button
        :text="'Lagre endringer'"
        :full-width="false"
        :uppercase="false"
        shape="rectangle"
        type="primary"
        class="form__submit-button"
      >
        <template v-if="state.isSubmitting" #icon>
          <b-spinner class="form__button-spinner" />
        </template>
      </base-button>
      <base-button
        :text="'Avbryt'"
        :disabled="state.isSubmitting"
        :uppercase="false"
        shape="rounded"
        type="tertiary"
        class="form__cancel-button"
        @click.prevent="closeUserEditing"
      />
    </div>
  </form>
</template>

<script lang="ts" setup>
import { computed, reactive, watch } from "vue";
import BaseButton from "@/components/Base/BaseButton/BaseButton.vue";
import { cloneDeep } from "lodash";
import { string } from "yup";
import { Field as VField, useForm } from "vee-validate";
import { EditUserDTO, User } from "@/custom-types/GeneralTypes";
import { useUsersApi } from "@/services/api/useUserApi";
import { Organization } from "@/stores/tender/types";

const { editUserInfo } = useUsersApi();

const emit = defineEmits(["updated-user", "close"]);

const props = defineProps<{
  show: boolean;
  user: User;
  organization: Organization;
}>();

const state = reactive({
  errorMessage: "Telefonnummer er påkrevd",
  isSubmitting: false,
});
const { resetForm, setValues, handleSubmit } = useForm({
  initialValues: {
    phoneNumber: "",
  },
});
const userEmail = computed(() => {
  console.log("User", props.user);
  return props.user.email;
});

watch(
  () => props.show,
  (newVal) => {
    if (newVal) {
      resetForm();
      setValues({
        phoneNumber: props.user.phoneNumber,
      });
    }
  },
  { immediate: true },
);

const fieldRules = string().required();
const handleOnSubmit = handleSubmit(async (values) => {
  if (state.isSubmitting) {
    return;
  }
  state.isSubmitting = true;
  const userCopy = cloneDeep(props.user);
  userCopy.phoneNumber = values.phoneNumber;
  const editUserDto: EditUserDTO = {
    userId: userCopy.userId ?? "",
    phoneNumber: userCopy.phoneNumber,
    orgNumber: props.organization.orgNumber,
    name: userCopy.name ?? "",
  };
  try {
    await editUserInfo(editUserDto);
    emit("updated-user", userCopy);
    state.isSubmitting = false;
    closeUserEditing();
  } catch (error) {
    console.log("Error editing user", error);
  }
});

const closeUserEditing = () => {
  emit("close");
};
</script>

<style lang="scss" scoped>
.form {
  padding: 0.5rem 0;

  &__label {
    font-weight: 600;
    font-size: 0.875rem;
    line-height: 1;
  }

  &__value {
    font-weight: 400;
    font-size: 0.875rem;
  }

  &__email,
  &__phone-number {
    margin-top: 1.625rem;
  }

  &__phone-number-input {
    width: 100%;
    max-width: 9rem;
    padding: 0 1rem;
    font-weight: 400;
    height: 2.5rem;
    font-size: 0.875rem;
  }

  &__value,
  &__phone-number-input {
    margin-top: 0.5rem;
  }

  &__buttons {
    display: flex;
    align-items: center;
    margin-top: 1.5625rem;
  }

  &__submit-button {
    border-radius: 4px;
    padding: 0.75rem 1rem !important;
    font-weight: 600;
    font-size: 0.875rem;
    border: none;
    display: flex;
    align-items: center;
    gap: 0.3125rem;
    color: white;
  }

  &__cancel-button {
    margin-left: 1.5625rem;
    font-weight: 600;
    font-size: 0.875rem;
    color: $color-black;
    border: none;
    text-decoration: underline;
    background: transparent;
  }

  &__button-spinner {
    width: 1rem;
    height: 1rem;
  }

  .error-msg {
    color: red;
    font-size: 0.75rem;
  }
}
</style>
