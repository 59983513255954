<template>
  <div class="new-offer-item" @click="onItemClick">
    <img :src="leftIconSrc" alt="New offer" />
    <div class="new-offer-item__left-text">
      <div class="new-offer-item__title">{{ title }}</div>
      <div class="new-offer-item__subtitle">
        {{ subtitle }}
      </div>
    </div>
    <div class="new-offer-item__right-text">{{ rightText }}</div>
    <img :src="rightIconSrc" alt="Arrow right" />
  </div>
</template>

<script>
export default {
  props: {
    leftIconSrc: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    subtitle: {
      type: String,
      default: "",
    },
    rightText: {
      type: String,
      default: "",
    },
    rightIconSrc: {
      type: String,
      required: true,
    },
  },
  emits: ["clicked"],
  methods: {
    onItemClick() {
      this.$emit("clicked");
    },
  },
};
</script>

<style lang="scss" scoped>
.new-offer-item {
  display: flex;
  align-items: center;
  color: $color-black;
  background: $color-primary-pink-dark;
  padding: 1rem;
  text-align: left;
  border-radius: 4px;
  cursor: pointer;

  &:hover {
    background: $color-primary-pink-darker;
  }

  &__left-text {
    flex-grow: 1;
    font-weight: 400;
    font-size: $font-size-base;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    min-width: 0;
  }

  &__title {
    font-weight: 400;
    font-size: $font-size-base;
    line-height: 1.25;
  }

  &__subtitle {
    font-weight: 400;
    font-size: $font-size-sm;
    color: #4e4e4e;
    line-height: 1.25;
  }

  &__right-text {
    flex-shrink: 0;
    font-weight: 400;
    font-size: $font-size-base;
  }
}
</style>
