<template>
  <div v-if="showComponent" class="fixed-cost-section">
    <div class="activities-costs-section__title">
      {{ activitiesCostsSectionTitle }}
    </div>
    <div class="activities-costs-section__description">
      <custom-block-content :blocks="activitiesCostsSectionDescription" />
    </div>
    <div class="fixed-cost-section__title">{{ title }}</div>
    <div class="fixed-cost-section__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <div
      v-if="localFixedCostActivities.length"
      class="fixed-cost-section__list"
    >
      <div
        v-for="(activity, index) in localFixedCostActivities"
        :key="activity.id"
        class="fixed-cost-section__list-item"
      >
        <fixed-cost-item
          :key="componentKey"
          :title="activity.title"
          :description="activity.description"
          :activity="activity"
          :is-first="index === 0"
          :is-last="index === localFixedCostActivities.length - 1"
          :fixed-cost-section-cms="fixedCostSectionCms"
          :activity-form-cms="activityFormCms"
          :read-only="readOnly"
          :service-activities-cms="serviceActivitiesCms"
          @open-edit-item-modal="openNewActivityModal(activity)"
          @open-delete-item-modal="openDeleteActivityItemModal(activity)"
        />
      </div>
    </div>
    <div v-else class="fixed-cost-section__empty-state">
      <empty-state :text="NoActivityText" />
    </div>
    <div v-if="!readOnly" class="fixed-cost-section__new-point-button">
      <secondary-button
        :icon-src="newProductLineButtonIcon"
        :text="newProductLineButtonText"
        @click="openNewActivityModal"
      />
    </div>
    <total-billable-price
      v-if="showTotalBillablePrice"
      :key="totalBillablePriceKey"
      :page-cms="pageCms"
      :fixed-cost-activities="localFixedCostActivities"
      :frequency-options="frequencyOptions"
      :service-activities-cms="serviceActivitiesCms"
      :service-offer="serviceOffer"
    />
    <delete-fixed-cost-item-modal
      v-if="activityToDelete"
      v-model="showDeleteActivityModal"
      :activity="activityToDelete"
      :delete-item-modal-cms="deleteItemModalCms"
      @delete-activity-item="handleDeleteActivityItem"
    />
    <activity-modal
      v-model="showActivityModal"
      :cost-type="ActivityCostType.Fixed"
      :tender="tender"
      :activity="activityToEdit"
      :activity-form-cms="activityFormCms"
      :frequency-options="frequencyOptions"
      :service-activities-cms="serviceActivitiesCms"
      :supplier-info="supplierInfo"
      @new-activity-added="handleNewActivityAdded"
      @add-new-activity="activityToEdit = null"
    />
  </div>
</template>

<script>
import cmsService from "@/services/cmsService";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import DeleteFixedCostItemModal from "./DeleteItemModal/DeleteFixedCostItemModal.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import ActivityModal from "@/components/ActivityModal/ActivityModal.vue";
import TotalBillablePrice from "./TotalBillablePrice.vue";
import { ActivityCostType } from "@/custom-types/types";
import FixedCostItem from "./FixedCostItem.vue";
import { cloneDeep, isEqual, sortBy } from "lodash";

export default {
  name: "FixedCostsSection",
  components: {
    ActivityModal,
    CustomBlockContent,
    DeleteFixedCostItemModal,
    SecondaryButton,
    EmptyState,
    TotalBillablePrice,
    FixedCostItem,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    serviceOffer: {
      type: [Object, null],
      default: null,
    },
    pageCms: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: true,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    supplierInfo: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["new-offer"],
  data() {
    return {
      showActivityModal: false,
      showDeleteActivityModal: false,
      activityToDelete: null,
      activityToEdit: null,
      newFixedCostFormCms: null,
      localFixedCostActivities: [],
      ActivityCostType: ActivityCostType,
      componentKey: 0,
      totalBillablePriceKey: 0,
    };
  },
  computed: {
    showComponent() {
      return !(this.readOnly && this.localFixedCostActivities.length === 0);
    },
    showTotalBillablePrice() {
      return this.localFixedCostActivities.some((activity) =>
        this.hasNoFrequency(activity),
      );
    },
    activitiesCostsSectionCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "activitiesAndCost",
      );
    },
    activitiesCostsSectionTitle() {
      return this.activitiesCostsSectionCms.title;
    },
    activitiesCostsSectionDescription() {
      return this.activitiesCostsSectionCms.content[0];
    },
    activityFormCms() {
      return this.pageCms.forms.find((form) => form.label === "activityForm");
    },
    fixedCostSectionCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "fixedCostsAndActivities",
      );
    },
    title() {
      return this.fixedCostSectionCms.title;
    },
    descriptionContent() {
      return this.fixedCostSectionCms.content[0];
    },
    NoActivityText() {
      return this.fixedCostSectionCms.otherText.find(
        (text) => text.label === "noCostsAndActivitiesAdded",
      ).title;
    },
    newProductLineButtonIcon() {
      const icon = this.fixedCostSectionCms.buttons.find(
        (text) => text.label === "newProductButton",
      ).icons[0].icon;
      return this.getIconUrl(icon).toString() || "";
    },
    newProductLineButtonText() {
      return this.fixedCostSectionCms.buttons.find(
        (text) => text.label === "newProductButton",
      ).title;
    },
    deleteItemModalCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "deleteItemLinePopup",
      );
    },
  },
  watch: {
    "offer.fixedCostActivities": {
      handler(newOfferActivities) {
        this.componentKey++;
        if (
          this.checkIfActivitiesAreEqual(
            newOfferActivities,
            this.localFixedCostActivities,
          )
        ) {
          return;
        }
        this.localFixedCostActivities = cloneDeep(newOfferActivities);
      },
      immediate: true,
      deep: true,
    },
    localFixedCostActivities: {
      handler() {
        if (
          this.checkIfActivitiesAreEqual(
            this.offer.fixedCostActivities,
            this.localFixedCostActivities,
          )
        ) {
          return;
        }
        const offerCopy = cloneDeep(this.offer);
        offerCopy.fixedCostActivities = cloneDeep(
          this.localFixedCostActivities,
        );
        this.$emit("new-offer", offerCopy);
        this.totalBillablePriceKey++;
        this.componentKey++;
      },
      deep: true,
    },
  },
  methods: {
    checkIfActivitiesAreEqual(activitiesA, activitiesB) {
      return isEqual(
        sortBy(activitiesA, (activity) => activity.id),
        sortBy(activitiesB, (activity) => activity.id),
      );
    },
    hasNoFrequency(activity) {
      return !(activity.frequencyGroup === "" && activity.frequency === "");
    },
    getIconUrl(icon) {
      return cmsService.resolveImage(icon);
    },
    openNewActivityModal(activity = null) {
      if (this.isSaving) {
        return;
      }
      this.activityToEdit = activity;
      this.showActivityModal = true;
    },
    openDeleteActivityItemModal(activity) {
      if (this.isSaving) {
        return;
      }
      this.activityToDelete = activity;
      this.showDeleteActivityModal = true;
    },
    handleDeleteActivityItem() {
      this.localFixedCostActivities = this.localFixedCostActivities.filter(
        (activity) => activity.id !== this.activityToDelete.id,
      );
      this.showDeleteActivityModal = false;
      this.activityToDelete = null;
    },
    handleNewActivityAdded(targetActivity) {
      const matchingActivity = this.localFixedCostActivities.find(
        (activity) => activity.id === targetActivity.id,
      );
      if (matchingActivity) {
        const index = this.localFixedCostActivities.findIndex(
          (activity) => activity.id === targetActivity.id,
        );
        this.localFixedCostActivities.splice(index, 1, targetActivity);
      } else {
        this.localFixedCostActivities.push(targetActivity);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.activities-costs-section {
  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
    line-height: 29px;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  &__description {
    font-size: $font-size-sm;
    font-style: italic;
    font-weight: 400;
    margin-bottom: 40px;
  }
}
.fixed-cost-section {
  margin-top: 40px;

  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 16px;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 17px;
    color: #1d1d1d;
    margin-bottom: 16px;
  }

  &__list {
    margin-top: 16px;
  }

  &__empty-state {
    margin-top: 16px;
  }

  &__new-point-button {
    margin-top: 16px;
  }
}
</style>
@/types/AppTypes
