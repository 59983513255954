import { createApp } from "vue";
import { createPinia } from "pinia";
import { createStore } from "vuex";
// @ts-expect-error Description of why the @ts-expect-error is necessary
import apiStore from "./store/modules/api/index.store.js";
// @ts-expect-error Description of why the @ts-expect-error is necessary
import cmsStore from "./store/modules/cms/cms.store.js";
// @ts-expect-error Description of why the @ts-expect-error is necessary
import supplierStore from "./store/modules/supplier/index.store.js";
// @ts-expect-error Description of why the @ts-expect-error is necessary
import offersStore from "./store/modules/offers/index.store.js";
import App from "./App.vue";
import router from "./router";
import "bootstrap/scss/bootstrap.scss";
import "bootstrap-vue-next/dist/bootstrap-vue-next.css";
import "@/assets/scss/_base.scss";
import { createBootstrap } from "bootstrap-vue-next";
import { registerValidateUserGuard } from "@/router/guard";
import * as Sentry from "@sentry/vue";
import { useConfiguration } from "@really/really-events-recorder-components";

if (history.scrollRestoration) {
  history.scrollRestoration = "manual";
}

const app = createApp(App);

Sentry.init({
  app,
  dsn: import.meta.env.VITE_APP_SENTRY_DSN,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [
    "localhost",
    /^https:\/\/testsupplier\.really\.no$/,
    /^https:\/\/leverandor\.really\.no$/,
  ],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
});

const store = createStore({
  modules: {
    cms: cmsStore,
    api: apiStore,
    supplier: supplierStore,
    offers: offersStore,
  },
});
const pinia = createPinia();
app.use(createBootstrap({ components: true, directives: true }));
app.use(router);
app.use(pinia);
app.use(store);

const { configuration } = useConfiguration();
configuration.BASE_URI = import.meta.env.VITE_APP_BASE_URI;
configuration.API_KEY = import.meta.env.VITE_APP_API_KEY;

router.isReady().then(() => {
  registerValidateUserGuard(router);
  app.mount("#app");
});
