<template>
  <select
    v-model="selectedOption"
    class="base-select"
    :class="selectClasses"
    :style="selectStyles"
  >
    <option v-if="placeholder" value="" disabled>
      {{ placeholder }}
    </option>
    <option
      v-for="option in options"
      :key="option.value"
      :value="option.value"
      :disabled="!!option.disabled"
    >
      {{ option.label }}
    </option>
  </select>
</template>

<script>
export default {
  props: {
    modelValue: {
      type: [String, Number, null],
      required: true,
    },
    placeholder: {
      type: String,
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
    borderRadius: {
      type: String,
      default: "", //right-only
    },
    error: {
      type: String,
      default: "",
    },
  },
  emits: ["update:modelValue"],
  computed: {
    selectStyles() {
      const arrowDownUrl = `/images/arrow-down.svg`;
      return {
        backgroundImage: `url(${arrowDownUrl})`,
      };
    },
    selectClasses() {
      const baseClass = "base-select";
      const classes = [`${baseClass}--border-radius-${this.borderRadius}`];
      if (this.error) {
        classes.push(`${baseClass}--error`);
      }
      return classes;
    },
    selectedOption: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.base-select {
  width: 100%;
  height: 2.75rem;
  border: 1px solid $color-black;
  border-radius: 4px;
  padding: 0 3.5rem 0 1.6rem;
  font-weight: 400;
  font-size: $font-size-sm;
  line-height: 1.2;
  user-select: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  background-repeat: no-repeat;
  background-position: calc(100% - 0.5rem) 50%;
  &::-ms-expand {
    display: none;
  }

  &--border-radius-right-only {
    border-radius: 0 4px 4px 0;
  }

  &--error {
    border-color: $color-error;
  }
}
</style>
