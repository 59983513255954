<template>
  <div class="activities-costs-section">
    <!-- Activity costs section -->
    <fixed-cost-section
      :tender="tender"
      :service-offer="serviceOffer"
      :page-cms="pageCms"
      :offer="offer"
      :frequency-options="frequencyOptions"
      :read-only="readOnly"
      :service-activities-cms="serviceActivitiesCms"
      :supplier-info="supplierInfo"
      :is-saving="isSaving"
      @new-offer="updateOffer"
    />
    <!-- Options variable costs section -->
    <variable-cost-section
      :tender="tender"
      :page-cms="pageCms"
      :offer="offer"
      :frequency-options="frequencyOptions"
      :read-only="readOnly"
      :service-activities-cms="serviceActivitiesCms"
      :supplier-info="supplierInfo"
      @new-offer="updateOffer"
    />
  </div>
</template>

<script>
import FixedCostSection from "./FixedCostSection.vue";
import VariableCostSection from "./VariableCostSection.vue";

export default {
  name: "ActivitiesCostsSection",
  components: {
    FixedCostSection,
    VariableCostSection,
  },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    serviceOffer: {
      type: [Object, null],
      default: null,
    },
    pageCms: {
      type: Object,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
    supplierInfo: {
      type: Object,
      required: true,
    },
    isSaving: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["new-offer"],
  computed: {},
  methods: {
    updateOffer(data) {
      this.$emit("new-offer", data);
    },
  },
};
</script>

<style lang="scss" scoped></style>
