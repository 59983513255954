<template>
  <div class="org-number-autocomplete">
    <input
      :id="inputId"
      type="text"
      :value="inputText"
      :placeholder="'Organisasjonsnummer'"
      class="org-number-autocomplete__input"
      @input="handleInput"
      @awesomplete-selectcomplete="handleAwesompleteSelect"
    />
    <b-spinner
      v-if="state.isSearching"
      type="grow"
      class="position-absolute top-50 end-0 me-2 translate-middle-y"
      small
    />
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, reactive, watch, nextTick, ref } from "vue";
import Awesomplete from "awesomplete";
import "awesomplete/awesomplete.css";
import {
  useCompanyRegistryApi,
  Company,
} from "@/services/api/useCompanyRegistryApi";
import { uuidv4 } from "@/utilities/uuid";
import { debounce } from "lodash";

interface MyCustomEvent extends CustomEvent {
  text: {
    label: string;
    value: string;
  };
}

interface Props {
  modelValue: {
    inputText: string;
    selectedCompany: (Company & { searchText: string }) | null;
  };
}

const props = defineProps<Props>();
const emit = defineEmits<{
  (event: "update:modelValue", value: Props["modelValue"]): void;
}>();

const { findCompany } = useCompanyRegistryApi();

const state = reactive({
  searchedCompanies: [] as Company[],
  isSearching: false,
});

const searchRequestToken = ref("");

const inputText = computed({
  get(): string {
    return props.modelValue.inputText;
  },
  set(newVal: string) {
    emit("update:modelValue", {
      inputText: newVal,
      selectedCompany: props.modelValue.selectedCompany,
    });
  },
});

const inputId = `autocomplete-input-${uuidv4()}`;

const getCompanySearchText = (company: Company): string => {
  return `${company.companyName} (Org.nr# ${company.orgNumber})`;
};

const getMatchingCompany = (inputText: string): Company | undefined => {
  return state.searchedCompanies.find(
    (company) => getCompanySearchText(company) === inputText,
  );
};

const handleInput = (event: Event) => {
  const newVal = (event.target as HTMLInputElement).value;
  inputText.value = newVal;
};

const handleAwesompleteSelect = (event: MyCustomEvent) => {
  const selectedValue = event?.text?.value;
  const matchingCompany = getMatchingCompany(selectedValue);
  emit("update:modelValue", {
    ...props.modelValue,
    inputText: selectedValue,
    selectedCompany: matchingCompany
      ? {
          ...matchingCompany,
          searchText: getCompanySearchText(matchingCompany),
        }
      : null,
  });
};

const debouncedSearch = debounce(async (newVal) => {
  if (newVal === undefined || newVal.length === 0) {
    return;
  }

  const searchTerm = newVal.replace(/\s/g, "");

  const currentSearchRequestToken = (searchRequestToken.value = uuidv4());
  try {
    state.isSearching = true;
    const response = await findCompany(searchTerm);
    if (
      currentSearchRequestToken === searchRequestToken.value &&
      response.data.length
    ) {
      state.searchedCompanies = response.data;

      if (searchTerm.length === 9 && response.data.length === 1) {
        const matchingCompany = response.data[0];
        if (matchingCompany) {
          emit("update:modelValue", {
            ...props.modelValue,
            inputText: getCompanySearchText(matchingCompany),
            selectedCompany: {
              ...matchingCompany,
              companyName: matchingCompany.companyName || "",
              orgNumber: matchingCompany.orgNumber || "",
              searchText: getCompanySearchText(matchingCompany),
            },
          });

          state.searchedCompanies = [];
        }
      }
    }
  } catch (error) {
    console.error("Error fetching companies:", error);
    // TODO: Handle error appropriately, e.g., show an error message to the user
  } finally {
    state.isSearching = false;
  }
}, 300);

watch(inputText, debouncedSearch, { immediate: true });

onMounted(() => {
  nextTick(() => {
    const inputElement = document.getElementById(inputId) as HTMLInputElement;
    if (inputElement) {
      const awesomplete = new Awesomplete(inputElement, {
        list: [],
      });

      watch(
        () => state.searchedCompanies,
        (newVal) => {
          if (awesomplete) {
            awesomplete.list = newVal.map(getCompanySearchText);
          }
        },
        { immediate: true },
      );
    } else {
      console.error("Autocomplete input element not found");
    }
  });
});
</script>

<style lang="scss" scoped>
:deep(.awesomplete) {
  display: block;

  li:hover mark,
  mark {
    background: transparent;
    padding: 0;
  }
}

.org-number-autocomplete {
  position: relative;

  &__input {
    width: 100%;
    border: 1px solid #1d1d1d;
    border-radius: 0.25rem;
    padding: 0 1.6rem;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 1.2;
    height: 2.75rem;
  }

  &--error {
    border: 1px solid $color-error;
  }

  &--border-radius-left-only {
    border-radius: 4px 0 0 4px;
  }
}
</style>
