<template>
  <div class="tenders-view">
    <tenders-list
      :loading="isLoading"
      :tenders-list="tendersList"
      :supplier-id="supplier.id"
      :supplier-users="supplierUsers"
    />
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import TendersList from "@/components/MyTenders/TendersList.vue";
import { useUsersApi } from "@/services/api/useUserApi.ts";

const { fetchSupplierUsers } = useUsersApi();

export default {
  components: {
    TendersList,
  },
  props: {
    listType: {
      type: String,
      default: "requests",
    },
  },
  computed: {
    ...mapState(["cms", "api"]),
    ...mapGetters("offers", {
      getAllTenders: "getAllTenders",
      requestedOffers: "getRequestedOffers",
      activeOffers: "getActiveOffers",
      offeredOffers: "getOfferedOffers",
      terminatedOrArchivedOffers: "getTerminatedOrArchivedOffers",
    }),
    ...mapGetters("supplier", {
      supplier: "getSupplier",
    }),
    supplierUsers() {
      return this.$store.getters["api/getSupplierUsers"];
    },
    tendersList() {
      const offersList = [
        {
          title: "accepted_offers",
          offers: this.requestedOffers,
        },
        {
          title: "pending_offers",
          offers: this.offeredOffers,
        },
      ];
      const agreementsList = [
        {
          title: "active_jobs",
          offers: this.activeOffers,
        },
        {
          title: "terminated_or_archived_jobs",
          offers: this.terminatedOrArchivedOffers,
        },
      ];
      const tendersList =
        this.listType === "requests" ? offersList : agreementsList;
      return tendersList.map((section) => {
        return {
          title: `${this.findLabel(section.title)} (${section.offers.length})`,
          tenders: Array.isArray(section.offers)
            ? section.offers.flatMap((offer) => offer.tenders || [])
            : [],
        };
      });
    },
    isLoading() {
      return !this.getAllTenders || !this.supplierUsers;
    },
  },
  created() {
    this.initialize();
  },
  methods: {
    findLabel(otherTextLabel) {
      return this.cms?.agreementsContent?.tabs?.find(
        (text) => text.label === otherTextLabel,
      )?.title;
    },
    async getSupplierUsers() {
      const usersResponse = await fetchSupplierUsers(this.supplier.id);
      this.$store.commit("api/saveSupplierUsers", usersResponse.data);
    },
    initialize() {
      if (!this.supplierUsers) {
        this.getSupplierUsers();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tenders-view {
  margin: 0 3rem 8rem;
}
</style>
