import cmsService from "@/services/cmsService";
import myPricesService from "@/services/myPricesService";
import supplierService from "@/services/supplierService";
import state from "@/store/modules/api/state";
import _ from "underscore";
import { useSystemApi } from "@/services/api/useSystemApi";

const { getServiceTypes } = useSystemApi();

export default {
  initializeStore({ commit }) {
    commit("reloadUser");
    commit("reloadSuppliers");
    commit("reloadForm");
    commit("saveForm", {
      key: "isDisabled",
      value: false,
    });
  },
  signIn: async ({ commit }, user) => {
    // add userId: userId, email: userInfo.email to user object
    const augmentedUser = {
      ...user,
      userId: user?.idTokenClaims?.sub,
      email: user?.idTokenClaims?.emails[0],
    };
    commit("saveUser", augmentedUser);
  },

  signOut({ commit }) {
    commit("deleteUser");
  },

  savePriceReady({ commit }, ready) {
    commit("savePriceReady", ready);
  },

  fetchSupplierInfo: async ({ commit }) => {
    const userId = state.user.userAccount.accountIdentifier;
    return supplierService
      .getSupplier(userId)
      .then((response) => {
        const data = response.data;
        if (data) {
          const supplier = { ...data };
          commit("saveSupplierInfo", supplier);
          commit("saveSupplierDetails", supplier);
        } else {
          // router.push("/error").catch(() => {});
        }
      })
      .catch(() => {
        console.log("sign in error");
        // router.push("/error").catch(() => {});
      });
  },
  fetchSupplierTypes: async ({ commit }) => {
    const response = await getServiceTypes();
    commit("saveSupplierTypes", response.data);
  },
  fetchPropertyTypes({ commit }) {
    myPricesService.fetchPropertyTypes().then((response) => {
      commit("savePropertyTypes", response.data);
    });
  },
  async fetchSupplierSegments({ commit }, segmentsParams) {
    try {
      const response =
        await myPricesService.fetchSupplierSegments(segmentsParams);
      commit("saveSupplierSegments", response.data);
    } catch (error) {
      commit("saveSupplierSegments", {});
      console.error("Error fetching supplier segments:", error);
    }
  },
  async fetchSupplierTypePricingPackages({ commit }, supplierType) {
    try {
      const response =
        await myPricesService.fetchSupplierTypePricingPackages(supplierType);
      commit("saveSupplierTypePricingPackages", response.data);
    } catch (error) {
      commit("saveSupplierTypePricingPackages", []);
      console.error("Error fetching supplier type pricing packages:", error);
    }
  },
  saveSupplierSegments({ commit }, segments) {
    commit("saveSupplierSegments", segments);
  },
  saveSupplierSegmentsParams({ commit }, segmentsParams) {
    commit("saveSupplierSegmentsParams", segmentsParams);
  },
  saveRange({ commit }, range) {
    commit("saveRange", range);
  },
  saveEditedRange({ commit }, range) {
    commit("saveEditedRange", range);
  },
  setYourPricesData({ commit }, pricingRanges) {
    commit("setYourPricesData", pricingRanges);
  },
  saveDeletedRangesForPosting({ commit }, range) {
    commit("saveDeletedRangesForPosting", range);
  },
  saveEditedPriceForPosting({ commit }, price) {
    commit("saveEditedPriceForPosting", price);
  },
  saveDeletedPriceForPosting({ commit }, price) {
    commit("saveDeletedPriceForPosting", price);
  },
  removeEditedPriceForPosting({ commit }, editedPriceArr) {
    commit("removeEditedPriceForPosting", editedPriceArr);
  },
  removeDeletedPriceForPosting({ commit }, deletedPriceArr) {
    commit("removeDeletedPriceForPosting", deletedPriceArr);
  },
  async fetchMyCompany({ commit }, orgNumber) {
    const company = await supplierService
      .findCompany(orgNumber)
      .then((response) => response.data)
      .then((companies) => companies[0]);
    commit("saveCompany", company);
  },
  fetchAllSuppliers({ commit }) {
    supplierService.fetchAllSuppliers().then((response) => {
      const suppliers = _.groupBy(response.data, "supplierTypeLabel");
      for (const serviceName in suppliers)
        commit("saveSupplier", {
          name: serviceName,
          value: suppliers[serviceName],
        });
    });
  },
  clearSuppliers({ commit }) {
    commit("clearSuppliers");
  },

  cacheForm({ commit }) {
    commit("saveForm", {
      key: "cached",
      value: true,
    });
  },
  clearFormCache({ commit }) {
    commit("saveForm", {
      key: "cached",
      value: false,
    });
  },

  async submitTender({ commit }, tenderDetails) {
    commit("saveTender", tenderDetails);
  },
  reloadSupplierPrices({ commit }, reload) {
    commit("reloadSupplierPrices", reload);
  },
  loadingPrices({ commit }, loading) {
    commit("loadingPrices", loading);
  },

  fetchErrors({ commit }) {
    cmsService.getErrors().then((response) => {
      commit("saveErrors", response);
    });
  },
};
