declare global {
  interface Window {
    google?: typeof google;
    googleMapsCallback?: () => void;
  }
}

const API_KEY = import.meta.env.VITE_APP_GOOGLE_API_KEY;
const GOOGLE_MAPS_CALLBACK = "googleMapsCallback";

let isInitialized = "google" in window;
let resolvePromise: (value: typeof google) => void;
// eslint-disable-next-line @typescript-eslint/no-explicit-any
let rejectPromise: (reason?: any) => void;

const promise = new Promise<typeof google>((resolve, reject) => {
  resolvePromise = resolve;
  rejectPromise = reject;
});

export default function initGoogleMaps(): Promise<typeof google> {
  if (isInitialized) {
    return promise;
  }

  isInitialized = true;

  window.googleMapsCallback = () => {
    resolvePromise(window.google!);
  };

  const script = document.createElement("script");
  script.async = true;
  script.defer = true;
  script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places&callback=${GOOGLE_MAPS_CALLBACK}`;
  script.onerror = () => {
    rejectPromise(new Error("Failed to load Google Maps script"));
  };

  const head = document.querySelector("head");
  if (head) {
    head.appendChild(script);
  } else {
    rejectPromise(new Error("Head element not found in the document"));
  }

  return promise;
}
