<template>
  <div class="total-billable-price">
    <div class="total-billable-price__title">{{ title }}</div>
    <div v-for="(item, index) in billablePriceDetails" :key="index">
      <hr v-if="item.isDivider" class="total-billable-price__divider" />
      <billable-price-detail
        v-else
        :key="componentKey"
        :component-key="componentKey"
        :description-text="item.text"
        :price="item.price"
        :currency="currency"
      />
    </div>
  </div>
</template>

<script>
import BillablePriceDetail from "./BillablePriceDetail.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { ActivityFrequency, JobType, PriceType } from "@/custom-types/types";
import {
  getFixedActivitySupplierCost,
  calculateOnceOrMorePerMonthTotal,
  // calculateTotalYearlyCostForFixedCostActivities,
} from "@/utilities/tenderUtilities";
import { updateBillablePriceDetails } from "@/utilities/billablePriceUtilities";
import {
  resolveFrequency,
  serviceActivityCms,
} from "@/utilities/activityUtilities";

export default {
  name: "TotalBillablePrice",
  components: {
    BillablePriceDetail,
  },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    fixedCostActivities: {
      type: Array,
      default: () => [],
    },
    frequencyOptions: {
      type: Object,
      required: false,
      default: () => ({}),
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
    serviceOffer: {
      type: [Object, null],
      default: null,
    },
  },
  data() {
    return {
      currency: "kr",
      averageOrTotalText: "",
      averagePriceText: "Gjennomsnittlig pris",
      averageBillableText: "Gjennomsnittlig fakturerbart",
      averagePricePerYearText: "Gjennomsnittlig pris per år",
      averageBillablePerYearText: "Gjennomsnittlig fakturerbart per år",
      totalBillableText: "Fakturerbart",
      billablePriceDetails: [],
      componentKey: 0,
      singleCostText: "Enkeltkostnader",
      perMonthText: "per måned",
    };
  },
  computed: {
    totalBillablePriceCms() {
      return (
        this.pageCms.pageSections.find(
          (section) => section.label === "totalBillablePrice",
        ) || {}
      );
    },
    title() {
      return this.totalBillablePriceCms.title || "";
    },
    getSingleCostText() {
      try {
        const { serviceActivitiesCms, singleCostText } = this;
        const activityCms = serviceActivityCms(
          serviceActivitiesCms,
          ActivityFrequency.Fixed,
        );

        return activityCms?.title ?? singleCostText;
      } catch (error) {
        console.warn("Error getting single cost text:", error);
        return this.singleCostText;
      }
    },
  },
  watch: {
    fixedCostActivities: {
      handler() {
        this.billablePriceDetails = this.calculateBillablePriceDetails();
      },
      immediate: true,
      deep: true,
    },
    billablePriceDetails: {
      handler() {
        this.componentKey++;
      },
      immediate: true,
      deep: true,
    },
  },
  created() {
    this.billablePriceDetails = this.calculateBillablePriceDetails();
  },
  methods: {
    calculateBillablePriceDetails() {
      const groupPrices = this.calculateGroupPrices();
      return this.buildBillablePriceDetails(groupPrices);
    },

    buildBillablePriceDetails(groupPrices) {
      const details = groupPrices.map(({ text, price }) => ({
        text,
        price: formatCurrency(price),
      }));

      return updateBillablePriceDetails(
        details,
        this.averagePricePerYearText,
        this.getSingleCostText,
      );
    },

    formatBillableText(
      description,
      frequencyGroup,
      frequency,
      limitedPeriod,
      limitedPeriodStartMonth,
      limitedPeriodEndMonth,
      jobType,
    ) {
      const formattedText = this.totalBillableText.trim();

      if (
        frequencyGroup === ActivityFrequency.Fixed &&
        !frequency &&
        jobType === JobType.Single
      ) {
        return `${formattedText} ${description}`;
      } else if (
        frequencyGroup === ActivityFrequency.Fixed &&
        !frequency &&
        jobType === JobType.Repeating
      ) {
        return `${this.getSingleCostText}`;
      }

      const frequencyText = this.getResolvedFrequencyText(
        frequencyGroup,
        frequency,
      );
      const cmsData = this.getServiceActivityCmsData(
        this.serviceActivitiesCms,
        frequencyGroup,
      );
      const limitedPeriodText = this.getLimitedPeriodText(
        limitedPeriod,
        limitedPeriodStartMonth,
        limitedPeriodEndMonth,
      );

      switch (frequencyGroup) {
        case ActivityFrequency.Yearly:
          return `${formattedText} ${frequencyText}`;

        case ActivityFrequency.Fixed:
          return cmsData;

        case ActivityFrequency.Monthly:
          return `${formattedText} ${this.perMonthText}${limitedPeriodText}`;

        default:
          return formattedText;
      }
    },

    getResolvedFrequencyText(frequencyGroup, frequency) {
      return frequency ? this.resolvedFrequency(frequencyGroup, frequency) : "";
    },

    getServiceActivityCmsData(serviceActivitiesCms, frequencyGroup) {
      return (
        serviceActivityCms(serviceActivitiesCms, frequencyGroup)?.title ||
        this.singleCostText
      );
    },

    getLimitedPeriodText(limitedPeriod, startMonth, endMonth) {
      return limitedPeriod
        ? ` (${startMonth?.substring(0, 3)} - ${endMonth?.substring(0, 3)})`
        : "";
    },

    resolvedFrequency(frequencyGroup, frequency) {
      const serviceActivityCmsData = serviceActivityCms(
        this.serviceActivitiesCms,
        frequencyGroup,
      );
      return resolveFrequency(
        serviceActivityCmsData,
        frequencyGroup,
        frequency,
      );
    },

    calculateGroupPrices() {
      const groupMap = this.buildGroupMap();
      if (this.fixedCostActivities.length > 0) {
        const drivingCosts = this.getDrivingCosts();
        if (drivingCosts) {
          groupMap.push(drivingCosts);
        }

        // let totalPrice = calculateTotalYearlyCostForFixedCostActivities(
        //   this.fixedCostActivities,
        //   this.frequencyOptions,
        // );

        // if (drivingCosts) {
        //   totalPrice += drivingCosts.frequencyValue * drivingCosts.price;
        // }

        // groupMap.push({
        //   text: this.averagePricePerYearText,
        //   price: totalPrice,
        // });
      }

      return groupMap;
    },

    getDrivingCosts() {
      const drivingPriceObject = this.serviceOffer?.serviceOfferPrices?.find(
        (item) => item.priceType === PriceType.Driving,
      );

      const result = drivingPriceObject
        ? {
            price: drivingPriceObject.priceSummary[0].supplierCost,
            text: "Kjørekostnader",
            frequencyValue: drivingPriceObject.priceSummary[0].frequencyValue,
          }
        : null;

      return result;
    },

    buildGroupMap() {
      const groupMap = new Map();

      for (const activity of this.fixedCostActivities) {
        const {
          description,
          frequencyGroup,
          limitedPeriod,
          limitedPeriodStartMonth,
          limitedPeriodEndMonth,
          jobType,
        } = activity;

        if (!frequencyGroup) continue;

        let price = getFixedActivitySupplierCost(activity);

        if (!Number.isFinite(price)) continue;

        const text = this.formatBillableText(
          description,
          frequencyGroup,
          activity.frequency,
          limitedPeriod,
          limitedPeriodStartMonth,
          limitedPeriodEndMonth,
          jobType,
        );

        const mapKey = text;

        switch (frequencyGroup) {
          case ActivityFrequency.Fixed:
            price = getFixedActivitySupplierCost(activity);
            break;
          case ActivityFrequency.Yearly:
            price = getFixedActivitySupplierCost(
              activity,
              this.frequencyOptions,
            );
            break;
          case ActivityFrequency.Monthly:
            price = this.calculateMonthlyPrice(activity);
            break;
          default:
            price = getFixedActivitySupplierCost(activity);
        }

        const currentTotal = groupMap.get(mapKey) || {
          text: "",
          price: 0,
        };
        const newTotal =
          currentTotal.price === price ? price : currentTotal.price + price;

        groupMap.set(mapKey, { text, price: newTotal });
      }

      return Array.from(groupMap.values());
    },

    calculateMonthlyPrice(activity) {
      if (activity.limitedPeriod) {
        return getFixedActivitySupplierCost(activity, this.frequencyOptions);
      }

      return calculateOnceOrMorePerMonthTotal(this.fixedCostActivities);
    },
  },
};
</script>

<style scoped lang="scss">
$color-primary: #000000;
$background-color: $color-primary-pink-dark;
$border-color: $color-primary-pink-darker;

@mixin font-style($weight, $size, $line-height, $color) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  color: $color;
}

.total-billable-price {
  box-sizing: border-box;
  width: 466px;
  height: auto;
  background: $background-color;
  border: 1px solid $border-color;
  border-radius: 8px;
  flex: none;
  order: 2;
  flex-grow: 0;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-top: 20px;

  &__divider {
    border: 1px solid $color-primary;
    margin: 0;
    margin-top: 11px;
  }

  &__title {
    @include font-style(600, 16px, 20px, $color-primary);
    text-align: left;
  }
}
</style>
