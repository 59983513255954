const capitalizeFirstLetter = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const firstLetterUppercase = (string: string): string => {
  return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
};

const parseCssStringToPx = (cssString: string) => {
  const value = parseInt(cssString);
  if (cssString.includes("rem")) {
    return value * 16;
  }
  return value;
};

export { capitalizeFirstLetter, firstLetterUppercase, parseCssStringToPx };
