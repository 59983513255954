import { executeApiRequest } from "@/services/api/apiService";
import {
  FinanceApi,
  FinanceApiEndpoints,
} from "@/config/api/finance/financeApi";
import type { InvoiceDTO } from "@/custom-types/GeneralTypes";

export const useFinanceApi = () => {
  const getTenderInvoices = async (params: {
    orgNumber: string;
    bookingNumber: string;
  }) => {
    return executeApiRequest<InvoiceDTO[]>(
      {
        endpointKey: Symbol.for(FinanceApiEndpoints.GET_TENDER_INVOICES),
        config: FinanceApi,
      },
      {
        pathParams: [
          "OrgNumber",
          params.orgNumber,
          "BookingNumber",
          params.bookingNumber,
          "Invoices",
        ],
      },
    );
  };

  return {
    getTenderInvoices,
  };
};
