<template>
  <div ref="agreementResponsible" class="agreement-responsible">
    <div
      class="agreement-responsible__header"
      :class="agreementResponsibleHeaderClasses"
      @click="toggleCollapse"
    >
      <img :src="`/images/24/user.svg`" alt="User" />
      <div class="agreement-responsible__header-title">
        <template v-if="selectedUserName">{{ selectedUserName }}</template>
        <b-spinner v-else type="grow" small />
      </div>
      <img :src="dropdownIconUrl" alt="Dropdown arrow" />
    </div>
    <div
      v-if="dropdownCollapsed"
      class="agreement-responsible__dropdown-content"
    >
      <template v-if="supplierUsers">
        <div
          v-for="user in augmentedSupplierUsers"
          :key="user.id"
          class="agreement-responsible__user"
          @click="handleUserClick(user.id)"
        >
          <div class="agreement-responsible__user-icon-wrapper">
            <img
              v-if="checkIfUserIsSelected(user.id)"
              :src="`/images/24/check.svg`"
              alt="Check"
            />
          </div>
          <div class="agreement-responsible__user-info">
            <div>{{ user.name }}</div>
            <div class="agreement-responsible__user-contact-info">
              {{ user.description }}
            </div>
          </div>
        </div>
      </template>
      <div v-else class="agreement-responsible__loader">
        <b-spinner type="grow" small />
      </div>
    </div>
    <new-agreement-responsible-modal
      v-model="showAgreementResponsibleModal"
      :user="userToSetAsResponsible"
      :tender="tender"
      :price-request="priceRequest"
      :supplier-id="supplierId"
    />
  </div>
</template>

<script>
import userService from "@/services/userService";
import NewAgreementResponsibleModal from "@/components/AgreementResponsible/NewAgreementResponsibleModal.vue";
import vClickOutside from "v-click-outside";

const { bind, unbind } = vClickOutside.directive;

export default {
  components: { NewAgreementResponsibleModal },
  props: {
    supplierId: {
      type: Number,
      required: true,
    },
    tender: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      dropdownCollapsed: false,
      showAgreementResponsibleModal: false,
      userIdToSetAsResponsible: null,
    };
  },
  computed: {
    supplierUsers() {
      return this.$store.getters["api/getSupplierUsers"];
    },
    supplierRequest() {
      return this.tender.priceRequests
        .flatMap((priceRequest) => priceRequest.supplierRequests)
        .find(
          (supplierRequest) => supplierRequest.supplierId === this.supplierId,
        );
    },
    priceRequest() {
      return this.tender.priceRequests.find((priceRequest) =>
        priceRequest.supplierRequests.find(
          (supplierRequest) => supplierRequest.id === this.supplierRequest?.id,
        ),
      );
    },
    selectedUserId() {
      return this.supplierRequest?.agreementOwnerUserId;
    },
    dropdownIconUrl() {
      const icon = this.dropdownCollapsed ? "arrow-up" : "arrow-down";
      return `/images/24/${icon}.svg`;
    },
    agreementResponsibleHeaderClasses() {
      return this.dropdownCollapsed
        ? ["agreement-responsible__header--collapsed"]
        : [];
    },
    augmentedSupplierUsers() {
      const users = [
        {
          id: "",
          name: "Alle brukere ansvarlig",
          description: "Alle brukere mottar varslinger",
        },
      ];
      this.supplierUsers?.forEach((user) => {
        users.push({
          id: user.userId,
          name: user.displayName,
          description: this.getUserDescription(user),
        });
      });
      return users;
    },
    selectedUserName() {
      const selectedUser = this.augmentedSupplierUsers.find((user) =>
        this.checkIfUserIsSelected(user.id),
      );
      return selectedUser ? selectedUser.name : "";
    },
    userToSetAsResponsible() {
      return this.supplierUsers?.find(
        (user) => user.userId === this.userIdToSetAsResponsible,
      );
    },
  },
  watch: {
    dropdownCollapsed(newVal) {
      if (newVal) {
        bind(this.$refs.agreementResponsible, {
          value: () => {
            this.dropdownCollapsed = false;
          },
        });
      } else {
        unbind(this.$refs.agreementResponsible);
      }
    },
  },
  created() {
    if (!this.supplierUsers) {
      this.getSupplierUsers();
    }
  },
  methods: {
    checkIfUserIsSelected(userId) {
      return (
        (!this.selectedUserId && !userId) || this.selectedUserId === userId
      );
    },
    toggleCollapse() {
      this.dropdownCollapsed = !this.dropdownCollapsed;
    },
    getUserDescription(user) {
      const records = [];
      const mobilePhone = user.mobilePhone?.trim();
      const email = user.email?.trim();
      if (mobilePhone) {
        records.push(mobilePhone);
      }
      if (email) {
        records.push(email);
      }
      return records.join(" - ");
    },
    async getSupplierUsers() {
      const usersResponse = await userService.fetchSupplierUsers(
        this.supplierId,
      );
      this.$store.commit("api/saveSupplierUsers", usersResponse.data);
    },
    handleUserClick(userId) {
      if (!this.checkIfUserIsSelected(userId)) {
        this.userIdToSetAsResponsible = userId;
        this.showAgreementResponsibleModal = true;
      }
      this.dropdownCollapsed = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-responsible {
  position: relative;
  max-width: 28rem;
  font-weight: 400;

  &__header {
    background-color: #ffffff;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    cursor: pointer;
    border-radius: 6px;
    border: 1px solid #1d1d1d;

    &:hover {
      background-color: #f5f5f5;
    }

    &--collapsed {
      border-radius: 6px 6px 0 0;
    }
  }

  &__header-title {
    flex-grow: 1;
    font-size: $font-size-base;
  }

  &__dropdown-content {
    background-color: #ffffff;
    border-radius: 0 0 6px 6px;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    z-index: 99;
  }

  &__user {
    background-color: #ffffff;
    padding: 0.75rem 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;

    &:hover {
      background-color: #f5f5f5;
    }

    &:last-child {
      border-radius: 0 0 6px 6px;
    }
  }

  &__user-icon-wrapper {
    width: 2rem;
  }

  &__user-contact-info {
    font-size: $font-size-sm;
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
