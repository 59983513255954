<template>
  <div class="info-tag-group">
    <InfoTag
      v-for="(item, index) in selectedSustainabilityGoals"
      :key="index"
      :title="item?.title"
      :subtitle="item?.subtitle"
      :icon-src="sustainabilityGoalsIcon"
    />
  </div>
</template>

<script setup lang="ts">
import { PropType, computed } from "vue";
import { useCmsStore } from "@/stores/cms";
import { ContentType } from "@/custom-types/CmsContentTypes";

const cmsStore = useCmsStore();

interface Goal {
  title: string;
  goalLabel: string;
  subtitle: string;
  selected: boolean;
}

const props = defineProps({
  sustainabilityGoals: {
    type: Array as PropType<Goal[]>,
    default: () => [],
    required: true,
  },
  sustainabilityGoalsIcon: {
    type: String,
    default: "",
  },
});

const sustainabilityGoalsCMS =
  cmsStore[ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS];

const selectedSustainabilityGoals = computed(() => {
  return props.sustainabilityGoals
    .filter((goal) => goal.selected)
    .map((goal) => ({
      ...goal,
      title:
        sustainabilityGoalsCMS?.find(
          (sustainabilityGoal) => sustainabilityGoal.label === goal.goalLabel,
        )?.title || goal.goalLabel,
    }));
});
</script>

<style lang="scss" scoped>
.info-tag-group {
  display: flex;
  flex-wrap: wrap;
  gap: 0.5rem;
}
</style>
