<template>
  <div class="amount-section">
    <p class="amount-section__amount">{{ amount }}</p>
    <p class="amount-section__text">{{ text }}</p>
  </div>
</template>

<script>
export default {
  name: "AmountSection",
  props: {
    amount: {
      type: [String, Number],
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
// Define Variables
$font-600: 600;
$font-400: 400;
$amount-color: #000000;
$text-color: #787878;

.amount-section {
  display: flex;
  flex-direction: column;
  margin-left: auto;
  text-align: right;
  width: 10rem;
}

.amount-section__amount {
  font-weight: $font-600;
  font-size: $font-size-base;
  line-height: 20px;
  color: $amount-color;
  margin-bottom: 0.3rem;
  margin-top: 0.25rem;
  text-align: end;
}

.amount-section__text {
  font-weight: $font-400;
  font-size: $font-size-sm;
  line-height: 17px;
  color: $text-color;
  margin-bottom: 0.5rem;
  text-align: end;
}
</style>
