<template>
  <div class="location-delivery-area-form">
    <div class="location-delivery-area-form__form">
      <div class="location-delivery-area-form__input-wrapper">
        <span
          class="location-delivery-area-form__icon location-delivery-area-form__icon--left"
        >
          <img :src="locationLeftIconSrc" alt="Location Icon" />
        </span>
        <input
          v-model="location"
          :name="'location'"
          :placeholder="locationPlaceholder"
          :class="locationInputClasses"
          :disabled="isSaving"
          @input="onLocationInput"
        />
        <span
          class="location-delivery-area-form__icon location-delivery-area-form__icon--right"
        >
          <img
            v-if="location.length > 0"
            :src="exitIconSrc"
            alt="Exit Icon"
            @click="clearLocationInput"
          />
        </span>
        <AddressSuggestionListBox
          v-if="formattedSuggestions.length > 0"
          :suggestions="formattedSuggestions"
          :sort-list="true"
          @selected-suggestion="handleSelectedSuggestion"
        />
      </div>
      <div class="location-delivery-area-form__input-wrapper">
        <span
          class="location-delivery-area-form__icon location-delivery-area-form__icon--left"
        >
          <img :src="deliveryAreaLeftIconSrc" alt="Location Icon" />
        </span>
        <input
          v-model="deliveryArea"
          :name="'deliveryArea'"
          :placeholder="deliveryAreaPlaceholder"
          :class="deliveryAreaInputClasses"
          :disabled="isSaving"
          type="number"
          @input="onDeliveryAreaInput"
        />
        <span
          class="location-delivery-area-form__icon location-delivery-area-form__icon--right"
        >
          <img
            v-if="deliveryArea.length > 0"
            :src="exitIconSrc"
            alt="Exit Icon"
            @click="clearDeliveryAreaInput"
          />
        </span>
      </div>
      <div class="location-delivery-area-form__delete-button-wrapper">
        <button
          class="location-delivery-area-form__delete-button"
          @click.prevent="onDelete"
        >
          <img :src="deleteIconSrc" alt="Delete Icon" />
        </button>
      </div>
    </div>
    <div class="location-delivery-area-form__input--error">
      <InputError
        v-if="locationError"
        class="location-delivery-area-form__input--error"
        :text="locationError"
      />
      <InputError
        v-if="deliveryAreaError"
        class="location-delivery-area-form__input--error"
        :text="deliveryAreaError"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType, computed, ref, watch } from "vue";
import InputError from "@/components/InputError/InputError.vue";
import { SupplierAddress, Address } from "@/stores/supplier/types";
import AddressSuggestionListBox from "./AddressSuggestionListBox.vue";
import useGmapsPlacesService from "@/composables/useGmapsPlacesService";
import { SearchSuggestion } from "@/custom-types/GeneralTypes";

const props = defineProps({
  locationValue: {
    type: String as PropType<string>,
    default: "",
  },
  locationPlaceholder: {
    type: String as PropType<string>,
    default: "",
  },
  locationLeftIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  deleteIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaPlaceholder: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaLeftIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  isSaving: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  exitIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
});

const {
  getPlaceDetails,
  getAddressComponents,
  getAutocompletePredictions,
  getAutocompletePredictionAddressText,
} = useGmapsPlacesService();

const emit = defineEmits([
  "update:address",
  "update:deliveryArea",
  "delete",
  "validate",
  "deleteAndAddNew",
]);

const location = ref("");
const deliveryArea = ref("");
const locationError = ref("");
const deliveryAreaError = ref("");
const locationErrorMessage = ref("Du må oppgi en gyldig adresse.");
const deliveryAreaErrorMessage = ref("Du må oppgi leveranseområde.");
const formattedSuggestions = ref<SearchSuggestion[]>([]);
const selectedAddress = ref<SupplierAddress>({} as SupplierAddress);

const isHandlingSelectedSuggestion = ref(false);

watch(
  location,
  async (newValue) => {
    if (isHandlingSelectedSuggestion.value) {
      return;
    }

    if (newValue.trim() === "") {
      formattedSuggestions.value = [];
      return;
    }

    const response = await getAutocompletePredictions(newValue);

    if (response) {
      formattedSuggestions.value = response.results.map((prediction) => ({
        id: prediction.place_id,
        suggestionText: getAutocompletePredictionAddressText(prediction),
        icon: "/images/addressIcon.svg",
      }));
    }
  },
  { immediate: true },
);

const handleSelectedSuggestion = async (suggestion: SearchSuggestion) => {
  isHandlingSelectedSuggestion.value = true;

  const placeResult = await getPlaceDetails(suggestion.id as string);

  if (!placeResult) {
    isHandlingSelectedSuggestion.value = false;
    return;
  }

  const addressComponents = getAddressComponents(
    placeResult.address_components,
  );
  const addressName = `${addressComponents.route} ${addressComponents.streetNumber}`;
  // generate 4 number postalCode if it is not available
  if (!addressComponents.postalCode) {
    addressComponents.postalCode = Math.floor(1000 + Math.random() * 9000)
      .toString()
      .substring(0, 4);
  }
  const address: Address = {
    id: null,
    address: addressName.trim(),
    city: addressComponents.city,
    postalCode: addressComponents.postalCode,
    county: addressComponents.county,
  };

  selectedAddress.value = {
    address: address,
    reservationKilometers: parseFloat(deliveryArea.value),
  };

  formattedSuggestions.value = [];
  location.value = address?.address || "";
  validateLocation(location.value);
  validateDeliveryArea(deliveryArea.value);
  updateSelectedAddress();

  setTimeout(() => {
    isHandlingSelectedSuggestion.value = false;
  }, 1000);
};

const locationInputClasses = computed(() => ({
  "location-delivery-area-form__input": true,
  "location-delivery-area-form__input--with-left-icon":
    props.locationLeftIconSrc,
  "location-delivery-area-form__input--with-right-icon": props.exitIconSrc,
  "location-delivery-area-form__input--error": locationError.value,
}));

const deliveryAreaInputClasses = computed(() => ({
  "location-delivery-area-form__input": true,
  "location-delivery-area-form__input--with-left-icon":
    props.deliveryAreaLeftIconSrc,
  "location-delivery-area-form__input--with-right-icon": props.exitIconSrc,
  "location-delivery-area-form__input--error": deliveryAreaError.value,
}));

const onLocationInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  validateLocation(target.value);
};

const onDeliveryAreaInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  const value = target.value;
  const isNumberInput = target.type === "number";

  let parsedValue: string | number = isNumberInput ? parseFloat(value) : value;

  if (isNumberInput) {
    parsedValue = validateNumberInput(parsedValue);
    target.value = parsedValue.toString();
  }

  emit("update:deliveryArea", parsedValue);
  validateDeliveryArea(parsedValue.toString());
  validateLocation(location.value);
};

const validateNumberInput = (value: string | number): number => {
  const numericValue = typeof value === "string" ? parseFloat(value) : value;
  return isNaN(numericValue) || numericValue < 0 ? 0 : numericValue;
};

const updateSelectedAddress = () => {
  emit("update:address", selectedAddress.value.address);
};

const onDelete = () => {
  if (
    location.value.toString().trim() === "" &&
    deliveryArea.value.toString().trim() === ""
  ) {
    return;
  } else if (
    location.value.toString().trim() !== "" ||
    deliveryArea.value.toString().trim() !== ""
  ) {
    location.value = "";
    deliveryArea.value = "";
    locationError.value = "";
    deliveryAreaError.value = "";
    return;
  }
  emit("delete");
};

const validateLocation = (value: string) => {
  if (value === "") {
    locationError.value = locationErrorMessage.value;
    return false;
  } else {
    locationError.value = "";
    return true;
  }
};

const validateDeliveryArea = (value: string) => {
  if (value === "") {
    deliveryAreaError.value = deliveryAreaErrorMessage.value;
    return false;
  } else {
    deliveryAreaError.value = "";
    return true;
  }
};

const clearLocationInput = () => {
  location.value = "";
};

const clearDeliveryAreaInput = () => {
  deliveryArea.value = "";
};

const validate = async () => {
  const isLocationValid = validateLocation(location.value);
  const isDeliveryAreaValid = validateDeliveryArea(deliveryArea.value);
  const validationResult = {
    isValid: isLocationValid && isDeliveryAreaValid,
    selectedAddress: selectedAddress.value,
  };
  return validationResult;
};

defineExpose({
  validate,
});
</script>

<style scoped lang="scss">
.location-delivery-area-form {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__form {
    display: grid;
    grid-template-columns: 2fr 1fr auto;
    grid-gap: 0.5rem;
    flex-wrap: wrap;
  }

  &__input-wrapper {
    position: relative;
    border-radius: 4px;
    border: 1px solid #f5f5f5;
    background: var(--White, #fff);
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.1);
  }

  &__input {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 0.25rem;
    background: var(--White, #fff);
    font-weight: 400;
    width: 100%;
    font-size: $font-size-sm;
    line-height: 1.2;
    height: 2.75rem;
    border-radius: 4px;
    border: 1px solid #dad1db;
    background: var(--White, #fff);
    border: none;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;

    &:focus {
      outline: none;
      border: none;
    }

    &--with-left-icon {
      padding-left: 3.6rem;

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &--with-right-icon {
      padding-right: 3.6rem;

      img {
        width: 24px;
        height: 24px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.1);
        }
      }
    }

    &--error {
      display: flex;
      flex-direction: row;
      gap: 1rem;
      margin-top: 0;
    }

    &:disabled {
      background: var(--Grey-1, #f5f5f5);
    }
  }

  &__icon {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: $font-size-sm;

    &--left {
      left: 1rem;
      cursor: pointer;
    }

    &--right {
      right: 1rem;
      cursor: pointer;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }

  &__delete-button-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
  }

  &__delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
