<template>
  <div class="price-robot-header__section ms-4">
    <div class="price-robot-header__title">
      {{ headerContentProp.title }}
    </div>
    <img
      :class="[`price-robot-header__image--${headerContentProp.imgClass}`]"
      :src="instructionImage"
      :alt="headerContentProp.title"
    />
    <div :class="[`price-robot-header__text--${headerContentProp.textClass}`]">
      <p class="price-robot-header__text-content">
        {{ headerInstructionText }}
        <strong>{{ segmentsSectionText }}</strong>
      </p>
    </div>
  </div>
</template>

<script setup>
import { computed, toRefs, defineProps } from "vue";
import { useStore } from "vuex";

// Props
const props = defineProps({
  headerContentProp: {
    type: Object,
    default: () => ({}),
  },
});

// Convert prop to reactive refs
const { headerContentProp } = toRefs(props);

// Computed properties and methods refactored to use Composition API
const store = useStore();
const serviceTypes = computed(() => store.getters["cms/getServiceTypes"]);
// const selectedSupplierType = computed(
// 	() => store.getters["cms/getSelectedSupplierType"]
// );
const supplierSegmentsParams = computed(
  () => store.state.api.supplierSegmentsParams,
);
const supplierTypes = computed(() => store.state.api.supplierTypes);
const propertyTypes = computed(() => store.state.api.propertyTypes);

const instructionImage = computed(
  () => `/images/${headerContentProp.value.instructionImage}`,
);

// Replace methods with computed properties
const headerInstructionText = computed(() => {
  const text = headerContentProp.value.instructionText;
  return text
    ? text.replace("Renhold og Bolig", segmentsSectionText.value)
    : "";
});

const segmentsSectionText = computed(() => {
  if (headerContentProp.value.textClass !== "segments") {
    return "";
  }
  const supplierLabel = supplierSegmentsParams.value.supplierType.label;
  const propertyLabel = supplierSegmentsParams.value.propertyType.label;
  const supplierType = getTranslated(
    supplierTypes.value,
    supplierLabel,
    "sysLabelText",
  );
  const propertyType = getTranslated(
    propertyTypes.value,
    propertyLabel,
    "sysLabel",
  );

  return headerContentProp.value.title === "Dine priser:"
    ? `${supplierType} og ${propertyType}`
    : `«${supplierType} - ${propertyType}»`;
});

// Methods
const getTranslated = (types, label, sysLabelProp) => {
  const type = types.find((type) => type.sysLabel === label);
  return type ? getSysLabelText(type[sysLabelProp]) : "";
};

const getSysLabelText = (sysLabel) => {
  if (!serviceTypes.value) return "";
  const serviceType = serviceTypes.value.find(
    (service) => service.label === sysLabel,
  );
  return serviceType ? serviceType.name : "";
};
</script>

<style lang="scss" scoped>
$padding-standard: 1rem;
$font-size-standard: 1rem;
$font-size-large: 1.25rem;
$font-size-small: 0.75rem;
$border-radius-standard: 0.6875rem;
$instruction-text-width: 22rem;
$instruction-text-position-left: 10.6rem;
$instruction-text-position-top: -2.5rem;
$color-primary-medium: #666;
$white-color: #fff;

@mixin price-robot-header__image {
  margin-left: $padding-standard;
  z-index: 2;
}

@mixin price-robot-header__text {
  font-style: italic;
  font-size: $font-size-base;
  color: $color-primary-medium;
  background-color: $white-color;
  border-radius: $border-radius-standard;
  line-height: 1.2;
  display: flex;
  align-items: center;
  letter-spacing: 0.01em;
  padding: $padding-standard;
  position: absolute;
  width: $instruction-text-width;
  left: $instruction-text-position-left;
  top: $instruction-text-position-top;
}

.price-robot-header {
  &__section {
    display: flex;
    padding-left: $padding-standard;
    align-items: baseline;
    gap: 2rem;
    position: relative;
  }

  &__title {
    margin-top: $padding-standard;
    font-weight: bold;
    font-size: $font-size-large;
  }

  &__image {
    margin-left: $padding-standard;
    z-index: 2;

    &--robot-head-img {
      @include price-robot-header__image;
    }

    &--general-robot-head-img {
      margin-left: 8.6rem;
      margin-top: 5rem;
      z-index: 2;
    }
  }

  &__text {
    font-style: italic;
    font-size: $font-size-base;
    color: $color-primary-medium;
    background-color: $white-color;
    border-radius: $border-radius-standard;
    line-height: 1.2;
    display: flex;
    align-items: center;
    letter-spacing: 0.01em;
    padding: $padding-standard;
    position: absolute;
    width: $instruction-text-width;
    left: $instruction-text-position-left;
    top: $instruction-text-position-top;

    &--industry {
      @include price-robot-header__text;
    }

    &--segments {
      @include price-robot-header__text;
      width: 28rem;
      left: 13rem;
      top: -1rem;
    }

    &--prices {
      @include price-robot-header__text;
      width: 28rem;
      left: 13rem;
      top: -1rem;
    }

    &--general-prices {
      @include price-robot-header__text;
      left: 13rem;
      top: 4rem;
    }

    &--save-prices {
      @include price-robot-header__text;
      width: 28rem;
      left: 17rem;
      top: -2rem;
    }

    &--about-us {
      @include price-robot-header__text;
      left: 14rem;
    }

    &--offices {
      @include price-robot-header__text;
      left: 16rem;
    }
  }

  &__text-content {
    margin-bottom: 0;
    font-weight: 700;
    font-style: italic;
    font-size: $font-size-small;
    color: $color-primary;
    padding-left: 1rem;
  }
}
</style>
