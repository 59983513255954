import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

export default {
  async fetchSupplierUsers(supplierId) {
    return Axios.get(`${apiConfig.apiConfig.getSupplierUsers}/${supplierId}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    });
  },
  async getUserByEmail(email) {
    return Axios.get(`${apiConfig.apiConfig.getUserByEmailApi}${email}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    });
  },
};
