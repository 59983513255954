import { Tender, SupplierRequest } from "@/stores/tender/types";

export const findSupplierRequest = (
  tender: Tender,
  supplierId: number,
): SupplierRequest | undefined => {
  return tender.priceRequests?.[0]?.supplierRequests?.find(
    (request) => request.supplierId === supplierId,
  );
};
