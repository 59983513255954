import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

export default {
  fetchSupplierTypes() {
    return Axios.get(`${apiConfig.apiConfig.getSupplierTypesApi}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    }).catch((reason) => console.error(reason));
  },
  fetchPropertyTypes() {
    return Axios.get(`${apiConfig.apiConfig.getPropertyTypesApi}`, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    }).catch((reason) => console.error(reason));
  },
  fetchSupplierSegments(segmentsParams) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierSegmentsApi}${segmentsParams.supplierType.id}/Segments`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  fetchSupplierSegmentRanges(rangeParams) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierRangesApi}${rangeParams.segmentId}/Supplier/${rangeParams.supplierId}/Ranges`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  fetchSupplierPriceGroups(pricesParams) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierPriceGroupsApi}${pricesParams.propertyType.id}/SupplierType/${pricesParams.supplierType.id}/Supplier/${pricesParams.supplierId}/PriceGroups`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  fetchFixedSupplierPriceGroups(pricesParams) {
    return Axios.get(
      `${apiConfig.apiConfig.getFixedSupplierPriceGroupsApi}${pricesParams.propertyType.id}/supplierType/${pricesParams.supplierType.id}/FixedPriceGroups`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  fetchSupplierPrices(pricesParams) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierPricesApi}${pricesParams.propertyType.id}/SupplierType/${pricesParams.supplierType.id}/Supplier/${pricesParams.supplierId}/Prices`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  saveRanges(rangesArray) {
    const rangesData = {
      ranges: rangesArray,
    };
    return Axios.post(apiConfig.apiConfig.saveRangesApi, rangesData, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    });
  },
  fetchSupplierReservations(supplierId, supplierTypeId) {
    return Axios.get(
      `${apiConfig.apiConfig.getSupplierReservationsApi}${supplierId}/supplierType/${supplierTypeId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  saveSupplierReservations(supplierId, supplierTypeId, reservations) {
    return Axios.put(
      `${apiConfig.apiConfig.saveSupplierReservationsApi}${supplierId}/supplierType/${supplierTypeId}`,
      reservations,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    );
  },
  fetchSupplierTypePricingPackages(supplierType) {
    return Axios.get(
      `${apiConfig.apiConfig.getPricingPackagesApi}${supplierType}/Packages`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },
  savePrices(pricingArray) {
    const prices = {
      Pricing: pricingArray,
    };
    return Axios.post(apiConfig.apiConfig.savePricingApi, prices, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    });
  },
};
