import {
  TenderApi,
  SystemApi,
  TenderApiEndpoints,
  SystemApiEndpoints,
  CustomerServiceEndpoints,
  CustomerServiceApi,
} from "@/config/api/tender/tenderApi";
import { executeApiRequest } from "@/services/api/apiService";
import {
  Tender,
  ServiceOffer,
  InvoiceRecipient,
  ChangeOfferStateDto,
  CompleteTenderDto,
  InformationRequestMessageDto,
  AgreementResponsibleDto,
  RejectPriceRequestDto,
  sendOfferDto,
  FrameAgreement,
  PublicNote,
  TenderArchiveDto,
  CloneableOffer,
} from "@/stores/tender/types";

const fetchSupplierOffers = async (params: {
  tenderId: number;
  supplierId: number;
}) => {
  return executeApiRequest<ServiceOffer[]>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.FETCH_SUPPLIER_OFFERS),
      config: TenderApi,
    },
    {
      pathParams: [params.tenderId, "Supplier", params.supplierId, "Offers"],
    },
  );
};

const fetchTenderOffersBySupplier = async (supplierId: number) => {
  return executeApiRequest<Tender[]>(
    {
      endpointKey: Symbol.for(
        TenderApiEndpoints.FETCH_TENDER_OFFERS_BY_SUPPLIER,
      ),
      config: TenderApi,
    },
    {
      pathParams: [supplierId],
    },
  );
};

const completeTenderOfferBySupplier = async (params: {
  tenderId: number;
  serviceOfferId: number;
  data: CompleteTenderDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(
        TenderApiEndpoints.COMPLETE_TENDER_OFFER_BY_SUPPLIER,
      ),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [params.tenderId, "Offer", params.serviceOfferId, "Complete"],
    },
  );
};

const sendInformationRequestMessage = async (params: {
  tenderId: number;
  data: InformationRequestMessageDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(
        TenderApiEndpoints.SEND_INFORMATION_REQUEST_MESSAGE,
      ),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [params.tenderId, "InformationRequest"],
    },
  );
};

const getTenderConfirmationUri = async (params: {
  tenderId: number;
  offerId: number;
  audience: "Customer" | "Supplier";
}) => {
  return executeApiRequest<Blob>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_TENDER_CONFIRMATION_URI),
      config: TenderApi,
    },
    {
      pathParams: [
        params.tenderId,
        "offer",
        params.offerId,
        "OrderConfirmation",
        params.audience,
      ],
      responseType: "blob",
    },
  );
};

const sendRejectPriceRequest = async (params: {
  tenderId: number;
  priceRequestId: number;
  data: RejectPriceRequestDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.SEND_REJECT_PRICE_REQUEST),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [
        params.tenderId,
        "PriceRequest",
        params.priceRequestId,
        "Reject",
      ],
    },
  );
};

const getTenderOffer = async (params: {
  tenderId: number;
  offerId: number;
}) => {
  return executeApiRequest<ServiceOffer>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_TENDER_OFFER),
      config: TenderApi,
    },
    {
      pathParams: [params.tenderId, "offer", params.offerId],
    },
  );
};

const getTender = async (
  params: { supplierId: number; tenderId: number },
  options?: { signal?: AbortSignal },
) => {
  return executeApiRequest<Tender>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_TENDER),
      config: TenderApi,
    },
    {
      pathParams: [params.tenderId, "Supplier", params.supplierId],
      signal: options?.signal,
    },
  );
};

const sendOffer = async (params: {
  tenderId: number;
  offerId: number;
  data: sendOfferDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.SEND_OFFER),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [params.tenderId, "offer", params.offerId, "change"],
    },
  );
};

const deleteSupplierOffer = async (params: {
  tenderId: number;
  offerId: number;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.DELETE_SUPPLIER_OFFER),
      config: TenderApi,
    },
    {
      pathParams: [params.tenderId, "Offer", params.offerId],
    },
  );
};

const withdrawSupplierOffer = async (params: {
  tenderId: number;
  offerId: number;
  data: ChangeOfferStateDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.WITHDRAW_SUPPLIER_OFFER),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [params.tenderId, "offer", params.offerId, "change"],
    },
  );
};

const setAgreementResponsible = async (params: {
  tenderId: number;
  priceRequestId: number;
  supplierId: number;
  data: AgreementResponsibleDto;
}) => {
  return executeApiRequest<void>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.SET_AGREEMENT_RESPONSIBLE),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [
        params.tenderId,
        "request",
        params.priceRequestId,
        "supplier",
        params.supplierId,
      ],
    },
  );
};

const getInvoiceRecipient = async (externalTenderId: string) => {
  return executeApiRequest<InvoiceRecipient>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_INVOICE_RECIPIENT),
      config: TenderApi,
    },
    {
      pathParams: [externalTenderId],
    },
  );
};

const submitTender = async (data: Tender) => {
  return executeApiRequest<Tender>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.SUBMIT_TENDER),
      config: TenderApi,
    },
    { data: data },
  );
};

const createSupplierTenderOffer = async (data: ServiceOffer) => {
  return executeApiRequest<ServiceOffer>(
    {
      endpointKey: Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
      config: SystemApi,
    },
    { data: data },
  );
};

const updateSupplierTenderOffer = async (params: {
  serviceOfferId: number;
  data: ServiceOffer;
}) => {
  return executeApiRequest<ServiceOffer>(
    {
      endpointKey: Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
      config: SystemApi,
    },
    {
      data: params.data,
      pathParams: [params.serviceOfferId],
    },
  );
};

const getActivityFormFrequencyOptions = async () => {
  return executeApiRequest<string[]>({
    endpointKey: Symbol.for(
      SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS,
    ),
    config: SystemApi,
  });
};

const getFrameAgreement = async (params: {
  organizationId: number;
  frameAgreementId: number;
}) => {
  return executeApiRequest<FrameAgreement>(
    {
      endpointKey: Symbol.for(CustomerServiceEndpoints.GET_FRAME_AGREEMENT),
      config: CustomerServiceApi,
    },
    {
      pathParams: [
        params.organizationId,
        "frameAgreement",
        params.frameAgreementId,
      ],
    },
  );
};

const getPublicNotes = async (params: {
  priceRequestId: number;
  supplierId: number;
}) => {
  return executeApiRequest<PublicNote[]>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_PUBLIC_NOTES),
      config: TenderApi,
    },
    {
      pathParams: [
        "PriceRequest",
        params.priceRequestId,
        "Supplier",
        params.supplierId,
        "Note",
      ],
    },
  );
};

const updateTenderArchive = async (params: {
  tenderId: number;
  requestId: number;
  flag: string;
  data: TenderArchiveDto;
}) => {
  return executeApiRequest<Tender>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.UPDATE_TENDER_ARCHIVE),
      config: TenderApi,
    },
    {
      data: params.data,
      pathParams: [
        params.tenderId,
        "supplierRequest",
        params.requestId,
        "archive",
        params.flag,
      ],
    },
  );
};

const getCloneableOffers = async (params: {
  supplierId: number;
  serviceCategoryId: number;
}) => {
  return executeApiRequest<CloneableOffer[]>(
    {
      endpointKey: Symbol.for(TenderApiEndpoints.GET_CLONEABLE_OFFERS),
      config: TenderApi,
    },
    {
      pathParams: [
        "Supplier",
        params.supplierId,
        "ServiceCategory",
        params.serviceCategoryId,
        "Offers",
        "Cloneable",
      ],
    },
  );
};

type UseTenderApi = () => {
  fetchSupplierOffers: typeof fetchSupplierOffers;
  fetchTenderOffersBySupplier: typeof fetchTenderOffersBySupplier;
  completeTenderOfferBySupplier: typeof completeTenderOfferBySupplier;
  sendInformationRequestMessage: typeof sendInformationRequestMessage;
  getTenderConfirmationUri: typeof getTenderConfirmationUri;
  sendRejectPriceRequest: typeof sendRejectPriceRequest;
  getTenderOffer: typeof getTenderOffer;
  getTender: typeof getTender;
  sendOffer: typeof sendOffer;
  deleteSupplierOffer: typeof deleteSupplierOffer;
  withdrawSupplierOffer: typeof withdrawSupplierOffer;
  setAgreementResponsible: typeof setAgreementResponsible;
  getInvoiceRecipient: typeof getInvoiceRecipient;
  submitTender: typeof submitTender;
  createSupplierTenderOffer: typeof createSupplierTenderOffer;
  updateSupplierTenderOffer: typeof updateSupplierTenderOffer;
  getActivityFormFrequencyOptions: typeof getActivityFormFrequencyOptions;
  getFrameAgreement: typeof getFrameAgreement;
  getPublicNotes: typeof getPublicNotes;
  updateTenderArchive: typeof updateTenderArchive;
  getCloneableOffers: typeof getCloneableOffers;
};

export const useTenderApi: UseTenderApi = () => {
  return {
    fetchSupplierOffers,
    fetchTenderOffersBySupplier,
    completeTenderOfferBySupplier,
    sendInformationRequestMessage,
    getTenderConfirmationUri,
    sendRejectPriceRequest,
    getTenderOffer,
    getTender,
    sendOffer,
    deleteSupplierOffer,
    withdrawSupplierOffer,
    setAgreementResponsible,
    getInvoiceRecipient,
    submitTender,
    createSupplierTenderOffer,
    updateSupplierTenderOffer,
    getActivityFormFrequencyOptions,
    getFrameAgreement,
    getPublicNotes,
    updateTenderArchive,
    getCloneableOffers,
  };
};
