<template>
  <base-price-calculator
    :title="otherText.title"
    :description="otherText.description"
    :billable-price-title="otherText.billablePriceTitle"
    :billable-price-description="otherText.billablePriceDescription"
    :billable-price="formattedBillablePrice"
  >
    <div>
      <div
        v-if="getInputErrorMessageToDisplay()"
        class="variable-price-calculator__error"
      >
        <input-error :text="getInputErrorMessageToDisplay()" />
      </div>
      <div class="variable-price-calculator__input-label">
        {{ pricePerPieceInputCms.title }}
      </div>
      <div class="variable-price-calculator__input-wrapper">
        <base-text-and-select-input
          :select-options="activityPieces"
          :text-input-right-text="pricePerPieceInputCms.unit"
          text-input-field-name="pricePerItem"
          text-input-width="6.75rem"
          select-input-width="10.25rem"
        />
      </div>
    </div>
  </base-price-calculator>
</template>

<script setup>
import BaseTextAndSelectInput from "@/components/ActivityModal/BaseTextAndSelectInput.vue";
import { cloneDeep } from "lodash";
import BasePriceCalculator from "@/components/ActivityModal/BasePriceCalculator.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { getCmsItem, getCmsItemText } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import Mustache from "mustache";
import { computed, watch } from "vue";

const emit = defineEmits(["new-form-values"]);

const props = defineProps({
  formErrors: {
    type: Object,
    required: true,
  },
  formValues: {
    type: Object,
    required: true,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  hasFrameAgreement: {
    type: Boolean,
    default: false,
  },
  frameAgreementCut: {
    type: Number,
    default: 0,
  },
});

const priceCalculatorCms = computed(() => {
  return getCmsItem(
    props.activityFormCms.formSections,
    "variableCostPriceCalculator",
  );
});
const serviceActivityCms = computed(() => {
  return getCmsItem(props.serviceActivitiesCms, "variable");
});
const otherText = computed(() => {
  return {
    title: getCmsItemText(priceCalculatorCms.value.otherText, "title"),
    description: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "description",
    ),
    billablePriceTitle: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "billablePriceTitle",
    ),
    billablePriceDescription: Mustache.render(
      getCmsItemText(
        priceCalculatorCms.value.otherText,
        "billablePriceDescription",
      ),
      {
        reallyCut: supplierCommission.value,
      },
    ),
  };
});
const pricePerPieceInputCms = computed(() => {
  return getCmsItem(priceCalculatorCms.value.inputs, "pricePerItem");
});
const activityPieces = computed(() => {
  return serviceActivityCms.value.activityItems.map((item) => {
    return {
      label: `Per ${item.singular}`,
      value: item.label,
    };
  });
});
const supplierCommission = computed(() => {
  if (props.hasFrameAgreement) {
    return props.frameAgreementCut;
  }
  return props.formValues.supplierCommission;
});
const billablePrice = computed(() => {
  const defaultPrice = 0;
  console.log("E", props.formErrors);
  if (props.formErrors.pricePerItem) {
    return defaultPrice;
  }
  const itemPrice = parseFloat(props.formValues.pricePerItem);
  console.log("item price", itemPrice);
  if (isNaN(itemPrice)) {
    return defaultPrice;
  }
  return ((100 - supplierCommission.value) / 100) * itemPrice;
});
const formattedBillablePrice = computed(() => {
  const selectedPiece = activityPieces.value.find(
    (piece) => piece.value === props.formValues.itemsUnit,
  );
  const pieceLabel = selectedPiece ? selectedPiece.label.toLowerCase() : "";
  const priceUnit = pricePerPieceInputCms.value.unit;
  return `${formatCurrency(billablePrice.value)} ${priceUnit} ${pieceLabel}`;
});

watch(
  () => activityPieces.value,
  () => {
    const pieceUnitsValues = activityPieces.value.map(
      (pieceUnit) => pieceUnit.value,
    );
    if (!pieceUnitsValues.includes(props.formValues.itemsUnit)) {
      const formValuesCopy = cloneDeep(props.formValues);
      formValuesCopy.itemsUnit = activityPieces.value[0].value;
      emit("new-form-values", formValuesCopy);
    }
  },
  { deep: true, immediate: true },
);

const getInputErrorMessageToDisplay = () => {
  const templateInputError = getCmsItemText(
    props.activityFormCms.otherText,
    "priceCalculatorInputError",
  );
  if (!props.formErrors.pricePerItem) {
    return "";
  }
  return Mustache.render(templateInputError, {
    inputLabel: `<span style="font-style: italic;font-weight: 600;">${pricePerPieceInputCms.value.title}</span>`,
  });
};
</script>

<style lang="scss" scoped>
.variable-price-calculator {
  &__error {
    margin-bottom: 1.5rem;
  }
}
</style>
