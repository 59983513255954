<template>
  <div class="tender-detail-header">
    <div class="tender-detail-header__slot-content">
      <slot name="agreementResponsible" />
      <slot name="tabs" />
    </div>
    <div class="tender-detail-header__actions">
      <BaseButton
        v-if="showGiveOfferButton"
        :icon="AddDocumentIcon"
        text="Gi tilbud"
        variant="accent"
        size="sm"
        :uppercase="true"
        @click="emit('on-new-offer-click')"
      />

      <BaseButton
        v-if="showMarkAsDoneButton"
        :icon="CheckWhiteIcon"
        text="Marker som utført"
        variant="primary"
        size="sm"
        :uppercase="true"
        @click="emit('on-mark-as-done-click')"
      />

      <BaseButton
        v-if="showRejectPriceRequestButton"
        :icon="CloseIcon"
        text="Avvis forespørsel"
        variant="tertiary"
        size="sm"
        :uppercase="true"
        @click="emit('on-price-request-rejected-click')"
      />

      <BaseButton
        v-if="showCreatePriceRequestButton"
        :icon="PlusIcon"
        text="Gi nytt tilbud"
        variant="tertiary"
        size="sm"
        :uppercase="true"
        @click="emit('on-create-price-request-click')"
      />
    </div>
  </div>
</template>

<script setup lang="ts">
import BaseButton from "@/components/Base/BaseButton/BaseButton.vue";
import AddDocumentIcon from "@/assets/img/AddDocument.svg";
import CheckWhiteIcon from "@/assets/img/CheckWhite.svg";
import CloseIcon from "@/assets/img/Close.svg";
import PlusIcon from "@/assets/img/Plus.svg";
import { Tender } from "@/stores/tender/types";

interface Props {
  supplierId: number;
  tender: Tender;
  showRejectPriceRequestButton: boolean;
  showMarkAsDoneButton: boolean;
  showGiveOfferButton: boolean;
  showCreatePriceRequestButton: boolean;
}

withDefaults(defineProps<Props>(), {
  supplierId: 0,
  tender: () => ({}) as Tender,
  showRejectPriceRequestButton: false,
  showMarkAsDoneButton: false,
  showGiveOfferButton: false,
  showCreatePriceRequestButton: false,
});

const emit = defineEmits<{
  (e: "on-new-offer-click"): void;
  (e: "on-create-price-request-click"): void;
  (e: "on-mark-as-done-click"): void;
  (e: "on-price-request-rejected-click"): void;
}>();
</script>

<style scoped lang="scss">
.tender-detail-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 64px;
  background: #fff;
  box-shadow:
    0px -1px 0px 0px #d0d0d0 inset,
    0px 1px 0px 0px #d0d0d0 inset;

  &__slot-content {
    display: flex;
    flex-direction: column;
    gap: 24px;
    flex: 1;
    text-align: left;
    font-size: 16px;
    font-weight: 600;
    color: #000;
  }

  &__info {
    flex: 1;
    text-align: center;
    font-size: 14px;
    color: #555;
  }

  &__tender-id,
  &__customer,
  &__valid-through {
    margin: 5px 0;
  }

  &__actions {
    flex: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    gap: 8px;
    margin-top: 75px;
  }
}
</style>
