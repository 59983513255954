<template>
  <div class="segment-range-inputs">
    <v-form :validation-schema="validationSchema">
      <div
        v-for="(range, index) in segmentAreaRanges"
        :key="range.id"
        class="segment-range-inputs__range-inputs row d-flex g-3"
        @mouseover="showRangeDeleteIndex = index"
        @mouseout="showRangeDeleteIndex = null"
      >
        <div class="col-auto">
          <label :for="`inputFrom-${range.id}`" class="col-form-label"
            >Fra</label
          >
        </div>
        <div class="col-auto">
          <div class="input-group">
            <v-field
              :id="`inputFrom-${range.id}`"
              v-model="range.rangeFrom"
              type="number"
              :name="`rangeFrom-${index}`"
              :validate-on-blur="true"
              :validate-on-input="true"
              :validate-on-change="true"
              class="form-control"
              placeholder="0"
            />
            <span class="input-group-text">{{
              unitRangeLabel(range.unit)
            }}</span>
          </div>
          <error-message :name="`rangeFrom-${index}`" class="form-error-text" />
        </div>

        <div class="col-auto">
          <label :for="`inputTo-${range.id}`" class="col-form-label">Til</label>
        </div>
        <div class="col-auto">
          <div class="input-group">
            <v-field
              :id="`inputTo-${range.id}`"
              v-model="range.rangeTo"
              type="number"
              :name="`rangeTo-${index}`"
              :validate-on-blur="true"
              :validate-on-input="true"
              :validate-on-change="true"
              class="form-control"
              placeholder="0"
            />
            <span class="input-group-text">{{
              unitRangeLabel(range.unit)
            }}</span>
          </div>
          <error-message :name="`rangeTo-${index}`" class="form-error-text" />
        </div>
        <div class="col-auto segment-range-inputs__delete-range-icon-wrapper">
          <transition name="fade">
            <button
              v-if="showRangeDeleteIndex === index"
              class="segment-range-inputs__delete-range-icon"
              @mouseout="showRangeDeleteIndex = null"
              @mouseover="showRangeDeleteIndex = index"
              @click.prevent="openDeleteModal(range)"
            >
              <img :src="`/images/${getDeleteRangeIcon(range)}`" />
            </button>
          </transition>
        </div>
      </div>
    </v-form>
  </div>
</template>

<script setup>
import { ref, watch, defineProps, defineEmits, onMounted, computed } from "vue";
import {
  defineRule,
  Form as VForm,
  Field as VField,
  ErrorMessage,
  useForm,
} from "vee-validate";
import * as yup from "yup";
import { useStore } from "vuex";

import { isOverlap } from "@/utilities/segmentRangeUtils";

const RANGE_ID_THRESHOLD = 99900;

const store = useStore();

const props = defineProps({
  segmentAreaRangesProp: {
    type: Array,
    default: () => [],
    required: true,
  },
});

const emit = defineEmits([
  "range-update",
  "open-delete-modal",
  "validation-error",
]);

const segmentAreaRanges = ref([]);
const showRangeDeleteIndex = ref(null);

onMounted(() => {
  if (Array.isArray(props.segmentAreaRangesProp)) {
    segmentAreaRanges.value = props.segmentAreaRangesProp;
  }
});

watch(
  () => props.segmentAreaRangesProp,
  async (newValue) => {
    if (Array.isArray(newValue)) {
      segmentAreaRanges.value = newValue;
      await validateRanges();
    }
  },
  { deep: true },
);

const validRanges = (value, [segmentAreaRanges, rangeIndex]) => {
  if (value === undefined || value === null || value === "") {
    return true;
  }
  const errorMessage = "Segmentområder kan ikke overlappe";

  // Get the current range being validated
  const currentRange = segmentAreaRanges[rangeIndex];
  if (!currentRange) return true;

  // Check for overlaps with other ranges
  const isOverlapping = segmentAreaRanges.some((otherRange, index) => {
    if (index === rangeIndex) return false;

    return isOverlap(currentRange, otherRange);
  });

  return isOverlapping ? errorMessage : true;
};

// Reactive validation schema
const validationSchema = computed(() => {
  const errorMessage = "Segmentområder kan ikke overlappe";
  const shape = {};
  segmentAreaRanges.value.forEach((_, index) => {
    shape[`rangeFrom-${index}`] = yup
      .number()
      .typeError(`Fra må være et gyldig tall`)
      .test({
        name: `rangeTo-${index}`,
        message: errorMessage,
        test: (value, ctx) => {
          if (value === undefined || value === null || value === "") {
            return true;
          }
          if (validRanges(value, [segmentAreaRanges.value, index]) === true) {
            return true;
          } else {
            return ctx.createError({
              message: errorMessage,
            });
          }
        },
      });
    shape[`rangeTo-${index}`] = yup
      .number()
      .typeError(`Til må være et gyldig tall`)
      .test({
        name: `rangeTo-${index}`,
        message: errorMessage,
        test: (value, ctx) => {
          if (value === undefined || value === null || value === "") {
            return true;
          }
          if (validRanges(value, [segmentAreaRanges.value, index]) === true) {
            return true;
          } else {
            return ctx.createError({
              message: errorMessage,
            });
          }
        },
      });
  });
  return yup.object().shape(shape);
});

// Define the validRanges rule
defineRule("validRanges", validRanges);

// Use form hook
useForm({ validationSchema });

const validateRanges = async () => {
  const hasOverlap = (
    await Promise.all(
      segmentAreaRanges.value.map((range, index) => {
        return validRanges(range, [segmentAreaRanges.value, index]);
      }),
    )
  ).some((result) => result !== true);

  emit("validation-error", hasOverlap);

  if (!hasOverlap) {
    updateRangesToStore(segmentAreaRanges.value);
  }
  return hasOverlap;
};

const updateRangesToStore = (segmentAreaRanges) => {
  const newRanges = segmentAreaRanges.filter(
    (range) => range.id > RANGE_ID_THRESHOLD && range.id < 100000,
  );

  newRanges.forEach((newRange) => {
    const foundRange = store.state.api.ranges.find(
      (range) => range.id === newRange.id,
    );
    saveRangeToStore(foundRange || newRange);
  });

  segmentAreaRanges
    .filter((range) => range.id < RANGE_ID_THRESHOLD)
    .forEach((editedRange) => {
      const foundRange = store.state.api.ranges.find(
        (range) => range.id === editedRange.id,
      );
      const foundEditedRange = store.state.api.editedRanges.find(
        (range) => range.id === editedRange.id,
      );

      if (!foundRange) {
        saveEditedRangeToStore(editedRange);
      } else if (foundEditedRange) {
        if (isRangeChanged(foundEditedRange, editedRange)) {
          saveEditedRangeToStore(editedRange);
        }
      } else {
        saveEditedRangeToStore(editedRange);
      }
    });
};

const isRangeChanged = (foundEditedRange, editedRange) => {
  if (!foundEditedRange || !editedRange) {
    return false;
  }

  const { rangeFrom: foundRangeFrom, rangeTo: foundRangeTo } = foundEditedRange;
  const { rangeFrom, rangeTo } = editedRange;

  return foundRangeFrom !== rangeFrom || foundRangeTo !== rangeTo;
};

const formatAndSaveRange = (range, action) => {
  const formattedRange = {
    ...range,
    rangeFrom: parseFloat(String(range.rangeFrom).replace(/,/g, ".")),
    rangeTo: parseFloat(String(range.rangeTo).replace(/,/g, ".")),
  };
  store.dispatch(action, formattedRange);
};

const saveEditedRangeToStore = (range) => {
  formatAndSaveRange(range, "api/saveEditedRange");
};

const saveRangeToStore = (range) => {
  formatAndSaveRange(range, "api/saveRange");
};

const unitRangeLabel = (unit) => {
  return unit === "m2" ? "㎡" : unit;
};

const getDeleteRangeIcon = (range) => {
  const pricingArr = store.state.api.pricing;

  if (!pricingArr) {
    return "my-prices/delete-red.svg";
  }

  const mergedPricesArray = pricingArr.flatMap((ranges) => ranges.ranges);
  const filteredRangePrices = mergedPricesArray.flatMap((priceRange) => {
    return Object.values(priceRange).filter((pricePkgValues) =>
      store.state.api.supplierTypePricingPackages.some(
        (pkg) =>
          pkg.packageLabel in priceRange &&
          pricePkgValues.rangeId &&
          pricePkgValues.rangeId.includes(range.id) &&
          pricePkgValues.segmentId &&
          pricePkgValues.segmentId.includes(range.segmentRangeId),
      ),
    );
  });

  const containPrices = filteredRangePrices.some(
    (priceObj) =>
      priceObj.price !== undefined &&
      priceObj.kilometerPrice !== undefined &&
      priceObj.numberOfTrips !== undefined,
  );

  return containPrices
    ? "my-prices/delete-grey.svg"
    : "my-prices/delete-red.svg";
};

const openDeleteModal = (range) => {
  if (getDeleteRangeIcon(range) === "my-prices/delete-red.svg") {
    emit("open-delete-modal", range);
  }
};
</script>

<style lang="scss" scoped>
.segment-range-inputs {
  margin-top: 1rem;
  display: flex;
  flex-wrap: wrap;
  width: max-content;

  &__range-inputs {
    margin-bottom: 0.5rem;
  }

  &__delete-range-icon-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 0.5rem;
    width: 2rem;
  }

  &__delete-range-icon {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transition: all 0.2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .form-control {
    border: 0.1rem solid $color-primary-dark;
    border-radius: 0;
    font-size: $font-size-sm;
  }

  .col-auto {
    padding-right: 0;
  }

  .col-form-label {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  .input-group {
    width: 10rem;
  }

  .input-group-text {
    background-color: $color-primary-light;
    border: 0.1rem solid $color-primary-dark;
    border-left: 0.1rem solid $color-primary-light;
    font-size: $font-size-sm;
    font-weight: 700;
    border-radius: 0;
    color: white;
  }

  .form-error-text {
    color: #dc3545;
    font-size: 10px;
    max-width: 10rem;
    display: flex;
    margin-top: 0.5rem;
    text-align: center;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
