import axios, { AxiosInstance, ResponseType } from "axios";
import { ApiConfig } from "@/config/api/types";

export const createApiClient = (
  apiConfig: ApiConfig,
  responseType: ResponseType = "json",
): AxiosInstance => {
  return axios.create({
    baseURL: `${apiConfig.baseUri}${apiConfig.contextPath}`,
    timeout: 3000000,
    headers: Object.fromEntries(
      apiConfig.headers.map((header) => [header.key, header.value]),
    ),
    responseType,
  });
};
