<template>
  <button class="secondary-button" @click="handleButtonClick">
    <img
      v-if="iconSrc"
      :src="iconSrc"
      class="secondary-button__icon"
      alt="Button icon"
    />
    <span>{{ text }}</span>
  </button>
</template>

<script>
export default {
  props: {
    iconSrc: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      required: true,
    },
  },
  emits: ["click"],
  methods: {
    handleButtonClick() {
      this.$emit("click");
    },
  },
};
</script>

<style lang="scss" scoped>
.secondary-button {
  padding: 0.825rem 1.7rem;
  background-color: $color-primary-pink-dark;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  border: none;
  font-weight: 400;
  font-size: $font-size-sm;

  &__icon {
    flex-shrink: 0;
    width: 1.25rem;
    height: 1.25rem;
  }

  &:hover {
    background: $color-primary-pink-darker;
  }
}
</style>
