<template>
  <div class="profile-input">
    <div class="profile-input__input-wrapper">
      <span
        v-if="leftText"
        class="profile-input__text profile-input__text--left"
      >
        {{ leftText }}
      </span>
      <textarea
        :value="modelValue"
        :placeholder="placeholder"
        :class="inputClasses"
        :disabled="disabled"
        rows="5"
        @input="onInput"
      />
      <span
        v-if="rightText"
        class="profile-input__text profile-input__text--right"
      >
        {{ rightText }}
      </span>
    </div>
    <div class="profile-input__button-wrapper">
      <ProfileButton
        :loading="isSaving"
        :disabled="isSaving"
        class="profile-input__button"
        @click="onSave"
      >
        {{ saveButtonText }}
      </ProfileButton>
      <ProfileButton
        :is-cancel="true"
        :disabled="isSaving"
        class="profile-input__button"
        @click="onCancel"
      >
        {{ cancelButtonText }}
      </ProfileButton>
    </div>
    <InputError
      v-if="errorMessage"
      class="profile-input__error"
      :text="errorMessage"
    />
  </div>
</template>

<script setup lang="ts">
import { toRef, PropType, computed } from "vue";
import { useField } from "vee-validate";
import ProfileButton from "./ProfileButton.vue";

const props = defineProps({
  modelValue: {
    type: String as PropType<string>,
    required: true,
  },
  name: {
    type: String as PropType<string>,
    required: true,
  },
  placeholder: {
    type: String as PropType<string>,
    default: "",
  },
  disabled: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  saveButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  cancelButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  leftText: {
    type: String as PropType<string>,
    default: "",
  },
  rightText: {
    type: String as PropType<string>,
    default: "",
  },
  editable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  isSaving: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue", "save", "cancel"]);

const { errorMessage } = useField(toRef(props, "name"));

const inputClasses = computed(() => ({
  "profile-input__input": true,
  "profile-input__input--with-left-text": props.leftText,
  "profile-input__input--with-right-text": props.rightText,
  "profile-input__input--error": errorMessage.value,
}));

const onInput = (event: Event) => {
  const target = event.target as HTMLInputElement;
  emit("update:modelValue", target.value);
};

const onSave = () => {
  emit("save");
};

const onCancel = () => {
  emit("cancel");
};
</script>

<style lang="scss" scoped>
.profile-input {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;

  &__value-display {
    font-weight: 600;
    color: var(--Black, #1d1d1d);
    font-family: Montserrat, sans-serif;
    font-size: 16px;
    line-height: normal;
  }

  &__input-wrapper {
    position: relative;
  }

  &__input {
    display: flex;
    padding: 12px 16px;
    align-items: center;
    gap: 0.25rem;
    border-radius: 0.25rem;
    border: 1px solid rgba(0, 0, 0, 0.5);
    background: var(--White, #fff);
    font-weight: 400;
    width: 100%;
    font-size: $font-size-sm;
    line-height: 1.2;
    height: auto;

    &--with-left-text {
      padding-left: 3.6rem;
    }

    &--with-right-text {
      padding-right: 3.6rem;
    }

    &--error {
      border-color: var(--Error, #ff0000);
    }

    &:disabled {
      background: var(--Grey-1, #f5f5f5);
    }
  }

  &__text {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-weight: 400;
    font-size: $font-size-sm;

    &--left {
      left: 1rem;
    }

    &--right {
      right: 1rem;
    }
  }

  &__button-wrapper {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
  }
}
</style>
