// src/composables/useFeedbackPanel.ts
import { ref, provide, inject, InjectionKey } from "vue";

interface FeedbackPanelContext {
  openFeedbackPanel: (tenderId: number) => void;
  isOpen: (tenderId: number) => boolean;
}

const FeedbackPanelKey: InjectionKey<FeedbackPanelContext> =
  Symbol("FeedbackPanel");

export function provideFeedbackPanel() {
  const openPanels = ref<Set<number>>(new Set());

  const openFeedbackPanel = (tenderId: number) => {
    openPanels.value.add(tenderId);
  };

  const isOpen = (tenderId: number) => openPanels.value.has(tenderId);

  const context: FeedbackPanelContext = {
    openFeedbackPanel,
    isOpen,
  };

  provide(FeedbackPanelKey, context);

  return context;
}

export function useFeedbackPanel() {
  const context = inject(FeedbackPanelKey);
  if (!context) {
    console.warn(
      "FeedbackPanel context not found. Providing default implementation.",
    );
    return {
      openFeedbackPanel: (tenderId: number) =>
        console.warn(
          `Attempted to open feedback panel for tender ${tenderId}, but context is missing.`,
        ),
      isOpen: () => false,
    };
  }
  return context;
}
