import { useSupplierProfileData } from "@/composables/useSupplierProfileData";
import { useCompanyProfileFormData } from "@/composables/useCompanyProfileFormData";
import { useSupplierApi } from "@/services/api/useSupplierApi";
import {
  UpdateSupplierProfileDto,
  ServiceCategoryWithOnlyIdAndCategoryLabel,
} from "@/stores/supplier/types";

export const useSupplierProfileActions = () => {
  const { updateSupplierProfileData } = useSupplierProfileData();
  const { updateCompanyProfileFormData } = useCompanyProfileFormData();
  const { updateSupplierProfile, createSupplier } = useSupplierApi();

  const saveSupplierProfile = async (
    updatedSupplierData: UpdateSupplierProfileDto,
  ) => {
    try {
      const sanitizedSupplierData =
        sanitizeServiceCategories(updatedSupplierData);
      const supplierId = sanitizedSupplierData?.id
        ? parseInt(sanitizedSupplierData.id.toString(), 10)
        : undefined;
      if (supplierId === undefined) {
        // Handle the case when supplierId is undefined
        throw new Error("Supplier ID is missing");
      }
      const response = await updateSupplierProfile({
        supplierId: supplierId,
        data: sanitizedSupplierData,
      });
      handleSuccessResponse(response);

      return response;
    } catch (error) {
      handleSaveError(error);
      throw error;
    }
  };

  const createSupplierProfile = async (
    supplierData: UpdateSupplierProfileDto,
  ) => {
    try {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const response: any = await createSupplier(supplierData);
      handleSuccessResponse(response);

      return response;
    } catch (error) {
      handleSaveError(error);
      throw error;
    }
  };

  const sanitizeServiceCategories = (
    supplierData: UpdateSupplierProfileDto,
  ): UpdateSupplierProfileDto => {
    if (
      supplierData.serviceCategories &&
      supplierData.serviceCategories.length === 0
    ) {
      return supplierData;
    }

    const sanitizedServiceCategories = supplierData.serviceCategories
      ?.map((category): ServiceCategoryWithOnlyIdAndCategoryLabel | null => {
        if (typeof category === "object" && category.categoryLabel) {
          return {
            id: category.serviceCategoryId || category?.id,
            categoryLabel: category.categoryLabel,
          };
        }
        return null;
      })
      .filter(
        (category): category is ServiceCategoryWithOnlyIdAndCategoryLabel =>
          category?.categoryLabel !== null,
      );

    return {
      ...supplierData,
      serviceCategories: sanitizedServiceCategories ?? null,
    };
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSuccessResponse = (response: any) => {
    // TODO: Handle the response
    console.log("Supplier profile saved successfully:", response.status);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSaveError = (error: any) => {
    console.error("Failed to save supplier profile:", error);
    // TODO: Handle the error, show an error message, or redirect to an error page
  };

  return {
    saveSupplierProfile,
    createSupplierProfile,
    updateSupplierProfileData,
    updateCompanyProfileFormData,
  };
};
