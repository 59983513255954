<template>
  <div class="service-report-detail-wrapper">
    <div class="service-report-detail-wrapper__files">
      <div class="service-report-detail-wrapper__files-title">
        {{ serviceReportContent.serviceReportDetails.filesText }}
      </div>
      <div
        v-for="(attachment, index) in serviceReport.serviceReportAttachments"
        :key="index"
        class="service-report-detail-wrapper__attachment"
      >
        <div class="service-report-detail-wrapper__attachment-name">
          {{ attachment.fileName }}
        </div>
        <a :href="attachment.fileUri" target="_blank">
          {{ serviceReportContent.serviceReportDetails.read.text }}
          <img
            :src="`/images/${serviceReportContent.serviceReportDetails.read.icon}`"
          />
        </a>
        <a
          :href="attachment.fileUri"
          target="_blank"
          :download="attachment.fileName"
        >
          {{ serviceReportContent.serviceReportDetails.download.text }}
          <img
            :src="`/images/${serviceReportContent.serviceReportDetails.download.icon}`"
          />
        </a>
      </div>
    </div>
    <div class="service-report-detail-wrapper__comments">
      <div class="service-report-detail-wrapper__comment-title">
        {{ serviceReportContent.serviceReportDetails.commentText }}
      </div>
      <div class="service-report-detail-wrapper__comment-text">
        {{ serviceReport.comment }}
      </div>
    </div>
    <div class="service-report-detail-wrapper__dates">
      <div class="service-report-detail-wrapper__dates-date">
        <div class="service-report-detail-wrapper__date-label">
          {{ serviceReportContent.serviceReportDetails.serviceDateText }}
        </div>
        <div class="service-report-detail-wrapper__date-value">
          {{ resolveDate(serviceReport.serviceDate) }}
        </div>
      </div>
      <div class="service-report-detail-wrapper__dates-date__next-service-date">
        <div class="service-report-detail-wrapper__date-label">
          {{ serviceReportContent.serviceReportDetails.nextServiceDateText }}
        </div>
        <div class="service-report-detail-wrapper__date-value">
          {{ resolveNextServiceDate(serviceReport.nextServiceDate) }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "ServiceReportDetails",

  props: {
    serviceReport: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapGetters("cms", {
      serviceReportContent: "getServiceReportContent",
    }),
  },

  methods: {
    resolveDate(date) {
      return new Date(date).toLocaleDateString("en-CA");
    },

    resolveNextServiceDate(date) {
      return new Date(date)
        .toLocaleDateString("en-CA", {
          month: "numeric",
          year: "numeric",
        })
        .replace(/\//g, "-");
    },
  },
};
</script>

<style lang="scss" scoped>
.service-report-detail-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: baseline;
  gap: 3rem;
  width: 100%;
  height: 100%;
  background-color: #f7f0fa4d;
  padding: 0.5rem 0.5rem;

  &__files {
    flex-basis: 44%;
  }

  &__comments {
    flex-basis: 28%;
  }

  &__dates {
    flex-basis: 28%;
  }

  &__attachment-name {
    font-weight: 400;
    font-size: $font-size-sm;
    color: #5b0050;
    width: 13rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &__attachment {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    height: 100%;
    margin-bottom: 1rem;

    a {
      display: flex;
      margin-left: 1rem;
      font-weight: 500;
      font-size: $font-size-sm;
      text-decoration-line: underline;
      color: #5b0050;

      img {
        margin-left: 0.5rem;
      }
    }
  }

  &__files-title {
    font-weight: 700;
    font-size: $font-size-sm;
    color: #5b0050;
  }

  &__comment-title {
    font-weight: 700;
    font-size: $font-size-sm;
    color: #5b0050;
  }

  &__comment-text {
    font-weight: 400;
    font-size: $font-size-xs;
    color: #5b0050;
  }

  &__dates-date {
    display: block;
    align-items: flex-start;

    &__next-service-date {
      margin-top: 1.875rem;
    }
  }

  &__date-label {
    font-weight: 700;
    font-size: $font-size-sm;
    color: #5b0050;
  }

  &__date-value {
    font-weight: 400;
    font-size: $font-size-xs;
    color: #5b0050;
  }
}
</style>
