<template>
  <div class="messages-view">
    <tender-conversations
      v-if="isTenderAccepted"
      :tender="tender"
      :supplier-id="supplierId"
      :price-request="priceRequest"
    />
    <price-request-conversation
      v-else
      :tender="tender"
      :supplier-id="supplierId"
      :price-request="priceRequest"
    />
  </div>
</template>

<script>
import PriceRequestConversation from "@/components/Conversation/PriceRequestConversation.vue";
import { RequestState } from "@/custom-types/GeneralTypes";
import TenderConversations from "@/components/Conversation/TenderConversations.vue";

export default {
  name: "MessagesWrapper",
  components: { TenderConversations, PriceRequestConversation },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  computed: {
    isTenderAccepted() {
      return this.tender.priceRequests?.[0]?.supplierRequests?.some(
        (request) => request.requestState === RequestState.ACCEPTED,
      );
    },
    priceRequest() {
      return this.tender ? this.tender.priceRequests[0] : null;
    },
  },
};
</script>

<style lang="scss" scoped>
.messages-view {
  min-height: 28rem;
  background-color: #fff;
  text-align: left;
  font-family: "Montserrat", sans-serif;
  color: $color-black;
}
</style>
