<template>
  <div
    class="agreement-partner"
    :class="partnerClasses"
    @click="toggleCollapse"
  >
    <div class="agreement-partner__header">
      <div>
        <div class="agreement-partner__title">{{ title }}</div>
        <div class="agreement-partner__partner-info">
          {{ firstPartnerDetail }}
        </div>
      </div>
      <img
        v-if="collapsible"
        class="agreement-partner__toggle-icon"
        :class="toggleIconClasses"
        :src="`/images/arrow-right_24.svg`"
        alt="Toggle icon"
      />
    </div>
    <b-collapse :visible="expanded">
      <div
        v-for="(info, index) in otherPartnerDetails"
        :key="index"
        class="agreement-partner__partner-info"
      >
        {{ info }}
      </div>
    </b-collapse>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      required: true,
    },
    partnerDetails: {
      type: Array,
      required: true,
    },
    collapsible: {
      type: Boolean,
      default: true,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  emits: ["toggle-collapse"],
  computed: {
    partnerClasses() {
      return this.collapsible ? ["agreement-partner--collapsible"] : [];
    },
    toggleIconClasses() {
      return this.expanded ? ["agreement-partner__toggle-icon--expanded"] : [];
    },
    firstPartnerDetail() {
      return this.partnerDetails.length ? this.partnerDetails[0] : "";
    },
    otherPartnerDetails() {
      const partnerInfoCopy = this.partnerDetails.slice();
      if (partnerInfoCopy.length) {
        partnerInfoCopy.shift();
      }
      return partnerInfoCopy;
    },
  },
  methods: {
    toggleCollapse() {
      this.$emit("toggle-collapse");
    },
  },
};
</script>

<style lang="scss" scoped>
.agreement-partner {
  padding: 0.75rem 1rem;
  background: $color-primary-pink-dark;
  color: $color-black;
  border-radius: 8px;

  &--collapsible {
    cursor: pointer;

    &:hover {
      background: $color-primary-pink-darker;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__partner-info {
    margin-top: 0.25rem;
    font-weight: 400;
    font-size: $font-size-base;
    line-height: 1.25;
  }

  &__toggle-icon {
    transform: rotate(90deg);

    &--expanded {
      transform: rotate(-90deg);
    }
  }
}
</style>
