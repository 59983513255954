import Cookies from "js-cookie";

const COOKIE_EXPIRATION_DAYS = 7;

export function useFeedbackCookie() {
  const setFeedbackViewedCookie = (key: string, value: boolean) => {
    Cookies.set(key, value.toString(), { expires: COOKIE_EXPIRATION_DAYS });
  };

  const getFeedbackViewedCookie = (key: string): boolean => {
    const value = Cookies.get(key);
    return value === "true";
  };

  return {
    setFeedbackViewedCookie,
    getFeedbackViewedCookie,
  };
}
