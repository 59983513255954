<template>
  <div class="invoice-recipient">
    <h2 class="invoice-recipient__title">Fakturamottaker</h2>
    <p class="invoice-recipient__description">
      Faktura sendes til Really! via EHF. Merk faktura med referansekoden og
      beskriv hvilke kostnaden som blir fakturert. Dersom EHF ikke er
      tilgjengelig kan faktura sendes til oppgitt epost.
    </p>
    <div class="invoice-recipient__details">
      <item
        v-for="(detail, index) in details"
        :key="index"
        :label="detail.label"
        :value="detail.value"
      />
    </div>
  </div>
</template>

<script>
import Item from "./Item.vue";
export default {
  name: "Invoice",
  components: {
    Item,
  },
  props: {
    externalTenderId: {
      type: String,
      required: true,
      default: "",
    },
    externalSupplierPortfolioId: {
      type: [Number, String],
      required: true,
      default: 0,
    },
    externalOfferId: {
      type: [String, Number],
      required: true,
      default: "",
    },
  },
  computed: {
    details() {
      return [
        { label: "Fakturamottaker", value: "Really! Services AS" },
        { label: "Org. nr.", value: "925401919" },
        { label: "Epost", value: "invoice@really.no" },
        { label: "Referanse", value: this.referenceCode },
      ];
    },
    referenceCode() {
      return `#${this.externalTenderId}-${this.externalSupplierPortfolioId}-${this.externalOfferId}`;
    },
  },
};
</script>

<style scoped lang="scss">
.invoice-recipient {
  $background-control: $color-primary-pink;
  $padding-vertical: 24px;
  $padding-horizontal: 32px;
  $title-color: #000;
  $title-font-size: $font-size-base;
  $description-color: #000;
  $description-font-size: $font-size-sm;
  $description-margin: 16px;
  $details-padding-bottom: 10px;

  border-radius: 4px;
  background: $background-control;
  background-color: $background-control;
  padding: $padding-vertical $padding-horizontal;
  margin-left: 10px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;

  &__title {
    color: $title-color;
    font-size: $title-font-size;
    font-weight: 600;
    text-align: left;
  }

  &__description {
    color: $description-color;
    font-size: $description-font-size;
    margin: $description-margin 0;
    font-weight: 400;
    text-align: left;
    font-style: italic;
  }

  &__details {
    font-size: $description-font-size;
    width: 100%;
  }
}
</style>
