<template>
  <div class="thread-message" :class="threadMessageClasses">
    <div class="thread-message__head">
      <div class="thread-message__title">
        {{ formattedMessage.senderName }}
      </div>
      <div class="thread-message__date-created">
        {{ formattedMessage.createdOn }}
      </div>
    </div>
    <div class="thread-message__body">
      <div class="thread-message__text">
        <message-renderer :message="formattedMessage.messageBody" />
      </div>
      <div v-if="formattedMessage.attachments.length">
        <div
          v-for="attachment in formattedMessage.attachments"
          :key="attachment.attachmentId"
          class="thread-message__attachment"
        >
          <message-attachment
            :file-name="attachment.fileName"
            :attachment-uri="attachment.attachmentUri"
          />
        </div>
      </div>
    </div>
    <div class="thread-message__see-offer">
      <button
        v-if="showSeeOfferButton"
        class="thread-message__see-offer-button"
        @click="handleSeeOfferClick(message.serviceOfferId, message.tenderId)"
      >
        <img :src="`/images/NewWindow.svg`" alt="ArrowLeft" />
        <span class="ms-2">{{ seeOfferText }}</span>
      </button>
    </div>
  </div>
</template>

<script>
import MessageRenderer from "@/components/MessageRenderer/MessageRenderer.vue";
import { MessageDomain } from "@/custom-types/types";
import dayjs from "dayjs";
import MessageAttachment from "@/components/Conversation/MessageAttachment.vue";
import { RouteNames } from "@/router/routes";

export default {
  components: { MessageAttachment, MessageRenderer },
  props: {
    message: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      seeOfferText: "Se tilbud",
    };
  },
  computed: {
    formattedMessage() {
      return {
        senderName: this.message.senderName,
        createdOn: dayjs(this.message.createdOn).format("DD.MM.YYYY HH:mm"),
        messageBody: this.message.messageBody,
        attachments: this.message.attachments ? this.message.attachments : [],
        offerLink: "",
      };
    },
    threadMessageClasses() {
      return this.message.messageDomain === MessageDomain.SupplierCustomer
        ? ["thread-message--light-purple"]
        : [];
    },
    showSeeOfferButton() {
      return (
        this.message.messageDomain === MessageDomain.SupplierCustomer &&
        this.message.serviceOfferId &&
        this.message.tenderId
      );
    },
  },
  methods: {
    handleSeeOfferClick(offerId, tenderId) {
      this.$router.push({
        name: RouteNames.EDIT_OFFER,
        params: {
          tenderId: tenderId,
          offerId: offerId,
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.thread-message {
  background: #f9f9f9;
  border: 1px solid #ededed;
  border-radius: 4px;
  padding: 1rem;
  line-height: 1.2;

  &--light-purple {
    background: #fbf3ff;
  }

  &__head {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__date-created {
    font-weight: 500;
    font-size: $font-size-sm;
  }

  &__text {
    margin-top: 0.5rem;
    font-weight: 300;
    font-size: $font-size-sm;
  }

  &__see-offer {
    margin-top: 20px;
  }

  &__see-offer-button {
    border: 1px solid #1d1d1d;
    border-radius: 8px;
    padding: 10px 24px 8px 24px !important;
    background: inherit;
    font-size: $font-size-sm;

    img {
      margin-bottom: 4px;
    }
  }

  &__attachment {
    max-width: 20rem;
    margin-top: 1rem;
  }
}
</style>
@/router @/types/AppTypes
