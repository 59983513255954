export default {
  save(key, value) {
    localStorage.setItem(key, JSON.stringify(value));
  },
  get(key) {
    const value = localStorage.getItem(key);
    return value && JSON.parse(value);
  },
  exists(key) {
    return this.get(key) !== null;
  },
  delete(key) {
    localStorage.removeItem(key);
  },
};
