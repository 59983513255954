<template>
  <div class="attachment" @click="handleAttachmentClick">
    <img
      :src="`/images/document_24.svg`"
      alt="Document icon"
      class="attachment__icon"
    />
    <div class="attachment__file-name">
      {{ attachment.fileName }}
    </div>
    <button
      class="attachment__remove-button"
      type="button"
      @click.stop="removeAttachment()"
    >
      <img
        :src="`/images/cross_20.svg`"
        alt="Remove icon"
        class="attachment__remove-button-icon"
      />
    </button>
  </div>
</template>

<script>
export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
  },

  emits: ["remove"],

  methods: {
    removeAttachment() {
      this.$emit("remove");
    },
    handleAttachmentClick() {
      const { file } = this.attachment;
      if (file) {
        this.openFileInNewTab(file);
      } else {
        this.handleMissingFile();
      }
    },

    openFileInNewTab(fileUrl) {
      window.open(fileUrl, "_blank")?.focus();
    },

    handleMissingFile() {
      console.error("No file URL found to open.");
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  background-color: #fff;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 14.375rem;
  cursor: pointer;

  &__file-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__remove-button {
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    padding: 0;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #f1f1f1;
    }
  }
}
</style>
