import imageUrlBuilder from "@sanity/image-url";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import queries from "@/config/cms/cmsQueries";
import config from "@/config/cms/cmsConfig";
import { createClient } from "@sanity/client";
import { LanguageCode } from "@/custom-types/GeneralTypes";
import { ContentType } from "@/custom-types/CmsContentTypes";

const client = createClient(config);
const builder = imageUrlBuilder(client);
interface QueryParams {
  [key: string]: string | number | boolean;
}

interface FetchCmsContentParams {
  queryId: ContentType;
  params?: QueryParams;
}

const fetchCmsContent = async <T>({
  queryId,
  params = {},
}: FetchCmsContentParams): Promise<T | T[] | undefined> => {
  const defaultParams: QueryParams = {
    language: LanguageCode.NO,
    ...params,
  };

  const query = queries[queryId];

  if (!query) {
    console.warn(`No query found for queryId: ${queryId}`);
    return;
  }

  try {
    const result = await client.fetch<T[]>(query, defaultParams);
    return result.length > 1 ? result : result[0];
  } catch (error) {
    console.error("Error fetching CMS content:", error);
    return;
  }
};

const resolveImage = (source: SanityImageSource): ImageUrlBuilder => {
  return builder.image(source);
};

export default {
  fetchCmsContent,
  resolveImage,
};
