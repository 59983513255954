import {
  extractFixedActivitiesFromManualServiceOffer,
  extractFixedActivitiesFromRobotServiceOffer,
} from "@/utilities/tenderUtilities";
import { ActivityFrequency, JobType, RequestType } from "@/custom-types/types";
import { firstLetterUppercase } from "@/utilities/stringUtils";
import { cloneDeep, isEqual, omit, sortBy } from "lodash";

const getFixedCostActivitiesDTOFromLocalOffer = (
  localOffer,
  serviceOffer,
  tender,
  supplierCommission,
  frequencyOptions,
  serviceTypeCms,
) => {
  const priceRequest = tender.priceRequests[0];

  const savedActivities = getSavedActivities(
    serviceOffer,
    tender,
    priceRequest.requestType,
    serviceTypeCms,
    supplierCommission,
  );

  return localOffer.fixedCostActivities.map((activity) => {
    return getActivityDTO(
      activity,
      frequencyOptions,
      priceRequest,
      savedActivities,
    );
  });
};

const getSavedActivities = (
  serviceOffer,
  tender,
  requestType,
  serviceTypeCms,
  supplierCommission,
) => {
  if (!serviceOffer) return [];

  return requestType === RequestType.Robot
    ? extractFixedActivitiesFromRobotServiceOffer(
        serviceOffer,
        tender,
        serviceTypeCms,
        supplierCommission,
      )
    : extractFixedActivitiesFromManualServiceOffer(
        serviceOffer,
        tender,
        supplierCommission,
      );
};

const getActivityDTO = (activity, frequencyOptions, priceRequest) => {
  const activityFrequencyOptions =
    frequencyOptions[activity.frequencyGroup.toLowerCase()];

  return {
    segmentGroupId: activity.id,
    activityGroupLabel: activity.description,
    unit: activity.itemsUnit,
    unitCost: activity.pricePerItem,
    value: activity.itemsCount,
    billingCycle:
      priceRequest.jobType === JobType.Single
        ? ActivityFrequency.Fixed
        : activity.frequencyGroup,
    activePeriod: activity.limitedPeriod,
    from: firstLetterUppercase(activity.limitedPeriodStartMonth),
    to: firstLetterUppercase(activity.limitedPeriodEndMonth),
    selectedFrequency: activityFrequencyOptions?.find(
      (frequency) => frequency.label === activity.frequency,
    ),
  };
};

const getVariableCostActivitiesDTOFromLocalOffer = (localOffer) => {
  return localOffer?.variableCostActivities?.map((activity) => {
    return {
      segmentGroupId: activity.id,
      activityGroupLabel: activity.description,
      unit: activity.itemsUnit,
      unitCost: activity.pricePerItem,
    };
  });
};

const getDescriptionsDTOFromLocalOffer = (localOffer) => {
  return localOffer?.descriptions?.map((description) => {
    return {
      title: description.title,
      body: description.description,
    };
  });
};

const getRemovedActivityIds = (localOffer, serviceOffer) => {
  if (!serviceOffer) {
    return [];
  }
  const segmentGroupIds = serviceOffer.serviceOfferPrices
    .flatMap((serviceOfferPrice) => serviceOfferPrice.segmentGroupPrices)
    .map((segmentGroupPrice) => segmentGroupPrice.segmentGroupId);
  const activityIds = localOffer.fixedCostActivities
    .concat(localOffer.variableCostActivities)
    .map((activity) => activity.id);
  return segmentGroupIds.filter(
    (segmentGroupId) => !activityIds.includes(segmentGroupId),
  );
};

const getAddedAttachmentsDTO = (localOffer, serviceOffer) => {
  const serviceOfferAttachmentIds =
    serviceOffer?.serviceOfferExtra?.attachments?.map(
      (attachment) => attachment.attachmentId,
    ) || [];

  return localOffer?.attachments?.filter(
    (attachment) => !serviceOfferAttachmentIds.includes(attachment.id),
  );
};

const getRemovedAttachmentsDTO = (localOffer, serviceOffer) => {
  let serviceOfferAttachmentIds = [];
  if (serviceOffer?.serviceOfferExtra?.attachments) {
    serviceOfferAttachmentIds =
      serviceOffer.serviceOfferExtra?.attachments?.map(
        (attachment) => attachment.attachmentId,
      );
  }

  if (!Array.isArray(localOffer.attachments)) {
    return [];
  }

  const localOfferAttachmentIds = localOffer?.attachments?.map(
    (attachment) => attachment.id,
  );
  return serviceOfferAttachmentIds?.filter(
    (attachmentId) => !localOfferAttachmentIds.includes(attachmentId),
  );
};

const getUpdatedLocalOfferOnSave = (
  currentLocalOffer,
  localOfferBeforeSave,
  localOfferAfterSave,
) => {
  const currentLocalOfferCopy = cloneDeep(currentLocalOffer);
  const updateNewItemIdsOnLocalOffer = (itemsField, isEqualFn) => {
    localOfferBeforeSave[itemsField].forEach((itemBeforeSave) => {
      const itemExists = localOfferAfterSave[itemsField].find(
        (itemAfterSave) => itemAfterSave.id === itemBeforeSave.id,
      );
      if (itemExists) {
        return;
      }
      const matchingSavedItem = localOfferAfterSave[itemsField].find(
        (itemAfterSave) => {
          const itemIsNew = !localOfferBeforeSave[itemsField].find(
            (itemBeforeSave) => itemBeforeSave.id === itemAfterSave.id,
          );
          return itemIsNew && isEqualFn(itemAfterSave, itemBeforeSave);
        },
      );
      if (!matchingSavedItem) {
        return;
      }
      const matchingCurrentItem = currentLocalOfferCopy[itemsField].find(
        (currentItem) => currentItem.id === itemBeforeSave.id,
      );
      if (matchingCurrentItem) {
        matchingCurrentItem.id = matchingSavedItem.id;
      }
    });
  };
  ["fixedCostActivities", "variableCostActivities"].forEach((record) => {
    updateNewItemIdsOnLocalOffer(record, (activityA, activityB) => {
      const activityACopy = cloneDeep(activityA);
      const activityBCopy = cloneDeep(activityB);
      delete activityACopy.id;
      delete activityBCopy.id;
      return isEqual(activityACopy, activityBCopy);
    });
  });
  updateNewItemIdsOnLocalOffer("attachments", (recordA, recordB) => {
    return recordA.fileName === recordB.fileName;
  });
  return currentLocalOfferCopy;
};

const offerHasUnsavedChanges = (currentLocalOffer, lastSavedLocalOffer) => {
  const savedDescriptions = lastSavedLocalOffer.descriptions.map((desc) =>
    omit(desc, ["id"]),
  );
  const currentDescriptions = currentLocalOffer.descriptions.map((desc) =>
    omit(desc, ["id"]),
  );
  const descriptionsMatch = isEqual(savedDescriptions, currentDescriptions);
  const savedAttachments = lastSavedLocalOffer?.attachments?.map(
    (attachment) => attachment.id,
  );
  const currentAttachments = currentLocalOffer?.attachments?.map(
    (attachment) => attachment.id,
  );
  const attachmentsMatch = isEqual(
    sortBy(savedAttachments),
    sortBy(currentAttachments),
  );
  const othersMatch = isEqual(
    omit(lastSavedLocalOffer, ["attachments", "descriptions"]),
    omit(currentLocalOffer, ["attachments", "descriptions"]),
  );
  return !descriptionsMatch || !attachmentsMatch || !othersMatch;
};

const useOffer = () => {
  return {
    getRemovedActivityIds,
    offerHasUnsavedChanges,
    getAddedAttachmentsDTO,
    getRemovedAttachmentsDTO,
    getUpdatedLocalOfferOnSave,
    getDescriptionsDTOFromLocalOffer,
    getFixedCostActivitiesDTOFromLocalOffer,
    getVariableCostActivitiesDTOFromLocalOffer,
  };
};

export default useOffer;
