<template>
  <div class="attachment" @click="handleAttachmentClick(attachment)">
    <img
      :src="`/images/document_24.svg`"
      alt="Document icon"
      class="attachment__icon"
    />
    <div class="attachment__file-name">
      {{ attachment.fileName }}
    </div>
    <button
      v-if="removeButtonVisible"
      class="attachment__remove-button"
      @click.stop="removeAttachment()"
    >
      <img
        :src="`/images/cross_20.svg`"
        alt="Remove icon"
        class="attachment__remove-button-icon"
      />
    </button>
  </div>
</template>

<script>
import useFileDownload from "@/hooks/useFileDownload.ts";

const { viewAttachmentFile } = useFileDownload();

export default {
  props: {
    attachment: {
      type: Object,
      required: true,
    },
    removable: {
      type: Boolean,
      default: false,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    tender: {
      type: Object,
      required: true,
    },
  },
  emits: ["remove"],
  computed: {
    removeButtonVisible() {
      return !this.readOnly && this.removable;
    },
  },
  methods: {
    removeAttachment() {
      this.$emit("remove");
    },
    handleAttachmentClick(attachment) {
      if (attachment.id) {
        viewAttachmentFile(
          {
            attachmentId: attachment.id,
            tenderId: this.tender.tenderId,
            supplierRequestId:
              this.tender.priceRequests?.[0].supplierRequests?.[0]?.id,
          },
          this.$router,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.attachment {
  background-color: #fff;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  width: 14.375rem;
  cursor: pointer;

  &__file-name {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__remove-button {
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    padding: 0;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #f1f1f1;
    }
  }
}
</style>
@/router
