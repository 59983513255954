import { cloneDeep, isEqual } from "lodash";

type CacheRecord<T> = {
  key: unknown;
  value: T;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const cachingDecorator = <T extends (...args: any[]) => any>(func: T): T => {
  const cache: CacheRecord<ReturnType<T>>[] = [];

  return new Proxy(func, {
    apply(target, thisArg, argArray) {
      const key = cloneDeep(argArray);
      const cacheRecord = cache.find((record) => isEqual(record.key, key));

      if (cacheRecord) {
        return cacheRecord.value;
      }

      const result = Reflect.apply(target, thisArg, argArray);
      cache.push({ key, value: result });

      return result;
    },
  }) as T;
};

export default cachingDecorator;
