<template>
  <div class="extended-billable-wrapper">
    <div v-if="isLoading" class="extended-billable__spinner-wrapper">
      <b-spinner class="extended-billable__loader" type="grow" large />
    </div>

    <div v-else class="extended-billable">
      <!-- Header Section -->
      <div class="extended-billable__header">
        <img
          :src="documentIcon"
          alt="Document icon"
          class="extended-billable__icon"
        />
        <div class="extended-billable__title">
          {{ offerName }}
        </div>
        <div class="extended-billable__price-section">
          <div class="extended-billable__price">
            {{ formattedSupplierCost }}
          </div>
          <div class="extended-billable__subtitle">
            {{ supplierCostSubtitle }}
          </div>
        </div>
      </div>

      <!-- Body Section -->
      <div class="extended-billable__body-section px-5">
        <!-- Action Buttons -->
        <div class="extended-billable__action-buttons">
          <button
            v-for="button in actionButtons"
            :key="button.id"
            class="extended-billable__btn"
            @click="button.action"
          >
            {{ button.label }}
          </button>
        </div>

        <!-- Summary Section -->
        <div class="extended-billable__summary-section">
          <div
            v-for="item in summaryItems"
            :key="item.id"
            class="extended-billable__subsection"
          >
            <div class="extended-billable__label">
              {{ item.label }}
            </div>
            <div class="extended-billable__value">
              {{ item.value }}
            </div>
          </div>
        </div>

        <!-- Signature Section -->
        <div class="extended-billable__summary-section">
          <div
            v-for="item in signatureItems"
            :key="item.id"
            class="extended-billable__subsection"
          >
            <div class="extended-billable__label">
              {{ item.label }} ({{ item.date }})
            </div>
            <div class="extended-billable__signature-details">
              <div class="extended-billable__name">{{ item.name }}</div>
              <div class="extended-billable__company">{{ item.company }}</div>
            </div>
          </div>
        </div>

        <!-- Cost Summary -->
        <div class="extended-billable__cost-summary">
          <billable-price-summary
            v-if="showBillablePriceSummary"
            :title="TITLES.COST_SUMMARY"
            :currency="CURRENCY"
            :billable-price-summary="billablePriceSummary"
            :frequency-options="frequencyOptions"
          />
        </div>

        <!-- Attachments -->
        <div class="extended-billable__attachments">
          <div class="extended-billable__label">
            {{ TITLES.ATTACHMENTS }}
          </div>
          <div class="extended-billable__attachment">
            <Attachments :attachments="attachments" :tender="tender" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import documentIcon from "@/assets/img/Document.svg";
import { computed, onMounted } from "vue";
import { useExtendedBillable } from "@/composables/useExtendedBillable";
import BillablePriceSummary from "@/components/BillablePriceSummary/BillablePriceSummary.vue";
import Attachments from "@/components/Attachments/Attachments.vue";
import { formatDateToDotSeparated } from "@/utilities/dateUtils";
import type { Tender, ServiceOffer } from "@/stores/tender/types";
import { useStore } from "vuex";

// Types
interface BillablePriceSummaryType {
  id: number;
  label: string;
  value: string;
}

interface FrequencyOption {
  label: string;
  value: string;
}

interface ServiceType {
  label: string;
  serviceCategories: ServiceCategory[];
}

interface ServiceCategory {
  label: string;
  title: string;
}

interface Props {
  tender: Tender;
  billablePriceSummary: BillablePriceSummaryType[];
  frequencyOptions: FrequencyOption;
  serviceOffers: ServiceOffer[];
  isLoading: boolean;
}

// Constants
const TITLES = {
  COST_SUMMARY: "Faste kostnader (ekskl. mva)",
  ATTACHMENTS: "Vedlegg til avtale",
} as const;

const CURRENCY = "kr";

// Props
const props = defineProps<Props>();

const store = useStore();

const serviceTypesCms = computed(() => store.state.cms.serviceTypes);

// Composables
const {
  offerName,
  formattedSupplierCost,
  supplierCostSubtitle,
  actionButtons,
  initialize,
  signatureItems,
} = useExtendedBillable({
  tender: props.tender,
  serviceOffers: props.serviceOffers,
  serviceTypesCms: serviceTypesCms.value,
});

// Computed Properties
const attachments = computed(
  () => props.serviceOffers[0]?.serviceOfferExtra?.attachments ?? [],
);

const wantedStartDate = computed(() => {
  const date = props.tender.priceRequests?.[0]?.wantedStartDate;
  return date ? formatDateToDotSeparated(date) : "";
});

// Helpers
const findServiceCategoryTitle = (
  serviceTypeLabel: string,
  serviceCategoryLabel: string,
): string =>
  serviceTypesCms.value
    .find((type: ServiceType) => type.label === serviceTypeLabel)
    ?.serviceCategories.find(
      (cat: ServiceCategory) => cat.label === serviceCategoryLabel,
    )?.title ?? "";

const serviceCategoryDetailsTitle = computed(() => {
  const priceRequest = props.tender.priceRequests?.[0];
  if (!priceRequest?.serviceTypeLabel || !priceRequest?.serviceCategoryLabel)
    return "";

  return findServiceCategoryTitle(
    priceRequest.serviceTypeLabel,
    priceRequest.serviceCategoryLabel,
  );
});

const summaryItems = computed(() => [
  {
    id: 1,
    label: "Tjenestepakke",
    value: serviceCategoryDetailsTitle.value,
  },
  {
    id: 2,
    label: "Oppstartsdato",
    value: wantedStartDate.value,
  },
]);

const showBillablePriceSummary = computed(
  () => props.billablePriceSummary.length > 0,
);

// Lifecycle
onMounted(initialize);
</script>

<style lang="scss" scoped>
// Variables
$base-padding: 20px;
$base-border: 1px solid #000;
$base-font-color: #333;
$sub-font-color: #666;
$highlight-color: rgba(0, 0, 0, 0.05);
$active-color: rgba(0, 0, 0, 0.1);

// Layout
$section-spacing: 32px;
$button-spacing: 10px;
$subsection-spacing: 8px;

// Colors
$text-primary: #1d1d1d;
$text-secondary: #787878;
$border-color: #868686;

// Typography
$title-weight: 700;
$button-weight: 600;
$value-weight: 600;

.extended-billable-wrapper {
  background: $color-primary-pink;
  background-color: $color-primary-pink;
  border-radius: 6px;
  width: 100%;
  z-index: 0;
  min-height: 600px;
}

.extended-billable {
  padding: 24px 24px 40px 24px;
  border-radius: 6px;
  width: 100%;
  z-index: 0;

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
  }

  &__header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: $base-padding;
  }

  &__title {
    font-weight: $title-weight;
    font-size: $font-size-base;
    color: $text-primary;
    line-height: normal;
    text-align: left;
    margin-left: 16px;
  }

  &__price-section {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    margin-left: auto;
  }

  &__price {
    font-size: $font-size-base;
    font-weight: $title-weight;
    color: $text-primary;
  }

  &__subtitle {
    font-size: $font-size-sm;
    color: $text-secondary;
    font-weight: 400;
  }

  &__action-buttons {
    display: flex;
    gap: $button-spacing;
    margin-top: 20px;
    margin-bottom: $base-padding;
  }

  &__btn {
    padding: 12px 16px;
    font-size: $font-size-xs;
    font-weight: $button-weight;
    border: $base-border;
    border-radius: 4px;
    cursor: pointer;
    background-color: inherit;
    transition: background-color 0.3s ease;

    &:hover {
      background-color: $color-primary;
      color: #fff;
      border: none;
    }

    &:active {
      background-color: $active-color;
    }
  }

  &__summary-section {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: $base-padding;
  }

  &__subsection {
    width: 50%;
    text-align: left;
    margin-top: $section-spacing;

    &:nth-child(odd) {
      padding-right: $subsection-spacing;
    }

    &:nth-child(even) {
      padding-left: $subsection-spacing;
    }
  }

  &__label {
    padding-bottom: 4px;
    border-bottom: 1px solid $border-color;
    font-size: $font-size-xs;
    font-weight: 400;
    color: $text-primary;
    text-transform: uppercase;
    text-align: left;
  }

  &__value {
    margin-top: 4px;
    font-size: $font-size-sm;
    font-weight: $value-weight;
    color: $text-primary;
  }

  &__signature-details {
    align-self: stretch;
    height: 38px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 4px;
  }

  &__name {
    align-self: stretch;
    color: #000;
    font-size: $font-size-sm;
    font-weight: 600;
    word-wrap: break-word;
    margin-top: 4px;
  }

  &__company {
    align-self: stretch;
    color: $text-primary;
    font-size: $font-size-sm;
    font-weight: 400;
    word-wrap: break-word;
  }

  &__attachments {
    padding-top: $section-spacing;
    margin-bottom: $base-padding;
  }
}
</style>
