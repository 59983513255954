<template>
  <div class="default-company-profile-section">
    <button class="default-company-profile-section__button" @click="onAdd">
      {{ addButtonText }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType } from "vue";

defineProps({
  addButtonText: { type: String as PropType<string>, required: true },
});

const emits = defineEmits(["add"]);

const onAdd = () => {
  emits("add");
};
</script>

<style lang="scss" scoped>
.default-company-profile-section {
  display: flex;
  justify-content: center;
  padding: 1.2rem 1.6rem;
  align-items: center;
  gap: 1.6rem;
  align-self: stretch;
  border-radius: 8px;
  border: 1px dashed rgba(0, 0, 0, 0.5);
  background: var(--White, #fff);

  &__text {
    flex-grow: 1;
    color: rgba(0, 0, 0, 0.5);
    font-weight: 400;
    font-size: $font-size-xs;
  }

  &__button {
    padding: 0.6rem 1.2rem;
    background-color: transparent;
    border-radius: 24px;
    border: 1px solid var(--Black, #1d1d1d);
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: $font-size-xs;
    font-weight: 600;
    text-transform: uppercase;
    color: #1d1d1d;

    &:hover {
      background-color: var(--Black, #1d1d1d);
      color: var(--White, #ffffff);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.95);
    }
  }
}
</style>
