import { ApiConfig } from "@/config/api/types";
import { authHeader } from "@/config/api/headers";

export enum FinanceApiEndpoints {
  GET_TENDER_INVOICES = "getTenderInvoices",
}

export const FinanceApi: ApiConfig = {
  baseUri: import.meta.env.VITE_APP_BASE_URI || "",
  contextPath: "/finance",
  headers: [authHeader],
  endpoints: [
    {
      key: Symbol.for(FinanceApiEndpoints.GET_TENDER_INVOICES),
      method: "GET",
      url: "",
      description: "Get invoices for tender",
    },
  ],
};
