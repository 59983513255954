<template>
  <div class="no-offers">
    <img :src="`/images/DocumentPlus.svg`" alt="empty" />
    <div class="no-offers__text">
      Du har ikke laget noen tilbud på denne prisforespørselen.
    </div>
    <div class="no-offers__button">
      <b-button
        v-if="isGiveOfferButtonVisible"
        :disabled="!isNotRejectedJob"
        class="no-offers__btn"
        size="lg"
        @click="handleGiveOfferClick"
      >
        Gi tilbud
      </b-button>
    </div>
    <b-alert
      :show="dismissCountDown"
      dismissible
      variant="danger"
      class="no-offers__alert"
      @dismissed="dismissCountDown = 0"
      @dismiss-count-down="countDownChanged"
      >{{ errorMessage }}
    </b-alert>
  </div>
</template>

<script>
import { RouteNames } from "@/router/routes";
import { TenderState, RequestState } from "@/custom-types/types";
import addressService from "@/services/addressService";
import { mapState } from "vuex";

export default {
  props: {
    tender: {
      type: Object,
      required: true,
    },
    isGiveOfferButtonVisible: {
      type: Boolean,
      required: true,
    },
    noSupplierOffersCms: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      rejectPriceRequestText: "Avvis forespørsel",
      markAsDoneText: "Marker som utført",
      giveOfferText: "Gi tilbud",
      createPriceRequestText: "Gi nytt tilbud",
      dismissSecs: 10,
      dismissCountDown: 0,
      showDismissibleAlert: false,
      errorMessage: "",
      noSupplierDetailErrorMessage:
        "Trenger leverandørdetaljene for tjenestetypen",
      noSupplierAddressesErrorMessage: "Trenger minst en addresse",
      supplierDetails: [],
      supplierAddresses: [],
    };
  },

  computed: {
    ...mapState(["supplier", "api", "cms"]),
    isNotRejectedJob() {
      // Check if the request state is rejected
      const requestState =
        this.tender.priceRequests?.[0]?.supplierRequests?.[0]?.requestState;
      if (requestState === RequestState.Rejected) {
        return false;
      }

      const VALID_STATES = [
        TenderState.Offered,
        TenderState.Accepted,
        TenderState.PriceRequested,
        TenderState.Completed,
      ];

      if (VALID_STATES.includes(this.tender?.tenderState)) {
        return true;
      }

      return true;
    },
  },

  mounted() {
    if (
      this.supplier.supplier &&
      Array.isArray(this.supplier.supplier.supplierDetails) &&
      this.supplier?.supplier?.supplierDetails.some(
        (element) => typeof element === "object" && element !== null,
      )
    ) {
      this.supplierDetails = this.supplier?.supplier?.supplierDetails;
      this.fetchSupplierAddresses();
    }
  },

  methods: {
    handleGiveOfferClick() {
      // if (this.supplierAddresses.length === 0) {
      //   return this.showAlert(this.noSupplierAddressesErrorMessage);
      // }

      // if (this.supplierDetails.length === 0) {
      //   return this.showAlert(this.noSupplierDetailErrorMessage);
      // }

      return this.$router.push({
        name: RouteNames.CREATE_OFFER,
        params: {
          tenderId: this.tender.tenderId,
        },
      });
    },

    countDownChanged(dismissCountDown) {
      this.dismissCountDown = dismissCountDown;
    },

    showAlert(message) {
      this.errorMessage = message;
      this.dismissCountDown = this.dismissSecs;
    },

    fetchSupplierAddresses() {
      if (!this.supplier || !this.supplier?.supplier?.id) return;
      addressService
        .getSupplierAddresses(this.supplier?.supplier?.id)
        .then((response) => {
          const data = response.data;
          if (data) this.supplierAddresses = data;
          else this.supplierAddresses = [];
        })
        .catch(() => {
          this.supplierAddresses = [];
        });
    },
  },
};
</script>

<style lang="scss" scoped>
.no-offers {
  text-align: center;
  background: $color-primary-lighter;
  border: 0.1rem solid $color-primary-pink-darker;
  border-radius: 0.25rem;
  padding: 3rem 1rem;
  min-height: 24rem;
  width: 100%;

  &__alert {
    margin: 24px;
  }

  &__text {
    margin-top: 2.5rem;
    margin-bottom: 2.6rem;
    font-size: $font-size-sm;
    font-weight: 400;
  }

  &__btn {
    font-weight: bold;
    font-size: $font-size-base;
    text-transform: uppercase !important;
    background-color: #611f69;
    border-color: #611f69;
    border-width: 0.1rem;
    color: white;
  }

  &__btn:hover {
    background-color: $color-primary-darker;
    border-color: $color-primary-darker;
    color: white;
  }

  &__btn:active {
    background-color: $color-primary-darker;
    border-color: $color-primary-darker;
    border-width: 0.1rem;
    color: white;
  }

  &__btn:focus {
    background-color: $color-primary-darker;
    border-color: #611f69;
    border-width: 0.1rem;
    color: white;
    box-shadow: 0 2px 10px 0 rgba(#611f69, 0.25);
  }
}
</style>
