import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

export default {
  async fetchServiceReports(query) {
    return Axios.get(
      `${apiConfig.apiConfig.getServiceReportApi}/CustomerOrganization/${query.customerOrgNumber}/Tender/${query.tenderId}/Offer/${query.serviceOfferId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    ).catch((reason) => console.error(reason));
  },

  async createServiceReport(query) {
    return Axios.post(`${apiConfig.apiConfig.createServiceReportApi}`, query, {
      headers: {
        "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
      },
    }).catch((reason) => console.error(reason));
  },

  deleteServiceReport(serviceReportId) {
    return Axios.delete(
      `${apiConfig.apiConfig.deleteServiceReport}/${serviceReportId}`,
      {
        headers: {
          "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
        },
      },
    );
  },
};
