import apiConfig from "@/config/apiConfig";
import authConfig from "@/config/authConfig";
import Axios from "axios";

// Axios instance
const axiosInstance = Axios.create({
  headers: {
    "Ocp-Apim-Subscription-Key": authConfig.keys.apiSubscriptionKey,
  },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    console.error(error);
    return Promise.reject(error);
  },
);

export default {
  async fetchSupplierOffers(tenderId, supplierId) {
    const response = await axiosInstance.get(
      `${apiConfig.apiConfig.getSupplierOffersApi}${tenderId}/Supplier/${supplierId}/Offers`,
    );
    return response;
  },

  async fetchTenderOffersBySupplier(supplierId) {
    const response = await axiosInstance.get(
      `${apiConfig.apiConfig.getTenderOffersBySupplier}${supplierId}`,
    );
    return response;
  },

  async completeTenderOfferBySupplier(tenderId, serviceOfferId, query) {
    const response = await axiosInstance.put(
      `${apiConfig.apiConfig.completeTenderOffer}${tenderId}/Offer/${serviceOfferId}/Complete`,
      query,
    );
    return response;
  },

  async sendInformationRequestMessage(tenderId, query) {
    const response = await axiosInstance.post(
      `${apiConfig.apiConfig.sendInformationRequestMessage}${tenderId}/InformationRequest`,
      query,
    );
    return response;
  },

  async getTenderConfirmationUri(tenderId, offerId, audience) {
    const response = await axiosInstance.get(
      `${apiConfig.apiConfig.getTenderConfirmationApi}${tenderId}/offer/${offerId}/OrderConfirmation/${audience}`,
      { responseType: "blob" },
    );
    return response;
  },

  async sendRejectPriceRequest(tenderId, priceRequestId, query) {
    const response = await axiosInstance.post(
      `${apiConfig.apiConfig.sendRejectPriceRequestApi}${tenderId}/PriceRequest/${priceRequestId}/Reject`,
      query,
    );
    return response;
  },

  async createSupplierTenderOffer(offerDto) {
    return await axiosInstance.post(
      `${apiConfig.apiConfig.createSupplierTenderOffer}`,
      offerDto,
    );
  },

  async updateSupplierTenderOffer(serviceOfferId, offerDto) {
    return await axiosInstance.put(
      `${apiConfig.apiConfig.updateSupplierTenderOffer}/${serviceOfferId}`,
      offerDto,
    );
  },

  async getActivityFormFrequencyOptions() {
    return await axiosInstance.get(
      `${apiConfig.apiConfig.getActivityFormFrequencyOptions}`,
    );
  },

  async getTenderOffer(tenderId, offerId) {
    return await axiosInstance.get(
      `${apiConfig.apiConfig.getTenderOffer}/${tenderId}/offer/${offerId}`,
    );
  },

  async getTender(supplierId, tenderId) {
    return await axiosInstance.get(
      `${apiConfig.apiConfig.getTender}/${tenderId}/Supplier/${supplierId}`,
    );
  },

  async sendOffer(tenderId, offerId, sendOfferDto) {
    return await axiosInstance.patch(
      `${apiConfig.apiConfig.getTender}/${tenderId}/offer/${offerId}/change`,
      sendOfferDto,
    );
  },

  async deleteSupplierOffer(tenderId, offerId) {
    return await axiosInstance.delete(
      `${apiConfig.apiConfig.deleteSupplierOffer}/${tenderId}/Offer/${offerId}`,
    );
  },

  async withdrawSupplierOffer(tenderId, offerId, changeOfferDto) {
    return await axiosInstance.patch(
      `${apiConfig.apiConfig.withdrawSupplierOffer}/${tenderId}/offer/${offerId}/change`,
      changeOfferDto,
    );
  },

  async setAgreementResponsible(tenderId, priceRequestId, supplierId, dto) {
    return await axiosInstance.patch(
      `${apiConfig.apiConfig.setAgreementResponsible}/${tenderId}/request/${priceRequestId}/supplier/${supplierId}`,
      dto,
    );
  },

  async getInvoiceRecipient(tenderId, offerId) {
    return await axiosInstance.get(
      `${apiConfig.apiConfig.getInvoiceRecipient}/${tenderId}/offer/${offerId}/InvoiceRecipient`,
    );
  },

  async submitTender(dto) {
    return await axiosInstance.post(`${apiConfig.apiConfig.submitTender}`, dto);
  },
};
