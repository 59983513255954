<template>
  <confirmation-modal
    v-model="showModal"
    title="Slett beskrivelse"
    description="Dette vil fjerne beskrivelsen fra tilbudet ditt"
    secondary-button-text="Avbryt"
    primary-button-text="Slett bESKRIVELSE"
    :is-submitting="isSubmitting"
    @secondary-click="closeModal"
    @primary-click="handlePrimaryClick"
  >
    <template #status-icon>
      <img
        :src="`/images/document-sent.svg`"
        class="delete-work-description__status-icon"
        alt="Status icon"
      />
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal.vue";

export default {
  components: { ConfirmationModal },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    descriptionItem: {
      type: Object,
      required: true,
    },
  },
  emits: ["update:modelValue", "item-deleted"],
  data() {
    return {
      isSubmitting: false,
      itemDeleted: false,
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(show) {
        this.$emit("update:modelValue", show);
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    handlePrimaryClick() {
      this.$emit("item-deleted", this.descriptionItem);
      this.closeModal();
    },
  },
};
</script>

<style lang="scss" scoped>
.delete-work-description {
  &__status-icon {
    width: 4rem;
  }
}
</style>
