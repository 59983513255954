import { ref } from "vue";
import initGoogleMaps from "@/utilities/gMapsInit";
import cachingDecorator from "@/utilities/cachingDecorator";

const SESSION_EXPIRY_TIME_MS = 120000;

interface SessionToken {
  token: google.maps.places.AutocompleteSessionToken | undefined;
  startTime: number | null;
}

const useGmapsPlacesService = () => {
  const sessionToken = ref<SessionToken>({
    token: undefined,
    startTime: null,
  });

  const geocoder = ref<google.maps.Geocoder | null>(null);
  const autocompleteService =
    ref<google.maps.places.AutocompleteService | null>(null);
  const placesService = ref<google.maps.places.PlacesService | null>(null);

  const findAddressComponent = (
    addressComponents: Array<google.maps.GeocoderAddressComponent>,
    types: string | string[],
  ) => {
    return addressComponents.find((component) =>
      Array.isArray(types)
        ? component.types.some((type) => types.includes(type))
        : component.types.includes(types),
    );
  };

  const getAddressComponents = (
    addressComponents?: Array<google.maps.GeocoderAddressComponent>,
  ) => {
    addressComponents = addressComponents || [];
    const streetNumberComponent = findAddressComponent(
      addressComponents,
      "street_number",
    );
    const routeComponent = findAddressComponent(addressComponents, "route");
    const postalCodeComponent = findAddressComponent(
      addressComponents,
      "postal_code",
    );
    const postalTownComponent = findAddressComponent(addressComponents, [
      "postal_town",
      "locality",
    ]);
    const administrativeAreaLevel1Component = findAddressComponent(
      addressComponents,
      ["administrative_area_level_1", "political"],
    );

    return {
      streetNumber: streetNumberComponent?.short_name || "",
      route: routeComponent?.short_name || "",
      postalCode: postalCodeComponent?.short_name || "",
      city: postalTownComponent?.short_name || "",
      county: administrativeAreaLevel1Component?.short_name || "",
    };
  };

  const getAutocompletePredictions = async (input: string) => {
    const trimmedInput = input.trim();
    if (!trimmedInput) {
      return { input: trimmedInput, results: [] };
    }

    const gmaps = await initGoogleMaps();
    if (!autocompleteService.value) {
      autocompleteService.value = new gmaps.maps.places.AutocompleteService();
    }

    const response = await autocompleteService.value.getPlacePredictions({
      input: trimmedInput,
      componentRestrictions: { country: ["no"] },
      types: ["street_address", "route"],
      sessionToken: await getSessionToken(),
    });

    return { input: trimmedInput, results: response.predictions.slice() };
  };

  const getPlaceDetails = async (placeId: string) => {
    const gmaps = await initGoogleMaps();
    return new Promise<google.maps.places.PlaceResult | null>((resolve) => {
      if (!placesService.value) {
        placesService.value = new gmaps.maps.places.PlacesService(
          document.createElement("div"),
        );
      }
      placesService.value.getDetails(
        {
          placeId: placeId,
          sessionToken: getAndResetSessionToken(),
          fields: ["place_id", "geometry", "address_component"],
        },
        (response) => resolve(response),
      );
    });
  };

  const getSessionToken = async () => {
    const startTime = sessionToken.value.startTime;
    if (
      !sessionToken.value.token ||
      !startTime ||
      new Date().getTime() - startTime > SESSION_EXPIRY_TIME_MS
    ) {
      const gmaps = await initGoogleMaps();
      sessionToken.value.token =
        new gmaps.maps.places.AutocompleteSessionToken();
      sessionToken.value.startTime = new Date().getTime();
    }
    return sessionToken.value.token;
  };

  const getAndResetSessionToken = () => {
    const token = sessionToken.value.token;
    sessionToken.value.token = undefined;
    return token;
  };

  const checkIfAutocompletePredictionIsStreetAddress = (
    prediction: google.maps.places.AutocompletePrediction,
  ) => {
    return prediction.types.includes("street_address");
  };

  const getAutocompletePredictionAddressText = (
    prediction: google.maps.places.AutocompletePrediction,
  ) => {
    return prediction.description.replace(", Norway", "");
  };

  const reverseGeocode = async (request: {
    location?: { lat: number; lng: number } | null;
    placeId?: string | null;
  }) => {
    if (!geocoder.value) {
      const gmaps = await initGoogleMaps();
      geocoder.value = new gmaps.maps.Geocoder();
    }
    const response = await geocoder.value.geocode(request);
    return response.results[0];
  };

  const cachedReverseGeocode = cachingDecorator(reverseGeocode);

  return {
    getSessionToken,
    getPlaceDetails,
    getAddressComponents,
    cachedReverseGeocode,
    findAddressComponent,
    getAndResetSessionToken,
    getAutocompletePredictions,
    getAutocompletePredictionAddressText,
    checkIfAutocompletePredictionIsStreetAddress,
  };
};

export default useGmapsPlacesService;
