<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
  >
    <div class="edit-name">
      <div class="edit-name__title">{{ title }}</div>
      <div class="edit-name__description">
        <span>{{ nameInputTitle }}</span>
        <span class="edit-name__max-characters">
          {{ maxCharactersText }}
        </span>
      </div>
      <div>
        <form class="offer-form" @submit.prevent="changeOfferName">
          <v-field
            v-slot="{ componentField, errors }"
            name="offerName"
            :rules="fieldRules"
            :validate-on-input="true"
          >
            <div v-if="errors.length" class="edit-name__field-input-error">
              <input-error :text="errors[0]" />
            </div>
            <div class="edit-name__field-input">
              <base-input
                v-bind="componentField"
                @keyup.enter="changeOfferName"
              />
            </div>
          </v-field>
        </form>
      </div>
      <div class="about-offer-name">
        <div class="about-offer-name__title">{{ aboutOfferNameTitle }}</div>
        <p class="about-offer-name__description">
          {{ aboutOfferNameDescription }}
        </p>
      </div>
      <div class="edit-name__action-buttons">
        <tertiary-button @click="handleSecondaryButtonClick">{{
          secondaryButtonText
        }}</tertiary-button>
        <primary-button @click="changeOfferName">{{
          changeNameButtonText
        }}</primary-button>
      </div>
    </div>
  </b-modal>
</template>

<script setup>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import { getCmsItemText } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import { computed, reactive, watch } from "vue";
import { useForm } from "vee-validate";
import { string } from "yup";
import { Field as VField } from "vee-validate";

const emit = defineEmits(["update:modelValue", "new-offer-name", "back"]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  title: {
    type: String,
    default: "",
    required: true,
  },
  changeNameFormCms: {
    type: Object,
    required: true,
  },
  initialOfferName: {
    type: String,
    default: "",
  },
  newServiceOfferConfirmed: {
    type: Boolean,
    default: false,
  },
});

const state = reactive({
  maxCharacters: 50,
});

const fieldRules = string()
  .required("Tilbudet må ha et navn.")
  .max(
    state.maxCharacters,
    `Navnet kan ikke være lengre enn ${state.maxCharacters} tegn.`,
  );

const { setValues, handleSubmit } = useForm({
  initialValues: {
    offerName: "",
  },
});

const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(value) {
    emit("update:modelValue", value);
  },
});
const nameInputTitle = computed(() => {
  return getCmsItemText(props.changeNameFormCms.inputs, "nameInput");
});
const maxCharactersText = computed(() => {
  return `(Maks ${state.maxCharacters} tegn)`;
});
const secondaryButtonText = computed(() => {
  const cancelText = getCmsItemText(
    props.changeNameFormCms.buttons,
    "cancelButton",
  );
  return props.newServiceOfferConfirmed ? cancelText : "Tilbake";
});
const changeNameButtonText = computed(() => {
  return getCmsItemText(props.changeNameFormCms.buttons, "changeNameButton");
});
const aboutOfferNameTitle = computed(() => {
  return getCmsItemText(
    props.changeNameFormCms.otherText,
    "aboutOfferNameTitle",
  );
});
const aboutOfferNameDescription = computed(() => {
  return getCmsItemText(
    props.changeNameFormCms.otherText,
    "aboutOfferNameDescription",
  );
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      setValues({
        offerName: props.initialOfferName,
      });
    }
  },
  { immediate: true },
);

const closeModal = () => {
  showModal.value = false;
};
const changeOfferName = handleSubmit(async (values) => {
  emit("new-offer-name", values.offerName);
  closeModal();
});
const handleSecondaryButtonClick = () => {
  if (props.newServiceOfferConfirmed) {
    showModal.value = false;
  } else {
    emit("back");
  }
};
</script>

<style lang="scss" scoped>
.edit-name {
  font-family: "Montserrat", sans-serif;
  padding: 1.5rem 2rem;
  border-radius: 4px;
  text-align: left;

  &__field-input,
  &__field-input-error {
    margin-top: 0.5rem;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__description {
    margin-top: 1rem;
    display: flex;
    align-items: baseline;
    gap: 0.25rem;
    font-size: $font-size-sm;
    font-weight: 600;
  }

  &__action-buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: center;
    gap: 1rem;
  }
}

.about-offer-name {
  margin-top: 1rem;
  background: #eefdff;
  padding: 1rem;

  &__title {
    font-size: $font-size-sm;
    font-weight: 600;
  }

  &__description {
    font-size: $font-size-sm;
    font-weight: 400;
    margin: 0.5rem 0 0;
  }
}

:deep(.modal-sm) {
  max-width: 498px !important;
}

:deep(.modal-content) {
  border: none;
  border-radius: 4px;
}
</style>
@/utilities/cmsUtilities
