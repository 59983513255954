<template>
  <div class="service-type-segments text-left">
    <price-robot-header
      :header-content-prop="cms.myPrices.segments.headerContent"
    />
    <div class="service-type-segments__segments-wrapper">
      <transition name="fade">
        <div
          v-if="groupedSegments.length"
          class="service-type-segments__grouped-segments-container"
        >
          <div
            v-for="(segments, index) in groupedSegments"
            :key="index"
            class="service-type-segments__grouped-segments"
          >
            <segment
              v-for="(segment, i) in segments"
              :key="i"
              :segment-content="segment"
              @validation-error="handleValidationError"
            />
          </div>
        </div>
      </transition>
      <BSpinner
        v-if="!groupedSegments.length"
        type="grow"
        class="service-type-segments__spinner"
      />
    </div>
    <b-alert
      v-model="showDismissibleAlert"
      :variant="alertVariant"
      class="service-type-segments__b-alert"
      dismissible
    >
      {{ alertText }}
    </b-alert>
    <save-button
      :is-loading="state.isLoading"
      :disabled="state.disableButton"
      :button-text="state.buttonText"
      :note-text="state.noteText"
      :component-type="state.componentType"
      @save-segment-ranges="handleSaveSegmentRanges"
    />
    <price-robot-footer />
  </div>
</template>

<script setup>
import { computed, ref, reactive } from "vue";
import { useStore } from "vuex";
import _ from "underscore";
import PriceRobotHeader from "@/components/PriceRobot/PriceRobotHeader.vue";
import PriceRobotFooter from "@/components/PriceRobot/PriceRobotFooter.vue";
import Segment from "./Segment.vue";
import SaveButton from "@/components/SaveButton/SaveButton.vue";
import myPricesService from "@/services/myPricesService";

const RANGE_ID_THRESHOLD = 99900;

const store = useStore();
const cms = computed(() => store.state.cms);
const api = computed(() => store.state.api);

// Reactive state
const state = reactive({
  isLoading: false,
  disableButton: false,
  componentType: "service-type-segments",
  buttonText: "Lagre Segmentområder",
  noteText:
    "Når du har lagt inn alle segmentene for overflater og frekvens bekrefter du med å trykke på knappen her.",
});

const showDismissibleAlert = ref(false);
const alertVariant = ref("");
const alertText = ref("");
const successAlertText = ref("Segmentområder lagret");
const errorAlertText = ref("Noe gikk galt. Prøv igjen.");

const groupedSegments = computed(() => {
  const segments = _.groupBy(api.value.supplierSegments, "groupId");
  return Object.values(segments);
});

// Methods
const handleValidationError = (val) => {
  state.disableButton = val;
};

const handleSaveSegmentRanges = async () => {
  state.disableButton = true;
  state.isLoading = true;
  await saveSupplierRanges();
};

const saveSupplierRanges = async () => {
  try {
    const resolvedRangesArray = resolveRangesArray(
      store.state.api.ranges,
      store.state.api.editedRanges,
      store.state.api.deletedRanges,
    );

    const rangesArray = formatRangesArrayForApi(resolvedRangesArray);

    const response = await myPricesService.saveRanges(rangesArray);

    if (response.status === 200) {
      await Promise.all([
        store.dispatch("api/saveRange", []),
        store.dispatch("api/saveEditedRange", []),
        store.dispatch("api/saveDeletedRangesForPosting", []),
      ]);
      reFetchSupplierSegments();
      showUiFeedback("success", successAlertText.value);
    } else {
      const errorMessage =
        response.status === 409
          ? errorAlertText.value
          : "Something went wrong. Try again.";
      throw new Error(errorMessage);
    }
  } catch (error) {
    showUiFeedback("danger", error.message);
  } finally {
    state.isLoading = false;
  }
};

const resolveRangesArray = (ranges, editedRanges, deletedRanges) => {
  return [...ranges, ...editedRanges, ...deletedRanges].flat();
};

const formatRangesArrayForApi = (rangesArray) => {
  return rangesArray.map((item) => {
    const { id, rangeFrom, rangeTo, ...rest } = item;

    const formattedItem = {
      ...rest,
      id: id > RANGE_ID_THRESHOLD && id < 100000 ? 0 : id,
      rangeFrom: Number(rangeFrom).toFixed(2),
      rangeTo:
        rangeTo === Number.POSITIVE_INFINITY ? -1 : Number(rangeTo).toFixed(2),
    };

    return formattedItem;
  });
};

const showUiFeedback = (variant, text) => {
  alertVariant.value = variant;
  alertText.value = text;
  showDismissibleAlert.value = true;
};

const reFetchSupplierSegments = () => {
  const updatedParams = {
    supplierType: store.state.cms.selectedSupplierType,
    propertyType: store.state.api.supplierSegmentsParams.propertyType,
  };
  store.dispatch("api/fetchSupplierSegments", updatedParams);
};
</script>

<style lang="scss" scoped>
$border-color: #5d9179;
$border-radius: 0.625rem;
$white-color: #fff;

.service-type-segments {
  font-size: $font-size-base;
  color: $color-primary-darker;
  border-bottom: 0.375rem solid $white-color;
  padding-top: 4rem;

  &__grouped-segments {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    border: 0.2rem solid $border-color;
    border-radius: $border-radius;
    padding: 2rem;
    gap: 4rem;
    width: auto;
    margin-right: 1rem;
    margin-top: 2rem;
  }

  &__segments-wrapper {
    display: flex;
    flex-direction: row;
    min-height: 12rem;
    overflow-x: auto;
    padding-left: 2.5rem;
    max-width: 100%;
    white-space: nowrap;
  }

  &__segments-wrapper::-webkit-scrollbar {
    display: none;
  }

  &__segments-wrapper {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__grouped-segments-container {
    display: inline-flex;
    flex-direction: row;
    gap: 4rem;
    max-width: 100vw;
  }

  &__grouped-segments {
    flex: 0 0 auto;
    display: flex;
    flex-direction: row;
    border: 0.2rem solid $border-color;
    border-radius: $border-radius;
    padding: 2rem;
    width: auto;
    margin-right: 1rem;
    margin-top: 2rem;
  }

  &__b-alert {
    width: max-content;
    margin-top: 4rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-size: $font-size-sm;
    font-weight: 700;
  }

  &__spinner {
    margin: auto;
    width: 3rem;
    height: 3rem;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
