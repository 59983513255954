import router from "@/router";
import tenderService from "@/services/tenderService";

export default {
  fetchOffers: async ({ commit }, supplierId) => {
    return tenderService
      .fetchTenderOffersBySupplier(supplierId)
      .then((response) => {
        const data = response.data;
        if (data) {
          commit("setOffers", data);
        }
      })
      .catch(() => {
        router.push("/error").catch(() => {});
      });
  },
  emptyOffers: async ({ commit }) => {
    commit("emptyOffers", null);
  },
  updateTender: async ({ commit }, tender) => {
    commit("updateTender", tender);
  },
};
