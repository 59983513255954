import policies from "@/config/policies";
import { PublicClientApplication } from "@azure/msal-browser";

export const msalConfig = {
  auth: {
    clientId: `${import.meta.env.VITE_APP_B2C_CLIENTID}`,
    authority: policies.b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [policies.b2cPolicies.authorityDomain],
    redirectUri: import.meta.env.VITE_APP_REDIRECT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    iframeHashTimeout: 10000,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export default {
  msalConfig: msalConfig,
  loginRequest: {
    scopes: ["openid", "profile"],
  },
  tokenRequest: {
    scopes: ["openid", "profile"],
  },
  keys: {
    apiSubscriptionKey: import.meta.env.VITE_APP_API_KEY,
  },
};
