<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="sm"
  >
    <div class="base-delete-modal">
      <img :src="icon" alt="icon" class="icon" />
      <div class="base-delete-modal__title">{{ title }}</div>
      <div class="base-delete-modal__description">
        <custom-block-content :blocks="description" />
      </div>
      <div class="base-delete-modal__action-buttons">
        <tertiary-button @click="closeModal">{{
          cancelButtonText
        }}</tertiary-button>
        <primary-button @click="deleteItem">{{
          deleteButtonText
        }}</primary-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";

export default {
  components: { PrimaryButton, TertiaryButton, CustomBlockContent },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: Object,
      required: true,
    },
    cancelButtonText: {
      type: String,
      required: true,
    },
    deleteButtonText: {
      type: String,
      required: true,
    },
  },

  emits: ["delete-item", "update:modelValue"],

  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
    async deleteItem() {
      this.$emit("delete-item");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-delete-modal {
  font-family: "Montserrat", sans-serif;
  background: #f5f5f5;
  border: 1px solid white;
  padding: 48px;
  border-radius: 4px;
  text-align: center;
  min-width: 530px;

  img {
    margin-bottom: 34px;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
    line-height: 29px;
    color: #000000;
    padding-bottom: 34px;
  }

  &__description {
    font-weight: 400;
    font-size: $font-size-base;
    line-height: 20px;
    text-align: center;
    color: #000000;
    padding-bottom: 50px;
  }

  &__action-buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}

:deep(.modal-sm) {
  max-width: 530px !important;
}

:deep(.modal-content) {
  border-radius: 4px;
}
</style>
