<template>
  <div class="billable-price-summary">
    <div class="billable-price-summary__title">{{ title }}</div>
    <div v-for="(item, index) in billablePriceSummary" :key="index">
      <hr v-if="item.isDivider" class="billable-price-summary__divider" />
      <billable-price-detail
        v-else
        :description-text="item.text"
        :price="item.price"
        :currency="currency"
        :component-key="index"
      />
    </div>
  </div>
</template>

<script>
import BillablePriceDetail from "@/components/ActivitiesCosts/BillablePriceDetail.vue";

export default {
  name: "BillablePriceSummary",
  components: {
    BillablePriceDetail,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    currency: {
      type: String,
      default: "",
    },
    billablePriceSummary: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style scoped lang="scss">
$color-primary: #000000;
$border-color: #9a9a9a;
$background-color: #fff;
$padding-vertical: 18px;
$padding-horizontal: 29px;

@mixin font-style($weight, $size, $line-height, $color) {
  font-weight: $weight;
  font-size: $size;
  line-height: $line-height;
  color: $color;
}

.billable-price-summary {
  box-sizing: border-box;
  width: auto;
  height: auto;
  border: none;
  flex: none;
  order: 2;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  margin-top: 32px;
  margin-right: 10px;
  border-bottom: 1px solid #868686;
  padding-bottom: 16px;

  &__divider {
    border: 0.5px solid #868686;
    margin: 0;
    margin-top: 11px;
  }

  &__title {
    @include font-style(400, 12px, normal, #1d1d1d);
    text-align: left;
    border-bottom: 1px solid #868686;
    text-transform: uppercase;
    padding-bottom: 4px;
  }

  &__btn {
    font-weight: bold;
    font-size: $font-size-sm;
    text-transform: uppercase !important;
    background-color: inherit;
    border-color: #611f69;
    border-width: 0.1rem;
    color: #611f69;
    padding: 14px 30px 14px 30px !important;
  }

  &__btn:hover {
    background-color: #611f69;
    border-color: #611f69;
    color: white;
  }

  &__btn:active {
    background-color: transparent;
    border-color: #611f69;
    border-width: 0.1rem;
    color: #611f69;
  }

  &__btn:focus {
    background-color: transparent;
    border-color: #611f69;
    border-width: 0.1rem;
    color: #611f69;
    box-shadow: 0 2px 10px 0 rgba(#611f69, 0.25);
  }

  &__see-agreement-button {
    text-align: left;
    margin-top: 40px;
  }
}
</style>
