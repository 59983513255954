<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="sm"
  >
    <div
      class="base-supplier-offer-modal"
      :class="{ 'text-align-center': alignItems === 'center' }"
    >
      <img
        v-if="icon"
        :src="icon"
        alt="icon"
        class="base-supplier-offer-modal__icon"
      />
      <div class="base-supplier-offer-modal__title">{{ title }}</div>
      <div
        v-if="description.length"
        class="base-supplier-offer-modal__description"
      >
        <custom-block-content :blocks="description[0]" />
        <custom-block-content v-if="description[2]" :blocks="description[2]" />
      </div>
      <div>
        <!-- Form slot -->
        <slot name="form" />
      </div>
      <div class="base-supplier-offer-modal__actions">
        <!-- Buttons slot -->
        <slot name="buttons" />
      </div>
    </div>
  </b-modal>
</template>

<script>
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";

export default {
  components: { CustomBlockContent },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    icon: {
      type: [String, Object],
      required: false,
      default: null,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: Array,
      required: true,
    },
    alignItems: {
      type: String,
      default: "center",
      validator: function (value) {
        return ["left", "center"].indexOf(value) !== -1;
      },
    },
  },
  emits: ["update:modelValue"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(value) {
        this.$emit("update:modelValue", value);
      },
    },
  },
  methods: {
    closeModal() {
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
// Define Variables
$font-family: "Montserrat", sans-serif;
$background-color: #ffffff;
$font-600: 600;
$font-400: 400;
$font-color: #000000;
$border-color: white;

.base-supplier-offer-modal {
  font-family: $font-family;
  background: $background-color;
  border: 1px solid $border-color;
  padding: 48px;
  border-radius: 4px;
  text-align: left;
  min-width: 530px;

  &__icon {
    margin-bottom: 34px;
  }

  &__title {
    font-weight: $font-600;
    font-size: $font-size-2xl;
    line-height: 29px;
    color: $font-color;
    padding-bottom: 34px;
  }

  &__description {
    font-weight: $font-400;
    font-size: $font-size-base;
    line-height: 20px;
    color: $font-color;
  }
}

.text-align-center {
  text-align: center;
}

:deep(.modal-sm) {
  max-width: 530px !important;
}

:deep(.modal-content) {
  border-radius: 4px;
}
</style>
