<template>
  <confirmation-modal
    v-model="showModal"
    :status-icon="`/images/document-user.svg`"
    :icon-on-edge="false"
    title="Ny ansvarlig"
    :description="descriptionText"
    secondary-button-text="Avbryt"
    primary-button-text="OK"
    :no-close-on-background="true"
    :is-submitting="isSubmitting"
    :error="submissionError"
    @secondary-click="handleSecondaryButtonClick"
    @primary-click="handlePrimaryButtonClick"
  >
    <template v-if="user" #extraContent>
      <div class="new-agreement-responsible__allocation-notification">
        <base-checkbox-input
          v-model="sendNotification"
          :label="sendNotificationText"
        />
      </div>
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal.vue";
import BaseCheckboxInput from "@/components/Base/BaseCheckboxInput/BaseCheckboxInput.vue";
import tenderService from "@/services/tenderService";

export default {
  components: { BaseCheckboxInput, ConfirmationModal },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    user: {
      type: [Object, null],
      default: null,
    },
    tender: {
      type: Object,
      required: true,
    },
    priceRequest: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  emits: ["update:modelValue"],
  data() {
    return {
      sendNotification: true,
      isSubmitting: false,
      submissionError: "",
    };
  },
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(show) {
        this.$emit("update:modelValue", show);
      },
    },
    userName() {
      return this.user ? this.user.displayName : "Alle brukere";
    },
    descriptionText() {
      return `${this.userName} vil motta alle varslinger knyttet til denne forespørselen.`;
    },
    sendNotificationText() {
      const displayName = this.user?.displayName;
      const firstName = displayName ? displayName.split(" ")[0] : "";
      return `Send varsling om tildeling til ${firstName}`;
    },
  },
  watch: {
    showModal(newValue) {
      if (newValue) {
        this.sendNotification = true;
        this.isSubmitting = false;
        this.submissionError = "";
      }
    },
  },
  methods: {
    handleSecondaryButtonClick() {
      this.showModal = false;
    },
    async handlePrimaryButtonClick() {
      this.isSubmitting = true;
      this.submissionError = "";
      try {
        await tenderService.setAgreementResponsible(
          this.tender.tenderId,
          this.priceRequest.id,
          this.supplierId,
          {
            AgreementOwnerUserId: this.user ? this.user.userId : null,
            SendNotification: this.user ? this.sendNotification : false,
          },
        );
        const tenderResponse = await tenderService.getTender(
          this.supplierId,
          this.tender.tenderId,
        );
        await this.$store.dispatch("offers/updateTender", tenderResponse.data);
      } catch (error) {
        const errorMessage = error?.response?.data;
        this.submissionError = errorMessage
          ? JSON.stringify(errorMessage)
          : "Submission error";
      }
      this.isSubmitting = false;
      this.showModal = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-agreement-responsible {
  &__allocation-notification {
    margin-top: 1.5rem;
    font-size: $font-size-base;
  }
}
</style>
