<template>
  <div class="price-robot-view">
    <div class="price-robot-view__container">
      <calculator-settings :cms-content="cms.myPrices" />
      <industry-services
        :cms-content="cms.myPrices"
        :show-service-types="true"
        :show-price-robot-footer="true"
        :valid-supplier-types="validServiceTypes"
        @service-type-selected="onServiceTypeSelected"
      />
      <service-type-segments v-if="isServiceTypeSegmentsFetched" />
      <supplier-segments-prices v-if="isServiceTypeSegmentsFetched" />
      <save-supplier-segments-prices v-if="isSupplierSegmentsPricesFetched" />
    </div>
  </div>
</template>

<script setup>
import { reactive, onBeforeMount, computed } from "vue";
import { useStore } from "vuex";
import _ from "underscore";
import IndustryServices from "@/components/IndustryServices/IndustryServices.vue";
import ServiceTypeSegments from "@/components/ServiceTypeSegments/ServiceTypeSegments";
import SupplierSegmentsPrices from "@/components/SupplierSegmentsPrices/SupplierSegmentsPrices";
import SaveSupplierSegmentsPrices from "@/components/SaveSupplierSegmentsPrices/SaveSupplierSegmentsPrices";
import CalculatorSettings from "@/components/CalculatorSettings/CalculatorSettings.vue";

const store = useStore();

// Reactive state
const state = reactive({
  selectedServiceType: {
    id: "",
    sysLabel: "",
  },
});

// Computed properties
const cms = computed(() => store.state.cms);
const supplierTypes = computed(
  () => store.getters["api/getVisibleSupplierTypes"],
);
const validServiceTypes = computed(() =>
  supplierTypes.value
    .filter((type) => type.sysLabel !== "otherServices")
    .map((supplierType) => {
      const icon = cms?.value?.supplierTypes?.find(
        (type) => type.id === supplierType.id,
      )?.icon;
      return { ...supplierType, icon };
    }),
);
const isServiceTypeSegmentsFetched = computed(() => {
  return !_.isEmpty(store.state.api.supplierSegmentsParams);
});

const isSupplierSegmentsPricesFetched = computed(() => {
  return !_.isEmpty(store.state.api.pricing);
});

// Vuex actions as methods
const fetchSupplierTypePricingPackages = (id) => {
  store.dispatch("api/fetchSupplierTypePricingPackages", id);
};

// Methods
const onServiceTypeSelected = (serviceType) => {
  store.dispatch("api/saveSupplierSegments", {});
  state.selectedServiceType.value = serviceType;
  fetchSupplierTypePricingPackages(serviceType.id);
  updateSupplierSegmentsParams();
};

const updateSupplierSegmentsParams = () => {
  const updatedSupplierSegmentsParams = {
    supplierType: {
      label: state.selectedServiceType.value.sysLabel,
      id: state.selectedServiceType.value.id,
    },
    propertyType: {
      label: store.state.api.supplierSegmentsParams.propertyType
        ? store.state.api.supplierSegmentsParams.propertyType.label
        : "",
      id: store.state.api.supplierSegmentsParams.propertyType
        ? store.state.api.supplierSegmentsParams.propertyType.id
        : "",
    },
  };
  saveSupplierSegmentsParams(updatedSupplierSegmentsParams);
  return getSupplierSegments(updatedSupplierSegmentsParams);
};

const saveSupplierSegmentsParams = (updatedParams) => {
  store.dispatch("api/saveSupplierSegmentsParams", updatedParams);
};

const getSupplierSegments = (updatedParams) => {
  store.dispatch("api/fetchSupplierSegments", updatedParams);
};

// Lifecycle hooks
onBeforeMount(() => {
  store.dispatch("cms/loadMyPricesSupplierTypeContent");
  store.dispatch("api/fetchPropertyTypes");
});
</script>

<style lang="scss" scoped>
.price-robot-view {
  display: flex;
  flex-direction: column;
  background-color: inherit;

  &__container {
    max-width: 100vw;
    margin: 0 auto;
    padding: 0;
  }
}
</style>
