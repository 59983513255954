<template>
  <confirmation-modal
    v-model="showModal"
    :status-icon="statusIcon"
    :title="cms.title"
    :description="cms.subtitle"
    :secondary-button-text="cms.secondaryButtonText"
    :primary-button-text="cms.primaryButtonText"
    :is-submitting="isSubmitting"
    @secondary-click="closeModal"
    @primary-click="handlePrimaryClick"
  >
    <template v-if="hasSubmissionError" #errorMessage>
      <p class="error">{{ submissionErrorMessage }}</p>
    </template>
  </confirmation-modal>
</template>

<script>
import ConfirmationModal from "@/components/ConfirmationModal/ConfirmationModal";
import { completeTenderOfferCms } from "@/components/CompleteTenderOfferModal/CompleteTenderOfferCms";
import tenderService from "@/services/tenderService";
import { mapGetters } from "vuex";

export default {
  name: "CompleteTenderOfferModal",
  components: { ConfirmationModal },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    serviceOfferId: {
      type: Number,
      required: true,
    },
    tenderId: {
      type: Number,
      required: true,
    },
    submissionErrorMessage: {
      type: String,
      default: completeTenderOfferCms.submissionErrorMessage,
    },
  },
  emits: ["update:modelValue", "completed"],
  data() {
    return {
      tenderOfferCompleted: false,
      isSubmitting: false,
      hasSubmissionError: false,
    };
  },
  computed: {
    ...mapGetters("api", ["getUserId", "getUserName", "getUserEmail"]),
    showModal: {
      get() {
        return this.modelValue;
      },
      set(show) {
        this.$emit("update:modelValue", show);
      },
    },
    cms() {
      const cmsMapping = {
        title: this.tenderOfferCompleted ? "successTitle" : "confirmationTitle",
        subtitle: this.tenderOfferCompleted
          ? "successSubtitle"
          : "confirmationSubtitle",
        primaryButtonText: "ok",
        secondaryButtonText: this.tenderOfferCompleted ? "" : "cancel",
      };

      return Object.fromEntries(
        Object.entries(cmsMapping).map(([key, prop]) => [
          key,
          completeTenderOfferCms[prop],
        ]),
      );
    },

    statusIcon() {
      return this.tenderOfferCompleted
        ? `/images/utility/round-check-mark.svg`
        : `/images/utility/round-question-mark.svg`;
    },
  },
  watch: {
    showModal(newVal) {
      if (newVal) {
        this.tenderOfferCompleted = false;
        this.isSubmitting = false;
        this.hasSubmissionError = false;
      }
    },
  },
  methods: {
    async completeTenderOffer() {
      this.isSubmitting = true;
      this.hasSubmissionError = false;

      const userId = this.getUserId;
      const data = {
        userId,
        audience: "Supplier",
      };

      try {
        const response = await tenderService.completeTenderOfferBySupplier(
          this.tenderId,
          this.serviceOfferId,
          data,
        );

        if (response.status === 200) {
          this.tenderOfferCompleted = true;
          setTimeout(() => {
            this.$emit("completed");
          }, 1500);
        } else {
          this.hasSubmissionError = true;
        }
      } catch (error) {
        this.hasSubmissionError = true;
        console.error("Error completing tender offer: ", error);
      } finally {
        setTimeout(() => {
          this.isSubmitting = false;
        }, 1500);
      }
    },

    closeModal() {
      this.showModal = false;
    },

    handlePrimaryClick() {
      if (this.tenderOfferCompleted) this.closeModal();
      else this.completeTenderOffer();
    },
  },
};
</script>

<style lang="scss" scoped>
.error {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  color: $color-error;
  margin-top: 0.16667rem;
}
</style>
