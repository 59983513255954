<template>
  <app-link :to="to" class="menu-item" :class="menuItemClasses">
    <slot />
    <span>{{ label }}</span>
  </app-link>
</template>

<script lang="ts" setup>
import { computed } from "vue";
import { RouteLocationRaw } from "vue-router";
import AppLink from "@/components/Base/AppLink/AppLink.vue";

const props = withDefaults(
  defineProps<{
    active?: boolean;
    label: string;
    to: RouteLocationRaw | string;
  }>(),
  {
    active: false,
  },
);

const menuItemClasses = computed(() => {
  return props.active ? [`menu-item--active`] : [];
});
</script>

<style lang="scss" scoped>
.menu-item {
  height: 100%;
  width: 100%;
  border: none;
  background: #f7f0fa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-weight: 400;
  font-size: 0.875rem;
  gap: 0.25rem;

  &:hover {
    background: #f1e6f5;
  }

  &:active {
    background: #dfcfe5;
  }

  &--active {
    background: #f1e6f5;
    border-bottom: 2px solid #441b49;
  }
}
</style>
