<template>
  <div class="offer-sent">
    <img :src="offerSentIconUrl" alt="Sent" />
    <div class="offer-sent__title">{{ offerSentCms.title }}</div>
    <div class="offer-sent__description-text">
      <custom-block-content :blocks="offerSentCms.content" />
    </div>
    <div class="offer-sent__okay-button">
      <primary-button @click="close">{{ okayButtonText }}</primary-button>
    </div>
  </div>
</template>

<script>
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import {
  getCmsItem,
  getCmsItemText,
  getIconUrl,
} from "@/utilities/cmsUtilities";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";

export default {
  components: { CustomBlockContent, PrimaryButton },
  props: {
    sendOfferPageCms: {
      type: Object,
      required: true,
    },
  },
  emits: ["close"],
  computed: {
    offerSentCms() {
      return getCmsItem(this.sendOfferPageCms.pageSections, "offerSent");
    },
    okayButtonText() {
      return getCmsItemText(this.offerSentCms.buttons, "okayButton");
    },
    offerSentIconUrl() {
      return getIconUrl(this.offerSentCms.icons, "offerSentIcon");
    },
  },
  methods: {
    close() {
      this.$emit("close");
    },
  },
};
</script>

<style lang="scss" scoped>
.offer-sent {
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: $font-size-2xl;
    font-weight: 600;
  }

  &__description-text {
    margin-top: 2rem;
    text-align: center;
    font-size: $font-size-base;
  }

  &__okay-button {
    margin-top: 2.5rem;
  }
}
</style>
@/utilities/cmsUtilities
