<template>
  <base-new-offer-item
    :title="title"
    :left-icon-src="leftIconSrc"
    :right-icon-src="rightIconSrc"
    @clicked="createBlankOffer"
  />
</template>

<script>
import BaseNewOfferItem from "@/views/TenderOffer/CreateNewOffer/BaseNewOfferItem.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { BaseNewOfferItem },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
  },
  emits: ["new-offer"],
  computed: {
    title() {
      const text = this.pageCms.otherText.find(
        (otherText) => otherText.label === "blankOfferButtonText",
      );
      return text ? text.title : "";
    },
    leftIconSrc() {
      return this.getIconUrl(this.pageCms.icons, "newOfferIcon").url();
    },
    rightIconSrc() {
      return this.getIconUrl(this.pageCms.icons, "arrowRightIcon").url();
    },
  },
  methods: {
    createBlankOffer() {
      this.$emit("new-offer");
    },
    getIconUrl(icons, label) {
      const matchingIcon = icons.find((icon) => icon.label === label);
      return matchingIcon ? cmsService.resolveImage(matchingIcon.icon) : "";
    },
  },
};
</script>

<style lang="scss" scoped>
.blank-offer-link {
  display: flex;
  align-items: center;
  text-decoration: none;
  color: $color-black;
  background: $color-primary-pink-dark;
  padding: 1rem;
  text-align: left;
  border-radius: 4px;

  &:hover {
    background: $color-primary-pink-darker;
  }

  &__text {
    flex-grow: 1;
    font-weight: 400;
    font-size: $font-size-base;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}
</style>
