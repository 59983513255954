import { reactive } from "vue";

export const useCompanyProfileFormData = () => {
  const companyProfileFormData: { [key: string]: string } = reactive({
    orgNumberInput: "",
    contactPersonInput: "",
    emailInput: "",
    companyTurnoverInput: "",
    companyEmployeeNumberInput: "",
  });

  const updateCompanyProfileFormData = (key: string, value: string) => {
    companyProfileFormData[key] = value;
  };

  const getCompanyProfileFormData = (key: string) => {
    return companyProfileFormData[key] || "";
  };

  return {
    companyProfileFormData,
    updateCompanyProfileFormData,
    getCompanyProfileFormData,
  };
};
