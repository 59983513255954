import Cookies from "js-cookie";
import { AccountInfo } from "@azure/msal-browser";

const AUTHENTICATION_COOKIE_KEY = import.meta.env
  .VITE_APP_MSAL_SSO_COOKIE_NAME as string;

const getCookieDomain = (): string | undefined => {
  return import.meta.env.VITE_APP_COOKIE_DOMAIN || undefined;
};

interface UseAuthenticationCookieReturn {
  getAuthenticationCookieData: () => AccountInfo | null;
  setAuthenticationCookieData: (data: AccountInfo | null) => void;
}

export function useAuthenticationCookie(): UseAuthenticationCookieReturn {
  const getAuthenticationCookieData = (): AccountInfo | null => {
    const cookieData = Cookies.get(AUTHENTICATION_COOKIE_KEY);
    if (!cookieData) return null;

    try {
      return JSON.parse(cookieData);
    } catch (error) {
      console.error("Failed to parse authentication cookie:", error);
      return null;
    }
  };

  const setAuthenticationCookieData = (data: AccountInfo | null) => {
    Cookies.set(AUTHENTICATION_COOKIE_KEY, JSON.stringify(data), {
      expires: 1,
      domain: getCookieDomain(),
    });
  };

  return {
    getAuthenticationCookieData,
    setAuthenticationCookieData,
  };
}
