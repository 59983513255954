<template>
  <div class="no-conversations">
    Du har ingen meldinger knyttet til denne avtalen
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no-conversations {
  font-weight: 400;
  font-size: $font-size-sm;
  text-align: center;
  background: $color-primary-pink;
  border: 1px solid #9a9a9a;
  border-radius: 4px;
  padding: 3rem 1rem;
}
</style>
