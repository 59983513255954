export default {
  getUser: (state) => state.user,
  getUserId: (state) => state.user.userId,
  getUserName: (state) => state.user.name,
  getUserEmail: (state) => state.user.email,
  getSupplierId: (state) => state.supplierInfo.id,
  getSupplierUsers: (state) => state.supplierUsers,
  getSupplierRoles: (state) =>
    state.user.userAccount.idTokenClaims.extension_SecurityGroups
      ? state.user.userAccount.idTokenClaims.extension_SecurityGroups
      : state.user.userAccount.idTokenClaims.groups,
  getVisibleSupplierTypes: (state) =>
    state.supplierTypes.filter((supplierType) => !supplierType.adminHidden),
  getServiceTypes: (state) => state.serviceTypes,
  getErrors: (state) => state.errors,
};
