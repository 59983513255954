interface ClientConfig {
  projectId: string;
  dataset: string;
  useCdn: boolean;
  token: string;
  apiVersion: string;
}

const config: ClientConfig = {
  projectId: import.meta.env.VITE_APP_SANITY_PROJECT_ID,
  dataset: import.meta.env.VITE_APP_SANITY_DATASET,
  token: import.meta.env.VITE_APP_SANITY_API_TOKEN,
  useCdn: false,
  apiVersion: import.meta.env.VITE_APP_SANITY_API_VERSION,
};

export default config;
