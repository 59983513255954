const formatNumber = (value: number, separator: string = " "): string => {
  const parts = value.toString().split(".");
  parts[0] = parts?.[0]?.replace(/\B(?=(\d{3})+(?!\d))/g, separator) ?? "";
  return parts.join(".");
};

const roundNumber = (value: number, decimalsPlaces: number = 2): number => {
  const p: number = Math.pow(10, decimalsPlaces);
  return Math.round((value + Number.EPSILON) * p) / p;
};

const formatCurrency = (value: number): string => {
  return formatNumber(roundNumber(value, 0));
};

export { roundNumber, formatCurrency };
