<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="true"
    centered
    hide-footer
    hide-header
    body-class="p-0"
    size="lg"
  >
    <form class="activity-form">
      <div class="activity-form__title">
        <custom-block-content :blocks="resolvedTitle" />
      </div>
      <div class="activity-form__field">
        <div class="activity-form__field-title">
          {{ descriptionInputCms.title }}
        </div>
        <div class="activity-form__field-description">
          {{ descriptionInputCms.description }}
        </div>
      </div>
      <v-field
        v-slot="{ componentField, errors }"
        name="description"
        :rules="string().required()"
        :validate-on-input="true"
      >
        <div v-if="errors.length" class="activity-form__field-input-error">
          <input-error :text="descriptionInputCms.error" />
        </div>
        <div class="activity-form__field-input">
          <base-input
            v-bind="componentField"
            :placeholder="descriptionInputCms.placeholder"
          />
        </div>
      </v-field>
      <div
        v-if="showFrequencyOptions"
        class="activity-form__frequency-options-wrapper"
      >
        <frequency-options
          :activity-form-cms="activityFormCms"
          :frequency-options="frequencyOptions"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          @new-form-values="updateFormValues"
        />
      </div>
      <div v-if="showPriceCalculator" class="activity-form__price-calculator">
        <fixed-price-calculator
          v-if="costType === ActivityCostType.Fixed"
          :activity-form-cms="activityFormCms"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          :has-frame-agreement="hasFrameAgreement"
          :frame-agreement-cut="frameAgreementCut"
          @new-form-values="updateFormValues"
        />
        <variable-price-calculator
          v-else
          :activity-form-cms="activityFormCms"
          :service-activities-cms="serviceActivitiesCms"
          :form-errors="state.stabilizedFormErrors"
          :form-values="values"
          :has-frame-agreement="hasFrameAgreement"
          :frame-agreement-cut="frameAgreementCut"
          @new-form-values="updateFormValues"
        />
      </div>
      <div class="activity-form__action-buttons">
        <tertiary-button @click="closeModal">{{
          getButtonText("cancelButton")
        }}</tertiary-button>
        <primary-button @click="saveActivityAndClose">{{
          getButtonText("saveButton")
        }}</primary-button>
        <primary-button @click="saveActivityAndAddNew">{{
          getButtonText("saveAndAddNewButton")
        }}</primary-button>
      </div>
    </form>
  </b-modal>
</template>

<script setup>
import { computed, reactive, watch, nextTick } from "vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import { getCmsItemText } from "@/utilities/cmsUtilities";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import InputError from "@/components/InputError/InputError.vue";
import { useForm } from "vee-validate";
import { uuidv4 } from "@/utilities/uuid";
import { cloneDeep, isEqual } from "lodash";
import { Field as VField } from "vee-validate";
import { string } from "yup";
import FrequencyOptions from "@/components/ActivityModal/FrequencyOptions.vue";
import { ActivityCostType, JobType } from "@/custom-types/types";
import FixedPriceCalculator from "@/components/ActivityModal/FixedPriceCalculator.vue";
import VariablePriceCalculator from "@/components/ActivityModal/VariablePriceCalculator.vue";

const emit = defineEmits([
  "input",
  "new-activity-added",
  "add-new-activity",
  "update:modelValue",
]);

const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
  costType: {
    type: String, //Fixed, Variable
    required: true,
  },
  tender: {
    type: Object,
    required: true,
  },
  activity: {
    type: [Object, null],
    default: null,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  frequencyOptions: {
    type: Object,
    required: true,
  },
  supplierInfo: {
    type: Object,
    required: true,
  },
});

const {
  errors: formErrors,
  resetForm,
  setValues,
  handleSubmit,
  values,
} = useForm({
  keepValuesOnUnmount: true,
  initialValues: getNewActivity(),
});

const state = reactive({
  stabilizedFormErrors: {},
});

const showModal = computed({
  get() {
    return props.modelValue;
  },
  set(newVal) {
    emit("update:modelValue", newVal);
  },
});
const resolvedTitle = computed(() => {
  return props.activityFormCms.title.content;
});
const descriptionInputCms = computed(() => {
  return props.activityFormCms.inputs.find(
    (input) => input.label === "activityDescription",
  );
});
const showFrequencyOptions = computed(() => {
  return props.costType === ActivityCostType.Fixed;
});
const showPriceCalculator = computed(() => {
  return props.costType === ActivityCostType.Variable || values.frequencyGroup;
});

const hasFrameAgreement = computed(() => {
  const priceRequest = props.tender.priceRequests[0];
  const supplierRequest = priceRequest.supplierRequests.find(
    (request) => request.supplierId === props.supplierInfo.id,
  );
  return supplierRequest ? supplierRequest.hasFrameAgreement : false;
});

const frameAgreementCut = computed(() => {
  if (hasFrameAgreement.value) {
    const frameAgreement = props.supplierInfo.frameAgreements.find(
      (agreement) =>
        agreement.customerOrganizationId ===
        props.tender.customerOrganizationId,
    );
    const commission = frameAgreement?.commission;
    return commission ? commission : 0;
  }
  return 0;
});

watch(
  () => showModal.value,
  (newVal) => {
    if (newVal) {
      resetForm();
      if (props.activity) {
        setValues(cloneDeep(props.activity));
      }
    }
  },
);

watch(
  () => formErrors.value,
  (newVal) => {
    if (!isEqual(cloneDeep(newVal), cloneDeep(state.stabilizedFormErrors))) {
      state.stabilizedFormErrors = cloneDeep(newVal);
    }
  },
  { deep: true, immediate: true },
);

function getNewActivity() {
  return {
    id: uuidv4(),
    description: "",
    frequencyGroup: "",
    frequency: "",
    limitedPeriod: false,
    limitedPeriodStartMonth: "",
    limitedPeriodEndMonth: "",
    itemsCount: 0,
    itemsUnit: "",
    pricePerItem: 0,
    requestType: props.tender.priceRequests[0].requestType,
    jobType: JobType.Repeating,
    supplierCommission: props.supplierInfo.commission,
  };
}
const getButtonText = (label) => {
  return getCmsItemText(props.activityFormCms.buttons, label);
};
const saveActivity = async (activityForm) => {
  const activityFormCopy = cloneDeep(activityForm);
  activityFormCopy.itemsCount = parseFloat(`${activityFormCopy.itemsCount}`);
  activityFormCopy.pricePerItem = parseFloat(
    `${activityFormCopy.pricePerItem}`,
  );
  if (activityFormCopy.itemsCount) {
    activityFormCopy.baseAmount =
      activityFormCopy.itemsCount * activityFormCopy.pricePerItem;
  }
  await emit("new-activity-added", activityFormCopy);
};
const saveActivityAndClose = handleSubmit((values) => {
  saveActivity(values);
  closeModal();
});

const saveActivityAndAddNew = handleSubmit(async (values) => {
  await saveActivity(values);
  nextTick(() => {
    resetForm();
    emit("add-new-activity");
  });
});

const closeModal = () => {
  showModal.value = false;
};
const updateFormValues = (formValues) => {
  setValues(formValues, false);
};
</script>

<style lang="scss" scoped>
.activity-form {
  padding: 1.5rem 1.5rem 2rem 1.5rem;
  font-family: "Montserrat", serif;

  &__title :deep(*) {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__field {
    margin-top: 1.5rem;
  }

  &__field-title {
    font-weight: 600;
    font-size: $font-size-base;
  }

  &__field-description {
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__field-input-error,
  &__field-input {
    margin-top: 0.5rem;
  }

  &__action-buttons {
    margin-top: 1.5rem;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
    }
  }

  &__frequency-options-wrapper {
    margin-top: 1.5rem;
  }

  &__price-calculator {
    margin-top: 1.5rem;
  }
}

:deep(.modal-lg) {
  max-width: 680px !important;
}
</style>
@/utilities/cmsUtilities@/utilities/uuid @/types/AppTypes
