<template>
  <div ref="menuContainer" class="work-description-menu">
    <button class="work-description-menu__button" @click="toggleShowMenu">
      <img :src="`/images/24/alternatives.svg`" alt="Menu icon" />
    </button>
    <div v-if="showMenu" class="work-description-menu__dropdown">
      <base-menu :items="menuItems" @item-clicked="onMenuItemClick" />
    </div>
  </div>
</template>

<script>
import BaseMenu from "@/components/Base/BaseMenu/BaseMenu.vue";
import { uuidv4 } from "@/utilities/uuid";
import vClickOutside from "v-click-outside";
const { bind, unbind } = vClickOutside.directive;

export default {
  components: { BaseMenu },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  data() {
    return {
      showMenu: false,
      menuItems: [
        {
          id: uuidv4(),
          text: "Endre",
          eventName: "edit-item",
        },
        {
          id: uuidv4(),
          text: "Slett",
          eventName: "delete-item",
        },
      ],
    };
  },
  watch: {
    showMenu(newVal) {
      if (!this.$refs.menuContainer) {
        return;
      }
      if (newVal) {
        bind(this.$refs.menuContainer, {
          value: this.onOutsideClick,
        });
      } else {
        unbind(this.$refs.menuContainer);
      }
    },
  },
  methods: {
    toggleShowMenu() {
      this.showMenu = !this.showMenu;
    },
    onOutsideClick() {
      this.showMenu = false;
    },
    onMenuItemClick(item) {
      this.$emit(item.eventName);
    },
  },
};
</script>

<style lang="scss" scoped>
.work-description-menu {
  display: inline-block;
  position: relative;

  &__button {
    background: #fff;
    border: 1px solid $color-primary-pink-darker;
    border-radius: 4px;
    width: 3rem;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__dropdown {
    position: absolute;
    right: 0;
    width: 140px;
    top: 100%;
    z-index: 1;
  }
}
</style>
@/utilities/uuid
