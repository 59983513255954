<template>
  <div class="location-delivery-area">
    <LocationDeliveryAreaDisplaySection
      :supplier-addresses="items"
      :location-icon="locationIcon"
      :area-icon="areaIcon"
      :location-subtext="locationSubtext"
      :delivery-area-subtext="deliveryAreaSubtext"
      :delete-icon-src="deleteIconSrc"
      :is-editable="isEditing"
      @delete="removeItem"
    />
    <div v-for="(item, index) in editedItems" :key="index">
      <LocationDeliveryAreaForm
        :ref="
          (el) =>
            setLocationDeliveryAreaFormRef(
              el as InstanceType<typeof LocationDeliveryAreaForm> | null,
              index,
            )
        "
        :is-saving="isSaving"
        :location-placeholder="locationPlaceholder"
        :delivery-area-placeholder="deliveryAreaPlaceholder"
        :location-left-icon-src="locationLeftIconSrc"
        :delivery-area-left-icon-src="deliveryAreaLeftIconSrc"
        :delete-icon-src="deleteIconSrc"
        :exit-icon-src="exitIconSrc"
        @update:address="updateAddress(index, $event)"
        @update:delivery-area="updateDeliveryArea(index, $event)"
        @delete="removeItem(index)"
        @deleteAndAddNew="removeAddItem(index)"
      />
    </div>
    <div class="py-2">
      <InputError
        v-if="similarAddressExists"
        :text="'En lignende adresse finnes allerede. Slett tidligere adresse.'"
      />
    </div>
    <div class="location-delivery-area__actions">
      <div class="location-delivery-area__primary-actions">
        <ProfileButton
          class="location-delivery-area__button"
          :loading="isSaving"
          :disabled="isSaving"
          @click="onSave"
        >
          {{ saveButtonText }}
        </ProfileButton>
        <ProfileButton
          :is-cancel="true"
          :disabled="isSaving"
          class="location-delivery-area__button"
          @click="onCancel"
        >
          {{ cancelButtonText }}
        </ProfileButton>
      </div>
      <div class="location-delivery-area__add-location-button">
        <AddLocationButton
          :add-location-button-icon-src="addLocationButtonIconSrc"
          :disabled="disableAddLocationButton"
          @click="addItem"
        >
          {{ addLocationButtonText }}
        </AddLocationButton>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, PropType, computed, watchEffect, watch } from "vue";
import LocationDeliveryAreaForm from "./LocationDeliveryAreaForm.vue";
import LocationDeliveryAreaDisplaySection from "./LocationDeliveryAreaDisplaySection.vue";
import AddLocationButton from "./AddLocationButton.vue";
import { Address, SupplierAddress } from "@/stores/supplier/types";
import InputError from "@/components/InputError/InputError.vue";

interface ValidationResult {
  isValid: boolean;
  selectedAddress: SupplierAddress;
}

const props = defineProps({
  supplierAddresses: {
    type: Array as PropType<SupplierAddress[]>,
    default: () => [],
    required: true,
  },
  locationPlaceholder: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaPlaceholder: {
    type: String as PropType<string>,
    default: "",
  },
  locationLeftIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaLeftIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  deleteIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  isSaving: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  saveButtonText: {
    type: String as PropType<string>,
    default: "Save",
  },
  cancelButtonText: {
    type: String as PropType<string>,
    default: "Cancel",
  },
  addLocationButtonText: {
    type: String as PropType<string>,
    default: "Add Location",
  },
  addLocationButtonIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  exitIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  locationIcon: {
    type: String as PropType<string>,
    default: "",
  },
  areaIcon: {
    type: String as PropType<string>,
    default: "",
  },
  locationSubtext: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaSubtext: {
    type: String as PropType<string>,
    default: "",
  },
  isEditing: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits(["saveSupplierAddress", "cancel"]);

const items = ref(props.supplierAddresses);
const editedItems = ref<SupplierAddress[]>([]);

const similarAddressExists = ref<boolean>(false);
const isEditing = ref<boolean>(props.isEditing);
const canAddMore = ref(false);
const shouldValidate = ref(true);

const locationDeliveryAreaForms = ref<
  InstanceType<typeof LocationDeliveryAreaForm>[]
>([]);

const setLocationDeliveryAreaFormRef = (
  el: InstanceType<typeof LocationDeliveryAreaForm> | null,
  index: number,
) => {
  if (el) {
    locationDeliveryAreaForms.value[index] = el;
  }
};

const disableAddLocationButton = computed(() => {
  return !canAddMore.value || props.isSaving;
});

const updateCanAddMore = async () => {
  if (!shouldValidate.value) {
    canAddMore.value = true;
    return;
  }
  const validationResults = await Promise.all(
    locationDeliveryAreaForms.value.map((form) => form.validate()),
  );
  canAddMore.value = validationResults.every((result) => result.isValid);
};

watchEffect(() => {
  updateCanAddMore();
});

const addEmptyItem = () => {
  console.log("addEmptyItem");

  editedItems.value.push(createEmptyItem());
  isEditing.value = true;
};

const resetEditedItems = () => {
  editedItems.value = [];
};

const createEmptyItem = () => ({
  address: {
    id: null,
    address: "",
    city: "",
    postalCode: "",
    county: "",
  },
  reservationKilometers: 0,
});

watch(
  () => props.isEditing,
  () => {
    // if isEditing is false, reset editedItems
    if (!props.isEditing) {
      editedItems.value = [];
    } else {
      // if isEditing is true, add empty item
      addEmptyItem();
    }
  },
  { immediate: true, deep: true },
);

const removeAddItem = (index: number) => {
  removeItem(index);
  addItem();
};

const removeItem = (index: number) => {
  shouldValidate.value = false;
  const item = items.value[index];
  if (item && item.address.id) {
    items.value.splice(index, 1);
    //addEmptyItem();
  }
  if (items.value.length !== 0) {
    editedItems.value.splice(index, 1);
  }
  locationDeliveryAreaForms.value.splice(index, 1);
  setTimeout(() => {
    shouldValidate.value = true;
  }, 100);
};

const updateAddress = (index: number, address: Address) => {
  const similarAddress = items.value.find((item) =>
    isSimilarAddress(item.address, address),
  );
  if (similarAddress) {
    handleSimilarAddress();
  }
  editedItems.value[index].address = address;
};

const updateDeliveryArea = (index: number, deliveryArea: string) => {
  editedItems.value[index].reservationKilometers = parseFloat(deliveryArea);
};

const handleSimilarAddress = () => {
  similarAddressExists.value = true;
  setTimeout(() => {
    similarAddressExists.value = false;
  }, 4000);
};

const isSimilarAddress = (address1: Address, address2: Address) => {
  return (
    address1?.address?.toLowerCase() === address2.address?.toLowerCase() &&
    address1?.county?.toLowerCase() === address2?.county?.toLowerCase()
  );
};

const saveItem = async (supplierAddresses: SupplierAddress[]) => {
  emit("saveSupplierAddress", {
    supplierAddresses,
    stillEditing: isEditing.value,
  });
};

const validateForms = async (): Promise<{
  isValid: boolean;
  validationResults: ValidationResult[];
}> => {
  const validationResults = await Promise.all(
    locationDeliveryAreaForms.value.map((form) => form.validate()),
  );

  const isValid = validationResults.every((result) => result.isValid);
  return { isValid, validationResults };
};

const hasSimilarAddress = (): boolean => {
  return editedItems.value.some((editedItem) =>
    items.value.some((item) =>
      isSimilarAddress(item?.address, editedItem.address),
    ),
  );
};

const saveSupplierAddresses = async (
  supplierAddresses: SupplierAddress[],
): Promise<void> => {
  await saveItem(supplierAddresses);
  items.value = supplierAddresses;
  resetEditedItems();
};

const addItem = async () => {
  if (!isEditing.value || !canAddMore.value) {
    return;
  }

  const { isValid } = await validateForms();

  if (!isValid) {
    return;
  }

  if (editedItems.value.length === 0) {
    shouldValidate.value = false;
    addEmptyItem();
    return;
  }

  if (hasSimilarAddress()) {
    handleSimilarAddress();
    return;
  }

  const supplierAddresses = [...items.value, ...editedItems.value];
  await saveSupplierAddresses(supplierAddresses);
};

const onSave = async () => {
  const { isValid } = await validateForms();

  if (hasSimilarAddress()) {
    handleSimilarAddress();
    return;
  }

  if (isValid) {
    const supplierAddresses = [...items.value, ...editedItems.value];
    isEditing.value = false;
    await saveItem(supplierAddresses);
    editedItems.value = [];
  }
};

const onCancel = () => {
  emit("cancel");
  isEditing.value = false;
  editedItems.value = [];
  locationDeliveryAreaForms.value = [];
};
</script>

<style lang="scss" scoped>
.location-delivery-area {
  &__actions {
    display: flex;
    justify-content: space-between;
    margin-top: 1rem;
  }

  &__primary-actions {
    display: flex;
    flex-direction: row;
    gap: 1rem;
  }

  &__add-location-button {
    margin-right: 4.7rem;
  }
}
</style>
