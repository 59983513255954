<template>
  <div class="tender-conversations">
    <template v-if="conversations">
      <conversation-thread
        v-if="showConversation"
        :show-back-button="true"
        :conversation="selectedConversation"
        :tender="tender"
        @back-button-clicked="onBackButtonClicked"
        @send-message="sendMessage"
      />
      <template v-else>
        <button
          v-if="showNewThreadButton"
          class="tender-conversations__new-conversation"
          @click="createNewConversation"
        >
          Ny meldingstråd
        </button>
        <conversation-list
          v-if="conversations.length"
          :conversations="conversations"
          :recipient-name="customerName"
          @conversation-clicked="onConversationClicked"
        />
        <no-conversations v-else />
      </template>
    </template>
    <div v-else class="tender-conversations__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script>
import ConversationThread from "@/components/Conversation/ConversationThread.vue";
import ConversationList from "@/components/Conversation/ConversationList.vue";
import supplierCommunicationService from "@/services/supplierCommunicationService";
import NoConversations from "@/components/Conversation/NoConversations.vue";
import { MessageDomain, MessageType, TenderState } from "@/custom-types/types";
import { getMessageAttachmentDto } from "@/utilities/fileUtilities";

export default {
  components: { NoConversations, ConversationThread, ConversationList },
  props: {
    tender: {
      type: Object,
      required: true,
    },
    priceRequest: {
      type: Object,
      required: true,
    },
    supplierId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      conversations: null,
      showConversation: false,
      selectedConversationId: "",
    };
  },
  computed: {
    showNewThreadButton() {
      return this.tender.tenderState === TenderState.Accepted;
    },
    selectedConversation() {
      if (!this.conversations) {
        return null;
      }
      return this.conversations.find(
        (conversation) =>
          conversation.latestMessage.conversationId ===
          this.selectedConversationId,
      );
    },
    user() {
      return this.$store.state.api.user;
    },
    supplierRequest() {
      return this.priceRequest.supplierRequests.find(
        (supplierRequest) => supplierRequest.supplierId === this.supplierId,
      );
    },
    customerName() {
      return this.tender.customerOrganization.name;
    },
  },
  created() {
    this.fetchConversations();
  },
  methods: {
    async fetchConversations() {
      try {
        const supplierRequestId = this.supplierRequest?.id || "";
        // Guard clause for empty supplierRequestId
        if (!supplierRequestId) {
          console.warn("No supplierRequestId found. Skipping fetch operation.");
          this.conversations = [];
          return;
        }
        const response =
          await supplierCommunicationService.fetchSupplierConversations(
            supplierRequestId,
          );
        this.conversations = response.data;
      } catch (error) {
        console.error("Failed to fetch supplier conversations:", error);
        // Handle the error
        this.conversations = [];
      }
    },
    async fetchConversationMessages(conversationId) {
      const response =
        await supplierCommunicationService.fetchConversationMessages(
          conversationId,
        );
      const conversation = response.data;
      if (!this.conversations) {
        this.conversations = [];
      }
      const matchingConversation = this.conversations.find(
        (conversation) =>
          conversation.latestMessage.conversationId === conversationId,
      );
      if (matchingConversation) {
        Object.assign(matchingConversation, conversation);
      } else {
        this.conversations.push(conversation);
      }
    },
    onConversationClicked(conversationId) {
      this.selectedConversationId = conversationId;
      const matchingConversation = this.conversations?.find(
        (conversation) =>
          conversation.latestMessage.conversationId === conversationId,
      );
      if (!matchingConversation?.thread?.length) {
        this.fetchConversationMessages(conversationId);
      }
      this.showConversation = true;
    },
    onBackButtonClicked() {
      this.showConversation = false;
      this.selectedConversationId = "";
    },
    createNewConversation() {
      this.selectedConversationId = "";
      this.showConversation = true;
    },
    async sendMessage(payload) {
      const conversation = this.conversations?.find(
        (conversation) =>
          conversation.latestMessage.conversationId === payload.conversationId,
      );
      const messageAttachments = await Promise.all(
        payload.attachments.map((attachment) =>
          getMessageAttachmentDto(attachment),
        ),
      );
      const messageDto = {
        conversationId: conversation
          ? conversation.latestMessage.conversationId
          : null,
        messageType: MessageType.GeneralMessage,
        messageBody: payload.message,
        attachments: messageAttachments,
        senderUserId: this.user.userId,
        senderName: this.user.name,
        senderEmail: this.user.email,
        supplierId: this.supplierRequest?.supplierId,
        supplierRequestId: this.supplierRequest?.id,
        tenderId: this.tender.tenderId,
        rootMessage: !conversation,
        messageDomain: MessageDomain.SupplierCustomer,
        price: null,
      };
      try {
        const newMessageResponse =
          await supplierCommunicationService.sendMessage(messageDto);
        const conversationId = newMessageResponse.data.conversationId;
        await this.fetchConversationMessages(conversationId);
        this.selectedConversationId = conversationId;
        payload.successCallback();
        payload.errorCallback();
      } catch (error) {
        console.log("Error sending message: ", error);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.tender-conversations {
  &__new-conversation {
    background: #611f69;
    border-radius: 6px;
    color: white;
    font-weight: 600;
    font-size: $font-size-base;
    border: none;
    padding: 1rem 2.5rem;
    text-transform: uppercase;
    margin-bottom: 1rem;
  }

  &__loader {
    padding: 4rem 0;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
</style>
@/utilities/fileUtilities @/types/AppTypes
