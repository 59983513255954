<template>
  <div class="new-message">
    <div class="text-end pe-3 py-3">
      <input-error v-if="errorMessage" :text="errorMessage" />
    </div>
    <div v-if="attachments.length" class="new-message__attachments">
      <div
        v-for="attachment in attachments"
        :key="attachment.id"
        class="new-message__attachment"
      >
        <img
          :src="`/images/document_24.svg`"
          alt="Document icon"
          class="new-message__attachment-left-icon"
        />
        <span class="new-message__attachment-title">
          {{ getAttachmentName(attachment) }}
        </span>
        <button
          class="new-message__attachment-remove-button"
          @click="removeAttachment(attachment)"
        >
          <img
            :src="`/images/cross_20.svg`"
            alt="Remove icon"
            class="new-message__attachment-right-icon"
          />
        </button>
      </div>
    </div>
    <div class="new-message__main-content">
      <div class="new-message__input-section" :class="inputSectionClasses">
        <textarea
          ref="textArea"
          v-model="inputMessage"
          class="new-message__input"
          placeholder="Lurer du på noe...?"
          rows="1"
          @input="clearInputError"
        />
        <file-input
          v-slot="{ openFileInput }"
          :valid-file-extensions="validFileExtensions"
          @selected-files="handleSelectedFiles"
        >
          <button class="new-message__attachment-button" @click="openFileInput">
            <img :src="`/images/attachment.svg`" alt="Attachment" />
          </button>
        </file-input>
      </div>
      <button class="new-message__send-button" @click="sendMessage">
        <b-spinner v-if="isSendingMessage" type="grow" small />
        <template v-else>
          <img :src="`/images/send.svg`" alt="Send" />
          <span>Send</span>
        </template>
      </button>
    </div>
    <div v-if="error" class="new-message__input-error">
      {{ error }}
    </div>
  </div>
</template>

<script>
import autosize from "autosize/dist/autosize";
import {
  getFilesWithValidExtension,
  getFilesWithValidSize,
} from "@/utilities/fileUtilities";
import { uuidv4 } from "@/utilities/uuid";
import FileInput from "@/components/FileInput/FileInput.vue";
import InputError from "@/components/InputError/InputError.vue";

export default {
  components: { FileInput, InputError },
  props: {
    conversation: {
      type: [Object, null],
      default: null,
    },
  },
  emits: ["send-message"],
  data() {
    return {
      isSendingMessage: false,
      inputMessage: "",
      error: "",
      errorMessage: "",
      attachments: [],
      validFileExtensions: [".pdf", ".jpg", ".jpeg", ".png"],
      maxFileSizeInMB: 20,
      maxAllowedFiles: 20,
    };
  },
  computed: {
    inputSectionClasses() {
      return this.error ? ["new-message__input-section--error"] : [];
    },
  },
  mounted() {
    autosize(this.$refs.textArea);
  },
  methods: {
    sendMessage() {
      const inputMessage = this.inputMessage.trim();
      if (this.isSendingMessage) {
        return;
      }
      if (!inputMessage) {
        this.error = "Dette er påkrevd";
        return;
      }
      this.isSendingMessage = true;
      this.$emit("send-message", {
        message: inputMessage,
        attachments: this.attachments.map((attachment) => attachment.file),
        conversationId: this.conversation
          ? this.conversation.latestMessage.conversationId
          : null,
        successCallback: () => {
          this.attachments = [];
          this.inputMessage = "";
          this.$nextTick(() => {
            autosize.update(this.$refs.textArea);
          });
          this.isSendingMessage = false;
        },
        errorCallback: () => {
          setTimeout(() => {
            if (this.isSendingMessage) {
              this.errorMessage = "Noe gikk galt. Prøv igjen.";
              this.isSendingMessage = false;
            }
          }, 6000);
          setTimeout(() => {
            this.errorMessage = "";
          }, 4000);
        },
      });
    },
    clearInputError() {
      this.error = "";
    },
    removeAttachment(attachment) {
      for (let i = this.attachments.length - 1; i >= 0; i--) {
        if (this.attachments[i].id === attachment.id) {
          this.attachments.splice(i, 1);
        }
      }
    },
    handleSelectedFiles(files) {
      const selectedFiles = Array.from(files);
      let validFiles = getFilesWithValidExtension(
        selectedFiles,
        this.validFileExtensions,
      );
      validFiles = getFilesWithValidSize(validFiles, this.maxFileSizeInMB);
      const selectedAttachments = validFiles.map((file) => {
        return {
          id: uuidv4(),
          file: file,
        };
      });
      selectedAttachments.forEach((attachment) => {
        this.attachments.push(attachment);
      });
    },
    getAttachmentName(attachment) {
      return attachment.file.name;
    },
  },
};
</script>

<style lang="scss" scoped>
.new-message {
  margin: 1rem;

  &__main-content {
    display: flex;
    align-items: flex-end;
  }

  &__input-section {
    flex-grow: 1;
    display: flex;
    align-items: center;
    background: #f1f1f1;
    border-radius: 4px;
    padding: 0.25rem 0.25rem 0.25rem 1rem;

    &--error {
      border: 1px solid #ff0000;
    }
  }

  &__input {
    flex-grow: 1;
    background-color: transparent;
    border: none;
    outline: none;
    font-weight: 400;
    font-size: $font-size-sm;
    resize: none;
    max-height: 10.625rem;
  }

  &__attachment-button {
    width: 2.5rem;
    height: 2.5rem;
    display: flex;
    align-self: flex-end;
    align-items: center;
    justify-content: center;
    border: none;
    background-color: transparent;

    &:hover {
      background-color: #f9f9f9;
    }
  }

  &__send-button {
    margin-left: 0.5rem;
    border: none;
    height: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.625rem;
    background-color: #611f69;
    color: white;
    font-weight: 400;
    font-size: $font-size-base;
    width: 6.75rem;
    border-radius: 4px;
  }

  &__input-error {
    font-weight: 600;
    color: #ff0000;
    font-size: $font-size-sm;
    margin-top: 0.1rem;
  }

  &__file-input {
    display: none;
  }

  &__attachments {
    display: flex;
    flex-wrap: wrap;
    gap: 0.5rem;
    margin-bottom: 0.5rem;
  }

  &__attachment {
    border: 1px solid #000000;
    border-radius: 4px;
    padding: 0.5rem;
    display: flex;
    align-items: center;
    width: 14.375rem;
  }

  &__attachment-title {
    flex-grow: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__attachment-remove-button {
    flex-shrink: 0;
    border: none;
    border-radius: 4px;
    padding: 0;
    background: transparent;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background: #f1f1f1;
    }
  }
}
</style>
@/utilities/fileUtilities@/utilities/uuid
