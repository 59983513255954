import state from "@/store/modules/api/state";
import repository from "@/store/repository";
import { defaultUser } from "@/store/modules/api/commons";

export default {
  savePriceReady(state, ready) {
    state.savePriceReady = ready;
  },
  reloadSuppliers() {
    if (repository.exists("suppliers"))
      state.suppliers = repository.get("suppliers");
  },
  saveUser(state, user) {
    state.user = user;
    repository.save("user", user);
  },
  saveSupplierInfo(state, supplierInfo) {
    state.supplierInfo = supplierInfo;
    repository.save("supplierInfo", supplierInfo);
  },
  saveSupplierUsers(state, users) {
    state.supplierUsers = users;
  },
  saveSupplierDetails(state, supplierDetails) {
    state.supplierDetails = supplierDetails;
    repository.save("supplierDetails", supplierDetails);
  },
  deleteUser(state) {
    state.user = defaultUser;
    repository.delete("user");
  },
  reloadUser(state) {
    if (repository.exists("user")) state.user = repository.get("user");
  },
  saveSupplier(state, supplierDetails) {
    state.suppliers[supplierDetails.name] = supplierDetails.value;
    state.suppliers.ready = true;
    repository.save("suppliers", state.suppliers);
  },
  clearSuppliers(state) {
    state.suppliers = {
      ready: false,
    };
    repository.delete("suppliers");
  },
  saveForm(state, formDetails) {
    if (formDetails.append) {
      state.form[formDetails.key] = state.form[formDetails.key].concat(
        formDetails.value,
      );
    } else state.form[formDetails.key] = formDetails.value;
    repository.save("form", state.form);
  },
  reloadForm(state) {
    if (repository.exists("form")) state.form = repository.get("form");
  },
  saveTender(state, tender) {
    state.tender = tender;
  },
  saveCompany(state, company) {
    state.company = company;
  },
  saveSupplierTypes(state, supplierTypes) {
    state.supplierTypes = supplierTypes;
    repository.save("supplierTypes", state.supplierTypes);
  },
  savePropertyTypes(state, propertyTypes) {
    state.propertyTypes = propertyTypes;
  },
  saveSupplierSegments(state, supplierSegments) {
    state.supplierSegments = supplierSegments;
  },
  saveSupplierSegmentsParams(state, supplierSegmentsParams) {
    state.supplierSegmentsParams = supplierSegmentsParams;
  },
  saveEditedRange(state, range) {
    const index = state.editedRanges.findIndex((r) => r.id === range.id);
    if (index !== -1) {
      state.editedRanges.splice(index, 1, range);
    } else {
      state.editedRanges.push(range);
    }
  },
  saveRange(state, range) {
    const index = state.ranges.findIndex((r) => r.id === range.id);
    if (index !== -1) {
      state.ranges.splice(index, 1, range);
    } else {
      state.ranges.push(range);
    }
  },
  saveSupplierTypePricingPackages(state, pricingPackages) {
    state.supplierTypePricingPackages = pricingPackages;
  },
  setYourPricesData(state, pricingRanges) {
    if (pricingRanges.length > 0) {
      pricingRanges.forEach((pricingRange) => {
        const index = state.pricing.findIndex(
          (p) => p.groupId === pricingRange.groupId,
        );
        if (index === -1) state.pricing.push(pricingRange);
      });
    } else state.pricing = [];
  },
  saveEditedPriceForPosting(state, prices) {
    state.editedPrices = prices;
  },
  saveDeletedPriceForPosting(state, price) {
    state.deletedPrices.push(price);
  },
  saveDeletedRangesForPosting(state, range) {
    state.deletedRanges = range;
  },
  removeEditedPriceForPosting(state, editedPriceArr) {
    state.editedPrices = editedPriceArr;
  },
  removeDeletedPriceForPosting(state, deletedPriceArr) {
    state.deletedPrices = deletedPriceArr;
  },
  reloadSupplierPrices(state, reload) {
    state.reloadPrices = reload;
  },
  loadingPrices(state, loading) {
    state.isPostingPrices = loading;
  },
  saveErrors(state, errors) {
    state.errors = errors;
  },
};
