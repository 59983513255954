import {
  TenderState,
  RequestType,
  RequestState,
  BillingCycle,
  OfferStatus,
} from "@/custom-types/types";
import { cloneDeep } from "lodash";

export const getTendersFromStore = (state) => state.offers || [];
export const isManualTender = (tender) =>
  tender.priceRequests?.[0]?.requestType !== RequestType.Robot;

export const isTenderSingleJob = (tender) => {
  if (
    !tender ||
    !tender.supplierPortfolios ||
    !tender.supplierPortfolios.length
  ) {
    return false;
  }

  const acceptedServiceOffer = tender.supplierPortfolios
    .flatMap((portfolio) => portfolio.serviceTypePackagePortfolio || [])
    .flatMap((packagePortfolio) => packagePortfolio.serviceOffers || [])
    .find((serviceOffer) => serviceOffer.offerState === OfferStatus.Accepted);

  return acceptedServiceOffer?.billingCycle === BillingCycle.Fixed;
};

export const mapToAddressTenders = (tenders) =>
  tenders
    .sort((a, b) => new Date(b.createdOn) - new Date(a.createdOn))
    .map((tender) => ({
      tenders: [tender],
      address: tender.priceRequests?.[0]?.customerPropertyAddress,
    }));

export const filterTendersByState = (tenders, states) =>
  tenders.filter((tender) => states.includes(tender.tenderState));
export const filterNotRejectedTenders = (tenders) =>
  tenders.filter(
    (tender) =>
      tender.priceRequests?.[0]?.supplierRequests?.[0]?.requestState !==
      RequestState.Rejected,
  );
export const filterManualTenders = (tenders) => tenders.filter(isManualTender);

export const createActiveTenders = (tenders) => {
  const activeTenders = [];
  const portfolioPriceRequests = [];
  tenders.forEach((tender) => {
    if (tender.priceRequests.length > 1) {
      tender.priceRequests.forEach((priceRequest) => {
        const pR = {
          tender: tender,
          priceRequest: priceRequest,
        };
        portfolioPriceRequests.push(pR);
      });
    } else {
      activeTenders.push(tender);
    }
  });

  portfolioPriceRequests.forEach((pr) => {
    const tender = { ...pr.tender };
    const customerPropertyId = pr.priceRequest.customerPropertyId;
    tender.priceRequests = [pr.priceRequest];

    tender.supplierPortfolios = tender.supplierPortfolios.map((supplier) => ({
      ...supplier,
      serviceTypePackagePortfolio: supplier.serviceTypePackagePortfolio.map(
        (pkg) => ({
          ...pkg,
          serviceOffers: pkg.serviceOffers.filter(
            (offer) => offer.customerPropertyId === customerPropertyId,
          ),
        }),
      ),
    }));

    activeTenders.push(tender);
  });

  return activeTenders;
};

export const getTenders = (state, tenderStates) => {
  const tenders = cloneDeep(getTendersFromStore(state));
  const filteredTenders = filterTendersByState(tenders, tenderStates);
  const notRejectedTenders = filterNotRejectedTenders(filteredTenders);
  const manualTenders = filterManualTenders(notRejectedTenders);

  return mapToAddressTenders(manualTenders);
};

export const getRejectedTenderOffers = (state) => {
  const tenders = cloneDeep(getTendersFromStore(state));

  const requestedStates = [
    TenderState.PriceRequested,
    TenderState.InformationRequested,
  ];

  const filteredTenders = filterTendersByState(tenders, requestedStates);

  const rejectedTenders = filteredTenders.filter((tender) =>
    tender.priceRequests?.[0]?.supplierRequests?.some(
      ({ requestState }) => requestState === RequestState.Rejected,
    ),
  );

  const manualTenders = filterManualTenders(rejectedTenders);

  return mapToAddressTenders(manualTenders);
};

export const getRejectedTenders = (state, tenderStates) => {
  const tenders = cloneDeep(getTendersFromStore(state));
  const filteredTenders = filterTendersByState(tenders, tenderStates);
  const rejectedTenders = filteredTenders.filter(
    (tender) => tender.tenderState === TenderState.Rejected,
  );
  return mapToAddressTenders(rejectedTenders);
};
