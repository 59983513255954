<template>
  <div class="section-header">
    <div class="section-header__title-icon-wrapper">
      <h2 class="section-header__title">{{ headerText }}</h2>
      <img
        class="section-header__info-icon"
        :src="infoIconSrc"
        alt="Info"
        @click="onInfoClick"
      />
    </div>
    <button
      v-if="!showEditButton"
      class="section-header__edit-button"
      @click="onEditClick"
    >
      <img :src="editIconSrc" alt="Edit" />
      {{ editButtonText }}
    </button>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, PropType } from "vue";

defineProps({
  headerText: {
    type: String as PropType<string>,
    required: true,
  },
  infoIconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  editIconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  editButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  showEditButton: {
    type: Boolean as PropType<boolean>,
    required: true,
  },
});

const emit = defineEmits(["info", "edit"]);

const onInfoClick = () => {
  emit("info");
};

const onEditClick = () => {
  emit("edit");
};
</script>

<style lang="scss" scoped>
.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-bottom: 1px solid #1d1d1d;
  padding: 0;

  &__title-icon-wrapper {
    display: flex;
    align-items: center;
  }

  &__title {
    font-size: $font-size-xs;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    text-transform: uppercase;
  }

  &__info-icon {
    margin-left: 0.5rem;
    margin-bottom: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
  }

  &__edit-button {
    padding: 0.5rem 1rem;
    background-color: transparent;
    border: none;
    cursor: pointer;
    font-size: $font-size-sm;
    font-weight: 400;

    &:hover {
      background-color: #f5f5f5;
    }

    img {
      margin-right: 0.5rem;
      width: 1.5rem;
      height: 1.5rem;
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: translateY(1px);
    }

    &:disabled {
      cursor: not-allowed;
      opacity: 0.5;
    }

    &:disabled:hover {
      background-color: transparent;
    }
  }
}
</style>
