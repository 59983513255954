import { SystemApi, SystemApiEndpoints } from "@/config/api/system/systemApi";
import { executeApiRequest } from "@/services/api/apiService";
import { Supplier, ServiceCategory } from "@/stores/supplier/types";
import { ServiceType } from "@/stores/supplier/types";

const getEndpointFactory = (endpointKey: symbol) => ({
  endpointKey,
  config: SystemApi,
});

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const createSupplierTenderOffer = async (data: any) => {
  return executeApiRequest<Supplier>(
    getEndpointFactory(
      Symbol.for(SystemApiEndpoints.CREATE_SUPPLIER_TENDER_OFFER),
    ),
    {
      data: data,
    },
  );
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const updateSupplierTenderOffer = async (data: any) => {
  return executeApiRequest<Supplier>(
    getEndpointFactory(
      Symbol.for(SystemApiEndpoints.UPDATE_SUPPLIER_TENDER_OFFER),
    ),
    {
      data: data,
    },
  );
};

const getActivityFormFrequencyOptions = async () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  return executeApiRequest<any>(
    getEndpointFactory(
      Symbol.for(SystemApiEndpoints.GET_ACTIVITY_FORM_FREQUENCY_OPTIONS),
    ),
  );
};

const getServiceCategories = async () => {
  return executeApiRequest<ServiceCategory[]>(
    getEndpointFactory(Symbol.for(SystemApiEndpoints.GET_SERVICE_CATEGORIES)),
  );
};

const getServiceTypes = async () => {
  return executeApiRequest<ServiceType[]>(
    getEndpointFactory(Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES)),
  );
};

const getServiceTypeCategories = async (serviceTypeId: number) => {
  return executeApiRequest<ServiceCategory[]>(
    getEndpointFactory(
      Symbol.for(SystemApiEndpoints.GET_SERVICE_TYPES_CATEGORIES),
    ),
    {
      pathParams: [serviceTypeId, "serviceCategories", "{lang=no}"],
    },
  );
};

type UseSystemApi = () => {
  createSupplierTenderOffer: typeof createSupplierTenderOffer;
  updateSupplierTenderOffer: typeof updateSupplierTenderOffer;
  getActivityFormFrequencyOptions: typeof getActivityFormFrequencyOptions;
  getServiceCategories: typeof getServiceCategories;
  getServiceTypes: typeof getServiceTypes;
  getServiceTypeCategories: typeof getServiceTypeCategories;
};

export const useSystemApi: UseSystemApi = () => {
  return {
    createSupplierTenderOffer,
    updateSupplierTenderOffer,
    getActivityFormFrequencyOptions,
    getServiceCategories,
    getServiceTypes,
    getServiceTypeCategories,
  };
};
