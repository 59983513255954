<template>
  <div :class="['industry-section', 'text-left', sectionClass]">
    <price-robot-header
      :header-content-prop="cmsContent.industry.headerContent"
    />

    <industry-service-types
      v-if="showServiceTypes"
      class="industry-section__service-types"
      :valid-service-types="validSupplierTypes"
      @service-type-selected="onServiceTypeSelected"
    />

    <price-robot-footer v-if="showPriceRobotFooter" />
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, onMounted, reactive } from "vue";
import { useStore } from "vuex";
import IndustryServiceTypes from "@/components/IndustryServices/IndustryServiceTypes.vue";
import PriceRobotHeader from "@/components/PriceRobot/PriceRobotHeader.vue";
import PriceRobotFooter from "@/components/PriceRobot/PriceRobotFooter.vue";

// Define props
const props = defineProps({
  cmsContent: {
    type: Object,
    required: true,
    default: () => ({}),
  },
  showServiceTypes: {
    type: Boolean,
    default: false,
  },
  showPriceRobotFooter: {
    type: Boolean,
    default: false,
  },
  validSupplierTypes: {
    type: Array,
    default: () => [],
  },
});

// Define emits
const emit = defineEmits(["service-type-selected"]);

// Use Vuex store
const store = useStore();

// State Reactive
const state = reactive({
  crookedArrowIcon: "",
  bottomArrowIcon: "",
});

// Computed properties
const sectionClass = computed(() => (props.showServiceTypes ? "" : "offices"));

// Methods
const onServiceTypeSelected = (serviceType) => {
  store.dispatch("cms/selectSupplierType", serviceType);
  emit("service-type-selected", serviceType);
};

const safeImage = (imagePath) => {
  try {
    return `/images/${imagePath}`;
  } catch (error) {
    console.error(`Failed to load image at path: ${imagePath}`, error);
    return "";
  }
};

// Lifecycle hooks
onMounted(() => {
  state.crookedArrowIcon = safeImage(
    props.cmsContent.industry.crookedArrowIcon,
  );
  state.bottomArrowIcon = safeImage(props.cmsContent.bottomArrowIcon);
});
</script>

<style lang="scss" scoped>
$border-color: #fff;

.industry-section {
  font-weight: $font-weight-semi-bold;
  font-size: $font-size-base;
  color: $color-primary-darker;
  background-color: $color-primary-pink;
  border-bottom: 0.375rem solid $border-color;
  padding-top: 5rem;

  &__arrow {
    &--crooked {
      margin-left: 4rem;
    }
    &--bottom {
      margin-left: 2.2rem;
    }
  }
}

.offices {
  padding-bottom: 3rem;
  border-bottom: none !important;
}
</style>
