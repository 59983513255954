import actions from "@/store/modules/offers/actions";
import getters from "@/store/modules/offers/getters";
import mutations from "@/store/modules/offers/mutations";
import state from "@/store/modules/offers/state";

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
