import { AccountInfo } from "@azure/msal-browser";
import { ReallyAdmin } from "@/custom-types/GeneralTypes";

interface UseCheckUserAccessFns {
  userIsReallyAdmin: (userAccount: AccountInfo) => boolean;
}

export function useCheckUserAccess(): UseCheckUserAccessFns {
  const userIsReallyAdmin = (userAccount: AccountInfo): boolean => {
    const securityGroups = getSecurityGroups(userAccount);
    return (
      securityGroups !== null &&
      securityGroups.includes(ReallyAdmin.REALLY_ADMIN)
    );
  };

  const getSecurityGroups = (
    userAccount: AccountInfo | undefined,
  ): string | null => {
    const securityGroups =
      userAccount?.idTokenClaims?.extension_SecurityGroups ||
      userAccount?.idTokenClaims?.groups;

    if (!securityGroups) {
      return null;
    }
    return securityGroups as string;
  };

  return { userIsReallyAdmin };
}
