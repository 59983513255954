export const rejectPriceRequestCms = {
  rejectRequest: "Avvis forespørsel",
  helpUnderstand:
    "Hjelp oss med å forstå hvorfor du avviser denne forespørselen.",
  reasons: [
    { id: 1, text: "Vi leverer ikke denne tjenesten" },
    { id: 2, text: "Vi tar ikke imot flere oppdrag pr d.d" },
    { id: 3, text: "Dårlig fit for mitt selskap" },
    { id: 4, text: "Annet" },
  ],
  provideReason: "Oppgi en begrunnelse",
  reasonPlaceholder: "Beskriv årsaken",
  importantNote:
    "Viktig: når du avviser denne forespørselen vil du ikke lenger kunne gi pris. Alle priser som eventuelt er gitt, vil bli slettet og kunde vil ikke lenger kunne velge deg som alternativ til dette oppdraget.",
  provideReasonError: "Vennligst gi en begrunnelse over.",
  customReasonError: "Vennligst oppgi en egendefinert årsak.",
  rejectRequestButton: "Avvis forespørsel",
  cancelButton: "Avbryt",
};
