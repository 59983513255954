import { loginRequest, msalInstance } from "@/config/auth/authConfig";
import { useAuthenticationCookie } from "@/composables/useAuthenticationCookie";
import { useUserStore } from "@/stores/user";
import { AccountInfo } from "@azure/msal-browser";
import { useStore } from "vuex";

interface UseMsalAuthenticationReturn {
  initializeMsal: () => Promise<void>;
  loginUser: (account: AccountInfo) => Promise<void>;
  signOut: () => Promise<void>;
  handleRedirectPromise: () => Promise<void>;
}

export function useMsalAuthentication(): UseMsalAuthenticationReturn {
  const userStore = useUserStore();
  const store = useStore();
  const { getAuthenticationCookieData, setAuthenticationCookieData } =
    useAuthenticationCookie();

  const initializeMsal = async () => {
    try {
      await msalInstance.initialize();
    } catch (error) {
      console.error("Error initializing MSAL:", error);
      // TODO: Add more specific error handling
    }
  };

  const loginUser = async (account: AccountInfo) => {
    try {
      await userStore.setMsalAccount(account);
      await setAuthenticationCookieData(account);
      if (store) {
        await store.dispatch("api/signIn", account);
        await store.dispatch("supplier/fetchSupplier");
      }
    } catch (error) {
      console.error("Error logging in user:", error);
      // TODO: Add more specific error handling
    }
  };

  const handleRedirectPromise = async () => {
    try {
      const tokenResponse = await msalInstance.handleRedirectPromise();
      const account = tokenResponse?.account ?? getAuthenticationCookieData();
      if (account) {
        await loginUser(account);
      } else {
        await msalInstance.loginRedirect(loginRequest);
      }
    } catch (error) {
      console.error("Error handling redirect promise:", error);
      // TODO: Add more specific error handling or logging if needed
    }
  };

  const signOut = async () => {
    try {
      const authenticationData = getAuthenticationCookieData();
      setAuthenticationCookieData(null);
      userStore.clearUserData();
      await msalInstance.logoutRedirect({
        account: authenticationData,
      });
    } catch (error) {
      console.error("Error signing out:", error);
      // TODO: Add more specific error handling
    }
  };

  return {
    initializeMsal,
    loginUser,
    signOut,
    handleRedirectPromise,
  };
}
