<template>
  <div class="info-tag">
    <img class="info-tag__icon" :src="iconSrc" :alt="'Icon alt'" />
    <div class="info-tag__text-wrapper">
      <div class="info-tag__title">{{ title }}</div>
      <div class="info-tag__subtitle">{{ subtitle }}</div>
    </div>
    <div>
      <button
        v-if="showRemoveButton"
        class="info-tag__close-button"
        @click="removeItem"
      >
        <img :src="closeIconSrc" alt="Remove" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";

defineProps({
  title: {
    type: [String, Number] as PropType<string | number>,
    required: true,
  },
  subtitle: {
    type: [String, Number] as PropType<string | number>,
    default: "",
  },
  iconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  showRemoveButton: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  closeIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
});

const emit = defineEmits(["remove"]);

const removeItem = () => {
  emit("remove");
};
</script>

<style lang="scss" scoped>
.info-tag {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  padding: 12px 16px;
  background-color: #fdfafe;
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  &__icon {
    display: inline-block;
    width: 32px;
    height: 32px;
  }

  &__text-wrapper {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }

  &__title {
    color: var(--Black, #1d1d1d);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
  }

  &__subtitle {
    color: #616161;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
  }

  &__close-button {
    border: none;
    background: #e4e4e4;
    display: flex;
    align-items: center;
    border-radius: 50%;
    margin-left: 12px;

    img {
      width: 16px;
      height: 16px;
    }
  }
}
</style>
