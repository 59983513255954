<template>
  <div class="item__item">
    <span class="item__label">{{ label }}</span>
    <span>{{ value }}</span>
  </div>
</template>

<script>
export default {
  name: "ContactInfoItem",
  props: {
    label: { type: String, required: true },
    value: { type: [String, Number], required: true },
  },
};
</script>

<style lang="scss">
$details-padding-bottom: 10px;

.item {
  &__item {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding-bottom: $details-padding-bottom;
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__label {
    font-weight: 400 !important;
  }
}
</style>
