import { PublicClientApplication, Configuration } from "@azure/msal-browser";

const b2cAuthority = `${import.meta.env.VITE_APP_B2C_AUTHORITY}${import.meta.env.VITE_APP_B2C_SIGNUPSIGNING_POLICY}`;
const b2cPolicies = {
  authorities: {
    signUpSignIn: {
      authority: b2cAuthority,
    },
  },
  forgotPassword: {
    authority: `${import.meta.env.VITE_APP_B2C_AUTHORITY}${import.meta.env.VITE_APP_B2C_RESET_POLICY}`,
  },
  authorityDomain: `${import.meta.env.VITE_APP_B2C_AUTHORITY_DOMAIN}`,
};

export const msalConfig: Configuration = {
  auth: {
    clientId: `${import.meta.env.VITE_APP_B2C_CLIENTID}`,
    authority: b2cPolicies.authorities.signUpSignIn.authority,
    knownAuthorities: [b2cPolicies.authorityDomain],
    redirectUri: import.meta.env.VITE_APP_REDIRECT_URI,
    postLogoutRedirectUri: import.meta.env.VITE_APP_REDIRECT_URI,
  },
  cache: {
    cacheLocation: "localStorage",
  },
  system: {
    iframeHashTimeout: 40000,
  },
};

export const msalInstance = new PublicClientApplication(msalConfig);

export const loginRequest = {
  scopes: ["openid", "profile"],
};
