import { UserApi, UserApiEndpoints } from "@/config/api/user/userApi";
import { executeApiRequest } from "@/services/api/apiService";
import { SupplierUser } from "@/stores/supplierUsers/types";
import {
  EditUserDTO,
  UserDTO,
  CreateUserDTO,
} from "@/custom-types/GeneralTypes";

const fetchSupplierUsers = async (supplierId: string) => {
  return executeApiRequest<SupplierUser[]>(
    {
      endpointKey: Symbol.for(UserApiEndpoints.FETCH_SUPPLIER_USERS),
      config: UserApi,
    },
    {
      pathParams: [supplierId],
    },
  );
};

const fetchCustomerByEmail = async (email: string) => {
  return executeApiRequest<SupplierUser>(
    {
      endpointKey: Symbol.for(UserApiEndpoints.GET_USER_BY_EMAIL),
      config: UserApi,
    },
    {
      pathParams: [email],
    },
  );
};

const getUserInfo = async (userId: string) => {
  return executeApiRequest<UserDTO>(
    {
      endpointKey: Symbol.for(UserApiEndpoints.GET_USER_INFO),
      config: UserApi,
    },
    {
      pathParams: [userId],
    },
  );
};

const editUserInfo = async (data: EditUserDTO) => {
  return executeApiRequest<string>(
    {
      endpointKey: Symbol.for(UserApiEndpoints.EDIT_USER_INFO),
      config: UserApi,
    },
    {
      data: data,
    },
  );
};

const createUser = async (data: CreateUserDTO) => {
  return executeApiRequest<string>(
    {
      endpointKey: Symbol.for(UserApiEndpoints.CREATE_USER),
      config: UserApi,
    },
    {
      data: data,
    },
  );
};

type UserServiceApi = () => {
  fetchSupplierUsers: typeof fetchSupplierUsers;
  fetchCustomerByEmail: typeof fetchCustomerByEmail;
  getUserInfo: typeof getUserInfo;
  editUserInfo: typeof editUserInfo;
  createUser: typeof createUser;
};

export const useUsersApi: UserServiceApi = () => {
  return {
    fetchSupplierUsers,
    fetchCustomerByEmail,
    getUserInfo,
    editUserInfo,
    createUser,
  };
};
