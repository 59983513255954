<template>
  <div class="user">
    <img class="user__icon" alt="Account icon" :src="AccountIcon" />
    <div v-if="user && organization" class="user__details">
      <div class="user__name">{{ userName }}</div>
      <div v-if="!state.editUser">
        <div class="user__email">{{ userEmail }}</div>
        <div class="user__phone">{{ userPhone }}</div>
        <button class="user__edit-button" @click.capture.stop="showEditUser">
          Endre detaljer
        </button>
      </div>
      <edit-user
        v-else
        :user="user"
        :show="state.editUser"
        :organization="organization"
        @updated-user="updateUser"
        @close="hideEditUser"
      />
    </div>
    <div v-else class="user__loader">
      <b-spinner type="grow" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { computed, reactive } from "vue";
import AccountIcon from "@/assets/img/account.svg";
import { User } from "@/custom-types/GeneralTypes";
import EditUser from "@/components/UserProfile/EditUser.vue";
import { Organization } from "@/stores/tender/types";

const emit = defineEmits(["updated-user"]);
const props = withDefaults(
  defineProps<{
    user?: User | null;
    organization?: Organization | null;
  }>(),
  {
    user: undefined,
    organization: undefined,
  },
);

const state = reactive({
  editUser: false,
});

const userName = computed(() => props.user?.name || "");
const userEmail = computed(() => props.user?.email || "");
const userPhone = computed(() => props.user?.phoneNumber || "");

const showEditUser = () => {
  state.editUser = true;
};

const hideEditUser = () => {
  state.editUser = false;
};
const updateUser = (updatedUser: User) => {
  emit("updated-user", updatedUser);
};
</script>

<style lang="scss" scoped>
.user {
  display: flex;
  padding: 0 0.75rem;

  &__icon {
    align-self: self-start;
  }

  &__details {
    margin-left: 0.75rem;
  }

  &__name {
    font-weight: 600;
    font-size: 1rem;
    line-height: 1;
  }

  &__email {
    margin-top: 0.5rem;
  }

  &__email,
  &__phone {
    font-weight: 400;
    font-size: 0.875rem;
  }

  &__edit-button {
    color: black;
    border: none;
    background-color: transparent;
    text-decoration: underline;
    font-weight: 600;
    font-size: 0.875rem;
    padding: 0;
    margin-top: 0.75rem;
  }

  &__loader {
    margin-left: 0.9375rem;
    display: flex;
    align-items: center;
  }
}
</style>
