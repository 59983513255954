<template>
  <div class="empty-state">
    <slot>
      <div class="empty-state__text">{{ text }}</div>
    </slot>
  </div>
</template>

<script>
export default {
  props: {
    text: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss" scoped>
.empty-state {
  background: $color-primary-pink;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 1.5rem 1rem;

  &__text {
    text-align: center;
    font-size: $font-size-sm;
  }
}
</style>
