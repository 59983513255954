import { useCmsStore } from "@/stores/cms";
import cmsService from "@/services/cms/cmsService";
import { SanityImageSource } from "@sanity/image-url/lib/types/types";
import { ImageUrlBuilder } from "@sanity/image-url/lib/types/builder";
import { ContentType } from "@/custom-types/CmsContentTypes";
import {
  Header,
  Pages,
  ServiceType,
  Certifications,
  SustainabilityGoals,
  PriceGroup,
} from "@/stores/cms/types";

export interface useCmsFns {
  fetchCmsContent: <
    T extends
      | Header
      | ServiceType[]
      | Pages
      | Certifications[]
      | SustainabilityGoals[]
      | PriceGroup[]
      | null,
  >(
    queryId: ContentType,
    params?: Record<string, string | number | boolean>,
  ) => Promise<T | T[] | undefined>;
  fetchAndSetCmsContent: (
    queryId: ContentType,
    params?: Record<string, string | number | boolean>,
  ) => Promise<void>;
  imageUrl(source: SanityImageSource): ImageUrlBuilder;
}

export function useCms(): useCmsFns & ReturnType<typeof useCmsStore> {
  const cmsStore = useCmsStore();

  const fetchCmsContent = async <
    T extends
      | Header
      | ServiceType[]
      | Pages
      | Certifications[]
      | SustainabilityGoals[]
      | PriceGroup[]
      | null,
  >(
    queryId: ContentType,
    params?: Record<string, string | number | boolean>,
  ): Promise<T | T[] | undefined> => {
    return await cmsService.fetchCmsContent<T>({ queryId, params });
  };

  const fetchAndSetCmsContent = async <
    T extends
      | Header
      | ServiceType[]
      | Pages
      | Certifications[]
      | SustainabilityGoals[]
      | PriceGroup[]
      | null,
  >(
    queryId: ContentType,
    params?: Record<string, string | number | boolean>,
  ) => {
    const content = await cmsService.fetchCmsContent<T>({ queryId, params });
    if (content !== undefined) {
      cmsStore.setContent(queryId, content as T);
    }
  };

  const imageUrl = (source: SanityImageSource): ImageUrlBuilder => {
    return cmsService.resolveImage(source);
  };

  return {
    fetchCmsContent,
    fetchAndSetCmsContent,
    imageUrl,
    ...cmsStore,
  };
}
