import { defaultUser } from "@/store/modules/api/commons";

export default {
  user: defaultUser,
  company: {
    companyName: "",
  },
  suppliers: {
    ready: false,
  },
  supplierUsers: null,
  form: {
    cached: false,
    isDisabled: false,
    property: {},
    service: {},
    contact: {},
  },
  tender: {},

  supplierInfo: {
    businessPhones: "",
    displayName: "",
    mail: "",
    mobilePhone: "",
    userPrincipalName: "",
    id: "",
    SupplierId: "",
  },
  supplierDetails: {},

  supplierTypes: [],
  propertyTypes: [],

  supplierSegmentsParams: {},
  supplierSegments: {},

  supplierTypePricingPackages: [],

  ranges: [],
  deletedRanges: [],
  editedRanges: [],

  pricing: [],
  deletedPrices: [],
  editedPrices: [],

  reloadPrices: false,
  isPostingPrices: false,

  savePriceReady: false,

  supplierMetadata: {
    id: null,
    SupplierId: null,
    SupplierTypeId: null,
    Description: "",
    Logo: "",
    NumEmployees: 0,
    Revenue: 0,
  },

  errors: [],
};
