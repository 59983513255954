import { defineStore } from "pinia";
import { markRaw, Component } from "vue";

interface SidePanelState {
  isOpen: boolean;
  component: Component | null;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  props: Record<string, any>;
}

export const useSidePanelStore = defineStore("sidePanel", {
  state: (): SidePanelState => ({
    isOpen: false,
    component: null,
    props: {},
  }),
  actions: {
    openPanel(
      title: string,
      component: Component,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      props: Record<string, any> = {},
    ) {
      this.isOpen = true;
      this.component = markRaw(component);
      this.props = { title, ...props };
    },
    closePanel() {
      this.isOpen = false;
    },
  },
});
