<template>
  <div v-if="supplierAddresses.length">
    <div
      v-for="(item, index) in supplierAddresses"
      :key="index"
      class="location-delivery-area-display"
    >
      <InfoTag
        :title="item?.address.address || ''"
        :subtitle="locationSubtext"
        :icon-src="locationIcon"
      />
      <InfoTag
        :title="item?.reservationKilometers || '0'"
        :subtitle="deliveryAreaSubtext"
        :icon-src="areaIcon"
      />
      <button
        v-if="isEditable"
        class="location-delivery-area-display__delete-button"
        @click.prevent="onDelete(index)"
      >
        <img :src="deleteIconSrc" alt="Delete Icon" />
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { PropType } from "vue";
import InfoTag from "./InfoTag.vue";
import { SupplierAddress } from "@/stores/supplier/types";

defineProps({
  supplierAddresses: {
    type: Array as PropType<SupplierAddress[]>,
    default: () => [],
    required: true,
  },
  locationIcon: {
    type: String as PropType<string>,
    default: "",
  },
  areaIcon: {
    type: String as PropType<string>,
    default: "",
  },
  locationSubtext: {
    type: String as PropType<string>,
    default: "",
  },
  deliveryAreaSubtext: {
    type: String as PropType<string>,
    default: "",
  },
  deleteIconSrc: {
    type: String as PropType<string>,
    default: "",
  },
  isEditable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
});

const emit = defineEmits(["delete"]);

const onDelete = (index: number) => {
  emit("delete", index);
};
</script>

<style lang="scss" scoped>
.location-delivery-area-display {
  display: grid;
  grid-template-columns: 2fr 1fr auto;
  grid-gap: 0.5rem;
  margin-bottom: 0.5rem;

  &__delete-button {
    background-color: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    margin: 0;
    transition: transform 0.2s ease-in-out;
    width: 4rem;

    &:hover {
      transform: scale(1.1);
    }

    img {
      width: 24px;
      height: 24px;
    }
  }
}
</style>
