<template>
  <b-modal
    v-model="showModal"
    :no-close-on-backdrop="false"
    centered
    hide-footer
    hide-header
  >
    <div class="confirmation">
      <div class="confirmation__status-icon-container">
        <slot name="status-icon">
          <img
            :src="statusIcon"
            class="confirmation__status-icon"
            alt="Status icon"
          />
        </slot>
      </div>
      <p class="confirmation__title">
        {{ cms.title }}
      </p>
      <p class="confirmation__description">
        {{ cms.subtitle }}
      </p>
      <div class="confirmation__button-section">
        <primary-button
          :uppercase="true"
          type="primary"
          @click="handlePrimaryButtonClick"
        >
          {{ cms.primaryButtonText }}
        </primary-button>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { rejectPriceRequestSuccessCms } from "@/components/RejectPriceRequestSuccessModal/RejectPriceRequestSuccessCms";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";

export default {
  components: { PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
  },
  emits: ["update:modelValue", "secondary-click", "primary-click"],
  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(show) {
        this.$emit("update:modelValue", show);
      },
    },
    cms() {
      return {
        title: rejectPriceRequestSuccessCms.successTitle,
        subtitle: rejectPriceRequestSuccessCms.successSubtitle,
        primaryButtonText: rejectPriceRequestSuccessCms.ok,
        secondaryButtonText: "",
      };
    },
    statusIcon() {
      return `/images/utility/round-check-mark.svg`;
    },
  },
  methods: {
    handleSecondaryButtonClick() {
      this.$emit("secondary-click");
    },
    handlePrimaryButtonClick() {
      this.$emit("primary-click");
    },
  },
};
</script>

<style lang="scss" scoped>
.confirmation {
  display: block;
  padding: 5.5rem 0 2.5rem;
  position: relative;
  text-align: center;
  font-weight: $font-weight-semi-bold;
  font-family: "Montserrat", serif;

  &__status-icon-container {
    position: absolute;
    top: -5.375rem;
    left: 50%;
    transform: translateX(-50%);
    width: 8rem;
    height: 8rem;
    background-color: $color-white;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &__status-icon {
    width: 100%;
  }

  &__title {
    font-weight: $font-weight-semi-bold;
    font-size: $font-size-2xl;
    color: $color-black;
    font-family: "Montserrat", serif;
  }

  &__description {
    font-weight: $font-weight-semi-bold;
    margin-top: 1rem;
    font-size: $font-size-base;
    color: $color-black;
    font-family: "Montserrat", serif;
  }

  &__button-section {
    display: grid;
    text-align: center;
    align-items: center;
    font-size: $font-size-base;
    font-weight: bold;
    font-family: "Montserrat", serif;
    margin: 4rem auto auto;
    max-width: 20%;
  }
}
</style>
