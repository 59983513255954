import {
  getTendersFromStore,
  mapToAddressTenders,
  createActiveTenders,
} from "@/utilities/offerUtilities";
import { RequestState } from "@/custom-types/GeneralTypes";
import { cloneDeep } from "lodash";

const isValidSupplierRequest = (
  supplierRequest,
  supplierId,
  states,
  includeArchived,
) => {
  const isMatchingSupplier = supplierRequest.supplierId === supplierId;
  const isMatchingState = states.includes(supplierRequest.requestState);
  const isArchivedState =
    supplierRequest.requestState === RequestState.ARCHIVED;
  const meetsArchiveCondition =
    isArchivedState ||
    (includeArchived ? supplierRequest.archived : !supplierRequest.archived);

  return isMatchingSupplier && isMatchingState && meetsArchiveCondition;
};

const filterTendersBySupplierAndStates = (
  tenders,
  supplierId,
  states,
  includeArchived = false,
) => {
  return tenders.filter((tender) =>
    tender.priceRequests.some((priceRequest) =>
      priceRequest.supplierRequests.some((supplierRequest) =>
        isValidSupplierRequest(
          supplierRequest,
          supplierId,
          states,
          includeArchived,
        ),
      ),
    ),
  );
};

// Getters
export default {
  getAllTenders: (state) => state.offers,

  getRequestedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const filteredTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [
        RequestState.PRICE_REQUESTED,
        RequestState.INFORMATION_REQUESTED,
        RequestState.LOST,
        RequestState.UNANSWERED,
        RequestState.REJECTED,
      ],
      false,
    );
    return mapToAddressTenders(filteredTenders);
  },

  getOfferedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const filteredTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [RequestState.OFFERED],
      false,
    );
    return mapToAddressTenders(filteredTenders);
  },

  getActiveOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const activeTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [RequestState.ACCEPTED],
      false,
    );
    return mapToAddressTenders(createActiveTenders(activeTenders));
  },

  getTerminatedOrArchivedOffers: (state, getters, rootState) => {
    const supplierId = rootState.supplier.supplier.id;
    const tenders = cloneDeep(getTendersFromStore(state));
    const terminatedOrArchivedTenders = filterTendersBySupplierAndStates(
      tenders,
      supplierId,
      [
        RequestState.COMPLETED,
        RequestState.REJECTED,
        RequestState.LOST,
        RequestState.ARCHIVED,
      ],
      true,
    );
    return mapToAddressTenders(terminatedOrArchivedTenders);
  },
};
