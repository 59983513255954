<template>
  <div>
    <div
      v-for="(listSection, index) in tendersList"
      :key="index"
      class="agreements-list__section"
    >
      <div v-if="listSection.title" class="agreements-list__title">
        {{ listSection.title }}
      </div>
      <template v-if="!loading">
        <base-list v-if="listSection.tenders.length">
          <supplier-tender-list-item
            v-for="tender in listSection.tenders"
            :key="tender.tenderId"
            :service-types-cms="serviceTypesCms"
            :tender="tender"
            :price-request-id="tender.priceRequests[0].id"
            :collapsed="isTenderCollapsed(tender)"
            :list-item-id="'row-' + tender.priceRequests[0].id"
            :supplier-users="supplierUsers"
            :supplier-id="supplierId"
            :feedback-viewed="getFeedbackViewedState(tender)"
            @clicked="toggleShowTenderDetails(tender)"
            @new-agreement-widget-clicked="handleWidgetClick"
            @customer-chose-another-supplier-widget-clicked="handleWidgetClick"
          >
            <template #collapseContent>
              <tenders-list-detail-content
                v-if="!isTenderCollapsed(tender)"
                :tender="tender"
                :supplier-id="supplierId"
                :supplier-users="supplierUsers"
                @scroll-to-row="handleScrollToRow"
              />
            </template>
          </supplier-tender-list-item>
        </base-list>
        <div v-else class="agreements-list__no-tenders">
          {{ noOffersText }}
        </div>
      </template>
      <div v-else class="agreements-list__loader">
        <b-spinner class="table-spinner align-middle" type="grow" />
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, computed, onMounted, watch } from "vue";
import { useStore } from "vuex";
import { useRoute, useRouter } from "vue-router";
import TendersListDetailContent from "./TendersListDetailContent.vue";
import {
  SupplierTenderListItem,
  BaseList,
} from "@really/really-tender-components";
import { parseCssStringToPx } from "@/utilities/stringUtils.ts";
import scssVariables from "@/assets/scss/_variables_alias.module.scss";
import { provideFeedbackPanel } from "@/composables/useFeedbackPanel";
import { TenderState } from "@/custom-types/GeneralTypes";
import { useFeedbackCookie } from "@/utilities/useFeedbackCookie";

const props = defineProps({
  tendersList: {
    type: Array,
    required: true,
  },
  loading: {
    type: Boolean,
    required: true,
    default: false,
  },
  supplierId: {
    type: Number,
    required: true,
  },
  supplierUsers: {
    type: Array,
    default: null,
  },
});

const store = useStore();
const route = useRoute();
const router = useRouter();

const { openFeedbackPanel } = provideFeedbackPanel();

const showTenderDetails = ref([]);
const noOffersText = ref("Ingen tilbud funnet");

const { getFeedbackViewedCookie } = useFeedbackCookie();

const serviceTypesCms = computed(() => store.state.cms.serviceTypes);

const STATIC_TENDER_STATES = [
  TenderState.COMPLETED,
  TenderState.OFFERED,
  TenderState.REJECTED,
];

onMounted(() => {
  const priceRequestId = parseInt(route.query.priceRequestId);
  if (priceRequestId) {
    showTenderDetails.value.push(priceRequestId);
  }
  initializeRowScroll();
});

const initializeRowScroll = () => {
  watch(
    () => props.loading,
    (newVal) => {
      if (!newVal) {
        setupSelectedTenderWatcher();
      }
    },
    { immediate: true },
  );
};

const setupSelectedTenderWatcher = () => {
  watch(
    route,
    (newRoute) => {
      const priceRequestId = parseInt(newRoute.query.priceRequestId);
      if (!priceRequestId) {
        return;
      }
      if (!showTenderDetails.value.includes(priceRequestId)) {
        showTenderDetails.value.push(priceRequestId);
      }
      scrollToRow(priceRequestId);
    },
    { immediate: true, deep: true },
  );
};

const scrollToRow = (id) => {
  const element = document.querySelector(`#row-${id}`);
  if (element) {
    const appHeaderHeight =
      parseCssStringToPx(scssVariables.headerTopBarHeight) +
      parseCssStringToPx(scssVariables.headerBottomBarHeight);
    const offsetFromHeader = 20;
    window.scrollTo({
      behavior: "smooth",
      top:
        element.getBoundingClientRect().top -
        document.body.getBoundingClientRect().top -
        (appHeaderHeight + offsetFromHeader),
    });
  }
};

const handleScrollToRow = () => {
  const priceRequestId = parseInt(route.query.priceRequestId);
  scrollToRow(priceRequestId);
};

const toggleShowTenderDetails = (tender) => {
  const priceRequest = tender.priceRequests[0];
  const matchingIndex = showTenderDetails.value.findIndex(
    (priceRequestId) => priceRequestId === priceRequest.id,
  );
  const query = {};
  if (matchingIndex >= 0) {
    showTenderDetails.value.splice(matchingIndex, 1);
  } else {
    showTenderDetails.value.push(priceRequest.id);
    query.priceRequestId = priceRequest.id;
  }
  router.replace({ query });
  openFeedbackPanel(tender.tenderId);
};

const handleWidgetClick = (tender) => {
  openFeedbackPanel(tender.tenderId);
  toggleShowTenderDetails(tender);
};

const isTenderCollapsed = (tender) => {
  return !showTenderDetails.value.includes(tender.priceRequests[0].id);
};

const getFeedbackViewedState = (tender) => {
  const baseKey = `feedbackViewed_${tender.tenderId}`;
  const feedbackViewedKey = STATIC_TENDER_STATES.includes(tender.tenderState)
    ? baseKey
    : `${baseKey}_${tender.tenderState}`;
  return getFeedbackViewedCookie(feedbackViewedKey);
};
</script>

<style lang="scss" scoped>
.agreements-list {
  &__section {
    margin-top: 3rem;
  }

  &__title {
    color: $color-primary-dark;
    text-transform: uppercase;
    text-align: left;
    font-weight: 800;
    margin-bottom: 1rem;
    font-size: 18px;
  }

  &__no-tenders {
    text-align: center;
    color: $color-primary-darker;
    font-weight: 700;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
  }

  &__loader {
    display: flex;
    justify-content: center;
    border-bottom: 2px solid $color-primary;
    padding: 0.75rem;
    color: $color-primary;
  }
}
</style>
