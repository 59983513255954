<template>
  <div class="customer-contact-information-wrapper">
    <div v-if="isLoading" class="customer-contact-information__spinner-wrapper">
      <b-spinner
        class="customer-contact-information__loader"
        type="grow"
        small
      />
    </div>
    <div v-else class="customer-contact-information">
      <h2 class="customer-contact-information__title">
        Kontaktinformasjon til kunde
      </h2>
      <div class="customer-contact-information__details">
        <contact-info-item
          v-for="(detail, index) in details"
          :key="index"
          :label="detail.label"
          :value="detail.value"
        />
      </div>
    </div>
  </div>
</template>

<script>
import ContactInfoItem from "./ContactInfoItem.vue";
export default {
  name: "CustomerContactInformation",
  components: { ContactInfoItem },
  props: {
    // add default values
    name: {
      type: String,
      default: "",
    },
    email: {
      type: String,
      default: "",
    },
    phoneNumber: {
      type: String,
      default: "",
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    details() {
      return [
        { label: "Navn", value: this.name },
        { label: "Epost", value: this.email },
        { label: "Telefon", value: this.phoneNumber },
      ];
    },
  },
};
</script>

<style scoped lang="scss">
$background: $color-primary-pink;
$padding-vertical: 24px;
$padding-horizontal: 32px;
$title-color: #000;
$title-font-size: $font-size-base;
$description-color: #000;
$description-font-size: $font-size-sm;
$description-margin: 16px;
$details-padding-bottom: 10px;

.customer-contact-information-wrapper {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 4px;
  background: $background;
  background-color: $background;
  margin-left: 10px;
  width: 100%;
  min-height: 180px;
}

.customer-contact-information {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 8px;
  border-radius: 4px;
  background: $background;
  padding: $padding-vertical $padding-horizontal;
  width: 100%;

  &__spinner-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
  }

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 3rem;
    height: 3rem;
    color: $color-primary;
    margin: auto;
  }

  &__title {
    color: $title-color;
    font-size: $title-font-size;
    font-weight: 600;
    text-align: left;
  }

  &__description {
    color: $description-color;
    font-size: $description-font-size;
    margin: $description-margin 0;
    font-weight: 400;
    text-align: left;
    font-style: italic;
  }

  &__details {
    font-size: $description-font-size;
    width: 100%;
  }
}
</style>
