<template>
  <div class="base-header">
    <div class="base-header__content">
      <tertiary-button
        class="base-header__back-button"
        variant="inverted"
        @click="goBack"
      >
        Tilbake
      </tertiary-button>
      <div class="base-header__offer-details">
        <img :src="`/images/document-white.svg`" alt="Document" />
        <div>
          <div class="base-header__offer-recipient">
            Tilbud til {{ offerRecipient }}
          </div>
          <div v-if="lastSaved" class="base-header__offer-last-saved">
            Sist lagret kl. {{ lastSaved }}
          </div>
        </div>
      </div>
      <div class="base-header__right-content">
        <slot name="right-content" />
      </div>
    </div>
  </div>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";

export default {
  components: { TertiaryButton },
  props: {
    offerRecipient: {
      type: String,
      required: true,
    },
    lastSaved: {
      type: [String, null],
      default: null,
    },
  },
  emits: ["go-back"],
  methods: {
    goBack() {
      this.$emit("go-back");
    },
  },
};
</script>

<style lang="scss" scoped>
.base-header {
  padding-top: 4rem;

  &__content {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    height: 4rem;
    z-index: 1000;
    background-color: #611f69;
    color: $color-white;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 4rem;
  }

  &__back-button {
    padding: 0.625rem 1rem;
  }

  &__offer-details {
    display: flex;
    align-items: center;
    gap: 0.5rem;
    text-align: left;
    font-weight: 400;
  }

  &__offer-recipient {
    font-size: $font-size-sm;
  }

  &__offer-last-saved {
    font-size: $font-size-xs;
    line-height: 1.18;
  }
}
</style>
