<template>
  <div class="activity-item" :class="containerClasses">
    <div class="activity-item__content">
      <img
        :src="`/images/document.svg`"
        alt="icon"
        class="activity-item__icon"
      />
      <div class="activity-item__title-section">
        <p class="activity-item__title">
          {{ activity.description }}
        </p>
        <p class="activity-item__text">
          {{ resolvedFrequency }} {{ limitedPeriod }}
        </p>
      </div>
      <amount-section :amount="activity.itemsCount" :text="pieceText" />
      <hr v-if="isServiceWithoutRobot" class="activity-item__vertical-line" />
      <amount-section
        v-if="isServiceWithoutRobot"
        :amount="formattedPrice(activity.pricePerItem)"
        :text="perPieceText"
      />
      <hr class="activity-item__vertical-line" />
      <amount-section :amount="formattedTotalPrice" :text="totalText" />
    </div>
    <div class="activity-item__billable-section">
      <amount-section :amount="totalBillablePrice" :text="totalBillableText" />
      <activities-costs-drop-down-menu
        v-if="!readOnly"
        class="activity-item__billable-section-menu"
        @edit-item="$emit('open-edit-item-modal', activity)"
        @delete-item="$emit('open-delete-item-modal', activity)"
      />
    </div>
  </div>
</template>

<script>
import ActivitiesCostsDropDownMenu from "./ActivitiesCostsDropDownMenu.vue";
import AmountSection from "./AmountSection.vue";
import { formatCurrency } from "@/utilities/numberUtils";
import { BillingCycle, RequestType } from "@/custom-types/types";
import { getCmsItemText } from "@/utilities/cmsUtilities";
import {
  resolveFrequency,
  serviceActivityCms,
} from "@/utilities/activityUtilities";

export default {
  name: "ActivityItem",
  components: { ActivitiesCostsDropDownMenu, AmountSection },
  props: {
    activity: {
      type: Object,
      required: true,
    },
    isFirst: {
      type: Boolean,
      default: false,
    },
    isLast: {
      type: Boolean,
      default: false,
    },
    fixedCostSectionCms: {
      type: Object,
      required: true,
    },
    activityFormCms: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
  },
  emits: ["open-delete-item-modal", "open-edit-item-modal"],
  data() {
    return {
      currency: "kr",
    };
  },
  computed: {
    containerClasses() {
      return {
        first: this.isFirst,
        last: this.isLast,
      };
    },
    itemCms() {
      const serviceActivityCmsData = serviceActivityCms(
        this.serviceActivitiesCms,
        this.activity.frequencyGroup,
      );
      return serviceActivityCmsData.activityItems.find(
        (item) => item.label === this.activity.itemsUnit,
      );
    },
    isServiceWithoutRobot() {
      return this.activity.requestType === RequestType.ServiceWithoutRobot;
    },
    pieceText() {
      return `${this.itemCms ? this.itemCms.title : this.activity.itemsUnit}`;
    },
    translatedBillingCycle() {
      return this.getTranslatedBillingCycle(this.activity.frequencyGroup);
    },
    perBillingCycle() {
      return this.translatedBillingCycle
        ? `per ${this.translatedBillingCycle}`
        : "";
    },
    perPieceText() {
      const itemUnit = this.itemCms
        ? this.itemCms.singular
        : this.activity.itemsUnit;
      const perBillingCycle = this.translatedBillingCycle
        ? `/${this.translatedBillingCycle}`
        : "";
      return `Per ${itemUnit}${perBillingCycle}`;
    },
    totalText() {
      return `${this.resolveCmsText("total")} ${this.perBillingCycle}`;
    },
    totalBillableText() {
      return `${this.resolveCmsText("totalBillable")} ${this.perBillingCycle}`;
    },
    totalPrice() {
      if (this.isServiceWithoutRobot) {
        return (
          this.activity.itemsCount * Math.round(this.activity.pricePerItem)
        );
      } else {
        return this.activity.baseAmount;
      }
    },
    formattedTotalPrice() {
      return `${formatCurrency(this.totalPrice)} ${this.currency}`;
    },
    totalBillablePrice() {
      const price =
        ((100 - this.activity.supplierCommission) / 100) *
        this.activity.baseAmount;
      return `${formatCurrency(price)} ${this.currency}`;
    },
    resolvedFrequency() {
      if (!this.activity.frequencyGroup) return "";
      const serviceActivityCmsData = serviceActivityCms(
        this.serviceActivitiesCms,
        this.activity.frequencyGroup,
      );
      return resolveFrequency(
        serviceActivityCmsData,
        this.activity.frequencyGroup,
        this.activity.frequency,
      );
    },
    limitedPeriod() {
      if (!this.activity.limitedPeriod) {
        return "";
      }
      let startMonth = this.activity.limitedPeriodStartMonth;
      let endMonth = this.activity.limitedPeriodEndMonth;
      if (typeof startMonth === "string" && startMonth.length >= 3) {
        startMonth = startMonth.slice(0, 3);
      }
      if (typeof endMonth === "string" && endMonth.length >= 3) {
        endMonth = endMonth.slice(0, 3);
      }
      return `(${startMonth} - ${endMonth})`;
    },
  },
  methods: {
    getTranslatedBillingCycle(billingCycle) {
      if (billingCycle === BillingCycle.Monthly) {
        return "mnd";
      }
      if (billingCycle === BillingCycle.Yearly) {
        return "gang";
      }
      return "";
    },
    resolveCmsText(label) {
      return getCmsItemText(this.fixedCostSectionCms.otherText, label);
    },
    formattedPrice(price) {
      return `${Math.round(price)} ${this.currency}`;
    },
  },
};
</script>

<style lang="scss" scoped>
$activity-border: 1px solid $color-primary-pink-darker;
$activity-bg: #fff;
$activity-section-bg: $color-primary-pink-dark;
$amount-color: #000000;
$label-color: #787878;
$line-bg: #9a9a9a;
$font-400: 400;
$font-600: 600;

.activity-item {
  display: flex;
  flex-direction: row;
  background: $activity-bg;
  border: $activity-border;

  &.first {
    border-top-left-radius: 4px;
    border-top-right-radius: 4px;
  }

  &.last {
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
  }

  &__content {
    display: flex;
    align-items: center;
    padding: 16px 16px 16px 23px;
    flex: 1 1 auto;
    min-width: 42rem;
  }

  &__title-section {
    display: flex;
    flex-direction: column;
    margin-right: auto;
    text-align: left;
    margin-left: 16px;
    align-items: baseline;
    min-width: 3rem;
  }

  &__title {
    font-weight: $font-600;
    font-size: $font-size-base;
    line-height: 20px;
    color: $amount-color;
    margin-bottom: 0.3rem;
    margin-top: 0.25rem;
    word-wrap: break-word;
    min-width: 285px;
    max-width: 285px;
  }

  &__text {
    font-weight: $font-400;
    font-size: $font-size-sm;
    line-height: 17px;
    color: $label-color;
    margin-bottom: 0.5rem;
  }

  &__vertical-line {
    width: 2px;
    height: 40px;
    background: $line-bg;
    margin-left: 16px;
    margin-right: 16px;
  }

  &__billable-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $activity-section-bg;
    box-shadow: inset 1px 0 0 $line-bg;
    align-items: center;
    padding: 0 16px 0 32px;
    gap: 24px;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    text-align: right;
    flex: 1 1 auto;

    &__menu {
      border-radius: 4px;
      margin-left: 8px;
    }
  }
}
</style>
