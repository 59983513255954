<template>
  <div class="error-layout">
    <b-row>
      <div>
        <p class="error-message ps-5 mb-0 text-left">
          {{ errorMessage || error }}
        </p>
      </div>

      <img class="robot-btm" :src="`/images/layout/${robotImg}`" alt="robot" />
    </b-row>
  </div>
</template>

<script>
export default {
  name: "ErrorMessage",
  props: {
    errorMessage: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      robotImg: "robot_error.svg",
      error: "Beklager feil her",
    };
  },
};
</script>

<style lang="scss" scoped>
.error-layout {
  background-color: #eeded7;
  border-radius: 0.6875em;
  padding-left: 2em;
  padding-right: 2em;
  padding-top: 1em;
  padding-bottom: 1em;
  position: relative;
}

.error-message {
  font-weight: $font-weight-bold;
  font-size: $font-size-base;
  color: $color-primary;
}

.robot-btm {
  width: 4rem;
  height: 4rem;
  position: absolute;
  bottom: 0;
}
// Small devices
@media (max-width: 768px) {
  .error-message {
    font-size: $font-size-sm;
  }
}
</style>
