<template>
  <div class="no-previous-offers">Ingen tidligere tilbud tilgjengelig</div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.no-previous-offers {
  background: $color-primary-pink;
  border: 1px solid $color-primary-pink-darker;
  border-radius: 4px;
  padding: 1.5rem 1rem;
  text-align: center;
  line-height: 1;
}
</style>
