<template>
  <div class="previous-offers">
    <div class="previous-offers__title">{{ title }}</div>
    <div
      v-if="reusableServiceOffers.length"
      class="previous-offers__offers-list"
    >
      <div
        v-for="offer in visibleOffers"
        :key="offer.id"
        class="previous-offers__offer-item"
      >
        <base-new-offer-item
          :title="offer.title"
          :subtitle="offer.subtitle"
          :right-text="offer.amount"
          :left-icon-src="leftIconSrc"
          :right-icon-src="rightIconSrc"
          @clicked="onOfferItemClick(offer.serviceOfferId, offer.tenderId)"
        />
      </div>
      <button
        v-if="showSeeMoreOffersButton"
        class="previous-offers__see-more-button"
        @click="seeMoreOffers"
      >
        {{ seeMoreText }}
      </button>
    </div>
    <div v-else class="previous-offers__no-offers">
      <no-previous-offers />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, ref } from "vue";
import Mustache from "mustache";
import NoPreviousOffers from "./NoPreviousOffers.vue";
import BaseNewOfferItem from "./BaseNewOfferItem.vue";
import { getIconUrl, getOtherText } from "@/utilities/cmsUtils";
import { useCmsStore } from "@/stores/cms";
import type { ServiceOffer, CloneableOffer } from "@/stores/tender/types";
import { ContentType } from "@/custom-types/CmsContentTypes";

interface ReusableOffer {
  id: number;
  tenderId: number;
  title: string;
  subtitle: string;
  amount: string;
  serviceOffer: ServiceOffer;
  serviceOfferId: number;
}

interface CmsServiceType {
  sysLabel?: string;
  label: string;
  name?: string;
  serviceCategories?: { label: string; title?: string }[];
}

interface Props {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  pageCms: Record<string, any>;
  cloneableOffers: CloneableOffer[];
  supplierId: number;
}

interface EmitEvents {
  (
    event: "new-offer",
    payload: { serviceOfferId: number; tenderId: number },
  ): void;
}

const cmsStore = useCmsStore();

const props = defineProps<Props>();
const emit = defineEmits<EmitEvents>();

const collapsedOffersCount = ref(4);
const showAllOffers = ref(false);

// Computed properties
const title = computed(() =>
  getOtherText(props.pageCms.otherText, "previousOffersTitle"),
);

const reusableServiceOffers = computed<ReusableOffer[]>(() =>
  props.cloneableOffers.map((offer): ReusableOffer => {
    return {
      id: offer.serviceOfferId,
      tenderId: offer.tenderId,
      title: offer.nickname || "Tilbud på tjenestetype",
      subtitle: formatSubtitle(offer),
      amount: "",
      serviceOffer: {} as ServiceOffer,
      serviceOfferId: offer.serviceOfferId,
    };
  }),
);

const visibleOffers = computed(() =>
  showAllOffers.value
    ? reusableServiceOffers.value
    : reusableServiceOffers.value.slice(0, collapsedOffersCount.value),
);

const showSeeMoreOffersButton = computed(
  () =>
    reusableServiceOffers.value.length > collapsedOffersCount.value &&
    !showAllOffers.value,
);

const leftIconSrc = computed(() =>
  getIconUrl(props.pageCms.icons, "reuseOfferIcon"),
);

const rightIconSrc = computed(() =>
  getIconUrl(props.pageCms.icons, "arrowRightIcon"),
);

const seeMoreText = computed(() => {
  const text = getOtherText(props.pageCms.otherText, "seeMoreOffersText");
  return Mustache.render(text, {
    count: visibleOffers.value.length,
    total: reusableServiceOffers.value.length,
  });
});

// Helper functions
const getTranslatedServiceCategory = (serviceCategoryLabel: string) => {
  const serviceTypesCms = cmsStore[
    ContentType.SERVICE_TYPES_CMS
  ] as CmsServiceType[];
  const matchedServiceType = findMatchingCmsServiceType(
    serviceTypesCms,
    serviceCategoryLabel,
  );
  const matchedServiceCategory = matchedServiceType
    ? findMatchingServiceCategory(matchedServiceType, serviceCategoryLabel)
    : null;

  if (matchedServiceType && matchedServiceCategory) {
    return `${matchedServiceType.name} - ${matchedServiceCategory.title}`;
  }
  return serviceCategoryLabel;
};

// Update formatSubtitle
const formatSubtitle = (offer: CloneableOffer): string => {
  const parts = [
    getTranslatedServiceCategory(offer.serviceCategoryLabel),
    offer.customerOrgName,
    offer.customerPropertyAddress,
  ].filter(Boolean);

  return parts.join(" - ");
};

const findMatchingCmsServiceType = (
  cms: CmsServiceType[],
  categoryLabel: string,
) =>
  cms.find((serviceType) =>
    serviceType.serviceCategories?.some(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (cat: any) => cat.label === categoryLabel,
    ),
  );

const findMatchingServiceCategory = (
  cmsServiceType: CmsServiceType,
  categoryLabel: string,
) =>
  cmsServiceType.serviceCategories?.find(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (cat: any) => cat.label === categoryLabel,
  );

// Event handlers
const seeMoreOffers = () => {
  showAllOffers.value = true;
};

const onOfferItemClick = (serviceOfferId: number, tenderId: number) => {
  emit("new-offer", { serviceOfferId, tenderId });
};
</script>

<style lang="scss" scoped>
.previous-offers {
  &__title {
    font-weight: 600;
    font-size: $font-size-base;
    color: $color-black;
  }

  &__no-offers {
    margin-top: 1rem;
  }

  &__offers-list {
    margin-top: 0.625rem;
  }

  &__offer-item {
    &:not(:first-child) {
      margin-top: 0.5rem;
    }
  }

  &__see-more-button {
    margin-top: 1rem;
    background: transparent;
    border: none;
    font-weight: 600;
    font-size: $font-size-sm;
    text-decoration: underline;
  }
}
</style>
