export enum ContentType {
  HEADER_CMS = "headerCms",
  HEADER_MENU_CMS = "headerMenuCms",
  SERVICE_TYPES_CMS = "serviceTypesCms",
  SUPPLIER_TASKS_PAGE_CMS = "supplierTasksPageCms",
  SUPPLIER_PROFILE_PAGE_CMS = "supplierProfilePageCms",
  SUPPLIER_PRICE_ROBOT_PAGE_CMS = "supplierPriceRobotPageCms",
  SUPPLIER_REGISTRATIONS_PAGE_CMS = "supplierRegistrationsPageCms",
  SUPPLIER_CERTIFICATIONS_CMS = "supplierCertificationsCms",
  SUPPLIER_SUSTAINABILITY_GOALS_CMS = "supplierSustainabilityGoalsCms",
  PRICE_GROUPS_CMS = "priceGroupsCms",
  SUPPLIER_WINDOW_CONSTRAINT_PAGE_CMS = "supplierWindowConstraintPageCms",
  SUPPLIER_LOADING_PAGE_CMS = "supplierLoadingPageCms",
  SERVICE_ACTIVITIES_CMS = "serviceActivitiesCms",
  EDIT_SUPPLIER_OFFER_PAGE_CMS = "editSupplierOfferPageCms",
  CREATE_OFFER_PAGE_CMS = "createOfferPageCms",
  TERMS_AND_CONDITIONS_PAGE_CMS = "termsAndConditionsPageCms",
  SEND_OFFER_MODAL_PAGE_CMS = "sendOfferModalPageCms",
  GIVE_OFFER_PAGE_CMS = "giveOfferPageCms",
  SUPPLIER_ERROR_PAGE_CMS = "supplierErrorPageCms",
  OFFER_ACCEPTANCE_REASONS_CMS = "offerAcceptanceReasonsCms",
}
