<template>
  <base-supplier-offer-modal
    v-model="showModal"
    :icon="icon"
    :title="deleteDraftOfferModalCms.title"
    :description="deleteDraftOfferModalCms.content"
    :align-items="'center'"
  >
    <!-- Buttons slot -->
    <template #buttons>
      <div class="modal__action-buttons">
        <tertiary-button :disabled="isSubmitting" @click="showModal = false">{{
          cancelButtonText
        }}</tertiary-button>
        <primary-button
          :is-submitting="isSubmitting"
          @click="deleteDraftOffer"
          >{{ deleteButtonText }}</primary-button
        >
      </div>
    </template>
  </base-supplier-offer-modal>
</template>

<script>
import TertiaryButton from "@/components/Buttons/TertiaryButton.vue";
import PrimaryButton from "@/components/Buttons/PrimaryButton.vue";
import BaseSupplierOfferModal from "./BaseSupplierOfferModal.vue";
import cmsService from "@/services/cmsService";

export default {
  components: { BaseSupplierOfferModal, TertiaryButton, PrimaryButton },
  props: {
    modelValue: {
      type: Boolean,
      required: true,
    },
    deleteDraftOfferModalCms: {
      type: Object,
      required: true,
    },
    messageDeletedText: {
      type: String,
      default: "",
    },
  },
  emits: ["delete-draft-offer", "update:modelValue"],
  data() {
    return {
      isSubmitting: false,
      showMessageDeletedText: false,
    };
  },

  computed: {
    showModal: {
      get() {
        return this.modelValue;
      },
      set(newVal) {
        this.$emit("update:modelValue", newVal);
      },
    },
    cancelButtonText() {
      const button =
        this.findItemByLabel(
          this.deleteDraftOfferModalCms.buttons,
          "cancelButton",
        ) ?? {};
      return button.title || "";
    },
    deleteButtonText() {
      const button =
        this.findItemByLabel(
          this.deleteDraftOfferModalCms.buttons,
          "deleteDraftButton",
        ) ?? {};
      return button.title || "";
    },
    icon() {
      const iconItem =
        this.findItemByLabel(
          this.deleteDraftOfferModalCms.icons,
          "deleteIcon",
        ) ?? {};
      const iconUrl = this.getIconUrl(iconItem.icon);
      return iconUrl || "";
    },
  },

  watch: {
    messageDeletedText(val) {
      if (val) {
        this.showMessageDeletedText = true;
        this.closeModalWithDelay();
      }
    },
  },

  methods: {
    findItemByLabel(array, label) {
      return array.find((item) => item.label === label);
    },
    getIconUrl(icon) {
      return icon ? cmsService.resolveImage(icon) : "";
    },
    deleteDraftOffer() {
      this.isSubmitting = true;
      this.$emit("delete-draft-offer");
      this.closeModalWithDelay();
    },
    closeModalWithDelay() {
      setTimeout(() => {
        this.isSubmitting = false;
        this.showModal = false;
      }, 3000);
    },
  },
};
</script>

<style lang="scss" scoped>
.modal {
  &__action-response-text {
    margin-top: 40px;
    font-weight: 600;
    font-size: $font-size-base;
    line-height: 20px;
    text-align: center;
  }
  &__action-buttons {
    margin-top: 56px;
    display: flex;
    justify-content: space-between;

    > button {
      padding: 1rem 2.5rem;
      font-weight: 600;
      font-size: $font-size-base;
      line-height: 20px;
    }
  }
}
</style>
