<template>
  <button
    class="profile-button"
    :class="{ 'profile-button--cancel': isCancel }"
    :disabled="disabled"
    @click="onClick"
  >
    <b-spinner v-if="loading" class="profile-button__spinner" />
    <slot />
  </button>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  isCancel: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["click"]);

const onClick = () => {
  if (!props.loading) {
    emit("click");
  }
};
</script>

<style lang="scss" scoped>
.profile-button {
  display: flex;
  height: 40px;
  padding: 0px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  background: var(--Yellow-Dark, #ecba4e);
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--Black, #1d1d1d);

  &:hover {
    background: rgba(236, 186, 78, 0.8);
  }

  &:active {
    background: rgba(236, 186, 78, 0.6);
  }

  &--cancel {
    background: none;
    border: 1px solid var(--Black, #1d1d1d);
    color: var(--Black, #1d1d1d);

    &:hover {
      background: rgba(0, 0, 0, 0.05);
    }

    &:active {
      background: rgba(0, 0, 0, 0.1);
    }
  }

  &__spinner {
    width: 16px;
    height: 16px;
  }
}
</style>
