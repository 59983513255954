<template>
  <div class="custom-block-content">
    <portable-text :value="blocks" />
  </div>
</template>

<script>
import { PortableText } from "@portabletext/vue";

export default {
  components: { PortableText },
  props: {
    blocks: {
      type: [Object, Array],
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-block-content {
  > p {
    margin-bottom: 0;
  }
}
</style>
