const updateBillablePriceDetails = (
  details,
  averagePricePerYearText,
  singleCostText,
) => {
  // Find index of 'Gjennomsnittlig pris per år'
  const averagePricePerYearIndex = details.findIndex(
    ({ text }) => text === averagePricePerYearText,
  );

  // Insert divider before 'Gjennomsnittlig pris per år'
  // if (!details[averagePricePerYearIndex - 1]?.isDivider) {
  //   details.splice(averagePricePerYearIndex, 0, {
  //     isDivider: true,
  //   });
  // }

  // Find index of 'Enkeltkostnader'
  const onlyOnceIndex = details.findIndex(
    ({ text }) => text === singleCostText,
  );

  // If 'Enkeltkostnader' is not in the details, return
  if (onlyOnceIndex === -1) return details;

  // Move 'Enkeltkostnader' immediately after 'Gjennomsnittlig pris per år'
  const [onlyOnceItem] = details.splice(onlyOnceIndex, 1);
  details.splice(averagePricePerYearIndex + 1, 0, onlyOnceItem);

  // If 'Enkeltkostnader' is in the details and it's price is the same as 'Gjennomsnittlig pris per år', remove it
  if (
    details[averagePricePerYearIndex + 1]?.price ===
    details[averagePricePerYearIndex]?.price
  ) {
    details.splice(averagePricePerYearIndex + 1, 1);
  }

  return details;
};

const updateBillablePriceSummaryDetails = (
  details,
  averagePricePerYearText,
  singleCostText,
  totalMonthlyText,
) => {
  const divider = true;
  const avgPricePerYear = details.find(
    ({ text }) => text === averagePricePerYearText,
  );

  const singleCost = details.find(({ text }) => text === singleCostText);
  const totalMonthly = details.find(({ text }) => text === totalMonthlyText);

  const remainingItems = details.filter(
    ({ text }) =>
      ![averagePricePerYearText, singleCostText, totalMonthlyText].includes(
        text,
      ) && !("isDivider" in details),
  );

  // Reassemble the list based on the specified order
  const newDetails = [];

  if (totalMonthly) newDetails.push(totalMonthly);
  newDetails.push(...remainingItems);

  if (divider) newDetails.push({ isDivider: true });
  if (avgPricePerYear) newDetails.push(avgPricePerYear);

  if (singleCost && avgPricePerYear?.price !== singleCost.price) {
    newDetails.push(singleCost);
  }

  return newDetails;
};

export { updateBillablePriceDetails, updateBillablePriceSummaryDetails };
