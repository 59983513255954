<template>
  <div class="service-card-category">
    <img class="service-card-category__icon" :src="iconSrc" :alt="'Icon alt'" />
    <div class="service-card-category__content">
      <label
        :class="{ 'checkbox--disabled': disabled }"
        class="checkbox"
        role="checkbox"
        :aria-checked="modelValue"
      >
        <span class="label-text">{{ title }}</span>
        <div class="checkmark-container">
          <div class="vertical-line" />
          <span class="checkmark">
            <img
              v-if="modelValue"
              :src="`/images/supplier-admin-metadata/CheckNegative.svg`"
              alt="checked icon"
              class="checkmark--checked"
            />
          </span>
        </div>
        <input
          :checked="modelValue"
          :disabled="disabled"
          class="checkbox"
          type="checkbox"
          @change="onInputChange"
        />
      </label>
    </div>
  </div>
</template>

<script lang="ts" setup>
defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
  modelValue: {
    type: Boolean,
    default: false,
  },
  title: {
    type: String,
    required: true,
  },
  iconSrc: {
    type: String,
    required: true,
  },
});

const emit = defineEmits(["update:modelValue"]);

const onInputChange = (event: Event) => {
  const target = event.target;
  if (target instanceof HTMLInputElement) {
    emit("update:modelValue", target.checked);
  }
};
</script>

<style lang="scss" scoped>
.service-card-category {
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  align-items: center;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  background: #fdfafe;
  height: 56px;

  &__icon {
    display: inline-block;
    width: 48px;
    height: 48px;
    padding-left: 16px;
  }

  &__content {
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
    width: 100%;
    height: 56px;
  }
}

.checkbox {
  --color: #7c3373;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  color: var(--color);
  width: 100%;

  &--disabled {
    --color: #6c757d;
    cursor: no-drop;
  }

  input[type="checkbox"] {
    display: none;
    opacity: 0 !important;
  }

  .label-text {
    overflow: hidden;
    color: var(--Dark-Purple, #441b49);
    text-overflow: ellipsis;
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: normal;
    width: 11rem;
  }

  .checkmark-container {
    display: flex;
    align-items: center;
    padding-right: 16px;
  }

  .vertical-line {
    width: 1px;
    height: 56px;
    background-color: var(--Black, #e1e1e1);
    margin-right: 1rem;
  }

  .checkmark {
    position: relative;
    width: 24px;
    height: 24px;
    border: 1px solid var(--Black, #1d1d1d);
    border-radius: 4px;

    &--checked {
      display: inline-block;
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translateY(-50%) translateX(-50%);
      width: 18px;
      height: 18px;
      border-radius: 4px;
    }
  }
}
</style>
