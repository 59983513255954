<template>
  <div v-if="showComponent" class="variable-cost-section">
    <!-- Title and description -->
    <div class="variable-cost-section__title">{{ title }}</div>
    <div class="variable-cost-section__description">
      <custom-block-content :blocks="descriptionContent" />
    </div>
    <!-- List of options & variable items -->
    <div
      v-if="localVariableCostActivities.length"
      class="variable-cost-section__list"
    >
      <div
        v-for="(activity, index) in localVariableCostActivities"
        :key="activity.id"
        class="variable-cost-section__list-item"
      >
        <variable-cost-item
          :key="componentKey"
          :title="activity.title"
          :description="activity.description"
          :activity="activity"
          :variable-cost-section-cms="variableCostSectionCms"
          :is-first="index === 0"
          :is-last="index === localVariableCostActivities.length - 1"
          :read-only="readOnly"
          :service-activities-cms="serviceActivitiesCms"
          @open-edit-item-modal="openNewActivityModal(activity)"
          @open-delete-item-modal="openDeleteActivityItemModal(activity)"
        />
      </div>
    </div>
    <!-- Empty state -->
    <div v-else class="variable-cost-section__empty-state">
      <empty-state :text="NoOptionsCostsText" />
    </div>
    <!-- Button that opens the modal -->
    <div v-if="!readOnly" class="variable-cost-section__new-point-button">
      <secondary-button
        :icon-src="newProductLineButtonIcon"
        :text="newProductLineButtonText"
        @click="openNewActivityModal"
      />
    </div>
    <!-- DeleteVariableCostItemModal -->
    <delete-variable-cost-item-modal
      v-if="activityToDelete"
      v-model="showDeleteActivityModal"
      :activity="activityToDelete"
      :delete-item-modal-cms="deleteItemModalCms"
      @delete-activity-item="handleDeleteActivityItem"
    />
    <!-- ActivityModal -->
    <activity-modal
      v-model="showActivityModal"
      :cost-type="ActivityCostType.Variable"
      :tender="tender"
      :activity="activityToEdit"
      :activity-form-cms="activityFormCms"
      :frequency-options="frequencyOptions"
      :service-activities-cms="serviceActivitiesCms"
      :supplier-info="supplierInfo"
      @new-activity-added="handleNewActivityAdded"
      @add-new-activity="activityToEdit = null"
    />
  </div>
</template>

<script>
import cmsService from "@/services/cmsService";
import EmptyState from "@/components/EmptyState/EmptyState.vue";
import SecondaryButton from "@/components/Buttons/SecondaryButton.vue";
import VariableCostItem from "./VariableCostItem.vue";
import DeleteVariableCostItemModal from "./DeleteItemModal/DeleteVariableCostItemModal.vue";
import CustomBlockContent from "@/components/CustomBlockContent/CustomBlockContent.vue";
import ActivityModal from "@/components/ActivityModal/ActivityModal.vue";
import { ActivityCostType } from "@/custom-types/types";
import { cloneDeep, isEqual, sortBy } from "lodash";

export default {
  name: "OptionsVariableCostsSection",
  components: {
    ActivityModal,
    CustomBlockContent,
    DeleteVariableCostItemModal,
    VariableCostItem,
    SecondaryButton,
    EmptyState,
  },
  props: {
    pageCms: {
      type: Object,
      required: true,
    },
    tender: {
      type: Object,
      required: true,
    },
    offer: {
      type: Object,
      required: true,
    },
    frequencyOptions: {
      type: Object,
      required: true,
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
    serviceActivitiesCms: {
      type: Array,
      required: true,
    },
    supplierInfo: {
      type: Object,
      required: true,
    },
  },
  emits: ["new-offer"],
  data() {
    return {
      showDeleteActivityModal: false,
      activityToDelete: null,
      newOptionFormCms: null,
      activityToEdit: null,
      showActivityModal: false,
      localVariableCostActivities: [],
      ActivityCostType: ActivityCostType,
      componentKey: 0,
    };
  },
  computed: {
    showComponent() {
      return !(this.readOnly && this.localVariableCostActivities.length === 0);
    },
    activityFormCms() {
      return this.pageCms.forms.find((form) => form.label === "activityForm");
    },
    variableCostSectionCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "variableCosts",
      );
    },
    title() {
      return this.variableCostSectionCms.title;
    },
    descriptionContent() {
      return this.variableCostSectionCms.content[0];
    },
    NoOptionsCostsText() {
      return this.variableCostSectionCms.otherText.find(
        (text) => text.label === "noOptionsAndVariableCostsAdded",
      ).title;
    },
    newProductLineButtonIcon() {
      const icon = this.variableCostSectionCms.buttons.find(
        (text) => text.label === "newProductLineButton",
      ).icons[0].icon;
      return this.getIconUrl(icon).toString() || "";
    },
    newProductLineButtonText() {
      return this.variableCostSectionCms.buttons.find(
        (text) => text.label === "newProductLineButton",
      ).title;
    },
    deleteItemModalCms() {
      return this.pageCms.pageSections.find(
        (section) => section.label === "deleteItemLinePopup",
      );
    },
  },
  watch: {
    "offer.variableCostActivities": {
      handler(newOfferActivities) {
        this.componentKey++;
        if (
          this.checkIfActivitiesAreEqual(
            newOfferActivities,
            this.localVariableCostActivities,
          )
        ) {
          return;
        }
        this.localVariableCostActivities = cloneDeep(newOfferActivities);
      },
      immediate: true,
      deep: true,
    },
    localVariableCostActivities: {
      handler() {
        if (
          this.checkIfActivitiesAreEqual(
            this.offer.variableCostActivities,
            this.localVariableCostActivities,
          )
        ) {
          return;
        }
        const offerCopy = cloneDeep(this.offer);
        offerCopy.variableCostActivities = cloneDeep(
          this.localVariableCostActivities,
        );
        this.$emit("new-offer", offerCopy);
      },
      deep: true,
    },
  },
  methods: {
    checkIfActivitiesAreEqual(activitiesA, activitiesB) {
      return isEqual(
        sortBy(activitiesA, (activity) => activity.id),
        sortBy(activitiesB, (activity) => activity.id),
      );
    },
    getIconUrl(icon) {
      return cmsService.resolveImage(icon);
    },
    openNewActivityModal(activity = null) {
      this.activityToEdit = activity;
      this.showActivityModal = true;
    },
    openDeleteActivityItemModal(activity) {
      this.activityToDelete = activity;
      this.showDeleteActivityModal = true;
    },
    handleDeleteActivityItem() {
      this.localVariableCostActivities =
        this.localVariableCostActivities.filter(
          (activity) => activity.id !== this.activityToDelete.id,
        );
      this.showDeleteActivityModal = false;
      this.activityToDelete = null;
    },
    handleNewActivityAdded(targetActivity) {
      console.log(
        "this.localVariableCostActivities",
        this.localVariableCostActivities,
      );
      const matchingActivity = this.localVariableCostActivities.find(
        (activity) => activity.id === targetActivity.id,
      );
      console.log(
        "matchingActivity",
        matchingActivity,
        "targetActivity",
        targetActivity,
      );
      if (matchingActivity) {
        Object.assign(matchingActivity, targetActivity);
      } else {
        this.localVariableCostActivities.push(targetActivity);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.variable-cost-section {
  margin-top: 40px;

  &__title {
    font-weight: 600;
    font-size: $font-size-lg;
    line-height: 22px;
    color: #1d1d1d;
    margin-bottom: 8px;
  }

  &__description {
    margin-top: 16px;
    font-weight: 400;
    font-size: $font-size-sm;
    line-height: 17px;
    color: #1d1d1d;
    margin-bottom: 16px;
  }

  &__list {
    margin-top: 16px;
  }

  &__empty-state {
    margin-top: 16px;
  }

  &__new-point-button {
    margin-top: 16px;
  }
}
</style>
@/types/AppTypes
