<template>
  <div class="company-profile-section">
    <SectionHeader
      v-bind="sectionHeaderProps"
      @edit="handleEdit"
      @info="toggleInfoText"
    />
    <SectionInfo
      v-if="showInfoText"
      v-bind="sectionInfoProps"
      @close="toggleInfoText"
    />
    <slot />
  </div>
</template>

<script setup lang="ts">
import { defineProps, PropType, ref, computed } from "vue";
import SectionHeader from "./SectionHeader.vue";
import SectionInfo from "./SectionInfo.vue";

const props = defineProps({
  headerText: {
    type: String as PropType<string>,
    required: true,
  },
  infoIconSrc: {
    type: String as PropType<string>,
    default: "",
    required: true,
  },
  editIconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  addButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  editButtonText: {
    type: String as PropType<string>,
    required: true,
  },
  editable: {
    type: Boolean as PropType<boolean>,
    default: false,
  },
  infoText: {
    type: String as PropType<string>,
    required: true,
  },
  closeIconSrc: {
    type: String as PropType<string>,
    required: true,
  },
  defaultText: {
    type: String as PropType<string>,
    default: "",
  },
});

const showInfoText = ref(false);

const emit = defineEmits(["edit"]);

const sectionHeaderProps = computed(() => ({
  headerText: props.headerText,
  infoIconSrc: props.infoIconSrc,
  editIconSrc: props.editIconSrc,
  editButtonText: props.editButtonText,
  showEditButton: props.editable,
}));

const sectionInfoProps = computed(() => ({
  infoText: props.infoText,
  closeIconSrc: props.closeIconSrc,
}));

const toggleInfoText = () => {
  showInfoText.value = !showInfoText.value;
};

const handleEdit = () => {
  emit("edit");
};
</script>

<style lang="scss" scoped>
.company-profile-section {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}
</style>
