import { ContentType } from "@/custom-types/CmsContentTypes";

interface Query {
  id: ContentType;
  query: string;
}

const queries: Query[] = [
  {
    id: ContentType.HEADER_CMS,
    query:
      "*[ _type == 'header' && label == 'supplierPortalHeader' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]",
  },
  {
    id: ContentType.HEADER_MENU_CMS,
    query:
      "*[ _type == 'header' && label == 'supplierPortalHeaderMenu' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]",
  },
  {
    id: ContentType.SUPPLIER_SUSTAINABILITY_GOALS_CMS,
    query:
      "*[ _type == 'sustainabilityGoals' && __i18n_lang == 'no' && !(_id in path('drafts.**')) ]",
  },
  {
    id: ContentType.SUPPLIER_CERTIFICATIONS_CMS,
    query:
      "*[ _type == 'certifications' && __i18n_lang == 'no' && !(_id in path('drafts.**')) ]",
  },
  // SupplierAdminPages-related Cms queries
  {
    id: ContentType.SUPPLIER_TASKS_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierTasksPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SUPPLIER_PROFILE_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierProfilePage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SUPPLIER_PRICE_ROBOT_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierPriceRobotPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SUPPLIER_REGISTRATIONS_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierRegistrationPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SUPPLIER_WINDOW_CONSTRAINT_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierWindowConstraintPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SUPPLIER_LOADING_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'supplierLoadingPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  // ServiceType-related Cms queries
  {
    id: ContentType.SERVICE_TYPES_CMS,
    query:
      "*[_type == 'serviceType' && __i18n_lang == $language && !(_id in path('drafts.**')) ]  | order(order asc) {serviceCategories[]->{selectOptions[]->,...},propertyTypes[]->,segmentGroup[]{segments[]{inputs[]{SelectOptions[]->,...},...},...},form[]{segments[]{inputs[]{SelectOptions[]->,...},...},...},..., serviceSubcategories[]{selectOptions[]->,...,...,...}}",
  },
  // Price-related Cms queries
  {
    id: ContentType.PRICE_GROUPS_CMS,
    query:
      "*[_type == 'priceGroup' && __i18n_lang == 'no' && !(_id in path('drafts.**')) ]",
  },
  // Offer-related Cms queries
  {
    id: ContentType.SERVICE_ACTIVITIES_CMS,
    query: `*[ _type == 'serviceActivity' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]{
      ...,frequencies[]->,activityItems[]->,
      }`,
  },
  {
    id: ContentType.EDIT_SUPPLIER_OFFER_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'editSupplierOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]{
      ...,
      forms[]{...,formSections[]{...,inputs[]{...,SelectOptions[]->}}},
      }`,
  },
  {
    id: ContentType.CREATE_OFFER_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'newOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.TERMS_AND_CONDITIONS_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'termsAndConditionsPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.SEND_OFFER_MODAL_PAGE_CMS,
    query: `*[ _type == 'pages' && label == 'sendOfferModalPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]`,
  },
  {
    id: ContentType.GIVE_OFFER_PAGE_CMS,
    query:
      "*[ _type == 'pages' && label == 'giveOfferPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]",
  },
  {
    id: ContentType.SUPPLIER_ERROR_PAGE_CMS,
    query:
      "*[ _type == 'pages' && label == 'supplierErrorPage' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]",
  },
  {
    id: ContentType.OFFER_ACCEPTANCE_REASONS_CMS,
    query:
      "*[ _type == 'pages' && label == 'offerAcceptanceReasons' && __i18n_lang == 'no' && !(_id in path('drafts.**'))]",
  },
];

const queriesMap: Record<ContentType, string> = queries.reduce(
  (map, query) => {
    map[query.id] = query.query;
    return map;
  },
  {} as Record<ContentType, string>,
);

export default queriesMap;
