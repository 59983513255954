<template>
  <div class="supplier-segment-prices">
    <price-robot-header :header-content-prop="headerContent" />

    <property-type-selector
      class="supplier-segment-prices__property-type-selector"
      @get-supplier-segments="getSupplierSegments"
    />

    <div
      v-if="isLoading"
      class="supplier-segment-prices__spinner-wrapper d-flex"
    >
      <b-spinner type="grow" class="supplier-segment-prices__spinner" large />
    </div>
    <div v-else class="row supplier-segment-prices__prices-table-wrapper">
      <div v-for="(prices, index) in segmentsPrices" :key="index">
        <price-matrix-table
          :key="componentPriceMatrixTableKey"
          :price-table-header="prices.segmentHeaders"
          :pricing-content="prices.ranges"
        />
      </div>
    </div>

    <price-robot-header :header-content-prop="generalPricingHeaderContent" />
    <price-robot-footer />

    <b-alert
      v-model="showDismissibleAlert"
      :variant="alertVariant"
      class="supplier-segment-prices__b-alert"
      dismissible
    >
      {{ alertText }}
    </b-alert>
  </div>
</template>

<script setup>
import { computed, ref, watch } from "vue";
import { useStore } from "vuex";
import PriceRobotHeader from "@/components/PriceRobot/PriceRobotHeader.vue";
import PriceRobotFooter from "@/components/PriceRobot/PriceRobotFooter.vue";
import PropertyTypeSelector from "@/components/PropertyTypeSelector/PropertyTypeSelector.vue";
import PriceMatrixTable from "./PriceMatrixTable.vue";
import myPricesService from "@/services/myPricesService";

const store = useStore();
const isLoading = ref(false);
const componentPriceMatrixTableKey = ref(0);

const filteredPrices = ref([]);
const showDismissibleAlert = ref(false);
const alertVariant = ref("");
const alertText = ref("");
const errorAlertText = ref("Noe gikk galt. Prøv igjen.");

const supplierId = computed(() => store.state.supplier.supplier.id);

const headerContent = computed(
  () => store.state.cms.myPrices.yourPrices.headerContent,
);

const generalPricingHeaderContent = computed(
  () => store.state.cms.myPrices.yourPrices.generalPriceContent.headerContent,
);

const segmentsPrices = computed(() => store.state.api.pricing);

const propertyTypeValues = computed(() =>
  Object.values(store.state.api.supplierSegmentsParams.propertyType),
);

watch(propertyTypeValues, (newValues) => {
  if (newValues.length > 0 && newValues[0]) {
    isLoading.value = true;
    setTimeout(() => {
      isLoading.value = false;
    }, 6000);
    fetchSupplierPrices();
  }
});

watch(
  () => store.state.api.supplierSegmentsParams,
  () => {
    store.dispatch("api/setYourPricesData", []);
    componentPriceMatrixTableKey.value++;
  },
);

watch(
  () => store.state.api.reloadPrices,
  (val) => {
    if (val) {
      store.dispatch("api/setYourPricesData", []);
      fetchSupplierPrices();
      store.dispatch("api/reloadSupplierPrices", false);
    }
  },
);

// Methods
const getSupplierSegments = (updatedSupplierSegmentsParams) => {
  store.dispatch("api/fetchSupplierSegments", updatedSupplierSegmentsParams);
};

const resolvePriceParams = () => {
  return {
    supplierType: store.state.api.supplierSegmentsParams.supplierType,
    propertyType: store.state.api.supplierSegmentsParams.propertyType.id
      ? store.state.api.supplierSegmentsParams.propertyType
      : store.state.api.propertyTypes[0],
    supplierId: supplierId.value,
  };
};

const fetchSupplierPrices = async () => {
  isLoading.value = true;
  const params = resolvePriceParams();

  const supplierTypePricingPackages =
    store.state.api.supplierTypePricingPackages;

  try {
    const response = await myPricesService.fetchSupplierPrices(params);
    if (response.status === 200) {
      filteredPrices.value = response.data;
    }

    if (
      response &&
      response.status === 200 &&
      supplierTypePricingPackages.length > 0
    ) {
      await fetchSupplierPriceGroups();
      // await fetchFixedSupplierPriceGroups();
    }
  } catch (error) {
    showUiFeedback("danger", errorAlertText);
  } finally {
    isLoading.value = false;
  }
};

const fetchSupplierPriceGroups = async () => {
  const params = resolvePriceParams();

  try {
    const response = await myPricesService.fetchSupplierPriceGroups(params);
    if (response.status === 200) {
      resolveSupplierPriceGroups(response.data);
    }
  } catch (error) {
    showUiFeedback("danger", errorAlertText);
  }
};

// const fetchFixedSupplierPriceGroups = async () => {
// 	const params = resolvePriceParams();

// 	try {
// 		const response =
// 			await myPricesService.fetchFixedSupplierPriceGroups(params);
// 		if (response.status === 200) {
// 			resolveSupplierPriceGroups(response.data);
// 		}
// 	} catch (error) {
// 		console.error(errorAlertText);
// 	}
// };

const resolveSupplierPriceGroups = (rangeGroupsArray) => {
  const formattedGroupRangesArray = rangeGroupsArray.map((priceGroup) =>
    resolveFormatRangesForPriceMatrixTable(priceGroup),
  );
  store.dispatch("api/setYourPricesData", formattedGroupRangesArray);
};

const resolveFormatRangesForPriceMatrixTable = (rangeGroup) => {
  rangeGroup["ranges"] = resolveGroupRanges(rangeGroup);
  // TODO: replace underscore rangeGroup["ranges"] = _.sortBy(rangeGroup["ranges"], "rangeFrom");
  rangeGroup.segmentHeaders = resolveSegmentHeaders(rangeGroup.segmentHeaders);

  return rangeGroup;
};

const resolveGroupRanges = (rangeGroup) => {
  return rangeGroup.ranges.map((rangeItem) => {
    const newRangeObj = {};

    rangeGroup.segmentHeaders.forEach((header) => {
      const rangeObj = resolveRangeObject(rangeItem, header);
      const key = resolveKeyForRangeObject(header);
      newRangeObj[key] = rangeObj.rangeDetails;
    });

    assignPricingPackages(rangeItem, rangeGroup, newRangeObj);
    return newRangeObj;
  });
};

const resolveRangeObject = (rangeItem, header) => {
  const segmentRange = rangeItem.find(
    (range) => range.segmentId === header.segmentId,
  );
  return {
    rangeDetails: formatRangeDetails(segmentRange),
  };
};

const formatRangeDetails = (rangeObj) => {
  // Return default string if rangeObj is not provided or falsy
  if (!rangeObj) return "∞";

  const rangeTo = rangeObj.rangeTo === -1 ? "∞" : rangeObj.rangeTo.toString();
  const rangeFrom = rangeObj.rangeFrom.toString();
  const unit = rangeObj.unit === "m2" ? "㎡" : rangeObj?.unit || "";

  return `${rangeFrom} - ${rangeTo} ${unit}`;
};

const assignPricingPackages = (rangeItem, rangeGroup, newRangeObj) => {
  // Assuming `store` and `filteredPrices` are defined in the outer scope
  store.state.api.supplierTypePricingPackages.forEach((pkg) => {
    const rangeIds = rangeItem.map((range) => range.rangeId);
    const segmentIds = rangeItem.map((range) => range.segmentId);

    // Check and set pricing package based on billing type
    if (
      newRangeObj.billingType === "Fixed" &&
      pkg.packageLabel === "Digital Heis"
    ) {
      newRangeObj[pkg.packageLabel] = resolvePricingPackageObj(
        rangeGroup,
        rangeIds,
        segmentIds,
        pkg,
        newRangeObj,
        "Fixed",
      );
    } else if (newRangeObj.billingType !== "Fixed") {
      newRangeObj[pkg.packageLabel] = resolvePricingPackageObj(
        rangeGroup,
        rangeIds,
        segmentIds,
        pkg,
        newRangeObj,
        "Yearly",
      );
    }

    // Filter and set pricing packages from supplier prices
    const filteredRangePrices = filteredPrices.value.filter((price) => {
      return (
        arraysContainsMatch(price.rangeId, rangeIds) &&
        arraysContainsMatch(price.segmentId, segmentIds)
      );
    });

    filteredRangePrices.forEach((rangePrice) => {
      if (
        rangePrice.packageId === pkg.packageId &&
        rangePrice.groupId === rangeGroup.groupId
      ) {
        const matchedPricePackage = JSON.parse(JSON.stringify(rangePrice));
        matchedPricePackage.rangeIds = rangeIds;
        newRangeObj[pkg.packageLabel] = matchedPricePackage;
      }
    });
  });
};

const resolveKeyForRangeObject = (header) => {
  return isNullLabel(header) ? header.segmentName : header.segmentLabel;
};

const resolvePricingPackageObj = (
  rangeGroup,
  rangeIds,
  segmentIds,
  pkg,
  newRangeObj,
  billingType,
) => {
  return {
    price: 0,
    kilometerPrice: 0,
    numberOfTrips: 0,
    groupId: rangeGroup.groupId,
    package: pkg.packageLabel,
    packageId: pkg.packageId,
    priceId: Math.floor(Math.random() * (1000000 - 990000) + 990000),
    rangeId: rangeIds,
    segmentId: segmentIds,
    unit: newRangeObj["unit"] || "",
    rangeIds: rangeIds,
    billingCycle: billingType,
  };
};

const arraysContainsMatch = (arr1, arr2) => {
  return arr2.every((id) => arr1.includes(id));
};

const resolveSegmentHeaders = (segmentHeaders) => {
  return segmentHeaders.map((header) =>
    isNullLabel(header) ? header.segmentName : header.segmentLabel,
  );
};

const isNullLabel = (segmentHeader) => {
  return segmentHeader.segmentLabel === null;
};

const showUiFeedback = (variant, text) => {
  alertVariant.value = variant;
  alertText.value = text;
  isLoading.value = false;
  showDismissibleAlert.value = true;
};
</script>

<style lang="scss" scoped>
$white-color: #fff;

.supplier-segment-prices {
  color: $color-primary-darker;
  background-color: $color-primary-pink;
  border-bottom: 0.375rem solid $white-color;
  padding-top: 3rem;

  &__property-type-selector {
    margin-top: 2rem;
  }

  &__prices-table-wrapper {
    max-width: 100vw;
    overflow-x: auto;
    padding-left: 2.5rem;
    padding-top: 1rem;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  &__prices-table-container {
    display: inline-flex;
    gap: 2rem;
  }

  &__spinner-wrapper {
    width: 100%;
    min-height: 10rem;
  }

  &__spinner {
    margin: auto;
    margin-top: 4rem;
    width: 3rem;
    height: 3rem;
  }

  &__b-alert {
    width: max-content;
    margin-top: 4rem;
    margin-left: 3rem;
    margin-bottom: 1rem;
    font-size: $font-size-sm;
    font-weight: 700;
  }
}
</style>
