<template>
  <button
    :class="buttonClasses"
    :disabled="disabled"
    :type="type"
    class="base-btn"
    data-testid="button"
  >
    <span
      v-if="hasIcon"
      class="base-btn__icon-container"
      data-testid="button-icon"
    >
      <slot name="icon">
        <img v-if="icon" :src="icon" class="base-btn__icon" alt="Button icon" />
      </slot>
    </span>
    <span v-if="text" :class="textClasses" data-testid="button-text">
      {{ text }}
    </span>
  </button>
</template>

<script setup lang="ts">
import { computed } from "vue";

type ButtonVariant = "primary" | "accent" | "secondary" | "tertiary";
type ButtonShape = "rectangle" | "rounded" | "square" | "circle";
type ButtonSize = "xs" | "sm" | "md";
type ButtonType = "button" | "submit" | "reset";

interface ButtonProps {
  icon?: string;
  text?: string;
  variant?: ButtonVariant;
  shape?: ButtonShape;
  size?: ButtonSize;
  disabled?: boolean;
  uppercase?: boolean;
  fullWidth?: boolean;
  active?: boolean;
  type?: ButtonType;
}

const props = withDefaults(defineProps<ButtonProps>(), {
  icon: "",
  text: "",
  variant: "accent",
  shape: "rounded",
  size: "md",
  disabled: false,
  uppercase: false,
  fullWidth: false,
  active: false,
  type: "button",
});

const buttonClasses = computed(() => [
  `base-btn--${props.variant}`,
  `base-btn--size-${props.size}`,
  `base-btn--${props.shape}`,
  {
    "base-btn--disabled": props.disabled,
    "base-btn--full-width": props.fullWidth,
    "base-btn--active": props.active,
  },
]);

const textClasses = computed(() => ({
  "base-btn__text--uppercase": props.uppercase,
}));

const hasIcon = computed(() => Boolean(props.icon.length > 0));
</script>

<style lang="scss" scoped>
$hover-shadow: 0 2px 10px 0 rgba($color-black, 0.25);

$variants: (
  "primary": (
    background: $color-primary,
    color: white,
    hover-background: $color-primary-dark,
    active-background: $color-black,
    disabled-background: $color-grey-dark,
  ),
  "accent": (
    background: $color-yellow-dark,
    color: $color-black,
    hover-background: $color-yellow-dark,
    active-background: $color-yellow-darker,
    disabled-background: $color-secondary-light,
  ),
  "secondary": (
    background: $color-secondary,
    color: $color-black,
    hover-background: $color-secondary-dark,
    active-background: $color-secondary-darker,
    disabled-background: $color-secondary,
  ),
  "tertiary": (
    background: transparent,
    color: $color-black,
    border-color: $color-black,
    hover-background: #eaeaea,
    disabled-border-color: $color-grey-dark,
  ),
);

$sizes: (
  "xs": 2rem,
  "sm": 2.5rem,
  "md": 3rem,
);

.base-btn {
  font-weight: 600;
  font-size: 0.75rem;
  border: none;
  display: flex;
  gap: 0.5rem;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 250ms ease;

  &__icon-container {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &:active:enabled {
    box-shadow: none;
  }

  &:disabled {
    cursor: no-drop;
  }

  @each $variant, $colors in $variants {
    &--#{$variant} {
      background-color: map-get($colors, background);
      color: map-get($colors, color);

      @if $variant == "tertiary" {
        border: 1px solid map-get($colors, border-color);
      }

      &:active:enabled {
        background-color: map-get($colors, active-background);
      }

      &:hover:enabled {
        box-shadow: $hover-shadow;
        background-color: map-get($colors, hover-background);
      }

      &:disabled {
        @if $variant == "tertiary" {
          border-color: map-get($colors, disabled-border-color);
          opacity: 0.3;
        } @else {
          background-color: map-get($colors, disabled-background);
        }
      }
    }
  }

  &--rounded,
  &--circle {
    border-radius: 9999px;
  }

  &--rectangle,
  &--square {
    border-radius: 4px;
  }

  &__text--uppercase {
    text-transform: uppercase;
  }

  &--full-width {
    width: 100%;
  }

  @each $size, $height in $sizes {
    &--size-#{$size} {
      height: $height;
      padding: 0 1.25rem;

      &.base-btn--square,
      &.base-btn--circle {
        width: $height;
        padding: 0;
      }
    }
  }

  &--active {
    &.base-btn--tertiary {
      background-color: #eaeaea;
    }
  }
}
</style>
