<template>
  <base-price-calculator
    :title="otherText.title"
    :description="otherText.description"
    :billable-price-title="otherText.billablePriceTitle"
    :billable-price-description="otherText.billablePriceDescription"
    :billable-price="billablePrice"
  >
    <div class="price-calculator">
      <div
        v-if="getInputErrorMessageToDisplay()"
        class="price-calculator__error"
      >
        <input-error :text="getInputErrorMessageToDisplay()" />
      </div>
      <div class="price-calculator__content">
        <div>
          <div class="price-calculator__item-count-label">
            {{ numberOfPiecesInputCms.title }}
          </div>
          <div class="price-calculator__item-count-input-wrapper">
            <base-text-and-select-input
              text-input-field-name="itemsCount"
              :select-options="pieceUnits"
            />
          </div>
        </div>
        <img :src="`/images/multiply.svg`" alt="Multiply icon" />
        <div>
          <div class="price-calculator__item-price-label">
            {{ pricePerItemLabel }}
          </div>
          <div class="price-calculator__item-price-input-wrapper">
            <v-field
              v-slot="{ componentField }"
              name="pricePerItem"
              :rules="number().required().moreThan(0)"
              :validate-on-input="true"
            >
              <base-input
                v-bind="componentField"
                :right-text="pricePerItemUnits"
              />
            </v-field>
          </div>
        </div>
        <img :src="`/images/equals.svg`" alt="Equals icon" />
        <div>
          <div class="price-calculator__result-label">
            {{ otherText.totalPriceTitle }}
          </div>
          <div class="price-calculator__result-wrapper">
            {{ formattedTotalPrice }}
            {{ otherText.totalPriceUnits }}
          </div>
        </div>
      </div>
    </div>
  </base-price-calculator>
</template>

<script setup>
import { computed, watch } from "vue";
import BaseTextAndSelectInput from "./BaseTextAndSelectInput.vue";
import BaseInput from "@/components/Base/BaseInput/BaseInput.vue";
import BasePriceCalculator from "./BasePriceCalculator.vue";
import {
  ActivityFrequency,
  ServiceActivityCmsLabelType,
} from "@/custom-types/types";
import { cloneDeep } from "lodash";
import { formatCurrency } from "@/utilities/numberUtils";
import { getCmsItem, getCmsItemText } from "@/utilities/cmsUtilities";
import InputError from "@/components/InputError/InputError.vue";
import Mustache from "mustache";
import { Field as VField } from "vee-validate";
import { number } from "yup";

const emit = defineEmits(["new-form-values"]);

const props = defineProps({
  formErrors: {
    type: Object,
    required: true,
  },
  formValues: {
    type: Object,
    required: true,
  },
  activityFormCms: {
    type: Object,
    required: true,
  },
  serviceActivitiesCms: {
    type: Array,
    required: true,
  },
  hasFrameAgreement: {
    type: Boolean,
    default: false,
  },
  frameAgreementCut: {
    type: Number,
    default: 0,
  },
});

const priceCalculatorCms = computed(() => {
  let cmsLabel = "timePriceCalculator";
  if (props.formValues.frequencyGroup === ActivityFrequency.Fixed) {
    cmsLabel = "singleCostPriceCalculator";
  }
  if (props.formValues.frequencyGroup === ActivityFrequency.Monthly) {
    cmsLabel = "monthlyPriceCalculator";
  }
  return getCmsItem(props.activityFormCms.formSections, cmsLabel);
});
const serviceActivityCms = computed(() => {
  let cmsLabel = ServiceActivityCmsLabelType.Yearly;
  if (props.formValues.frequencyGroup === ActivityFrequency.Fixed) {
    cmsLabel = ServiceActivityCmsLabelType.Fixed;
  }
  if (props.formValues.frequencyGroup === ActivityFrequency.Monthly) {
    cmsLabel = ServiceActivityCmsLabelType.Monthly;
  }
  return getCmsItem(props.serviceActivitiesCms, cmsLabel);
});

const numberOfPiecesInputCms = computed(() => {
  return getCmsItem(priceCalculatorCms.value.inputs, "numberOfPieces");
});
const pricePerPieceInputCms = computed(() => {
  return getCmsItem(priceCalculatorCms.value.inputs, "pricePerPiece");
});
const otherText = computed(() => {
  return {
    title: getCmsItemText(priceCalculatorCms.value.otherText, "title"),
    description: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "description",
    ),
    billablePriceTitle: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "billablePriceTitle",
    ),
    billablePriceDescription: Mustache.render(
      getCmsItemText(
        priceCalculatorCms.value.otherText,
        "billablePriceDescription",
      ),
      {
        reallyCut: supplierCommission.value,
      },
    ),
    billablePriceUnits: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "billablePriceUnits",
    ),
    totalPriceTitle: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "totalPriceTitle",
    ),
    totalPriceUnits: getCmsItemText(
      priceCalculatorCms.value.otherText,
      "totalPriceUnits",
    ),
  };
});
const pieceUnits = computed(() => {
  return serviceActivityCms.value.activityItems.map((item) => {
    return {
      label: item.title,
      value: item.label,
    };
  });
});
const isValid = computed(() => {
  return !props.formErrors.itemsCount && !props.formErrors.pricePerItem;
});
const totalPrice = computed(() => {
  if (!isValid.value) {
    return 0;
  }
  const itemCount = parseFloat(props.formValues.itemsCount);
  const itemPrice = parseFloat(props.formValues.pricePerItem);
  if (isNaN(itemCount) || isNaN(itemPrice)) {
    return 0;
  }
  return itemCount * itemPrice;
});
const formattedTotalPrice = computed(() => {
  return formatCurrency(totalPrice.value);
});
const supplierCommission = computed(() => {
  if (props.hasFrameAgreement) {
    return props.frameAgreementCut;
  }
  return props.formValues.supplierCommission;
});
const billablePrice = computed(() => {
  const price = ((100 - supplierCommission.value) / 100) * totalPrice.value;
  return `${formatCurrency(price)} ${otherText.value.billablePriceUnits}`;
});
const pricePerItemLabel = computed(() => {
  const inputTitles = getCmsItem(
    pricePerPieceInputCms.value.otherTextGroups,
    "inputTitles",
  );
  const inputTitle = inputTitles.texts.find(
    (text) => text.label === props.formValues.itemsUnit,
  );
  return inputTitle ? inputTitle.title : "";
});
const pricePerItemUnits = computed(() => {
  const itemCms = serviceActivityCms.value.activityItems.find(
    (item) => item.label === props.formValues.itemsUnit,
  );
  return `kr/${itemCms.unit}`;
});

watch(
  () => pieceUnits.value,
  () => {
    initializeSelectedPieceUnits();
  },
  { deep: true, immediate: true },
);

const getInputErrorMessageToDisplay = () => {
  let inputLabel = "";
  if (props.formErrors.itemsCount) {
    inputLabel = numberOfPiecesInputCms.value.title;
  } else if (props.formErrors.pricePerItem) {
    inputLabel = pricePerItemLabel.value;
  }
  if (!inputLabel) {
    return "";
  }
  const templateInputError = getCmsItemText(
    props.activityFormCms.otherText,
    "priceCalculatorInputError",
  );
  return Mustache.render(templateInputError, {
    inputLabel: `<span style="font-style: italic;font-weight: 600;">${inputLabel}</span>`,
  });
};
function initializeSelectedPieceUnits() {
  const pieceUnitsValues = pieceUnits.value.map((pieceUnit) => pieceUnit.value);
  if (!pieceUnitsValues.includes(props.formValues.itemsUnit)) {
    const formValuesCopy = cloneDeep(props.formValues);
    formValuesCopy.itemsUnit = pieceUnits.value[0].value;
    emit("new-form-values", formValuesCopy);
  }
}
</script>

<style lang="scss" scoped>
.price-calculator {
  &__error {
    margin-bottom: 1.5rem;
  }

  &__content {
    display: flex;
    align-items: flex-end;
    gap: 0.625rem;
  }

  &__item-price-input-wrapper {
    width: 11.5rem;
  }

  &__item-count-label,
  &__item-price-label,
  &__result-label {
    font-weight: 600;
    font-size: $font-size-sm;
  }

  &__result-wrapper {
    height: 2.75rem;
    width: 8.375rem;
    background: $color-primary-pink-dark;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 400;
    font-size: $font-size-sm;
  }

  &__item-count-input-wrapper,
  &__item-price-input-wrapper,
  &__result-wrapper {
    margin-top: 0.5rem;
  }
}
</style>
