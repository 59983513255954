import { reactive } from "vue";

export const useProfileSectionData = () => {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const profileSectionData: any = reactive({
    companyDescriptionSection: "",
    myServicesSection: [],
    locationDeliveryAreaSection: [],
    certificationsApprovalsSection: [],
    sustainabilityGoalsSection: [],
  });

  const updateProfileSectionData = (key: string, value: unknown) => {
    profileSectionData[key] = value;
  };

  const getProfileSectionData = (key: string) => {
    return profileSectionData[key];
  };

  return {
    profileSectionData,
    updateProfileSectionData,
    getProfileSectionData,
  };
};
