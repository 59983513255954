<template>
  <button class="add-location-button" :disabled="disabled" @click="onClick">
    <img
      :src="addLocationButtonIconSrc"
      class="add-location-button__img"
      alt="Plus Icon"
    />
    <slot />
  </button>
</template>

<script setup lang="ts">
import { defineProps, defineEmits } from "vue";

const props = defineProps({
  isCancel: {
    type: Boolean,
    default: false,
  },
  loading: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  addLocationButtonIconSrc: {
    type: String,
    default: "",
  },
});

const emit = defineEmits(["click"]);

const onClick = () => {
  if (!props.loading) {
    emit("click");
  }
};
</script>

<style lang="scss" scoped>
.add-location-button {
  display: flex;
  height: 40px;
  padding: 0px 20px;
  align-items: center;
  gap: 8px;
  border-radius: 24px;
  cursor: pointer;
  transition: background-color 0.3s;
  color: var(--Black, #1d1d1d);
  background: none;
  border: 1px solid var(--Black, #1d1d1d);
  color: var(--Black, #1d1d1d);

  img {
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: rgba(0, 0, 0, 0.05);
  }

  &:active {
    background: rgba(0, 0, 0, 0.1);
  }
}
</style>
