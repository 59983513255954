export default {
  b2cPolicies: {
    names: {
      signUpSignIn: import.meta.env.VITE_APP_B2C_SIGNUPSIGNING_POLICY,
      forgotPassword: import.meta.env.VITE_APP_B2C_RESET_POLICY,
    },
    authorities: {
      signUpSignIn: {
        authority:
          import.meta.env.VITE_APP_B2C_AUTHORITY +
          import.meta.env.VITE_APP_B2C_SIGNUPSIGNING_POLICY,
      },
      forgotPassword: {
        authority:
          import.meta.env.VITE_APP_B2C_AUTHORITY +
          import.meta.env.VITE_APP_B2C_RESET_POLICY,
      },
    },
    authorityDomain: import.meta.env.VITE_APP_B2C_AUTHORITY_DOMAIN,
  },
};
