<template>
  <div class="price-matrix-table">
    <b-table-simple responsive>
      <b-thead>
        <b-tr>
          <b-th v-for="field in fields" :key="field.key" :class="field.thClass">
            {{ field.label }}
          </b-th>
        </b-tr>
      </b-thead>
      <b-tbody>
        <b-tr
          v-for="(pricingContentRecord, index) in pricingContent"
          :key="index"
        >
          <b-td v-for="field in fields" :key="field.key" :class="field.tdClass">
            <template v-if="findPackage(field.key)">
              <price-package-inputs
                :pricing-package-inputs="pricingContentRecord[field.key]"
                class="price--matrix-table__package-inputs"
              />
            </template>
            <template v-else>
              {{ pricingContentRecord[field.key] }}
            </template>
          </b-td>
        </b-tr>
      </b-tbody>
    </b-table-simple>
  </div>
</template>

<script setup>
import { computed } from "vue";
import {
  BTableSimple,
  BThead,
  BTr,
  BTh,
  BTbody,
  BTd,
} from "bootstrap-vue-next";
import PricePackageInputs from "./PricePackageInputs";
import { useStore } from "vuex";

const props = defineProps({
  pricingContent: {
    type: Array,
    required: true,
  },
  priceTableHeader: {
    type: Array,
    required: true,
  },
});

const store = useStore();
const supplierType = store.state.api.supplierSegmentsParams.supplierType;

const pricingPackages = computed(() =>
  getPricingPackages(
    store.state.api.supplierTypePricingPackages,
    supplierType,
    props.priceTableHeader,
  ),
);
const fields = computed(() =>
  createFields(props.priceTableHeader, pricingPackages.value),
);

const findPackage = (packageLabel) => {
  return pricingPackages.value.find((p) => p.packageLabel === packageLabel);
};

const getPricingPackages = (
  supplierTypePricingPackages,
  supplierType,
  segmentHeaders,
) => {
  let pricingPackages = supplierTypePricingPackages;
  const isSupplierTypeMaintenance = supplierType === 2;
  const isHourlySegmentGroup = segmentHeaders.includes("Timer pr dag");

  if (isSupplierTypeMaintenance) {
    pricingPackages = isHourlySegmentGroup
      ? pricingPackages.filter((pkg) => pkg.packageId === 5)
      : pricingPackages.filter((pkg) => pkg.packageId !== 5);
  }

  return pricingPackages;
};

const createFields = (segmentHeaders, pricingPackages) => {
  // Create field definitions for segment headers
  const headerFields = segmentHeaders.map((header) => ({
    key: header,
    label: header,
    thClass: "segment-column-class",
    tdClass: "segment-column-class",
  }));

  // Create field definitions for pricing package columns
  const packageFields = pricingPackages.map((pkg) => ({
    key: pkg.packageLabel,
    label: pkg.packageLabel,
    thClass: "pricing-package-head-class",
    tdClass: "pricing-package-column-class",
  }));

  return [...headerFields, ...packageFields];
};
</script>

<style lang="scss" scoped>
.price-matrix-table {
  display: block;
  padding-top: 1rem;

  &__package-inputs {
    display: flex;
    min-height: 12rem;
    overflow-x: auto;
  }
}

table {
  width: 100%;
  display: block;
  overflow-x: auto;
}

thead {
  font-weight: bold;

  th {
    font-weight: bold;
    min-width: 9rem;
    font-size: 1rem;
    color: $color-primary-darker;
    background-color: $color-primary-pink;
  }
}

tbody {
  td,
  tr {
    font-weight: 400;
    font-size: $font-size-sm;
    color: $color-primary-darker;
    background-color: #fdfafe;
    border-top: 0.75rem solid #fdfafe;
  }
}

th,
td {
  border: none;
  padding: 0.2rem 2rem;

  &:first-child {
    padding-left: 0.2rem;
  }
}

.segment-column-class {
  min-width: 15rem;
}
</style>
