<template>
  <div id="app">
    <router-view v-slot="{ Component }">
      <component
        :is="Component"
        v-if="isLargeScreen && !isLoading"
        class="router-view"
      />
      <window-constraint v-else-if="!isLargeScreen && !isLoading" />
      <loading-view v-else />
    </router-view>
    <BaseSidePanel />
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import { useMediaQuery } from "@vueuse/core";
import { useMsalAuthentication } from "@/composables/useMsalAuthentication";
import WindowConstraint from "@/components/WindowConstraint/WindowConstraint.vue";
import LoadingView from "@/views/LoadingView/LoadingView.vue";
import { useStore } from "vuex";
import cmsService from "@/services/cmsService.js";
import { useSupplierStore } from "@/stores/supplier";
import BaseSidePanel from "@/components/BaseSidePanel/BaseSidePanel.vue";

const { initializeMsal, handleRedirectPromise } = useMsalAuthentication();

const isLargeScreen = useMediaQuery("(min-width: 1024px)");
const isLoading = ref(true);
const store = useStore();
const supplierStore = useSupplierStore();

const fetchServicesTypesCms = async () => {
  const serviceTypesCms = await cmsService.getServiceTypesContent();
  await store.dispatch("cms/saveServiceTypes", serviceTypesCms);
};

const executeNonBlockingCalls = () => {
  fetchServicesTypesCms();
  const supplier = store.getters["supplier/getSupplier"];
  const supplierId = supplier.id;
  if (supplierId) {
    store.dispatch("offers/fetchOffers", supplier.id);
  }
};

const initialize = async () => {
  try {
    await initializeMsal();
    await handleRedirectPromise();
    await store.dispatch("cms/initializeStore");
    await store.dispatch("cms/loadIndustryServices");
    await store.dispatch("api/fetchSupplierTypes");
    await supplierStore.fetchServiceTypes();
    await store.dispatch("api/fetchErrors");
    executeNonBlockingCalls();
  } catch (error) {
    console.log("HandleRedirectPromise error", error);
  } finally {
    isLoading.value = false;
  }
};
initialize();
</script>

<style lang="scss" scoped></style>
