<template>
  <div class="create-offer-view">
    <offer-view-base-header
      :offer-recipient="customerOrganizationName"
      @go-back="goBack"
    />
    <template v-if="pageCms">
      <div class="create-offer-view__title-wrapper">
        <h1 class="create-offer-view__title">{{ pageTitle }}</h1>
        <button
          class="create-offer-view__new-tab-button"
          @click="handleOnNewTabClick"
        >
          <img :src="`/images/new_tab.svg`" alt="new-tab" />
          Hvordan lage tilbud?
        </button>
      </div>

      <div class="create-offer-view__body">
        <blank-offer-item :page-cms="pageCms" @new-offer="createNewOffer" />
        <div
          v-if="cloneableOffers.length > 0"
          class="create-offer-view__previous-offers"
        >
          <reuse-previous-offers
            :page-cms="pageCms"
            :service-offers="serviceOffers"
            :cloneable-offers="cloneableOffers"
            :supplier-id="supplierInfo.id"
            @new-offer="createNewReusableOffer"
          />
        </div>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted } from "vue";
import { useRouter } from "vue-router";
import { RouteNames } from "@/router/types";

import OfferViewBaseHeader from "@/components/OfferViewBaseHeader/OfferViewBaseHeader.vue";
import BlankOfferItem from "./BlankOfferItem.vue";
import ReusePreviousOffers from "./PreviousOffers.vue";
import { useTenderApi } from "@/services/api/useTenderApi";
import cmsService from "@/services/cmsService";

import type {
  Tender,
  ServiceOffer,
  CloneableOffer,
} from "@/stores/tender/types";

const props = defineProps<{
  tender: Tender;
  supplierInfo: {
    id: number;
  };
}>();

const router = useRouter();
const { getCloneableOffers } = useTenderApi();

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const pageCms = ref<any>(null);
const cloneableOffers = ref<CloneableOffer[]>([]);

// Computed properties
const customerOrganizationName = computed(
  () => props.tender?.customerOrganization?.name,
);
const pageTitle = computed(() => pageCms.value?.title ?? "");

const serviceOffers = computed(() =>
  cloneableOffers.value.map((offer) => ({
    id: offer.serviceOfferId,
    tenderId: offer.tenderId,
    title: offer.nickname || "Tilbud på tjenestetype",
    subtitle: `${offer.serviceCategoryLabel} - ${offer.customerOrgName} - ${offer.customerPropertyAddress}`,
    serviceOffer: {
      id: offer.serviceOfferId,
      tenderId: offer.tenderId,
    },
  })),
);

// Methods
const fetchPageCms = async () => {
  const response = await cmsService.getCreateOfferPage();
  pageCms.value = response[0];
};

const fetchCloneableOffers = async () => {
  try {
    const serviceCategoryId = props.tender.priceRequests[0]?.serviceCategoryId;
    if (!serviceCategoryId) return;

    const offers = await getCloneableOffers({
      supplierId: props.supplierInfo.id,
      serviceCategoryId: Number(serviceCategoryId),
    });
    cloneableOffers.value = offers.data;
  } catch (error) {
    console.error("Error fetching cloneable offers:", error);
  }
};

const createNewReusableOffer = ({
  serviceOfferId,
  tenderId,
}: {
  serviceOfferId: number;
  tenderId: number;
}) => {
  const query = serviceOfferId
    ? { "reusable-offer": serviceOfferId, "reusable-tender": tenderId }
    : {};

  router.push({
    name: RouteNames.EDIT_OFFER,
    query,
  });
};

const createNewOffer = (reusableServiceOffer: ServiceOffer | null = null) => {
  const query = reusableServiceOffer
    ? { "reusable-offer": reusableServiceOffer.id }
    : {};

  router.push({
    name: RouteNames.EDIT_OFFER,
    query,
  });
};

const goBack = () => router.back();

const handleOnNewTabClick = () => {
  window.open(import.meta.env.VITE_APP_NEED_HELP_OFFER_URL, "_blank");
};

// Lifecycle
onMounted(() => {
  fetchPageCms();
  fetchCloneableOffers();
});
</script>

<style lang="scss" scoped>
.create-offer-view {
  padding: 4.75rem 0;

  &__title-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    max-width: 37.125rem;
    margin: 3rem auto 0;
  }

  &__title {
    font-weight: 600;
    font-size: $font-size-2xl;
  }

  &__new-tab-button {
    display: flex;
    height: 40px;
    padding: 0px 16px;
    align-items: center;
    gap: 8px;
    border-radius: 4px;
    background: var(--Yellow-Dark, #ecba4e);
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
    color: var(--Black, #1d1d1d);
    font-weight: 700;

    img {
      width: 24px;
      height: 24px;
    }

    &:hover {
      background: rgba(236, 186, 78, 0.8);
    }

    &:active {
      background: rgba(236, 186, 78, 0.6);
    }
  }

  &__body {
    max-width: 37.125rem;
    margin: 3rem auto 0;
  }

  &__previous-offers {
    margin-top: 2.75rem;
  }
}

:deep(*) {
  font-family: "Montserrat", sans-serif;
  text-align: left;
}
</style>
